import React, { useState, useEffect } from 'react';
import styles from './ProfileMenu.module.scss';  // Importing the styles object
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ProfileMenu = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>('MY SKIN RESUME');
    const location = useLocation();
    const {t} = useTranslation()

    // Update selected menu item based on the current route
    useEffect(() => {
        const path = location.pathname;

        if (path.includes('favorites')) {
            setSelectedMenuItem('FAVORITES');
        } else if (path.includes('saved-articles')) {
            setSelectedMenuItem('SAVED ARTICLES');
        } else if (path.includes('my-orders')) {
            setSelectedMenuItem('MY ORDERS');
        } else if (path.includes('settings')) {
            setSelectedMenuItem('SETTINGS');
        } else if (path.includes('vouchers')) {
            setSelectedMenuItem('VOUCHERS');
        } else {
            setSelectedMenuItem('MY SKIN RESUME');
        }
    }, [location.pathname]);

    const handleMenuClick = (item: string) => {
        setSelectedMenuItem(item);
    };

    return (
        <div className={styles.menu}>  {/* Applying class via styles */}
            <ul>
                <li onClick={() => handleMenuClick('MY SKIN RESUME')} className={selectedMenuItem === 'MY SKIN RESUME' ? styles.selected : ''}>
                    <Link to="/profile">{t('profileMenu.mySkinResume')}</Link>
                </li>

                <li className={selectedMenuItem === 'FAVORITES' ? styles.selected : ''}>
                    <Link to="/profile/favorites">
                        <span>{t('profileMenu.favorites')}</span>
                    </Link>
                </li>
                <li className={selectedMenuItem === 'SAVED ARTICLES' ? styles.selected : ''}>
                    <Link to="/profile/saved-articles">
                        <span>{t('profileMenu.savedArticles')}</span>
                    </Link>
                </li>
                <li onClick={() => handleMenuClick('MY ORDERS')} className={selectedMenuItem === 'MY ORDERS' ? styles.selected : ''}>
                    <Link to="/profile/my-orders">{t('profileMenu.myOrders')}</Link>
                </li>

                <li onClick={() => handleMenuClick('SETTINGS')} className={selectedMenuItem === 'SETTINGS' ? styles.selected : ''}>
                    <Link to="/profile/settings">{t('profileMenu.settings')}</Link>
                </li>

                <li onClick={() => handleMenuClick('VOUCHERS')} className={selectedMenuItem === 'VOUCHERS' ? styles.selected : ''}>
                    <Link to="/profile/vouchers">{t('profileMenu.vouchers')}</Link>
                </li>
            </ul>
        </div>
    );
};
