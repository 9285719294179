import React, { useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import GeneralButton from '../../IngredientsScan/GeneralButton';
import HowItWorksButton from '../../IngredientsScan/HowItWorksButton';
import ScanModal from '../../IngredientsScan/ScanModal';
import scanIcon from '../../../assets/icons/scanIcon.svg';
import { useNavigate } from 'react-router-dom';
import BetaBadge from 'components/IngredientsScan/BetaBadge/BetaBadge';

interface IngredientScannerPreviewProps {
  sectionRef: (el: HTMLDivElement | null) => void;
  magnetScrollingStyle: React.CSSProperties;
}

function LandingStep({ sectionRef, magnetScrollingStyle }: IngredientScannerPreviewProps) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  function handleNavigate() {
    navigate('/ingredients-scan');
  }

  const isMobile = window.innerWidth < 1001;

  const renderMobile = () => (
    <div ref={sectionRef} style={magnetScrollingStyle}>
      <div className={styles.container}>
        <div>
          <h1 className={styles.title}>{t('ingredientsScan.landing.title')}</h1>
          <p className={styles.subtitle}>{t('ingredientsScan.landing.subtitle')}</p>
        </div>
        <div className={styles.steps}>
          <div>
            <p className={styles.step}>{t('ingredientsScan.landing.steps.step1')}</p>
            <p className={styles.step}>{t('ingredientsScan.landing.steps.step2')}</p>
            <p className={styles.step}>{t('ingredientsScan.landing.steps.step3')}</p>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <GeneralButton text={t('ingredientsScan.landing.scanButton')} icon={scanIcon} onClick={handleNavigate} />
          <HowItWorksButton onClick={() => setIsModalOpen(true)} />
        </div>
        <BetaBadge />
      </div>
    </div>
  );

  const renderDesktop = () => (
    <div ref={sectionRef} style={magnetScrollingStyle}>
      <div className={styles.container}>
        <div>
          <h1 className={styles.title}>{t('ingredientsScan.landing.title')}</h1>
          <p className={styles.subtitle}>{t('ingredientsScan.landing.subtitle')}</p>
        </div>
        <div className={styles.steps}>
          <div>
            <p className={styles.step}>{t('ingredientsScan.landing.steps.step1')}</p>
            <p className={styles.step}>{t('ingredientsScan.landing.steps.step2')}</p>
            <p className={styles.step}>{t('ingredientsScan.landing.steps.step3')}</p>
            <div className={styles.scanButtonContainer}>
              <GeneralButton text={t('ingredientsScan.landing.scanButton')} icon={scanIcon} onClick={handleNavigate} />
            </div>
          </div>
        </div>
      </div>
      <BetaBadge />
      <div className={styles.buttonsContainer}>
        <HowItWorksButton onClick={() => setIsModalOpen(true)} />
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? renderMobile() : renderDesktop()}
      {isModalOpen && <ScanModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} handleSetNextStep={handleNavigate} />}
    </>
  );
}

export default LandingStep;
