import React, { useEffect, useState } from 'react';
import styles from './PaymentSuccess.module.scss';
import valentineDaysBack from '../../assets/images/valentineDaysBack.jpg';
import profileSelectionIcon from '../../assets/images/profileSelectionIcon.png';
import globalService from '../../service/GlobalService';
import userApi from 'service/API/UserApi';
import { userAtom } from '../../stores/UserProfile';
import { useAtom } from 'jotai';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
    const [user, setUser] = useAtom(userAtom);
    const [isAnonymousUser, setIsAnonymousUser] = useState(!user || Object.keys(user).length === 0);
    const isMobile = window.innerWidth < 768;
    const {t} = useTranslation()

    const navigate = useNavigate();

    // Call the function when the component renders
    useEffect(() => {
        globalService.handleUserCartAfterPayment(isAnonymousUser, user, setUser);
    }, [isAnonymousUser]);

    useEffect(() => {
        if (!isAnonymousUser) {
            userApi.clearUserCartInDb(user?._id as string);
        }

    }, [isAnonymousUser]);

    useEffect(() => {
        if (!user || Object.keys(user).length === 0) {
            setIsAnonymousUser(true);
        }
        else {
            setIsAnonymousUser(false);
        }
    }, [user]);

    const handleBackToShop = () => {
        window.location.href = '/shop';
    };

    const handleViewOrders = () => {
        navigate('/profile/my-orders');
    };

    return (
        <>
            <HomepageNavbar />
            {isMobile ? (
                <div className={styles.mobile_success_checkoutWithHeaderContainer}>
                    <div className={styles.mobile_success_completeOrderContainer}>
                        {/* Image container */}
                        <div className={styles.mobile_success_imageContainer}>
                            <img
                                src={valentineDaysBack}
                                alt="Valentine Days Back"
                                className={styles.mobile_success_valentineDaysBack}
                            />
                        </div>
                        {/* Text and logo container */}
                        <div className={styles.mobile_success_textLogoContainer}>
                            <div className={styles.mobile_success_checkoutSuccessIconContainer}>
                                <img
                                    src={profileSelectionIcon}
                                    alt="Checkout Success"
                                    className={styles.mobile_success_checkoutSuccessIcon}
                                />
                            </div>
                            <p className={styles.mobile_success_completeOrderTitle}>{t('paymentSuccess.orderIsTitle')}</p>
                            <p className={styles.mobile_success_completeOrderTitle}>{t('paymentSuccess.completedTitle')}</p>
                            <p className={styles.mobile_success_completeOrderSubtitle}>{t('paymentSuccess.thankYou')}</p>
                            {user && (<div className={styles.viewOrdersContainer}>
                                <span className={styles.viewOrdersText} onClick={handleViewOrders}>{t('paymentSuccess.viewOrder')}</span>
                            </div>)}
                            <div className={styles.mobile_success_backToShopButtonContainer}>
                                <button
                                    className={styles.mobile_success_backToShopButton}
                                    onClick={handleBackToShop}
                                >
                                    {t('paymentSuccess.backToShop')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.success_checkoutWithHeaderContainer}>
                    <div className={styles.success_completeOrderContainer}>
                        {/* Image container */}
                        <div className={styles.success_imageContainer}>
                            <img
                                src={valentineDaysBack}
                                alt="Valentine Days Back"
                                className={styles.success_valentineDaysBack}
                            />
                        </div>
                        {/* Text and logo container */}
                        <div className={styles.success_textLogoContainer}>
                            <div className={styles.success_checkoutSuccessIconContainer}>
                                <img
                                    src={profileSelectionIcon}
                                    alt="Checkout Success"
                                    className={styles.success_checkoutSuccessIcon}
                                />
                            </div>
                            <p className={styles.success_completeOrderTitle}>{t('paymentSuccess.orderCompleted')}</p>
                            <p className={styles.success_completeOrderSubtitle}>
                                {t('paymentSuccess.thankYou')}
                            </p>
                            <div className={styles.success_backToShopButtonContainer}>
                                <button
                                    className={styles.success_backToShopButton}
                                    onClick={handleBackToShop}
                                >
                                    {t('paymentSuccess.backToShop')}
                                </button>
                            </div>
                            {user && (<div className={styles.viewOrdersContainer}>
                                <span className={styles.viewOrdersText} onClick={handleViewOrders}>{t('paymentSuccess.viewOrder')}</span>
                            </div>)}
                        </div>
                    </div>
                </div>
            )}
        </>
    );

};

export default PaymentSuccess;
