import React, { useState, useEffect } from 'react';

interface ImageWithFallbackProps {
    src: string;
    alt: string;
    className: string;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = React.memo(({
    src,
    alt,
    className
}) => {
    const [loaded, setLoaded] = useState(false);
    const [showImage, setShowImage] = useState(false);

    useEffect(() => {
        if (loaded) {
            const timer = setTimeout(() => {
                setShowImage(true);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [loaded]);

    return (
        <>
            <img
                src={src}
                alt={alt}
                className={className}
                style={{
                    opacity: 1,
                }}
                onLoad={() => setLoaded(true)}
            />
            <div className="article-image-overlay"></div>
        </>
    );
});