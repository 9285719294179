import styles from './style.module.scss';
import { useAtom } from 'jotai';
import { userAtom } from '../../stores/UserProfile';
import { setLocalStorageItem } from '../../utils/localStoragUtil';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SignUpBackgroudImage from '../../assets/images/headSectionBackground.jpg';
import blackLogo from '../../assets/icons/blackLogo.svg';
import globalService from '../../service/GlobalService';
import { loadLocalCart, deleteLocalCart } from '../../service/localCartService';
import UserApi from 'service/API/UserApi';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import vIcon from '../../assets/images/vIcon.png'
import { validateEmail } from '../../utils/inputValidationUtil';
import { useTranslation } from 'react-i18next';

export const LogInPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [, setUser] = useAtom(userAtom);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  useEffect(() => {
    const state = location.state as { errorMessage?: string, returnPath?: string };
    if (state?.errorMessage) {
      setError(state.errorMessage);
    }
  }, [location]);

  const resetError = (error: string | null, setError: (error: null) => void) => {
    // Resets the error state by setting it to null if there is an existing error.
    if (error) {
      setError(null);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    resetError(error, setError);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await UserApi.userLogin(email, password);
      if (response.success && response.data) {
        const { user, accessToken, refreshToken } = response.data;
        setLocalStorageItem('accessToken', accessToken);
        setLocalStorageItem('refreshToken', refreshToken);
        // First save user data and tokens
        await globalService.saveUser(user, setUser);
        // Handle cart merging
        const localCart = loadLocalCart();

        if (localCart.length > 0) {
          const newUser = { ...user };

          // Only add items that don't exist in the user's cart
          for (const localItem of localCart) {
            const existingItem = newUser.cart.find((item: any) =>
              item.product._id === localItem.product._id
            );

            if (!existingItem) {
              // Add new item to user's cart
              newUser.cart.push(localItem);
              // Update database
              await UserApi.addProductToCart(setUser, localItem);
            }
            // If item exists, keep the user's existing quantity
          }

          await globalService.saveUser(newUser, setUser);
          // Only delete local cart after successful merge
          deleteLocalCart();
        }
        // Handle navigation
        const state = location.state as { returnPath?: string };
        navigate(state?.returnPath || '/shop');
      } else {
        console.error('err: ', response);
        setError(response?.message || t('login.errors.invalidCredentials'));
      }
    } catch (err: any) {
      console.error('Login error: ', err);
      setError(t('login.errors.invalidCredentials'));
    }
  };

  const inputErrorClass = error ? 'error' : '';

  return (
    <>
      <div className={styles.loginHeaderBlock} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
        <HomepageNavbar />
        <div className={styles.loginContainer}>
          <div className={styles.headSection}>
            <img className={styles.olisLogo} alt="Group" src={blackLogo} />
            <h3 className={styles.signInTitle}>{t('login.signIn')}</h3>
            <div className={styles.signInSubtitleContainer}>
              <span className={styles.signInSubtitle}>{t('login.welcomeMessage')}</span>
            </div>
          </div>

          <div className={styles.formContainer}>
            <form onSubmit={handleLogin}>
              <div className={styles.loginInputGroup} style={{ position: 'relative' }}>
                <input
                  type="email"
                  id="email"
                  placeholder={t('login.emailPlaceholder')}
                  value={email}
                  onChange={handleEmailChange}
                  className={`login-input ${styles[inputErrorClass]}`}
                  required
                />
                {isEmailValid && (
                  <img
                    src={vIcon}
                    alt="Valid Email"
                    className={styles.validIcon}
                  />
                )}
              </div>

              <div className={styles.loginInputGroup} style={{ marginBottom: '8px' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder={t('login.passwordPlaceholder')}
                  value={password}
                  onChange={handlePasswordChange}
                  className={`login-input ${styles[inputErrorClass]}`}
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className={`login-show-btn ${styles[inputErrorClass]}`}
                >
                  {showPassword ? t('common.hidePassword') : t('common.showPassword')}
                </button>
              </div>
              {error &&
                <div className={styles.errorContainer}>
                  <p className={styles.errorMessage}>{error}</p>
                </div>
              }
              <button type="submit" className={styles.loginButton} disabled={!email || !password}>
              {t('login.signIn')}
              </button>
            </form>
          </div>
          <div className={styles.loginForgotPasswordLinkContainer}>
            <a href="login/forgot-password" className={styles.loginForgotPasswordLink}>
            {t('login.forgotPassword')}
            </a>
          </div>
          <p className={styles.loginText}>
          {t('login.needAccount')}{' '}
            <a href="/signup" className={styles.loginLink}>
            {t('login.signUp')}
            </a>
          </p>
        </div>
      </div >
    </>
  );
};
