import userApi from "../service/API/UserApi"
import globalService from "./GlobalService"
import { IUser, TsetUser, IProductInCart, IAddress, IAnonymousUser } from '../types/TInterfaces'
import { Dispatch, SetStateAction } from "react"
import { NavigateFunction } from 'react-router-dom';
import { loadLocalCart, deleteLocalCart } from './localCartService';
import { handleTokenExpiration } from '../utils/tokenHandler';
import { setLocalStorageItem, getLocalStorageItem } from "utils/localStoragUtil";
import { ShopProduct } from "types/ShopInterfaces";

export default class UserService {

    static isAnonymousUser(user: IUser | IAnonymousUser | null): boolean {
        return user?.anonymous ?? false;
    }

    static checkIfProductExistInCart(user: IUser, product: ShopProduct) {
        const index = user.cart.findIndex(item => item.product._id === product._id)
        return index !== -1 ? user.cart[index] : null
    }



    static async addtocart(user: IUser, setUser: Dispatch<SetStateAction<IUser | null>>, product: ShopProduct, navigate: NavigateFunction) {
        try {
            const newUser = { ...user }
            let newProduct: IProductInCart

            const productInCart = this.checkIfProductExistInCart(user, product)

            if (productInCart) {
                productInCart.quantity += 1
                newProduct = { ...productInCart }
            } else {
                newProduct = {
                    product: product,
                    quantity: 1,
                }
                newUser.cart.push(newProduct)
            }

            const result = await userApi.addProductToCart(setUser, newProduct, navigate)
            if (result.success) await globalService.saveUser(newUser, setUser)

            return result

        } catch (error: any) {
            if (handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            console.error('Error when attempting to add product cart: ', error);
            return { success: false, message: "Failed to add product to cart." };
        }
    }


    static async updateUserSPF(user: IUser, setUser: TsetUser, data: any) {
        try {
            data.lastUpdated = new Date()
            const result = await userApi.updateUserSPF(setUser, data)
            if (result.success) {
                const newUser: IUser = { ...user }
                newUser.SPF = data
                await globalService.saveUser(newUser, setUser)
            } else { console.error('Failed to update Skin Profile:', result.message || 'Unknown error') }
        } catch (error: any) {
            console.error('Failed to update Skin Profile:', error.message || error)
        }
    }

    static async updateCart(user: IUser, setUser: Dispatch<SetStateAction<IUser | null>>, product: ShopProduct, newQuantity: number, navigate: NavigateFunction) {
        try {
            const result = await userApi.updateProductInCart(setUser, product._id, newQuantity, navigate);
            if (result.success) {
                const newUser = { ...user };
                const productIndex = newUser.cart.findIndex(item => item.product._id === product._id);
                if (productIndex !== -1) {
                    newUser.cart[productIndex].quantity = newQuantity;
                }
                await globalService.saveUser(newUser, setUser);
            }
            return result;
        } catch (error: any) {
            if (handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            console.error('Error when attempting to update product in cart:', error.message || error);
            return { success: false, message: "Failed to update product in cart." };
        }
    }

    static async removeFromCart(user: IUser, setUser: Dispatch<SetStateAction<IUser | null>>, product: IProductInCart, navigate: NavigateFunction) {
        try {
            const productId = product.product._id;
            const result = await userApi.removeProductFromCart(setUser, productId, navigate);
            if (result.success) {
                const newUser = { ...user };
                newUser.cart = newUser.cart.filter(item => item.product._id !== product.product._id);
                await globalService.saveUser(newUser, setUser);
            }
            return result;
        } catch (error: any) {
            if (handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            console.error('Error when attempting to remove product from cart:', error.message || error);
            return { success: false, message: "Failed to remove product from cart." };
        }
    }

    static async addDeliveryAddress(user: IUser, setUser: TsetUser, address: any, navigate: NavigateFunction, type?: string) {
        try {
            const result = await userApi.addDeliveryAddress(setUser, address, navigate)
            if (result.success && result.address) {
                const newUser: IUser = { ...user }
                const newAddress = result.address as Partial<IAddress>;
                if (address.asDefault) {
                    newUser.addresses.unshift(newAddress as IAddress)
                } else {
                    newUser.addresses.push(newAddress as IAddress)
                }
                await globalService.saveUser(newUser, setUser)
                return result
            } else {
                console.error('Failed to update delivery address:', result.message || 'Unknown error')
                return result
            }
        } catch (error: any) {
            if (handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            console.error('Failed to update delivery address:', error.message || error);
            return { success: false, message: "Internal server error" };
        }
    }

    static async deleteDeliveryAddress(user: IUser, setUser: Dispatch<SetStateAction<IUser | null>>, addressId: string, navigate: NavigateFunction) {
        try {
            const result = await userApi.deleteDeliveryAddress(setUser, addressId, navigate)
            if (result.success) {
                const newUser = { ...user }
                newUser.addresses = newUser.addresses.filter(item => item._id !== addressId)
                await globalService.saveUser(newUser, setUser)
            }
            return result
        } catch (error: any) {
            if (handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            console.error('Failed to delete delivery address:', error.message || error);
            return { success: false, message: "Internal server error" };
        }
    }

    static async restoreUserCart(user: IUser, setUser: Dispatch<SetStateAction<IUser | null>>) {
        try {
            const localCart = loadLocalCart();
            if (localCart.length > 0) {
                const newUser = { ...user };
                newUser.cart = localCart;
                await globalService.saveUser(newUser, setUser);
                deleteLocalCart(); // Clear local cart after merging
            }
            return true;
        } catch (error) {
            console.error('Error restoring user cart:', error);
            return false;
        }
    }


    static async updateDeliveryAddress(user: IUser, setUser: TsetUser, newAddress: any, addressId: string, navigate: NavigateFunction) {
        try {
            const result = await userApi.updateDeliveryAddress(setUser, newAddress, addressId, navigate)

            if (result.success && result.address) {
                const newUser: IUser = { ...user }
                const index = newUser.addresses.findIndex(item => item._id === addressId)
                if (index !== -1) {
                    if (newAddress.asDefault) {
                        newUser.addresses.splice(index, 1)
                        newUser.addresses.unshift(newAddress as IAddress)
                    } else {
                        newUser.addresses[index] = newAddress as IAddress
                    }
                    await globalService.saveUser(newUser, setUser)
                    return result
                } else {
                    console.error('Failed to update delivery address:', result.message || 'Unknown error')
                    return { success: false, message: result.message || 'Unknown error' };
                }
            }
            return { success: false, message: result.message || 'Unknown error' };
        } catch (error: any) {
            if (handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            console.error('Failed to update delivery address:', error.message || error);
            return { success: false, message: "Internal server error" };
        }
    }

    static async getUserData(user: IUser, setUser: TsetUser) {
        try {
            if (!user._id) return { success: false, message: "User ID not found" };
            const result = await userApi.getUserData(user._id, setUser);
            if (result.success && result.data?.addresses) {
                const newUser = { ...user };
                newUser.addresses = result.data.addresses;
                await globalService.saveUser(newUser, setUser);
                return { success: true, message: "User data updated successfully" };
            }
            return { success: false, message: "No address data received" };
        } catch (error: any) {
            console.error('Failed to fetch user data:', error.message || error);
            return { success: false, message: "Internal server error" };
        }
    }

    static async modifyPassword(
        user: IUser,
        setUser: Dispatch<SetStateAction<IUser | null>>,
        currentPassword: string,
        newPassword: string
    ) {
        try {
            const result = await userApi.modifyPassword(setUser, currentPassword, newPassword);

            if (result.success) {
                const updatedUser = { ...user };
                await globalService.saveUser(updatedUser, setUser);
                return { success: true, message: "Password updated successfully." };
            } else {
                console.error('Password modification failed:', result.message);
                return { success: false, message: result.message };
            }
        } catch (error: any) {
            console.error('Failed to modify password:', error.message || error);
            return { success: false, message: "An error occurred while updating the password." };
        }
    }

    static async updateUser(user: IUser, setUser: TsetUser, newData: Partial<IUser>, navigate: NavigateFunction) {
        try {
            if (!user._id) {
                throw new Error("User ID is required for updating user.");
            }

            const result = await userApi.updateUser(setUser, newData, navigate);

            if (result.success && result.user) {
                const updatedUser: IUser = { ...user, ...result.user };
                await globalService.saveUser(updatedUser, setUser);
                return { success: true, message: "User updated successfully." };
            } else {
                return { success: false, message: result.message };
            }
        } catch (error) {
            console.error("Error updating user:", error);
            return { success: false, message: "An error occurred while updating the user." };
        }
    }

    static async addVoucher(user: IUser, setUser: TsetUser, voucherCode: string) {
        try {
            const result = await userApi.addVoucher(setUser, voucherCode);
            if (result.success && result.voucher) {
                const newUser = { ...user };
                if (!newUser.vouchers) {
                    newUser.vouchers = [];
                }
                newUser.vouchers.push(result.voucher);
                await globalService.saveUser(newUser, setUser);
                return { success: true, voucher: result.voucher };
            }
            return { success: false, message: result.message };
        } catch (error: any) {
            console.error('Error adding voucher:', error);
            return { success: false, message: "Failed to add voucher." };
        }
    }

    static async AnonymousAddVoucher(email: string, voucherCode: string) {
        try {
            const result = await userApi.AnonymousAddVoucher(email, voucherCode);
            if (result.success && result.voucher) {
                const vouchers = getLocalStorageItem('vouchers') || [];

                if (!vouchers.some((voucher: any) => voucher.code === result.voucher.code)) {
                    vouchers.push(result.voucher);
                    setLocalStorageItem('vouchers', vouchers);
                }

                return { success: true, voucher: result.voucher };
            }
            return { success: false, message: result.message };
        } catch (error: any) {
            console.error('Error adding voucher:', error);
            return { success: false, message: "Failed to add voucher." };
        }
    }

    static async getUserVouchers(user: IUser, setUser: TsetUser) {
        try {
            const result = await userApi.getUserVouchers(setUser);
            if (result.success) {
                return { success: true, vouchers: result.vouchers };
            }
            return { success: false, vouchers: [] };
        } catch (error: any) {
            console.error('Error fetching vouchers:', error);
            return { success: false, vouchers: [] };
        }
    }

    static async applyVoucher(user: IUser, setUser: TsetUser, voucherCode: string) {
        try {
            const result = await userApi.applyVoucher(setUser, voucherCode);
            if (result.success) {
                return { success: true, voucher: result.voucher };
            }
            return { success: false, message: result.message };
        } catch (error: any) {
            console.error('Error using voucher:', error);
            return { success: false, message: "Failed to apply voucher." };
        }
    }

    static async AnonymousApplyVoucher(email: string, voucherCode: string) {
        try {
            const result = await userApi.AnonymousApplyVoucher(email, voucherCode);
            if (result.success) {
                return { success: true, voucher: result.voucher };
            }
            return { success: false, message: result.message };
        } catch (error: any) {
            console.error('Error using voucher:', error);
            return { success: false, message: "Failed to apply voucher." };
        }
    }

    // In UserService.tsx
    // In UserService.tsx
    static async deleteVoucher(userId: string, voucherId: string): Promise<{ success: boolean; message: string }> {
        try {
            const result = await userApi.deleteVoucher(userId, voucherId);
            if (result.success) {
                return { success: true, message: 'Voucher deleted successfully' };
            }
            return { success: false, message: result.message || 'Failed to delete voucher' };
        } catch (error: any) {
            console.error('Error deleting voucher:', error);
            return { success: false, message: 'Failed to delete voucher' };
        }
    }

    static async generateFriendVoucher(user: IUser, setUser: TsetUser) {
        try {
            const result = await userApi.generateFriendVoucher(setUser);
            if (result.success) {
                return {
                    success: true,
                    voucher: result.voucher,
                    message: result.message
                };
            }
            return {
                success: false,
                message: result.message || "Failed to generate friend voucher"
            };
        } catch (error: any) {
            console.error('Error generating friend voucher:', error);
            return {
                success: false,
                message: "Failed to generate friend voucher."
            };
        }
    }
}
