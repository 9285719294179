import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ShopHeadBestsellers } from 'components/shopPage/ShopHeadBestsellers/ShopHeadBestsellers';
import styles from './editPage.module.scss';
import { GridProducts } from 'components/grid/gridProducts';
import { FooterSection } from "components/homepage/FooterSection/FooterSection";
import { CategoriesFilter } from 'pages/Categories/CategoriesFilter/CategoriesFilter';
import { ShopProduct } from 'types/ShopInterfaces';

export const EditPage: React.FC = () => {
    const location = useLocation();
    const { tile } = location.state || {};
    const gridProductsRef = useRef<HTMLDivElement>(null);

    // State for filtered products
    const [filteredProducts, setFilteredProducts] = useState<ShopProduct[]>([]);

    // Update filtered products when categories are selected
    const handleFilteredProducts = (products: ShopProduct[]) => {
        setFilteredProducts(products);
    };

    return (
        <>
            <div className={styles.collectionPage}>
                {/* Shop Head Bestsellers with dynamic background */}
                {tile.image && (
                    <ShopHeadBestsellers backgroundImage={tile.image} bestsellerHeaderAdvice={tile.title || tile.name} descriptionText={tile.subTitle} />
                )}
            </div>

            <div className={styles.collectionPageContent}>
                {/* Sidebar Categories Filter */}
                <div className={styles.categoriesFilter}>
                    <CategoriesFilter
                        products={tile.products}
                        onFilteredProducts={handleFilteredProducts}
                        targetComponentRef={gridProductsRef}
                    />
                </div>

                {/* Display Filtered Products */}
                <div ref={gridProductsRef} className={styles.collectionPageGrid}>
                    <GridProducts products={filteredProducts} />
                </div>
            </div>

            {/* Footer Section */}
            <div ref={gridProductsRef}>
                <FooterSection />
            </div>
        </>
    );
};
