import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { StepIndicator } from 'components/counter/counter';
import styles from '../style.module.scss';
import { useTranslation } from 'react-i18next';

// Generate the days, months, and years for dropdown options
const days = Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0')); // ['01', '02', ..., '31']
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']; // MM format
const years = Array.from({ length: 100 }, (_, i) => (new Date().getFullYear() - i).toString()); // Past 100 years

export const SPF_Age: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const {t} = useTranslation()

  const [day, setDay] = useState<string | undefined>();
  const [month, setMonth] = useState<string | undefined>();
  const [year, setYear] = useState<string | undefined>();

  const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => setDay(e.target.value);
  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => setMonth(e.target.value);
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => setYear(e.target.value);

  const isNextDisabled = !(day && month && year);

  const goToNext = () => {
    if (!isNextDisabled) {
      const selectedDate = `${year}-${month}-${day}`;
      setSkinProfile({ ...skinProfile, age: selectedDate });
      navigate('/spf-form/spf-skin-type');
    }
  };

  useEffect(() => {
    // Initialize day, month, and year if user.SPF.age exists
    if (user?.SPF?.age) {
      const date = new Date(user.SPF.age);
      const savedYear = date.getUTCFullYear().toString();
      const savedMonth = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
      const savedDay = date.getUTCDate().toString().padStart(2, '0');

      setYear(savedYear);
      setMonth(savedMonth);
      setDay(savedDay);
    }
  }, [user, skinProfile]);

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <div className={styles.groupOne}>

            <StepIndicator currentStep={2} />
            <div className={styles.titleContainer}>
              <h1 className={styles.TitlesSpf}>{t('spfForm.age.title')}</h1>
            </div>
            <div className={styles.subtitleContainer}>
              <h5 className={styles.SubtitleSpf}>{t('spfForm.age.subtitle')}</h5>
            </div>
          </div>

          <div className={styles.groupTwo}>
            <div className={styles.inputAgeGroup}>
              <select value={day} onChange={handleDayChange} className={styles.dropdown}>
                <option value="">JJ</option>
                {days.map((d) => (
                  <option key={d} value={d}>
                    {d}
                  </option>
                ))}
              </select>
              <select value={month} onChange={handleMonthChange} className={styles.dropdown}>
                <option value="">MM</option>
                {months.map((m) => (
                  <option key={m} value={m}>
                    {m}
                  </option>
                ))}
              </select>
              <select value={year} onChange={handleYearChange} className={styles.dropdown}>
                <option value="">YYYY</option>
                {years.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.groupThree}>
            <div className={`${styles.spfbuttonContainer} ${isNextDisabled ? styles.disabled : ''}`}>
              <button
                className={styles.nextButtonForm}
                disabled={isNextDisabled}
                onClick={goToNext}
              >
                {t('common.nextButton')}
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
