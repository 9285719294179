import React, { useState, useEffect } from 'react';
import styles from './ProfileHomePage.module.scss';
import { SkinResume } from '../SkinResume/SkinResume';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { SkinData } from '../SkinResume/components/SkinData/SkinData';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';
import { ProfileSettings } from '../ProfileSettings/ProfileSettings';
import { ContactBox } from '../ProfileSettings/components/ContactBox/ContactBox';
import { CautionBox } from '../ProfileSettings/components/CautionBox/CautionBox';
import { ProfileVouchers } from '../ProfileVouchers/ProfileVouchers';
import { OrderDisplay } from '../MyOrders/components/OrderDisplay/OrderDisplay';
import { getLocalStorageItem } from 'utils/localStoragUtil';
import { IOrder } from 'types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import userApi from 'service/API/UserApi';
import { FavoriteArticleSection } from '../MyFavorite/MyFavoriteArticle/fevoriteArticleSection';
import { FavoriteProductSection } from '../MyFavorite/MyFavoriteProduct/fevoriteProductSection';
import { useWindowSize } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

export const ProfileHomePage = () => {
    const [user] = useAtom(userAtom);
    const {t} = useTranslation()

    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const [orders, setOrders] = useState<IOrder[]>([]);
    const navigate = useNavigate()

    const { isMobile } = useWindowSize();


    useEffect(() => {
        const storedOrders = getLocalStorageItem('userOrders');

        if (storedOrders) {
            setOrders(storedOrders as IOrder[]);
            setIsEmpty(storedOrders.length === 0);
        } else {
            setIsEmpty(true);
        }
    }, []);

    const currentOrders = orders.filter((order) => {
        const lastStatus = order.statusHistory && Array.isArray(order.statusHistory) && order.statusHistory.length > 0
            ? order.statusHistory[order.statusHistory.length - 1]
            : null;

        return lastStatus && (lastStatus.status === 'RECEIVED' || lastStatus.status === 'PROCESSED' || lastStatus.status === 'SHIPPED');
    }).reverse();

    useEffect(() => {
        const fetchUserOrders = async () => {
            if (user) {
                await userApi.getUserOrders(user._id as string);
            }
        };

        fetchUserOrders();
    }, [user]);

    const handleSeeAll = () => {
        navigate('/profile/my-orders')
    }

    const handleStartToShop = () => {
        navigate('/shop');
    }

    return (
        <div>
            {!isMobile ?
                <SkinResume />
                :
                <div className={styles.mobileContainer}>
                    <HomepageNavbar />

                    <div className={styles.headSection}>
                        <h3 className={styles.userName}>{user?.name}</h3>
                        <h3 className={styles.userName}>{user?.lastName}</h3>
                    </div>

                    <div className={styles.sectionBottom}>
                        < SkinData />
                        <div className={styles.favoriteProductContainer}>
                            <FavoriteProductSection />
                        </div>
                        <div className={styles.favoriteArticleContainer}>
                            <FavoriteArticleSection />
                        </div>

                        <div className={`${styles.mobileFunctionContainer} ${currentOrders.length === 0 ? styles.mobileFunctionContainerNoBorder : ''}`}>
                            <h3 className={styles.mobileTitle}>{t('profileHomePage.orders.title')}</h3>
                            <button className={styles.seeAllBtn} onClick={handleSeeAll}>{t('profileHomePage.orders.seeAllButton')}</button>
                        </div>

                        {currentOrders.length > 0 ? (
                            <>
                                <div className={styles.mobileSubTitleContainer}>
                                    <h3 className={styles.mobileSubTitle}>{t('profileHomePage.orders.currentTitle')}</h3>
                                </div>
                                {currentOrders.map((order, index) => (
                                    <OrderDisplay key={`current-${index}`} order={order} />
                                ))}
                            </>
                        ) : (
                            <>
                                <div className={styles.noOrdersContainer}>
                                    <h3 className={styles.mobileCenterSubTitle} >
                                        {t('profileHomePage.orders.noActiveOrdersMessage')}
                                    </h3>
                                </div>
                                <div className={styles.shopButtonContainer}>
                                    <button className={styles.mobileShopButton} onClick={handleStartToShop}>{t('profileHomePage.orders.startToShopButton')}</button>
                                </div>

                            </>
                        )}
                        <ProfileSettings />
                        <ContactBox />
                        <CautionBox />
                    </div>
                </div>
            }
        </div >
    );
};
