import React, { useState, useEffect } from 'react';
import { IIngredient } from 'types/IIngredientsScanner';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface IngredientModalProps {
  ingredient: IIngredient | null;
  onClose: () => void;
}

function IngredientModal({ ingredient, onClose }: IngredientModalProps) {
  const { t } = useTranslation();
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (ingredient) {
      setIsClosing(false);
    }
  }, [ingredient]);

  const handleClose = () => {
    setIsClosing(true);
    // Wait for animation to complete before actually closing
    setTimeout(() => {
      onClose();
    }, 300); // Match this with your animation duration
  };

  if (!ingredient) return null;

  return (
    <>
      <div className={`${styles.overlay} ${isClosing ? styles.overlayClosing : ''}`} onClick={handleClose} />
      <div className={`${styles.modal} ${isClosing ? styles.closing : ''}`}>
        <button onClick={handleClose} className={styles.closeButton}>
          {t('common.close')}
        </button>
        <div className={styles.content}>
          <div className={styles.section}>
            <h2 className={styles.title}>{ingredient.inci_name}</h2>
            <h3 className={styles.subtitle}>{ingredient.cluster_name}</h3>
          </div>
          <div className={styles.section}>
            <h3>{t('ingredientsScan.results.ingredientsFound.description')}</h3>
            <p className={styles.description}>{ingredient.description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default IngredientModal;
