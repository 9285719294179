import type React from "react"
import loadingGIF from "../../assets/gifs/loadingGIF.gif"
import styles from "./loadingComponent.module.scss"
import { HomepageNavbar } from "components/navbars/HomepageNavbar/HomepageNavbar"
import { useEffect, useLayoutEffect } from "react"

interface LoadingComponentProps {
  loading: boolean
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ loading }) => {

  useEffect(() => {
    if (loading) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
    }

    return () => {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
    };
  }, [loading]);



  return loading === true ? (
    <>
      <div className={styles.navbar}>
        <HomepageNavbar />
      </div>
      <div className={styles.loadingPageContainer}>
        <div className={styles.imageContainer}>
          <img
            src={loadingGIF}
            alt="Loading"
            style={{ transform: "rotate(-45deg)", flexShrink: 0 }}
            width={650} />
        </div>
      </div>
    </>
  ) :
    <></>
}

export default LoadingComponent

