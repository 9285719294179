import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useAllActives } from '../../../hooks/useNotionActives';
import { ActiveArticleCard } from './ActiveArticleCard';
import activesBg from '../../../assets/images/activesListBg.png';
import activesCard from '../../../assets/images/activesCard.png';
import infoCircle from '../../../assets/images/infoCircle2.png';
import './style.scss';
import { useTranslation } from 'react-i18next';

export const ActivesList: React.FC = () => {
    const { allActives } = useAllActives();
    const {t} = useTranslation()

    return (
        <div className='actives-container-feed'>
            <HomepageNavbar />
            <div className="actives-list-container">
                <div className="actives-list__image" style={{ backgroundImage: `url(${activesBg})` }}>
                    <div className="actives-list__image-overlay">
                        <h1 className="actives-list__title">{t('actives.activesListTitle')}</h1>
                        <span className="actives-list__article-count">
                            {allActives?.length} {t('actives.activesCountLabel')}
                        </span>
                    </div>
                    <div className="actives-list__bottom-text">
                       {t('actives.activesDescription')}
                    </div>
                </div>
                <div className="actives-list__articles-content">
                    <div className="actives-list__articles-section">
                        <div className="actives-list__rectangle">
                            <div className="actives-list__rectangle-content">
                                <img src={infoCircle} alt="Description" className="actives-list__rectangle-image" />
                                <div className="actives-list__rectangle-text-container">
                                    <div className="actives-list__rectangle-text" style={{ textTransform: 'uppercase', fontWeight: 700 }}>
                                        {t('actives.cautionTitle')}
                                    </div>
                                    <div className="actives-list__rectangle-text" style={{ fontWeight: 400 }}>
                                        {t('actives.cautionDescription')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actives-list__cards-wrapper">
                        <div className="actives-list__articles-section actives-list__articles-section--no-top-padding">
                            <div className="actives-list__articles-grid">
                                {allActives
                                    ?.filter((active) => active.image)
                                    .map((active) => (
                                        <ActiveArticleCard key={active._id} active={active} />
                                    ))}

                                {/* Display without images */}
                                {allActives
                                    ?.filter((active) => !active.image)
                                    .map((active) => (
                                        <ActiveArticleCard key={active._id} active={active} />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};




