export const getErrorMessage = (error: any) => {
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : error.message || 'Unknown error';
    return errorMessage;
  };

export const fallBackToClipboard = (message : string, url : string, t: any) => {
    const text = `${message}\n \n${url}`;
    navigator.clipboard.writeText(`${message}\n ${url}`)
      .then(() => {
          alert(t('articles.shareArticle.fallbackMessage', { message : text }));
      });
  }