import React, { useEffect, useState, useRef } from 'react';
import styles from './AllVouchers.module.scss';
import { Voucher } from 'types/TInterfaces';
import { validateVoucher } from 'utils/voucherUtils';
import SelectedIcon from '../../assets/icons/SelectedAddress.svg';

interface AllVouchersModalProps {
    isOpen: boolean;
    onClose: () => void;
    vouchers: Voucher[];
    onSelectVoucher: (code: string) => void;
}

const AllVouchersModal: React.FC<AllVouchersModalProps> = ({
    isOpen,
    onClose,
    vouchers,
    onSelectVoucher
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [selectedVoucher, setSelectedVoucher] = useState<Voucher | null>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    const isVoucherInactive = (voucher: Voucher) => {
        return (
            voucher.status === 'used' ||
            new Date(voucher.voucherID.expirationDate) < new Date() ||
            !voucher.voucherID.active
        );
    };

    const getBackgroundStyle = (imageUrl: string | undefined) => {
        const validImageUrl = imageUrl && imageUrl.trim() !== '';
        return {
            backgroundImage: validImageUrl ? `url(${imageUrl})` : 'none',
            backgroundColor: validImageUrl ? 'transparent' : '#5D5246',
        };
    };

    const handleAddButtonClick = () => {
        const validationResult = validateVoucher(selectedVoucher as Voucher);
        if (validationResult.isValid) {
            onSelectVoucher(selectedVoucher?.voucherID.code as string);
        }
    };

    const getVoucherCardClassName = (voucher: Voucher) => {
        if (selectedVoucher === null) {
            return '';
        }

        return selectedVoucher === voucher ? '' : styles.transparent;
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} ref={modalContentRef}>
                {isMobile && (<div className={styles.headerContainer}>
                    <button className={styles.closeButton} onClick={onClose}>
                        CLOSE
                    </button>
                    <h2 className={styles.modalHeader}>ALL VOUCHERS</h2>
                </div>)}
                {!isMobile && (<div className={styles.headerContainer}>
                    <h2 className={styles.modalHeader}>ALL VOUCHERS</h2>
                    <button className={styles.closeButton} onClick={onClose}>
                        CLOSE
                    </button>
                </div>)}
                <div className={styles.vouchersList}>
                    {/* Check if there are no vouchers */}
                    {vouchers.length === 0 ? (
                        <p className={styles.noVouchersMessage}>No vouchers available</p>
                    ) : (
                        vouchers.map((voucher) => {
                            const validationResult = validateVoucher(voucher);
                            const isSelected = selectedVoucher === voucher;

                            return (
                                <div
                                    key={voucher._id}
                                    className={`${styles.voucherCard} ${!validationResult.isValid ? styles.inactive : ''} ${getVoucherCardClassName(voucher)}`}
                                    onClick={() => {
                                        if (isMobile && validationResult.isValid) {
                                            setSelectedVoucher(isSelected ? null : voucher);
                                        } else if (!isMobile && validationResult.isValid) {
                                            onSelectVoucher(voucher.voucherID.code);
                                        }
                                    }}
                                    style={{
                                        ...getBackgroundStyle(voucher.voucherID.imageUrl),
                                        filter: isVoucherInactive(voucher) ? 'grayscale(100%)' : 'none',
                                    }}
                                >
                                    <div className={styles.expirationDateContainer}>
                                        <p className={styles.expirationDate}>
                                            {voucher.status === 'used'
                                                ? 'USED'
                                                : !voucher.voucherID.active
                                                    ? 'NOT ACTIVE'
                                                    : new Date(voucher.voucherID.expirationDate) < new Date()
                                                        ? 'EXPIRED'
                                                        : `EXP. ${new Date(voucher.voucherID.expirationDate).toLocaleDateString('en-GB')}`}
                                        </p>
                                    </div>
                                    <div className={styles.voucherInfo}>
                                        <p className={styles.voucherCode}>{voucher.voucherID.code}</p>
                                        <p className={styles.discountValue}>
                                            {voucher.voucherID.discountType === 'percentage'
                                                ? `${voucher.voucherID.discountValue}% OFF`
                                                : `€${voucher.voucherID.discountValue} OFF`}
                                        </p>
                                    </div>
                                    {isSelected && (
                                        <div className={styles.selectedIconContainer}>
                                            <img src={SelectedIcon} alt="Selected" className={styles.selectedIcon} />
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    )}
                </div>
                {isMobile && (
                    <div>
                        <button
                            className={styles.addButton}
                            disabled={!selectedVoucher}
                            onClick={handleAddButtonClick}
                        >
                            ADD
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllVouchersModal;

