import React from "react";
import styles from './styles.module.scss';
import blackDot from '../../../assets/icons/blackDot.svg';

import one from '../../../assets/numbers/01.png';
import two from '../../../assets/numbers/02.png';
import three from '../../../assets/numbers/03.png';
import four from '../../../assets/numbers/04.png';
import five from '../../../assets/numbers/05.png';
import six from '../../../assets/numbers/06.png';
import seven from '../../../assets/numbers/07.png';
import eight from '../../../assets/numbers/08.png';
import nine from '../../../assets/numbers/09.png';
import zero from '../../../assets/numbers/10.png';
import slash from '../../../assets/numbers/slash.png';
import { useTranslation } from "react-i18next";

interface BrandDetailsSectionProps {
    about: string;
    score?: number; // genuinely a number
}

export const BrandDetailsSection: React.FC<BrandDetailsSectionProps> = ({ about, score }) => {
    const digitToImageMap: Record<string, string> = {
        '0': zero,
        '1': one,
        '2': two,
        '3': three,
        '4': four,
        '5': five,
        '6': six,
        '7': seven,
        '8': eight,
        '9': nine,
        '/': slash
    };
    const {t} = useTranslation()

    const aboutSection = (
        <div className={styles.aboutSection}>
            <h1 className={styles.aboutTitle}>{t('common.about')}</h1>
            <span className={styles.aboutText}>{about}</span>
        </div>
    );

    // If score is not provided, we can decide to either hide the section or show something else
    const formattedScore = score !== undefined ? `${score}/100` : '';

    const scoreSection = score !== undefined ? (
        <div className={styles.scoreSection}>
            <div className={styles.scoreAlignment}>
                <div className={styles.titleAndDot}>
                    <img className={styles.blackDot} src={blackDot} alt="dot" />
                    <h1 className={styles.brandAvg}>{t('brands.brandScore.average')}</h1>
                </div>

                <div className={styles.scoreImages}>
                    {formattedScore.split('').map((char, idx) => {
                        const src = digitToImageMap[char] || '';
                        return (
                            <img
                                key={idx}
                                src={src}
                                alt={char}
                                className={styles.digitImage}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    ) : null;

    return (
        <div className={styles.brandDetailsSection}>
            {aboutSection}
            {scoreSection}
        </div>
    );
};
