import React, { useState, useEffect } from 'react';
import styles from './SimilarProducts.module.scss';
import { CarouselService } from 'components/carousels/service/carouselService';
import { useWindowSize } from 'hooks/useWindowSize';
import productsApi from 'service/API/ProductsApi';
import { useTranslation } from 'react-i18next';
interface SimilarProductsProps {
  productId: string;
}

export const SimmilarProducts: React.FC<SimilarProductsProps> = ({ productId }) => {
  const { isMobile } = useWindowSize();
  const [similarProducts, setSimilarProducts] = useState<any[]>([]);
  const [showSimilarProducts, setShowSimilarProducts] = useState(false);
  const {t} = useTranslation()

  useEffect(() => {
    if (similarProducts.length > 0) {
      setShowSimilarProducts(true);
    } else {
      setShowSimilarProducts(false);
    }
  }, [similarProducts]);

  // fetch similar products
  useEffect(() => {
    const fetchSimilarProductsData = async (prodId: string) => {
      try {
        const { similarProducts } = await productsApi.fetchSimilarProducts(prodId);
        setSimilarProducts(similarProducts);
      } catch (error) {
        console.error('Error fetching similar products:', error);
      }
    };

    if (productId) {
      fetchSimilarProductsData(productId);
    }
  }, [productId]);


  return (
    <>
      {showSimilarProducts && (
        <>
          {isMobile && (
            <p className={styles.mobileHeader}>
              {/* SIMILAR PRODUCTS <br /> RECOMMENDED BY OLI'S */}
              SIMILAR PRODUCTS
            </p>
          )}

          <div className={styles.container} style={{ backgroundColor: '#F7F6E7' }}>
            <div className={styles.headerBlock}>
              {!isMobile && (
                <p className={styles.header}>{t('productDetails.similarProducts')}</p>
              )}
            </div>

            <div className={styles.carouselContainer}>
              <CarouselService products={similarProducts} carouselLayout='SIMILAR_PRODUCTS' quantity={10} paginationLocation={isMobile ? "none" : "down"} slideStyles={{
                desktop: {
                  relatedProductSlide: { height: '49vh', width: '19.7vw', pointerEvents: 'all' },
                  imageContainer: { maxHeight: '32vh' }
                },
                mobile: {
                  relatedProductSlide: { height: '24vh', width: '32vw', pointerEvents: 'all' },
                  //relatedProductSlide: { height: '28vh', width: '32vw', pointerEvents: 'all' },
                  imageContainer: { maxHeight: '50vh' }
                }
              }} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
