import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNotionArticles } from '../../../../hooks/useNotionArticles';
import LatestTrendTitle from '../../../../assets/images/LatestTrendTitle.png';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import './style.scss';
import { NotionArticle } from 'hooks/useNotionArticles.types';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

export const Hero: React.FC = () => {
  const { data: articles, isLoading, error } = useNotionArticles({ page: 1, limit: 4 });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation()
  //TODO: get currentLanguage from context
  const { currentLanguage } = useCurrentLanguage();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoading) {
    return <div>{t('feed.loading')}</div>;
  }

  if (error) {
    return <div>{t('feed.errorLoading')}</div>;
  }

  if (!articles) {
    return null;
  }

  // Filter articles to only include those that have an image
  const featuredArticles = articles
    .filter((article: NotionArticle) => article.translations[currentLanguage].properties.Image.files[0]?.url)
    .slice(0, 2); // Get the first two articles with images

  return (
    <div className="hero">
      <HomepageNavbar />
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1 }} />
      <img src={LatestTrendTitle} alt="Latest Trend" className="hero__title" />
      {featuredArticles.map((article: NotionArticle) => (
        <Link
          to={`/feed/article/${article.id}`}
          key={article.id}
          className={`hero__article hero__article--${article.id}`}
        >
          <div
            className="hero__article-background"
            style={{ backgroundImage: `url(${article.translations[currentLanguage].properties.Image.files[0]?.url})` }}
          />
          <div className="hero__article-content">
            <span className="hero__category">
              {article?.translations[currentLanguage].dossierNames && article.translations[currentLanguage].dossierNames.length > 0 ? (
                isMobile ? (
                  <span className="dossierName">
                    {article.translations[currentLanguage].dossierNames[0].toUpperCase()}
                  </span>
                ) : (
                  <>
                    {article.translations[currentLanguage].dossierNames.slice(0, 3).map((name, index, array) => (
                      <React.Fragment key={index}>
                        <span className="dossierName">{name.toUpperCase()}</span>
                        {index < array.length - 1 && ' | '}
                      </React.Fragment>
                    ))}
                    {article.translations[currentLanguage].dossierNames.length > 3 && ''}
                  </>
                )
              ) : (
                <span />
              )}
            </span>
            <div className="hero__article-title-container">
              <h2 className="hero__article-title">
                {article.translations[currentLanguage].properties.Title.title[0]?.plain_text}
              </h2>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};
