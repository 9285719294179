import React, { useEffect, useState } from 'react';
import './style.scss';
import guideImage from '../../../../assets/images/guideImage.jpg';
import ahas from '../../../../assets/images/ahas.jpg';
import allantoin from '../../../../assets/images/allantoin.jpg';
import aloevera from '../../../../assets/images/aloevera.jpg';
import { useNotionActives } from 'hooks/useNotionArticles';
import { Link } from 'react-router-dom';
import useAllActives from 'hooks/useNotionActives';
import { useTranslation } from 'react-i18next';

type Option = {
  id: string;
  label: string;
  description?: string;
};

const options: Option[] = [
  { id: 'feed/actives', label: 'ACTIVES' },
  // { id: 'feed/combination-actives', label: 'COMBINATION ACTIVES' },
  // { id: 'feed/hydrolats', label: 'HYDROLATS' },
  // { id: 'feed/vitamins', label: 'VITAMINS' },
];

export const Guide: React.FC = () => {
  const { allActives } = useAllActives();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const {t} = useTranslation()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const activeCount = allActives ? allActives.length : 0;
  // const combinationCount = 0; // Assuming static counts for demo purposes
  const hydrolatsCount = 0; // Assuming static counts for demo purposes
  const vitaminsCount = 0; // Assuming static counts for demo purposes

  const updatedOptions = options.map((option) => {
    switch (option.label) {
      case 'ACTIVES':
        return { ...option, description: `${activeCount} Actives` };
      // case 'COMBINATION ACTIVES':
      //   return { ...option, description: `${combinationCount} Combinations` };
      case 'HYDROLATS':
        return { ...option, description: `${hydrolatsCount} Hydrolats` };
      case 'VITAMINS':
        return { ...option, description: `${vitaminsCount} Vitamins` };
      default:
        return option;
    }
  });

  return (
    <section className="guide-section">
      <h2>{t('feed.guides')}</h2>
      <div className="guide-container">
        <div className="guide-menu">
          <ol>
            {updatedOptions.map((option, index) => (
              <li key={option.id} className='guide-item'>
                <Link to={`/${option.id}`}>
                  {isMobile && option.label === 'COMBINATION ACTIVES' ? (
                    <div className='combination-container'>
                      <span className="menu-number-combination">{`${String(index + 1).padStart(2, '0')}`}</span>

                      <div className='combination-text'>
                        <span className="menu-count">{option.label.split(' ')[0]}</span>
                        <span className="menu-text">
                          {option.label.split(' ')[1]}
                          <span className="menu-description">{option.description}</span>
                        </span>
                      </div>

                    </div>
                  ) : (
                    <>
                      <span className="menu-number">{`${String(index + 1).padStart(2, '0')}`}</span>
                      {option.label}
                      <span className="menu-description">{option.description}</span>
                    </>
                  )}
                </Link>
                {/* <span className="menu-description">{option.description}</span> */}
              </li>
            ))}
          </ol>
        </div>
        <img src={guideImage} alt="Guide" className="guide-image" />
      </div>
    </section>
  );
};
