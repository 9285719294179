import api from './apiConfig';
import globalService from 'service/GlobalService';
import { IUser, ShippingAddress, IProductInCart } from 'types/TInterfaces';
import { Dispatch } from 'react';
import { SetStateAction } from 'jotai';
import { handleApiError } from '../errorHandler';
import { setLocalStorageItem } from 'utils/localStoragUtil';
import { NavigateFunction } from 'react-router-dom';
import { handleTokenExpiration } from 'utils/tokenHandler';

export default class userApi {
    static async updateUserFavorite(productId: string, action: string) {
        try {
            const response = await api.post(
                '/user/favorite',
                { action, productId },
            );
            return response.data;
        } catch (error: any) {
            if (error.response?.status === 401) {
                // Handle 401 Unauthorized specifically
                console.error('Unauthorized access:', error);
                return { success: false, message: 'Unauthorized access. Please login again.', tokenMissing: true };
            }
            console.error('Error updating favorites products:', error);
            throw error;
        }
    }

    static async updateUserFavoriteArticle(articleId: string, action: string) {
        try {
            const response = await api.post('/user/favorite-article', { action, articleId });
            return response.data;
        } catch (error) {
            console.error('Error updating favorites articles:', error);
            throw error;
        }
    }

    static async getUserFavoritesProducts() {
        try {
            const response = await api.get(
                '/user/user-favorites-products',
            );
            // Now response.data is your array of populated product objects.
            return response.data;
        } catch (error) {
            console.error('Error fetching user favorite products:', error);
            return { success: false, message: 'An error occurred while fetching favorite products.' };
        }
    }
    static async getUserFavoritesArticles() {
        try {
            const response = await api.get('/user/user-favorites-articles');
            return response.data; // Assuming response.data is the desired format
        } catch (error) {
            console.error('Error fetching user favorite articles:', error);
            // Handle specific error scenarios if necessary or return a generic error message
            return { success: false, message: 'An error occurred while fetching favorite articles.' };
        }
    }

    static async modifyPassword(setUser: Dispatch<SetStateAction<IUser | null>>, currentPassword: string, newPassword: string) {
        try {
            const passwordData = {
                data: {
                    currentPassword,
                    newPassword,
                },
            };

            const response = await api.post('/user/modyfyPassword', passwordData);

            if (response.status === 200) {
                const { updatedUser, accessToken, refreshToken } = response.data;
                setUser(updatedUser);
                setLocalStorageItem('accessToken', accessToken);
                setLocalStorageItem('refreshToken', refreshToken);
                return { success: true, message: 'Password modified successfully.' };
            } else {
                return { success: false, message: response.data.error || 'Failed to modify password.' };
            }
        } catch (error) {
            console.error('Error modifying password:', error);
            return { success: false, message: 'Your password is not correct !' };
        }
    }

    static async updateUserSPF(setUser: Dispatch<SetStateAction<IUser | null>>, data: any) {
        try {
            const response = await api.post('/user/spf', { data });
            return { success: response.status === 200, message: response.data };
        } catch (error) {
            console.error('Error updating skin profile:', error);
            return { success: false, message: 'An error occurred while updating the skin profile.' };
        }
    }

    static async addProductToCart(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        product: IProductInCart,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string }> {
        try {
            const response = await api.post('/user/addtocart', { product });
            return { success: response.status === 200, message: response.data };

        } catch (error: any) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }

            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }

            const retryResponse = await handleApiError(error, this.addProductToCart.bind(this), setUser, product);
            return retryResponse || { success: false, message: "Failed to add product to cart due to an API error." };
        }
    }

    /**
     * Updates the quantity of a product in the user's cart.
     * 
     * @param setUser - A function to update the state of the user.
     * @param productId - The ID of the product to update.
     * @param count - The new quantity of the product.
     * @returns A promise that resolves to an object indicating the success of the operation and a message.
     */
    static async updateProductInCart(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        productId: string,
        count: number,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string }> {
        try {
            const countValues = { [productId]: count };
            const response = await api.post(
                '/user/updatecart',
                { productId, countValues },
            );
            return { success: response.status === 200, message: response.data };
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            return { success: false, message: 'Error occurred while updating cart' };
        }
    }

    static async removeProductFromCart(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        productId: string,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string }> {
        try {
            const response = await api.post(
                '/user/removefromcart',
                { productId },

            );
            return { success: response.status === 200, message: response.data };
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            return { success: false, message: 'Error occurred while removing product from cart' };
        }
    }
    static async addDeliveryAddress(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        address: any,
        navigate?: NavigateFunction,
    ): Promise<{ success: boolean; message: string; address?: ShippingAddress, }> {
        try {
            const response = await api.post('/user/addaddress', { address });
            return response.data;
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }

            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }
            const retryResponse = await handleApiError(
                error,
                this.addDeliveryAddress.bind(this),
                setUser,
                address
            );

            return retryResponse || {
                success: false,
                message: "Failed to add delivery address.",
            };
        }
    }

    static async deleteDeliveryAddress(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        addressId: any,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string }> {
        try {
            const response = await api.post('/user/deletedeliveryaddress', { addressId });
            if (response.status === 200) {
                return { success: true, message: 'Delivery address deleted successfully.' };
            } else {
                return { success: false, message: response.data.error || 'Failed to delete delivery address.' };
            }
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }

            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }

            const retryResponse = await handleApiError(
                error,
                this.deleteDeliveryAddress.bind(this),
                setUser,
                addressId
            );

            return retryResponse || { success: false, message: "Failed to delete delivery address due to an API error." };
        }
    }

    static async userLogin(email: string, password: string) {
        try {
            const response = await api.post('/auth/login', {
                email,
                password,
            });
            if (response.status === 200) {
                return { success: true, data: { user: response.data.user, accessToken: response.data.accessToken, refreshToken: response.data.refreshToken } };
            }
            else {
                return { success: false, message: 'Invalid email or password' };
            }
        } catch (error: any) {
            if (error.response?.status === 401) {
                return { success: false, message: 'Invalid email or password' };
            }

            return { success: false, message: 'An error occurred while logging in.' };
        }
    }

    static async updateDeliveryAddress(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        newAddress: ShippingAddress,
        addressId: string,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string; address?: ShippingAddress }> {
        try {
            const response = await api.post(
                '/user/updatedeliveryaddress',
                { addressId, newAddress }
            );

            return {
                success: response.status === 200,
                message: response.data.message,
                address: response.data.address
            };
        } catch (error: unknown) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }

            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }

            const retryResponse: { success: boolean; message: string; address?: ShippingAddress } | null = await handleApiError(
                error,
                this.updateDeliveryAddress.bind(this),
                setUser,
                newAddress,
                addressId
            );

            return retryResponse || { success: false, message: "Failed to update delivery address." };
        }
    }

    static async getUserData(
        userId: string,
        setUser: Dispatch<SetStateAction<IUser | null>>
    ): Promise<{ success: boolean; message: string; data?: IUser }> {
        try {
            const response = await api.get(`/user/data?userId=${userId}`);

            return {
                success: response.status === 200,
                message: response.data.message,
                data: response.data
            };
        } catch (error: unknown) {
            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 409) {
                    return { success: false, message: apiError.response.data };
                }
            }

            const retryResponse = await handleApiError(
                error,
                this.getUserData.bind(this),
                setUser
            );

            return retryResponse || { success: false, message: "Failed to fetch user data." };
        }
    }

    static async updateUser(
        setUser: Dispatch<SetStateAction<IUser | null>>,
        updatedData: Partial<IUser>,
        navigate?: NavigateFunction
    ): Promise<{ success: boolean; message: string; user?: IUser }> {
        try {
            const response = await api.post('/user/update-info', { data: updatedData });

            if (response.status === 200) {
                const updatedUser = response.data.updatedUser;
                setUser(updatedUser);
                return { success: true, message: 'User updated successfully.', user: updatedUser };
            } else {
                return { success: false, message: response.data.error || 'Failed to update user.' };
            }
        } catch (error) {
            if (navigate && handleTokenExpiration(error, setUser, navigate)) {
                return { success: false, message: 'Session expired' };
            }
            console.error('Error updating user:', error);
            return { success: false, message: 'An error occurred while updating the user.' };
        }
    }

    static async forgotPassword(email: string, language: string): Promise<{ success: boolean; message: string }> {
        try {
            const response = await api.post('/auth/forgot', { email, language });
            return { success: response.status === 200, message: response.data.result || 'Password reset email sent.' };
        } catch (error) {
            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 404) {
                    return { success: false, message: 'User does not exist' };
                }
            }
            return { success: false, message: 'An error occurred while processing your request.' };
        }
    }

    static async getUserOrders(userId: string) {
        try {
            const response = await api.post('/orders/allOrders', { userId });

            if (response.status === 200) {
                const orders = response.data;

                localStorage.setItem('userOrders', JSON.stringify(orders));
                return orders;
            } else {
                console.error('Failed to fetch orders:', response.status, response.data);
                throw new Error('Failed to fetch orders');
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error in getUserOrders:', error.message);
            } else {
                console.error('An unknown error occurred:', error);
            }

            throw error;
        }
    }

    static async getAnonymousUserOrder(orderId: string) {
        try {
            const response = await api.post('/orders/getOrder', { orderId });

            if (response.status === 200) {
                const order = response.data;
                return order;
            } else {
                console.error('Failed to fetch orders:', response.status, response.data);
                throw new Error('Failed to fetch orders');
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error in getUserOrders:', error.message);
            } else {
                console.error('An unknown error occurred:', error);
            }

            throw error;
        }
    }

    static async rebuyAll(userId: string, skus: string[], setUser: Dispatch<SetStateAction<IUser | null>>) {
        try {
            const response = await api.post('/orders/rebuyAll', { userId, skus });

            if (response.status === 200) {
                const updatedUser = response.data.user;

                globalService.saveUser(updatedUser, setUser)
            } else {
                console.error('Failed to rebuy all products in order:', response.status, response.data);
                throw new Error('Failed to rebuy all products in order');
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error in rebuyAll function:', error.message);
            } else {
                console.error('An unknown error occurred:', error);
            }

            throw error;
        }
    }

    static async newsletterSubscribe(email: string) {
        try {
            const response = await api.post('/automations/add-user-to-newsletter', { email });

            if (response.status === 200) {

            } else {
                console.error('Failed to subscribe to the newsletter');
                throw new Error('Failed to subscribe to the newsletter');
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error('Failed to subscribe to the newsletter', error.message);
            } else {
                console.error('Failed to subscribe to the newsletter', error);
            }

            throw error;
        }
    }

    static async clearUserCartInDb(userId: string) {
        try {
            const response = await api.post('/user/clearCart', { userId });
            if (response.status === 200) {
                return { success: true };
            } else {
                return { success: false, message: 'Invalid userId' };
            }
        } catch (error) {
            console.error('Error clearing cart in:', error);
            return { success: false, message: 'An error occurred while clearing user cart.' };
        }
    }

    static async addVoucher(setUser: Dispatch<SetStateAction<IUser | null>>, voucherCode: string) {
        try {
            const response = await api.post('/user/vouchers/add', { voucherCode });
            return {
                success: response.status === 200,
                voucher: response.data.voucher,
                message: response.data.message
            };
        } catch (error: any) {
            if (error.response?.data?.message) {
                return { success: false, message: error.response.data.message };
            }

            return { success: false, message: "Failed to add voucher." };
        }
    }

    static async AnonymousAddVoucher(email: string, voucherCode: string) {
        try {
            const response = await api.post('vouchers/addAnonymousVoucher', { email, voucherCode });
            return {
                success: response.status === 200,
                voucher: response.data.voucher,
                message: response.data.message
            };
        } catch (error: any) {
            if (error.response?.data?.message) {
                return { success: false, message: error.response.data.message };
            }

            return { success: false, message: "Failed to add voucher for anoynmous user." };
        }
    }

    static async generateFriendVoucher(setUser: Dispatch<SetStateAction<IUser | null>>) {
        try {
            const response = await api.post('/user/vouchers/generate-friend', {});
            return {
                success: response.status === 200,
                voucher: response.data.voucher,
                message: response.data.message
            };
        } catch (error: any) {
            if (error.response?.data?.message) {
                return { success: false, message: error.response.data.message };
            }

            return { success: false, message: "Failed to generate friend voucher." };
        }
    }

    static async getUserVouchers(setUser: Dispatch<SetStateAction<IUser | null>>) {
        try {
            const response = await api.get('/user/vouchers');
            return {
                success: response.status === 200,
                vouchers: response.data.vouchers || []
            };
        } catch (error) {
            console.error('Error fetching vouchers:', error);
            return { success: false, vouchers: [] };
        }
    }

    static async applyVoucher(setUser: Dispatch<SetStateAction<IUser | null>>, voucherCode: string) {
        try {
            const response = await api.post('/user/vouchers/apply', { voucherCode });

            if (response.data.success) {
                return {
                    success: true,
                    voucher: response.data.voucher,
                    message: response.data.message
                };
            } else {
                return {
                    success: false,
                    message: response.data.message || 'Failed to apply voucher'
                };
            }
        } catch (error: any) {
            console.error('Voucher application error:', error);
            if (error.response?.data?.message) {
                return { success: false, message: error.response.data.message };
            }
            return { success: false, message: "Failed to apply voucher." };
        }
    }

    static async AnonymousApplyVoucher(email: string, voucherCode: string) {
        try {
            const response = await api.post('vouchers/applyAnonymousVoucher', { email, voucherCode });

            if (response.data.success) {
                return {
                    success: true,
                    voucher: response.data.voucher,
                    message: response.data.message
                };
            } else {
                return {
                    success: false,
                    message: response.data.message || 'Failed to apply voucher'
                };
            }
        } catch (error: any) {
            console.error('Voucher application error:', error);
            if (error.response?.data?.message) {
                return { success: false, message: error.response.data.message };
            }
            return { success: false, message: "Failed to apply voucher." };
        }
    }

    static async deleteVoucher(userId: string, voucherId: string) {
        try {
            const response = await api.delete(`user/${userId}/vouchers/${voucherId}`);

            return {
                success: response.status === 200,
                message: response.data.message || 'Voucher deleted successfully'
            };
        } catch (error: any) {
            console.error('Error deleting voucher:', error);
            if (error.response?.data?.message) {
                return { success: false, message: error.response.data.message };
            }
            return { success: false, message: 'Failed to delete voucher' };
        }
    }
}

