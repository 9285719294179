import React from 'react';
import styles from './style.module.scss';
import { CarouselService } from 'components/carousels/service/carouselService';
import { useTranslation } from 'react-i18next';

interface Props {
  isMiniature?: boolean;
  brandName: string;
}

export const RecommandedProducts: React.FC<Props> = ({ brandName, isMiniature = false }) => {
  const isMobile = window.innerWidth <= 768;
  const {t} = useTranslation()


  return (
    <div className={`${styles.container} ${isMiniature ? styles.miniature : ''}`} style={{ backgroundColor: '#F7F6E7' }}>
      <div className={styles.headerBlock}>
        {!isMiniature && <p className={styles.header}>{t('productDetails.recommendedProducts.brandNameProducts', {brandName})}</p>}
      </div>
      <div className={styles.carouselContainer}>
        <CarouselService isMiniature={isMiniature} brandName={brandName} carouselLayout='RECOMMANDED_PRODUCTS' quantity={10} paginationLocation={isMobile ? "none" : "down"} slideStyles={{
          desktop: {
            relatedProductSlide: { height: '49vh', width: '19.7vw', pointerEvents: 'all' },
            imageContainer: { maxHeight: '32vh' }
          },
          mobile: {
            relatedProductSlide: { height: '24vh', width: '32vw', pointerEvents: 'all' },
            //relatedProductSlide: { height: '28vh', width: '32vw', pointerEvents: 'all' },
            imageContainer: { maxHeight: '50vh' }
          }
        }} />
      </div>
    </div>
  );
};
//count=