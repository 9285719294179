import React from 'react';
import styles from './Modal.module.scss';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    t: (key: string) => string
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, t }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.headerContainer}>
                    <button className={styles.closeButton} onClick={onClose}>{t('common.cancel')}</button>
                    <h2 className={styles.addVoucherHeader}>{t('profileVouchers.addVoucherButton')}</h2>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;