import styles from './style.module.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignUpBackgroudImage from '../../assets/images/headSectionBackground.jpg';
import blackLogo from '../../assets/icons/blackLogo.svg';
import authApi from 'service/API/authApi';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useTranslation } from 'react-i18next';

export const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { token } = useParams();
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [tokenError, setTokenError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const [validationState, setValidationState] = useState({
        minLength: false,
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
    });

    useEffect(() => {
        if (token) {
            const verifyToken = async () => {
                try {
                    const response = await authApi.checkIsValidLink(token);

                    if ('success' in response && !response.success) {
                        setTokenError(response.message);
                    } else if ('email' in response && !response.isValid) {
                        setTokenError('Invalid or expired token.');
                    }
                } catch (err: any) {
                    setTokenError('Something went wrong. Please try again.');
                }
            };

            verifyToken();
        } else {
            setTokenError('Token error.');
        }
    }, [token]);


    const resetError = (currentError: string | null, updateError: (value: string | null) => void) => {
        if (currentError) {
            updateError(null);
        }
    };

    const validatePassword = (password: string) => {
        setValidationState({
            minLength: password.length >= 8,
            hasUppercase: /[A-Z]/.test(password),
            hasLowercase: /[a-z]/.test(password),
            hasNumber: /[0-9]/.test(password),
        });
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        resetError(error, setError);
        validatePassword(newPassword);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        resetError(error, setError);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError(t('login.forgetPassword.passwordsMismatch'));
            return;
        }

        if (token) {
            const { success, message } = await authApi.updateNewPassword(token, password);

            if (success) {
                setError(null);
                navigate('/reset-done');
            } else {
                setError(t('login.forgetPassword.wentWrong'));
            }
        }

        else {
            setTokenError('Token is missing');
        }
    };


    const inputErrorClass = error ? 'error' : '';

    return (
        <>
            <div className={styles.forgetPasswordHeaderBlock} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
                <HomepageNavbar />
                <div className={styles.forgetPasswordContainer}>
                    <div className={styles.headSection}>
                        <img className={styles.olisLogo} alt="Group" src={blackLogo} />

                        {tokenError && (<h1 className={styles.forgetPasswordTitle}>{t('login.forgetPassword.somethingWentWrong')}</h1>)}
                        {!tokenError && (<h1 className={styles.forgetPasswordTitle}>{t('login.forgetPassword.title')}</h1>)}
                    </div>

                    {!tokenError && (<div className={styles.formContainer}>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.forgetPasswordInput} style={{ position: 'relative' }}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    placeholder={t('login.forgetPassword.newPasswordPlaceholder')}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className={styles.forgetPasswordShowBtn}
                                >
                                    {showPassword ? t('common.hidePassword') : t('common.showPassword') }
                                </button>
                            </div>
                            <div className={styles.forgetPasswordInput} style={{ position: 'relative', marginBottom: '8px' }}>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    id="confirmPassword"
                                    placeholder={t('login.forgetPassword.confirmPasswordPlaceholder')}
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={toggleConfirmPasswordVisibility}
                                    className={styles.forgetPasswordShowBtn}
                                >
                                    {showConfirmPassword ? t('common.hidePassword') : t('common.showPassword') }
                                </button>
                            </div>

                            {error &&
                                <div className={styles.errorContainer}>
                                    <p className={styles.errorMessage}>{error}</p>
                                </div>
                            }

                            <button
                                type="submit"
                                className={styles.forgetPasswordButton}
                                disabled={!password || !confirmPassword || Object.values(validationState).some((isValid) => !isValid)}
                            >
                                {t('login.forgetPassword.resetButton')}
                            </button>
                        </form>
                    </div>)}
                    <div className={styles.forgetPasswordAdvice}>
                        <div>
                            <h5>{t('login.forgetPassword.advices')}</h5>
                        </div>
                        <div className={styles.passwordRestrictionsContainer}>
                            <span className={!validationState.minLength ? styles.errorMessage : styles.adviceMessage}>
                            {t('login.forgetPassword.requirements.minLength')}
                            </span>
                            <span className={!validationState.hasUppercase ? styles.errorMessage : styles.adviceMessage}>
                            {t('login.forgetPassword.requirements.uppercase')}
                            </span>
                            <span className={!validationState.hasLowercase ? styles.errorMessage : styles.adviceMessage}>
                            {t('login.forgetPassword.requirements.lowercase')}
                            </span>
                            <span className={!validationState.hasNumber ? styles.errorMessage : styles.adviceMessage}>
                            {t('login.forgetPassword.requirements.number')}
                            </span>
                        </div>
                    </div>

                    <p className={styles.forgetPasswordText}>
                        {t('login.needAccount')}{' '}
                        <a href="/signup" className={styles.forgetPasswordLink}>
                           { t('login.signUp')}
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
};
