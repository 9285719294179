import React, { useEffect, useState } from 'react';
import { StepIndicator } from 'components/counter/counter';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { useNavigate } from 'react-router-dom';
import styles from '../style.module.scss';
import { useTranslation } from 'react-i18next';

export const AnalyzeSkin: React.FC = () => {
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const navigate = useNavigate();
    const {t} = useTranslation()

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingPercentage((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prev + 1;
            });
        }, 30);

        if (loadingPercentage === 100) {
            navigate('/shop');
        }

        return () => clearInterval(interval);
    }, [loadingPercentage, navigate]);

    return (
        <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
            <HomepageNavbar />
            <div className={styles.formContainer}>
                <div className={styles.formContentContainer}>
                    <div className={styles.groupOne}>
                        <StepIndicator currentStep={6} />
                        <div className={styles.titleContainer}>
                            <h1 className={styles.TitlesSpf}>{t('spfForm.analyze.inProgress')}</h1>
                        </div>
                    </div>
                    <div className={styles.loaderContainer}>
                        <div className={styles.loader}>
                            <div className={styles.percentage}>{loadingPercentage}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
