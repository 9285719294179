import React, { useEffect, useState } from 'react';
import styles from './ScorePopup.module.scss'
import xIcon from '../../../assets/icons/xIconScorePopup.svg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ScorePopupProps {
    onClose: () => void;
}

export const ScorePopup: React.FC<ScorePopupProps> = ({ onClose }) => {
    const isMobile = window.innerWidth < 1360;
    const navigate = useNavigate();
    const {t} = useTranslation()

    function navigateToArticle() {
        navigate('/feed/article/a13935aa-64f0-42eb-856b-6073c5f30cbd');
    }

    return (
        <div className={styles.popupLayout} onClick={onClose}>
            <div className={styles.popupContainer} onClick={(e) => e.stopPropagation()}>
                <div className={styles.xButtonContainer}>
                    <img src={xIcon} alt="cancel" onClick={onClose} />
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.infoContainer}>
                        <div className={styles.infoTitleContainer}>
                            <h3 className={styles.infoTitleText}>{t('productDetails.scorePopup.rateProductsTitle')}</h3>
                        </div>
                        <div className={styles.infoDataContainer}>
                            <div className={styles.textAndTitleContainer} style={{ marginBottom: isMobile ? "14px" : "20px" }}>
                                <h3 className={styles.infoSubTitleText}>{t('productDetails.scorePopup.ingredientQualityTitle')}</h3>
                                <span className={styles.infoText}>{t('productDetails.scorePopup.ingredientQualityDesc')}</span>
                            </div>
                            <div className={styles.textAndTitleContainer} style={{ marginBottom: isMobile ? "14px" : "20px" }}>
                                <h3 className={styles.infoSubTitleText}>{t('productDetails.scorePopup.formulationTitle')}</h3>
                                <span className={styles.infoText}>{t('productDetails.scorePopup.formulationDesc')}</span>
                            </div>
                            <div className={styles.textAndTitleContainer} style={{ marginBottom: isMobile ? "14px" : "20px" }}>
                                <h3 className={styles.infoSubTitleText}>{t('productDetails.scorePopup.resultsTestingTitle')}</h3>
                                <span className={styles.infoText}>{t('productDetails.scorePopup.resultsTestingDesc')}</span>
                            </div>
                            <div className={styles.textAndTitleContainer}>
                                <h3 className={styles.infoSubTitleText}>{t('productDetails.scorePopup.ethicsTitle')}</h3>
                                <span className={styles.infoText}>{t('productDetails.scorePopup.ethicsDesc')}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.infoContainer}>
                        <div className={styles.infoTitleContainer}>
                            <h3 className={styles.infoTitleText}>{t('productDetails.scorePopup.ratingsTitle')}</h3>
                        </div>
                        <div className={styles.infoDataContainer}>
                            <div className={styles.textAndTitleContainer} style={{ marginBottom: isMobile ? "14px" : "20px" }}>
                                <h3 className={styles.infoSubTitleText}>{t('productDetails.scorePopup.bestRatingTitle')}</h3>
                                <span className={styles.infoText}>{t('productDetails.scorePopup.bestRatingDesc')}</span>
                            </div>
                            <div className={styles.textAndTitleContainer} style={{ marginBottom: isMobile ? "14px" : "20px" }}>
                                <h3 className={styles.infoSubTitleText}>{t('productDetails.scorePopup.goodRatingTitle')}</h3>
                                <span className={styles.infoText}>{t('productDetails.scorePopup.goodRatingDesc')}</span>
                            </div>
                            <div className={styles.textAndTitleContainer} style={{ marginBottom: isMobile ? "14px" : "20px" }}>
                                <h3 className={styles.infoSubTitleText}>{t('productDetails.scorePopup.notQuiteRatingTitle')}</h3>
                                <span className={styles.infoText}>{t('productDetails.scorePopup.notQuiteRatingDesc')}</span>
                            </div>
                            <div className={styles.textAndTitleContainer}>
                                <h3 className={styles.infoSubTitleText}>{t('productDetails.scorePopup.notWorthItRatingTitle')}</h3>
                                <span className={styles.infoText}>{t('productDetails.scorePopup.notWorthItRatingDesc')}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.bottomLabelContainer}>
                        <span className={styles.bottomLabelText}>{t('productDetails.scorePopup.learnMoreLabel')} </span>
                        <span onClick={navigateToArticle} className={styles.bottomLabelText} style={{ textDecoration: "underline", cursor: 'pointer' }}>{t('productDetails.scorePopup.readMoreLabel')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};