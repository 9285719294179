import styles from './style.module.scss';
import { useAllBrands } from 'hooks/useAllBrands';
import { useEffect, useState } from 'react';
import { brandOfTheWeek } from 'utils/brandOfTheWeek';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ShopBanner {
  image: string;
  text?: string;
  subText?: string;
}

interface BrandWithCategories {
  brand: string;
  shopBanner: ShopBanner[];
}

export const LargePhotoSection = () => {
  const { data: allBrandsData } = useAllBrands();
  const brandName = brandOfTheWeek;
  const [BrandBackground, setBrandBackground] = useState<string | null>(null);
  const [text, setText] = useState<string | null>(null);
  const [subText, setSubText] = useState<string | null>(null);
  const navigate = useNavigate();
  const {t} = useTranslation()

  useEffect(() => {
    if (!allBrandsData || !brandName) return;

    const foundDetails = allBrandsData.find(
      (b) => b.brand.toLowerCase() === brandName.toLowerCase()
    ) as BrandWithCategories | undefined;

    if (foundDetails) {
      setBrandBackground(foundDetails.shopBanner[0]?.image || '');
      setText(foundDetails.shopBanner[0]?.text || '');
      setSubText(foundDetails.shopBanner[0]?.subText || '');
    }
  }, [allBrandsData, brandName]);

  const handleLearnMore = () => {
    const encodedBrandName = encodeURIComponent(brandName);
    navigate(`/brand/${encodedBrandName}`);
  };

  return (
    <div
      className={styles.LargePhotoSectionContainer}
      style={{ backgroundImage: `url(${BrandBackground})` }}
    >
      <div className={styles.ContantContainer}>
        <h1 className={styles.LargePhotoSectionTitle}>
          {text}
        </h1>
        <p className={styles.LargePhotoSectionSubTitle}>
          {subText}
        </p>
        <button className={styles.LargePhotoSectionButton} onClick={handleLearnMore}>
          {t('common.learnMore')}
        </button>
      </div>
      {/* <ProductAdvice /> */}
    </div>
  );
};
