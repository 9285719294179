import styles from "./style.module.scss";
import { useAllBrands } from 'hooks/useAllBrands';
import { useEffect, useRef, useState } from "react";
import { FooterSection } from "components/homepage/FooterSection/FooterSection";
import { ShopHeadBestsellers } from "components/shopPage/ShopHeadBestsellers/ShopHeadBestsellers";
import hotBrandsImage from 'assets/images/hotbrandsBackground.png';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import vIcon from '../../assets/icons/vIcon.svg';
import emptyVIcon from '../../assets/icons/emptyCheckBox.svg';
import { backgroundBrands as allBackgroundBrands } from 'assets/brands/backgrounds/backgroundBrands'; // Adjust path as needed
import { Brand } from "service/API/ProductsApi";
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { useWindowSize } from 'hooks/useWindowSize';
import { useTranslation } from "react-i18next";

// Define or extend the Brand type to include categories and productCount
type BrandWithCategories = Brand & {
    categories?: string[];
    about?: string;
    productCount?: number;
    image?: string;
    titleImage?: string;
    brand?: string;
    backgroundImage?: string;
    title?: string;
};

// Define the type for the grouped brands object
type GroupedBrands = {
    [key: string]: BrandWithCategories[];
};

export const HotBrands = () => {
    const { data: allBrandsData, error, isLoading } = useAllBrands();
    const [activeLetter, setActiveLetter] = useState<string | null>(null);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const { isMobile } = useWindowSize();
    const [activeGroup, setActiveGroup] = useState(1);
    const sliderRef = useRef<any>(null);
    const [details, setDetails] = useState<BrandWithCategories | null>(null);
    const { t } = useTranslation();


    // 1. Filter out "HomePage" from the brand data
    const filteredAllBrandsData = allBrandsData?.filter(
        (brand) => brand.brand !== "HomePage"
    );

    // Filter background brands to exclude "default"
    const backgroundBrands = allBackgroundBrands.filter(
        (brand) =>
            brand.name.toLowerCase() !== "default" &&
            brand.name.toLowerCase() !== "homepage"
    );

    // 2. Calculate brand count after filtering "HomePage"
    const brandsCount = filteredAllBrandsData?.length || 0;

    // Extract unique categories from the filtered data
    const uniqueCategories = [
        ...new Set(
            filteredAllBrandsData
                ?.flatMap((brand: BrandWithCategories) => brand.categories || [])
                .map((category) => category.toLowerCase()) || []
        ),
    ].sort();

    // Filtered brands based on selected categories
    const filteredBrands = selectedCategories.length > 0
        ? filteredAllBrandsData?.filter((brand: BrandWithCategories) =>
            brand.categories?.some((category) =>
                selectedCategories.includes(category.toLowerCase())
            )
        )
        : filteredAllBrandsData;

    // Group filtered brands by the first letter of each brand name
    const groupedBrands: GroupedBrands | undefined = filteredBrands?.reduce(
        (acc: GroupedBrands, brand: BrandWithCategories) => {
            if (brand.brand && typeof brand.brand === 'string') {
                const firstLetter = brand.brand[0].toUpperCase();
                if (!acc[firstLetter]) {
                    acc[firstLetter] = [];
                }
                acc[firstLetter].push(brand);
            }
            return acc;
        },
        {} as GroupedBrands
    );

    // Handle scrolling within the brand list
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const container = event.currentTarget;
        const elements = container.querySelectorAll(`.${styles.hotbrandsletter}`);

        elements.forEach((elem: Element) => {
            if ((elem as HTMLElement).offsetTop - container.scrollTop < 20) {
                setActiveLetter((elem as HTMLElement).innerText);
            }
        });
    };

    // Handle category filter
    const handleCategoryChange = (category: string) => {
        setSelectedCategories((prev) =>
            category === 'ALL'
                ? []
                : prev.includes(category)
                    ? prev.filter((c) => c !== category)
                    : [...prev, category]
        );
    };

    const handleSlideChange = (swiper: any) => {
        const groupIndex = Math.ceil((swiper.activeIndex + 1) / 4);
        setActiveGroup(groupIndex);
    };

    const handleNumberClick = (groupIndex: number) => {
        setActiveGroup(groupIndex);
        if (sliderRef.current?.swiper) {
            sliderRef.current.swiper.slideTo((groupIndex - 1) * 4);
        }
    };

    // Helper to find brand info by name, if needed
    const getImageAndTitle = (brandName: string): BrandWithCategories | null => {
        if (!filteredAllBrandsData) return null;
        const foundDetails = filteredAllBrandsData.find(
            (b) => b.brand.toLowerCase() === brandName.toLowerCase()
        );
        return foundDetails ?? null;
    };

    return (
        <>
            <ShopHeadBestsellers
                backgroundImage={hotBrandsImage}
                brandsCount={brandsCount}
            />
            <div className={styles.hotbrandscontainer}>
                <h1 className={styles.hotbrandspopularbrandstitle}>{t('brands.popularBrandsTitle')}</h1>

                <div className={styles.swiperpaginationcontainer}>
                    <ScrollNumberIndicator
                        count={Math.ceil(backgroundBrands.length / 4)}
                        activeNumber={activeGroup}
                        fontSize={12}
                        onNumberClick={handleNumberClick}
                        color="black"
                    />
                </div>

                <Swiper
                    slidesPerView={isMobile ? 1.4 : 4}
                    spaceBetween={10}
                    onSlideChange={handleSlideChange}
                    loop={true}
                    ref={sliderRef}
                >
                    {filteredAllBrandsData?.map((brand: BrandWithCategories, index) => {
                        const productCount = brand.productCount;

                        return (
                            <SwiperSlide key={index}>
                                <Link to={`/brand/${encodeURIComponent(brand.brand)}`}>
                                    <div className={styles.hotbrandsimagecontainer}>
                                        <span className={styles.hotbrandspopularoverlay}>
                                        {t('brands.popularOverlay')}
                                        </span>
                                        <img
                                            className={styles.hotbrandsimage}
                                            src={brand.backgroundImage || ''}
                                            alt={brand.brand}
                                        />
                                        <span className={styles.hotbrandsnameoverlay}>
                                            {brand.brand}
                                            {productCount !== undefined && productCount > 0 && (
                                                <span className={styles.hotbrandproductcount}>
                                                    &nbsp;({productCount})
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>

                <div className={styles.categoriesandbrandscontainer}>
                    <h2 className={styles.hotbrandstitle}>{t('brands.allBrandsTitle')}</h2>

                    <div className={styles.hotbrandscategoriescontainer}>
                        {uniqueCategories.map((category, index) => (
                            <label key={index} className={styles.hotbrandscategory}>
                                <input
                                    type="checkbox"
                                    className={styles.hiddencheckbox}
                                    checked={selectedCategories.includes(category)}
                                    onChange={() => handleCategoryChange(category)}
                                />
                                <img
                                    className={styles.checkboxcustom}
                                    src={
                                        selectedCategories.includes(category)
                                            ? vIcon
                                            : emptyVIcon
                                    }
                                    alt="checkbox"
                                />
                                <span className={styles.categoriesName}>{category}</span>
                            </label>
                        ))}
                    </div>

                    <hr className={styles.hotbrandsseparator} />

                    <div className={styles.hotbrandslistcontainer} onScroll={handleScroll}>


                        {groupedBrands &&
                            Object.entries(groupedBrands)
                                .sort(([letterA], [letterB]) => letterA.localeCompare(letterB))
                                .map(([letter, brands]) => {
                                    // Sort each brand array by the brand name
                                    const sortedBrands = [...brands].sort((a, b) =>
                                        a.brand.localeCompare(b.brand)
                                    );

                                    return (
                                        <div key={letter}>
                                            <h3
                                                className={`${styles.hotbrandsletter} ${activeLetter === letter ? 'active' : ''
                                                    }`}
                                            >
                                                {letter}
                                            </h3>
                                            <div className={styles.hotbrandslist}>
                                                {sortedBrands.map((brand, index) => (
                                                    <div key={index} className={styles.hotbranditem}>
                                                        <Link
                                                            to={`/brand/${encodeURIComponent(brand.brand)}`}
                                                            className={styles.hotbrandlink}
                                                        >
                                                            <span className={styles.numberinlist}>
                                                                {index + 1}
                                                            </span>
                                                            <span className={styles.brandname}>
                                                                {brand.brand}
                                                            </span>
                                                            <span className={styles.hotbrandproductcount}>
                                                                ({brand.productCount})
                                                            </span>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                })}
                    </div>
                </div>
            </div>

            <div className={styles.hotbrandsfootercontainer}>
                <FooterSection />
            </div>
        </>
    );
};
