
import React from 'react';
import { DeliveryService } from '../../../../../types/TInterfaces';
import styles from './AddressForm.module.scss';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { AddressBook } from '../AddressBook/AddressBook';
import { CountryCode } from 'libphonenumber-js';
import PhoneInput from 'react-phone-number-input';

// Icons
import vIcon from '../../../../../assets/images/vIcon.png';
import xIcon from '../../../../../assets/images/xIcon.png';
import { useTranslation } from 'react-i18next';

interface Suggestion {
    id: string;
    place_name: string;
    country: string;
}

// --- SUB-COMPONENTS (Desktop & Mobile) ---
type TAddress = {
    addressName: string;
    firstName: string;
    lastName: string;
    phone: string;
    country: string;
    city: string;
    street: string;
    postalCode: string;
    addressComplement: string;
    asDefault: boolean;
};

type TShippingFormProps = {
    address: TAddress;
    errors: Record<string, string>;
    suggestions: Suggestion[];
    showSuggestions: boolean;
    inputValue: string;
    deliveryCountries: Pick<DeliveryService, 'country'>[];

    handleBack: () => void;
    handleDelete: (id: string) => Promise<void>;
    handleAdd: () => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handlePhoneChange: (value: string | undefined) => void;
    handleSuggestionClick: (suggestion: string, country: string) => void;
    handleInputFocus: (fieldName: string) => void;
    handleCancel: () => void;
    handleSubmit: (e: React.FormEvent) => void;

    addressId?: string;
    validateFields: () => boolean;
    addClicked: boolean;
    user?: any; // replace 'any' with your user type if needed
    countryForPhoneInput: string;
};


export const MobileShippingForm: React.FC<TShippingFormProps> = (props) => {
    const {
        address,
        errors,
        suggestions,
        showSuggestions,
        inputValue,
        deliveryCountries,
        handleChange,
        handlePhoneChange,
        handleSuggestionClick,
        handleInputFocus,
        handleCancel,
        handleSubmit,
        validateFields,
        addClicked,
        handleAdd,
        addressId,
        user,
        countryForPhoneInput,
    } = props;

    const {t} = useTranslation()

    return (
        <div className={styles.mobileContainer}>
            <HomepageNavbar />

            <div className={styles.mobileHeaderSection}>
                <h3 className={styles.mobileTitle}>{t('profileSettings.forms.fields.addressBook')}</h3>
                {!addressId && !addClicked && (
                    <button className={styles.mobileAddBtn} onClick={handleAdd}>
                        {t('profileSettings.addButton')}
                    </button>
                )}
            </div>

            {addClicked || addressId ? (
                <>
                    <h3 className={styles.mobileSubTitle}>{addressId ? t('profileSettings.forms.fields.editAddress') : t('profileSettings.forms.fields.addAddress')}</h3>
                    <form onSubmit={handleSubmit}>
                        {/* ADDRESS NAME */}
                        <div className={styles.inputField}>
                            <input
                                type="text"
                                name="addressName"
                                onFocus={() => handleInputFocus('addressName')}
                                placeholder={t('profileSettings.forms.fields.addressName')}
                                value={address.addressName}
                                style={errors.addressName ? { marginBottom: '5px' } : {}}
                                onChange={handleChange}
                                required
                            />
                            {errors.addressName ? (
                                <img src={xIcon} alt="Error" className={styles.validationIcon} />
                            ) : (
                                address.addressName && (
                                    <img src={vIcon} alt="Valid" className={styles.validationIcon} />
                                )
                            )}
                            {errors.addressName && <div className={styles.error}>{errors.addressName}</div>}
                        </div>

                        {/* FIRST NAME */}
                        <div className={styles.inputField} >
                            <input
                                type="text"
                                name="firstName"
                                placeholder={t('profileSettings.forms.fields.firstName')}
                                value={address.firstName}
                                onChange={handleChange}
                                onFocus={() => handleInputFocus('firstName')}
                                required
                                style={errors.firstName ? { marginBottom: '5px' } : {}}
                            />
                            {errors.firstName ? (
                                <img src={xIcon} alt="Error" className={styles.validationIcon} />
                            ) : (
                                address.firstName && (
                                    <img src={vIcon} alt="Valid" className={styles.validationIcon} />
                                )
                            )}
                            {errors.firstName && <div className={styles.error}>{errors.firstName}</div>}
                        </div>

                        {/* LAST NAME */}
                        <div className={styles.inputField}>
                            <input
                                type="text"
                                name="lastName"
                                placeholder={t('profileSettings.forms.fields.lastName')}
                                value={address.lastName}
                                onChange={handleChange}
                                style={errors.lastName ? { marginBottom: '5px' } : {}}
                                onFocus={() => handleInputFocus('lastName')}
                                required
                            />
                            {errors.lastName ? (
                                <img src={xIcon} alt="Error" className={styles.validationIcon} />
                            ) : (
                                address.lastName && (
                                    <img src={vIcon} alt="Valid" className={styles.validationIcon} />
                                )
                            )}
                            {errors.lastName && <div className={styles.error}>{errors.lastName}</div>}
                        </div>

                        {/* PHONE */}
                        <div className={styles.phoneField}>
                            <PhoneInput
                                id="phone"
                                className={styles.phoneInput}
                                style={errors.phone ? { marginBottom: '5px' } : {}}
                                defaultCountry={countryForPhoneInput as CountryCode}
                                international
                                onFocus={() => handleInputFocus('phone')}
                                value={address.phone}
                                onChange={handlePhoneChange}
                            />
                            {errors.phone ? (
                                <img src={xIcon} alt="Error" className={styles.validationIcon} />
                            ) : (
                                address.phone && <img src={vIcon} alt="Valid" className={styles.validationIcon} />
                            )}
                            {errors.phone && <div className={styles.error}>{errors.phone}</div>}
                        </div>

                        {/* STREET (with Suggestions) */}
                        <div className={styles.inputField}>
                            <input
                                type="text"
                                name="street"
                                placeholder={t('profileSettings.forms.fields.enterAddress')}
                                value={inputValue}
                                onFocus={() => handleInputFocus('street')}
                                style={errors.street ? { marginBottom: '5px' } : {}}
                                onChange={(e) => {
                                    const val = e.target.value;
                                    handleChange(e);
                                }}
                            />
                            {errors.street ? (
                                <img src={xIcon} alt="Error" className={styles.validationIcon} />
                            ) : (
                                address.street && (
                                    <img src={vIcon} alt="Valid" className={styles.validationIcon} />
                                )
                            )}
                            {errors.street && <div className={styles.error}>{errors.street}</div>}

                            {showSuggestions && suggestions.length > 0 && (
                                <div className={styles.suggestionsContainer}>
                                    {suggestions.map((suggestion, index) => (
                                        <div key={index} className={styles.suggestionItemContainer}>
                                            <p
                                                className={styles.suggestionItem}
                                                onClick={() => handleSuggestionClick(suggestion.place_name, suggestion.country)}
                                            >
                                                {suggestion.place_name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* ADDRESS COMPLEMENT */}
                        <div className={styles.inputField}>
                            <input
                                type="text"
                                name="addressComplement"
                                placeholder={t('profileSettings.forms.fields.addressDetails')}
                                value={address.addressComplement}
                                style={errors.addressComplement ? { marginBottom: '5px' } : {}}
                                onChange={handleChange}
                                onFocus={() => handleInputFocus('addressComplement')}
                            />
                            {errors.addressComplement ? (
                                <img src={xIcon} alt="Error" className={styles.validationIcon} />
                            ) : (
                                address.addressComplement && (
                                    <img src={vIcon} alt="Valid" className={styles.validationIcon} />
                                )
                            )}
                            {errors.addressComplement && (
                                <div className={styles.error}>{errors.addressComplement}</div>
                            )}
                        </div>

                        {/* CITY */}
                        <div>
                            <input
                                className={styles.shortFields}
                                type="text"
                                name="city"
                                placeholder={t('profileSettings.forms.fields.state')}
                                onFocus={() => handleInputFocus('city')}
                                value={address.city}
                                style={errors.city ? { marginBottom: '5px' } : {}}
                                onChange={handleChange}
                                required
                            />
                            {errors.city ? (
                                <img src={xIcon} alt="Error" className={styles.validationIcon} />
                            ) : (
                                address.city && <img src={vIcon} alt="Valid" className={styles.validationIcon} />
                            )}
                            {errors.city && <div className={styles.error}>{errors.city}</div>}
                        </div>

                        {/* COUNTRY */}
                        <div>
                            <select
                                className={styles.selectCountry}
                                name="country"
                                value={address.country || ''}
                                onChange={handleChange}
                                onFocus={() => handleInputFocus('country')}
                                required
                            >
                                <option value="">{t('profileSettings.forms.fields.selectCountry')}</option>
                                {deliveryCountries.map((countryObj, index) => (
                                    <option key={index} value={countryObj.country}>
                                        {countryObj.country}
                                    </option>
                                ))}
                            </select>
                            {address.country && (
                                <img src={vIcon} alt="Valid" className={styles.validationIcon} />
                            )}
                        </div>

                        {/* ZIP CODE */}
                        <div>
                            <input
                                className={styles.shortFields}
                                type="text"
                                name="postalCode"
                                placeholder={t('profileSettings.forms.fields.zipCode')}
                                value={address.postalCode}
                                style={errors.postalCode ? { marginBottom: '5px' } : {}}
                                onFocus={() => handleInputFocus('postalCode')}
                                onChange={handleChange}
                                required
                            />
                            {errors.postalCode ? (
                                <img src={xIcon} alt="Error" className={styles.validationIcon} />
                            ) : (
                                address.postalCode && (
                                    <img src={vIcon} alt="Valid" className={styles.validationIcon} />
                                )
                            )}
                            {errors.postalCode && <div className={styles.error}>{errors.postalCode}</div>}
                        </div>

                        {/* SET DEFAULT */}
                        <div className={styles.checkboxWrapper48}>
                            <label>
                                <input
                                    type="checkbox"
                                    name="asDefault"
                                    checked={address.asDefault}
                                    onChange={handleChange}
                                />
                                {t('profileSettings.forms.fields.saveAsDefault')}
                            </label>
                        </div>

                        <button
                            className={styles.submitBtn}
                            type="submit"
                            disabled={!validateFields()}
                        >
                            {addressId ? t('profileSettings.addressForm.saveButton') : t('profileSettings.forms.fields.addAddress')}
                        </button>
                    </form>
                </>
            ) : (
                <AddressBook />
            )
            }
        </div >
    );
};
