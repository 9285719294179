import React from 'react';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import { AddressCard } from '../../../../checkout/adressCard';
import { IAddress } from '../../../../../types/TInterfaces';
import { convertIAddressToShippingAddress } from './ConvertAddressUtil';
import { Link, useNavigate } from 'react-router-dom';
import styles from './AddressBook.module.scss';
import { useTranslation } from 'react-i18next';

export const AddressBook: React.FC = () => {
    const [user] = useAtom(userAtom);
    const navigate = useNavigate();
    const {t} = useTranslation()
    const isMobile = window.innerWidth < 768;


    const addresses: IAddress[] = user?.addresses || [];

    // Convert addresses to ShippingAddress
    const shippingAddresses = addresses.filter(address => address.type === 'shipping')
        .map(address => convertIAddressToShippingAddress(address));

    const handleEdit = (addressId: string) => {
        navigate(`/profile/settings/add-address/${addressId}`);
    };

    return (
        <div className={styles.addressBook}>
            {!isMobile && (<div className={styles.header}>
                <h4 className={styles.title}>{t('profileSettings.addressBook.title')}</h4>
                <Link to="add-address" className={styles.btnAdd}>
                    {t('profileSettings.addButton')}
                </Link>
            </div>)}
            {shippingAddresses.length > 0 ? (
                <div className={styles.addressList}>
                    {shippingAddresses.map((address, index) => (
                        <AddressCard
                            key={address._id}
                            shippingAddress={address}
                            isSelected={index === 0}
                            onEdit={() => handleEdit(address._id as string)}
                        />
                    ))}
                </div>
            ) : (
                <div className={styles.noAddresses}>{t('profileSettings.noAddressesMessage')}</div>
            )}
        </div>
    );
};
