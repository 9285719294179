import Dot from 'assets/icons/blackDot.svg';
import WhiteLogo from 'assets/icons/whiteLogo.svg';
import FooterBack from 'assets/images/homePageFooterBackground.jpg';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import userApi from 'service/API/UserApi';
import subscribedUserApi from 'service/API/subscribedUserApi';
import { validateEmail } from 'utils/inputValidationUtil';
import { config } from '../../../config';
import './style.scss';
import { useTranslation } from 'react-i18next';
import TranslateButtons from 'components/navbars/HomepageNavbar/TranslateButton';
import LanguageSelector from './LanguageSelector/LanguageSelector';

interface LatestTrendsProps {
  isComingSoonPage?: boolean;
}

export const FooterSection: React.FC<LatestTrendsProps> = ({ isComingSoonPage = false }) => {
  const navigate = useNavigate();
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [showNewsletterForm, setShowNewsletterForm] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {t} = useTranslation()

  const toggleSection = (section: string) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };
  const handleNewsletter = () => {
    setShowNewsletterForm(!showNewsletterForm);
  };
  const handleSubscribe = async (Email: string) => {
    const isSubscribed = await subscribedUserApi.checkIfSubscribed(Email);
    if (isSubscribed) {
      setError(true);
      setErrorMessage(t('footer.errors.alreadySubscribed'));
      return;
    }
    if (!validateEmail(Email)) {
      setError(true);
      setErrorMessage(t('footer.errors.invalidEmail'));
      return;
    }
    userApi.newsletterSubscribe(Email);
    setShowNewsletterForm(false);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError(false);
    setErrorMessage('');
  };

  const handleEmail = () => {
    const email = config.REACT_APP_SUPPORT_EMAIL;
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };

  const handleNavigate = (url: string) => {
    window.open(url, '_blank', 'noopener noreferrer');
  }

  const handleAboutUsNavigation = () => {
    const link = config.ABOUT_US_ARTICLE_LINK
      navigate(link)
  }

  return (
    <div className="footerContainer" style={{ backgroundImage: `url(${FooterBack})` }}>
      {!isComingSoonPage && (<div className="footerMenu">

        <div className="topContainer">
          <p className="footerHeader">{t('footer.newsletterHeader')}</p>

          <div className="newsletterContainer">
            <div className="dotIcon" style={{ backgroundImage: `url(${Dot})` }} />
            {!showNewsletterForm ? (
              <p onClick={handleNewsletter} className="regNews">
                {t('footer.registerNewsletter')}
              </p>
            ) : (
              <div className="newsletterForm">
                <input
                  className="newsletterInput"
                  type="text"
                  placeholder={t('footer.enterEmail')}
                  autoFocus
                  value={email}
                  onChange={handleEmailChange} // Update the state when input changes
                // Set the value of the input to the state
                />
                <button onClick={() => handleSubscribe(email)} className="subscribeBtn">
                {t('footer.subscribe')}
                </button>
                {error && <p className="errorMessage">{errorMessage}</p>}
              </div>
            )}
          </div>
        </div>

        <div className="menuLinks">

          <div className="menuSection">
            <div className='header-mobile-section' onClick={() => toggleSection('shop')}>
              <p className="footerMenuHeader">{t('footer.shop.header')}</p>
              <span className="toggleIcon">
                {expandedSection === 'shop' ? '-' : '+'}
              </span>
            </div>
            <span className={`arrow ${expandedSection === 'shop' ? 'up' : 'down'}`}></span>
            <ul className={`footerMenuList ${expandedSection === 'shop' ? 'expanded' : ''}`}>
              <li onClick={() => navigate('/shop')} className="footerMenuItem">{t('footer.shop.header')}</li>
              <li onClick={() => navigate('/feed')} className="footerMenuItem">{t('footer.shop.editorials')}</li>
              <li onClick={() => navigate('/hot-brands')} className="footerMenuItem">{t('footer.shop.brands')}</li>
            </ul>

          </div>

          <div className="menuSection">
            <div className='header-mobile-section' onClick={() => toggleSection('other')}>
              <p className="footerMenuHeader">{t('footer.other.header')}</p>
              <span className="toggleIcon">
                {expandedSection === 'other' ? '-' : '+'}
              </span>
            </div>
            <span className={`arrow ${expandedSection === 'other' ? 'up' : 'down'}`}></span>
            <ul className={`footerMenuList ${expandedSection === 'other' ? 'expanded' : ''}`}>
              <li onClick={() => navigate('/faq')} className="footerMenuItem">{t('footer.other.faq')}</li>
              <li className="footerMenuItem" onClick={handleAboutUsNavigation}>{t('footer.other.aboutUs')}</li>
              <li className="footerMenuItem" onClick={handleEmail}>{t('footer.other.contactUs')}</li>
            </ul>
          </div>
          <div className="menuSection">
            <div className='header-mobile-section' onClick={() => toggleSection('social')}>
              <p className="footerMenuHeader">{t('footer.social.header')}</p>
              <span className="toggleIcon">
                {expandedSection === 'social' ? '-' : '+'}
              </span>
            </div>
            <span className={`arrow ${expandedSection === 'social' ? 'up' : 'down'}`}></span>
            <ul className={`footerMenuList ${expandedSection === 'social' ? 'expanded' : ''}`}>
              <a className="footerMenuItem" onClick={() => handleNavigate("https://www.instagram.com/olis.lab")}>{t('socialMedia.instagram')}</a>
              <a className="footerMenuItem" onClick={() => handleNavigate("https://www.linkedin.com/company/olis-lab")}>{t('socialMedia.linkedin')}</a>
              <a className="footerMenuItem" onClick={() => handleNavigate("https://www.tiktok.com/@olis_lab")}>{t('socialMedia.tiktok')}</a>
            </ul>
          </div>
        </div>

        <div className="policyBlock">
          <div className="allRightsContainer">
          <p className="allRights">{t('footer.allRights')}</p>
          {window.innerWidth < 768 && <LanguageSelector />}
          </div>
          {window.innerWidth > 768 && <LanguageSelector />}
          <div className="policies">
            <p className="policyBtn" onClick={() => navigate('/legal/terms')}>
            {t('footer.termsOfUse')}
            </p>
            <p className="policyBtn" onClick={() => navigate('/legal/privacy')}>
            {t('footer.privacyPolicy')}
            </p>
          </div>
        </div>
      </div>)}

      <div className="footerBottom">
        <div
          className="footerLogo"
          style={{ backgroundImage: `url(${WhiteLogo})` }}
        />
        <p className="footerAdvice">Take better care of yourself</p>
        {isComingSoonPage && (
          <div className="mediaLinksContainer">
            <Link className="mediaLinks" to="https://www.instagram.com/olis.lab" target="_blank" rel="noopener noreferrer">{t('socialMedia.instagram')}</Link>
            <Link className="mediaLinks" to="https://www.linkedin.com/company/olis-lab" target="_blank" rel="noopener noreferrer">{t('socialMedia.linkedin')}</Link>
            <Link className="mediaLinks" to="https://www.tiktok.com/@olis_lab" target="_blank" rel="noopener noreferrer">{t('socialMedia.tiktok')}</Link>
          </div>
        )}
      </div>

      {(isComingSoonPage && (
        <p className="allRightsComingSoon">{t('footer.trademark')}</p>
      ))}
    </div>
  );
};
