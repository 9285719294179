import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import userService from 'service/UserService';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { StepIndicator } from 'components/counter/counter';
import styles from '../style.module.scss';
import zero from '../../../assets/numbers/10.png';
import one from '../../../assets/numbers/01.png';
import two from '../../../assets/numbers/02.png';
import three from '../../../assets/numbers/03.png';
import four from '../../../assets/numbers/04.png';
import { useTranslation } from 'react-i18next';

export const SPF_Resume: React.FC = () => {
  const [skinProfile] = useAtom(skinProfileAtom);
  const [user, setUser] = useAtom(userAtom);
  const {t} = useTranslation()
  const navigate = useNavigate();

  const saveProfile = async () => {
    if (!user || !user._id) {
      console.error('User is not logged in');
      return;
    }

    try {
      user && skinProfile && (await userService.updateUserSPF(user, setUser, skinProfile));
      navigate('/spf-form/spf-analyze');
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  const handleEdit = (path: string) => {
    navigate(path);
  };
  
  

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <div className={styles.groupOne}>
            <StepIndicator currentStep={6} /> {/* Step 5 */}
            <div className={styles.titleContainer}>
              <h1 className={styles.TitlesSpf}>{t('spfForm.resume.title')}</h1>
            </div>
            <div className={styles.subtitleContainer}>
              <h5 className={styles.SubtitleSpf}>{t('spfForm.resume.subtitle')}</h5>
            </div>
          </div>

          <div className={styles.skinReviewContainer}>
            <div className={styles.skinResumeSection}>
              <div className={styles.skinReviewNumberContainer}>
                <img src={zero} alt="0" className={styles.skinReviewNumber} />
                <img src={one} alt="1" className={styles.skinReviewNumber} />
              </div>
              <div className={styles.skinReviewValueContainer}>
                <p className={styles.skinReviewValueTitle}>{t('spfForm.resume.skinTypeTitle')}</p>
                <p className={styles.skinReviewValue}>{skinProfile?.textToRender?.skinType ? t(skinProfile.textToRender.skinType) : t('spfForm.resume.notSpecified')}</p>
              </div>
              <div className={styles.skinReviewEditButtonContainer}>
                <button className={styles.skinReviewEditButton} onClick={() => handleEdit('/spf-form/spf-skin-type')}>
                  {t('spfForm.resume.editButton')}
                </button>
              </div>
            </div>
            {/* Concerns */}
            <div className={styles.skinReviewSection}>
              <div className={styles.skinResumeSection}>

                <div className={styles.skinReviewNumberContainer}>
                  <img src={zero} alt="0" className={styles.skinReviewNumber} />
                  <img src={two} alt="1" className={styles.skinReviewNumber} />
                </div>
                <div className={styles.skinReviewValueContainer}>
                  <p className={styles.skinReviewValueTitle}>{t('spfForm.resume.concernsTitle')}</p>
                  <p className={styles.skinReviewValue}>{skinProfile?.textToRender?.concerns?.length ? skinProfile.textToRender.concerns.map((key) => t(key)).join(', ') : t('spfForm.resume.notSpecified')}</p>
                </div>
                <div className={styles.skinReviewEditButtonContainer}>
                  <button className={styles.skinReviewEditButton} onClick={() => handleEdit('/spf-form/spf-concerns')}>
                    {t('spfForm.resume.editButton')}
                  </button>
                </div>
              </div>
            </div>
            {/* Risks */}
            <div className={styles.skinReviewSection}>
              <div className={styles.skinResumeSection}>
                <div className={styles.skinReviewNumberContainer}>
                  <img src={zero} alt="0" className={styles.skinReviewNumber} />
                  <img src={three} alt="1" className={styles.skinReviewNumber} />
                </div>
                <div className={styles.skinReviewValueContainer}>
                  <p className={styles.skinReviewValueTitle}>{t('spfForm.resume.risksTitle')}</p>
                  <p className={styles.skinReviewValue}>{skinProfile?.textToRender?.risks?.length
                    ? skinProfile.textToRender.risks.map((key) => t(key)).join(', ') : t('spfForm.resume.notSpecified')}</p>
                </div>
                <div className={styles.skinReviewEditButtonContainer}>
                  <button className={styles.skinReviewEditButton} onClick={() => handleEdit('/spf-form/spf-risks')}>
                    {t('spfForm.resume.editButton')}
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.groupThree}>
              <div className={styles.spfbuttonContainerWide}>
                <button className={styles.nextButtonForm} onClick={saveProfile}>
                  {t('spfForm.resume.confirmButton')}
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div >
  );
};
