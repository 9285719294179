import './style.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { IProductInCart } from '../../types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import userService from 'service/UserService';
import { useAtom } from 'jotai';
import { userAtom } from '../../stores/UserProfile';
import emptyCart from '../../assets/icons/emptyCart.svg';
import BlackCounter from 'assets/images/blackCounter.png';
import {
  loadLocalCart,
  removeFromLocalCart,
  updateLocalCartItemQuantity
} from '../../service/localCartService';
import { useFavoriteHandler } from '../../utils/faveoritUtil';
import userApi from 'service/API/UserApi';
import productsApi from 'service/API/ProductsApi';
import { useFetchFavorites } from 'hooks/fetchFavorites';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

interface CartProps {
  isOpen: boolean;
  onClose: () => void;
  cartItems: IProductInCart[];
  setCartItems: (
    items:
      | IProductInCart[]
      | ((prevItems: IProductInCart[]) => IProductInCart[])
  ) => void;
}

const CART_CONSTANTS = {
  DEFAULT_QUANTITY: '0',
  DEFAULT_SIZE: '250ML',
  CURRENCY: '€',
  NO_PRICE: 'cart.noPrice',
  NO_TITLE: 'cart.noTitle',
  NO_DESCRIPTION: 'cart.noDescription',
  EMPTY_CART_MESSAGE: 'cart.emptyCart',
} as const;

const Cart: React.FC<CartProps> = ({
  isOpen,
  onClose,
  cartItems,
  setCartItems,
}) => {
  const [fadeClass, setFadeClass] = useState('');
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [tempQuantity, setTempQuantity] = useState<number | null>(null);
  const [currentUser, setUser] = useAtom(userAtom);
  const [freshProducts, setFreshProducts] = useState<{ [productId: string]: any }>({});
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation()

  const {currentLanguage} = useCurrentLanguage()

  // Favorites handling
  const { favorites, setFavorites, handleFavoriteToggle } = useFavoriteHandler(userApi);
  useFetchFavorites(setFavorites); // optional custom hook that fetches user favorites

  // 1. On mount, decide cart items from user or local storage
  useEffect(() => {
    if (currentUser) {
      setCartItems(currentUser.cart);
    } else {
      const localCartItems = loadLocalCart();
      setCartItems(localCartItems);
    }
  }, [currentUser, setCartItems]);

  // 2. Prevent scrolling background when cart is open
  useEffect(() => {
    if (isOpen) {
      setFadeClass('fade-in');
      document.body.style.overflow = 'hidden';
    } else {
      setFadeClass('');
      document.body.style.overflow = '';
    }
  }, [isOpen]);


  const fetchFreshProducts = async () => {
    try {
      // 1) Gather all product IDs from cart items
      const productIds = cartItems.map((item) => item.product._id);
      if (productIds.length === 0) return;

      // 2) Fetch fresh data from the server
      const freshData = await productsApi.getProductsByArrayOfIds(productIds);

      // 3) Build a map: { [productId]: { ...freshFields } }
      const freshProductsMap = freshData.data?.reduce(
        (acc: any, product: any) => {
          acc[product._id] = product;
          return acc;
        },
        {}
      );

      setFreshProducts(freshProductsMap || {});

      // 4) Update the cart depending on user
      if (currentUser && freshProductsMap) {
        // If logged in
        const updatedCart = currentUser.cart.map((cartItem) => {
          const freshProd = freshProductsMap[cartItem.product._id];
          if (freshProd) {
            return {
              ...cartItem,
              product: {
                ...cartItem.product,
                title: freshProd.title,
                brand: freshProd.brand,
                imgUrl: freshProd.imgUrl,
                price: freshProd.price,
                quantity: freshProd.quantity,
              },
            };
          }
          return cartItem;
        });

        const updatedUser = { ...currentUser, cart: updatedCart };
        setUser(updatedUser);
        setCartItems(updatedCart);
      } else {
        // If no user, update local cart
        const localCartItems = loadLocalCart();
        const updatedLocal = localCartItems.map((cartItem) => {
          const freshProd = freshProductsMap[cartItem.product._id];
          if (freshProd) {
            return {
              ...cartItem,
              product: {
                ...cartItem.product,
                price: freshProd.price,
              },
            };
          }
          return cartItem;
        });
        localStorage.setItem('localCart', JSON.stringify(updatedLocal));
        setCartItems(updatedLocal);
      }
    } catch (error) {
      console.error('Error fetching fresh product data:', error);
    }
  };

  // 4. Fetch fresh product data only once each time the cart is opened
  const [dataFetched, setDataFetched] = useState(false);
  useEffect(() => {
    if (!isOpen || dataFetched) return; // only fetch if cart is opening and data has not been fetched yet
    fetchFreshProducts();
    setDataFetched(true); // Set data fetched to true after fetching

    return () => setDataFetched(false); // Reset on component unmount or when cart closes
  }, []);

  /**
   *  Utility methods for cart
   */
  const getTotalItems = useCallback(
    () => cartItems.reduce((total, item) => total + item.quantity, 0),
    [cartItems]
  );

  const updateCart = (updatedCartItems: IProductInCart[]) => {
    setCartItems(updatedCartItems);
  };

  const getTotalPrice = useCallback(() => {
    if (cartItems.length === 0) return '0.00';
    const total = cartItems.reduce((sum, item) => {
      const freshPrice = freshProducts[item.product._id]?.price;
      const priceToUse = freshPrice ?? item.product.price ?? 0;
      return sum + item.quantity * Number(priceToUse);
    }, 0);
    return total.toFixed(2);
  }, [cartItems, freshProducts]);

  // Removing a product
  const removeItemFromCart = async (id: string) => {
    if (currentUser) {
      const product = cartItems.find((item) => item.product._id === id);
      if (!product) return console.error('Product not found in cart.');

      const result = await userService.removeFromCart(
        currentUser,
        setUser,
        product,
        navigate
      );
      if (result.success) {
        const updatedCartItems = cartItems.filter(
          (item) => item.product._id !== id
        );
        updateCart(updatedCartItems);
      } else {
        console.error('Failed to remove product from cart:', result.message);
      }
    } else {
      const updatedCart = removeFromLocalCart(id);
      updateCart(updatedCart);
    }
  };

  // Editing quantity
  const handleEditQuantity = (id: string) => {
    setEditingItemId(id);
    const item = cartItems.find((i) => i.product._id === id);
    if (item) setTempQuantity(item.quantity);
  };

  const changeItemQuantity = (id: string, newQuantity: number) => {
    setTempQuantity(Math.max(1, newQuantity));
  };

  const handleApproveChange = async (id: string) => {
    try {
      const product = cartItems.find((item) => item.product._id === id);
      if (!product) return console.error('Product not found in cart');

      if (currentUser) {
        // 1) Update in local state
        const updatedCartItems = cartItems.map((item) =>
          item.product._id === id ? { ...item, quantity: tempQuantity! } : item
        );
        updateCart(updatedCartItems);
        setEditingItemId(null);

        // 2) Update on server
        const result = await userService.updateCart(
          currentUser,
          setUser,
          product.product,
          tempQuantity!,
          navigate
        );
        if (!result.success) {
          console.error('Failed to update cart on server:', result.message);
        }
      } else {
        // local storage
        const updatedCart = updateLocalCartItemQuantity(id, tempQuantity!);
        updateCart(updatedCart);
        setEditingItemId(null);
      }
    } catch (error) {
      console.error('Failed to update cart:', error);
    }
  };

  // Checkout
  const handleCheckout = async () => {
    await fetchFreshProducts();
    navigate('/checkout');
  };

  // Return null if cart is closed
  if (!isOpen) return null;

  // Generate cart items
  const generateCartItems = (): React.ReactNode => {
    if (cartItems.length === 0) {
      return (
        <div className="empty-cart">
          <img src={emptyCart} alt="Empty Cart" className="empty-cart-image" />
          <p className="empty-cart-text">
            {t(CART_CONSTANTS.EMPTY_CART_MESSAGE)}
          </p>
        </div>
      );
    }

    return cartItems.map((item) => (
      <div key={item.product._id} className="cart-item">
        {renderItemImage(item)}
        <div className="item-details">
          {renderItemHeader(item)}
          {renderItemDescription(item)}
          <p style={{ marginTop: '10px' }}>{renderItemSize(item)}</p>
          {renderSaveProduct(item)}
        </div>
        <div className="item-price">
          {renderItemQuantity(item)} * {renderItemPrice(item)}
          {renderItemActions(item)}
        </div>
      </div>
    ));
  };

  const renderItemImage = (item: IProductInCart) => (
    <img
      src={item.product?.imgUrl || emptyCart}
      alt={item.product?.translations?.[currentLanguage]?.title || 'Empty Cart'}
      className="cart-item-image"
    />
  );

  const renderItemHeader = (item: IProductInCart) => (
    <h5 className="item-header">
      {item?.product?.translations?.[currentLanguage]?.title || t(CART_CONSTANTS.NO_TITLE)}
    </h5>
  );

  const renderItemDescription = (item: IProductInCart) => (
    <span className="item-description">
      {item?.product?.translations?.[currentLanguage]?.description || t(CART_CONSTANTS.NO_DESCRIPTION)}
    </span>
  );

  const renderItemSize = (item: IProductInCart) =>
    item?.product?.quantity
      ? `${item?.product?.quantity}ML`
      : CART_CONSTANTS.DEFAULT_SIZE;

  const renderSaveProduct = (item: IProductInCart) => (
    <div className="save-product-container-cart">
      <button
        className="save-product-button-cart"
        onClick={() => handleFavoriteToggle(item.product._id)}
      >
        {favorites[item?.product?._id] ? t('cart.removeFavorite') : t('cart.addFavorite')}
      </button>
    </div>
  );

  const renderItemQuantity = (item: IProductInCart) =>
    item?.quantity || CART_CONSTANTS.DEFAULT_QUANTITY;

  const renderItemPrice = (item: IProductInCart) => {
    const freshPrice = freshProducts[item?.product?._id]?.price;
    const priceToUse = freshPrice ?? item?.product?.price ?? 0;
    return (
      <span>
        {CART_CONSTANTS.CURRENCY}
        {Number(priceToUse).toFixed(2)}
      </span>
    );
  };

  const renderItemActions = (item: IProductInCart) =>
     editingItemId === item?.product?._id ? (
      <div className="quantity-controls">
        <div className="quantity-controls-container">
          <button
            onClick={() => changeItemQuantity(item?.product._id, tempQuantity! - 1)}
          >
            -
          </button>
          <span className="quantity-controls-text">{tempQuantity}</span>
          <button
            onClick={() => changeItemQuantity(item?.product._id, tempQuantity! + 1)}
          >
            +
          </button>
        </div>
        <button onClick={() => handleApproveChange(item?.product._id)}>
          {t('cart.approve')}
        </button>
      </div>
    ) : (
      <div className="default-controls">
        <div className="change-controls-hover">
          <button
            onClick={() => handleEditQuantity(item.product._id)}
            style={{ fontWeight: 700 }}
          >
            {t('common.change')}
          </button>
        </div>
        <button onClick={() => removeItemFromCart(item.product._id)}>
          {t('common.delete')}
        </button>
      </div>
    );


  // Cart header
  const cartHeader = () => {
    const isCartEmpty = getTotalItems() === 0;
    return (
      <div className={isCartEmpty ? 'cart-header-empty' : 'cart-header'}>
        {/* We have an extra button here if needed, but hidden for now */}
        <span className="favorites-btn" style={{ visibility: 'hidden' }}>
          {t('cart.favorites')}
        </span>
        <span className="close-btn" onClick={onClose}>
          {t('common.close')}
          <div
            className="item-count"
            style={{ backgroundImage: `url(${BlackCounter})` }}
          >
            {getTotalItems()}
          </div>
        </span>
      </div>
    );
  };

  // Cart footer
  const cartFooter = () => (
    cartItems.length > 0 ? (
      <>
        {!isMobile && (
          <div className="subtotal">
            <strong>{t('cart.subtotal', { totalItems: getTotalItems() })}</strong>
            <span className="subtotal-price">
              {CART_CONSTANTS.CURRENCY}
              {getTotalPrice()}
            </span>
          </div>
        )}
        <button className="checkout-btn" onClick={handleCheckout}>
          {t('cart.checkout')}
        </button>
      </>
    ) : (
      <button className="checkout-btn" onClick={() => navigate('/bestsellers')}>
        {t('common.discoverBestsellers')}
      </button>
    )
  );

  // Determine cart height
  const calculateCartHeight = () => {
    const itemCount = cartItems.length;
    const isTallScreen = window.innerHeight > 900;

    if (isMobile) {
      return '90vh'; // Fill most of the screen height on mobile
    } else {
      if (isTallScreen) {
        if (itemCount === 0) return '360px';
        if (itemCount === 1) return '360px';
        if (itemCount === 2) return '500px';
        if (itemCount === 3) return '600px';
        return '750px';
      } else {
        // Standard desktop height
        if (itemCount === 0) return '360px';
        if (itemCount === 1) return '360px';
        if (itemCount === 2) return '450px';
        if (itemCount === 3) return '600px';
        return '700px';
      }
    }
  };

  return (
    <div className={`cart-overlay ${fadeClass}`}>
      <div className="cart-modal" style={{ height: calculateCartHeight() }}>
        {cartHeader()}
        <div className="cart-items">
          {generateCartItems()}
          {isMobile && cartItems.length > 0 && (
            <div className="subtotal" style={{ marginTop: '10px' }}>
              <strong>{t('cart.subtotal', { totalItems: getTotalItems() })}</strong>
              <span className="subtotal-price">
                {CART_CONSTANTS.CURRENCY}
                {getTotalPrice()}
              </span>
            </div>
          )}
        </div>
        <div className="cart-footer">{cartFooter()}</div>
      </div>
    </div>
  );
};


export default Cart;
