import react from 'react';
import styles from './oli.module.scss';
import { useTranslation } from 'react-i18next';

interface WhyOliProps {
    whyOLI: string; // Ensure this is defined as a string
    brandName?: string; // Optional brandName prop
}

export const WhyOli: React.FC<WhyOliProps> = ({ whyOLI, brandName }) => {
    const {t} = useTranslation()

    return (
        <div className={styles.whyOliContainer}>
            <div className={styles.InnerContainer}>
                <h1 className={styles.title}>{t('brands.whyOli')} {brandName}</h1>
                <div className={styles.textContainer}>
                    <p className={styles.text}>{whyOLI}</p>
                </div>
            </div>

        </div>
    );
};