import React from 'react';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import HeaderImg from 'assets/textFont/prod .svg'; // Ensure no space in filename
import WhiteDot from 'assets/icons/whiteDot.svg';
import DefaultImage from 'assets/images/86.jpg'; // Renamed for clarity
import { useTranslation } from 'react-i18next';

// 1. Define the Props Interface
interface BrowsingThroughProps {
    image?: string; // Optional prop for the image URL]
    title?: string; // Optional prop for the title
    brandName?: string; // Optional prop for the brandName
    description?: string; // Optional prop for the description
    link?: string;
}

// 2. Update the Component to Use Props and Interface
export const Banner4: React.FC<BrowsingThroughProps> = ({
    image = DefaultImage,
    title ,
    description ,
    brandName,
    link = ''
}) => {
    const navigate = useNavigate();
    const isBrandPage: boolean = window.location.pathname.includes('/brand');
    const { t } = useTranslation();

    const handleShopClick = (): void => {
        navigate('/shop');
    };
    const handleReadClick = (): void => {
        if (!isBrandPage) {
            if (!link) {
                navigate(`/feed`); // Call navigate directly if not on brand page
            } else {
                navigate(`/feed/article/${link}`); // Call navigate directly if not on brand page
            }
        }
    };

    const isMobile: boolean = window.innerWidth < 768;

    return (
        <div className={styles.SaferIngredientsContainer} onClick={handleReadClick}>
            <div style={{ paddingLeft: 20, paddingTop: !isMobile ? 0 : 0, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <div>
                    <p className={styles.SaferIngredientsheaderDescription}>
                    {brandName || t('banners.brandName')}
                    </p>
                    <p
                        style={{ lineHeight: 1, marginTop: 30 }}
                        className={styles.SaferIngredientsheaderHeader}
                    >
                        {title || t('banners.browsingThroughTitle')}
                    </p>
                </div>


                <div className={styles.containerBottom}>

                    <div className={styles.dotReadMoreWrapper}>
                        <div
                            className={styles.SaferIngredientsDot}
                            style={{
                                backgroundImage: `url(${WhiteDot})`,
                                height: 8.34,
                                width: 9.02,
                                objectFit: 'contain',
                                marginRight: 4,    // a little spacing
                            }}
                        />
                        {!isBrandPage && (
                            <p className={styles.readMore}>{t('banners.readMore')}</p>

                        )}
                    </div>

                    {/* 2. The description on its own line */}
                    <p className={styles.SaferIngredientsDescription}>
                    {description || t('banners.description')}
                    </p>
                </div>
            </div>
            <img
                alt="Safer Ingredients Background"
                className={styles.SaferIngredientsContainerIMG}
                src={image} // Use the image prop or default
            />
        </div>
    );
};
