import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import categoriesApi from 'service/API/categoriesApi';

import styles from './styles.module.scss';

// Import Swiper's CSS
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

interface SubcategoryName {
    en: string;
    fr: string;
}

interface Subcategory {
    name: SubcategoryName;
    imgUrl: string;
}

interface SubcategoryDetail {
    name: SubcategoryName;
    image: string;
    text: string;
}

export const SubCategoriesSection = () => {
    const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
    const [categoryFromURL, setCategoryFromURL] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentLanguage } = useCurrentLanguage();
    const isMobile = window.innerWidth <= 440;
    const [mobileDataArray, setMobileDataArray] = useState<SubcategoryDetail[]>([]);

    // Fetch subcategories based on the category from the URL
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoryFromURLParam = searchParams.get('category')?.toLocaleLowerCase();
        setCategoryFromURL(categoryFromURLParam || '');

        if (categoryFromURLParam) {
            const fetchSubcategories = async () => {
                try {
                    const response = await categoriesApi.getSubcategories(categoryFromURLParam);
                    setSubcategories(response || []);
                } catch (error) {
                    console.error('Error fetching subcategories:', error);
                }
            };
            fetchSubcategories();
        }
    }, [location.search]);

    // Get the subcategories array from the fetched data
    const subcategoriesArray = subcategories;

    // Updated mapping for mobile data array
    useEffect(() => {
        if (subcategoriesArray.length > 0) {
            const mappedData = subcategoriesArray.map((subcategory: Subcategory) => ({
                name: subcategory.name,
                image: subcategory.imgUrl,
                text: subcategory.name[currentLanguage],
            }));
            setMobileDataArray(mappedData);
        }
    }, [subcategoriesArray, currentLanguage]);

    const handleSubcategoryClick = (subcategoryName: SubcategoryName, subCategoryImgUrl: string) => {
        // Use English name for URL/routing
        const encodedSearchQuery = encodeURIComponent(subcategoryName.en.toLowerCase());
        navigate(`/collection/products?search=${categoryFromURL}=${encodedSearchQuery}`, {
            state: { subCategoryImgUrl },
        });
    };

    const MobileSwipper = () => (
        <div className={styles.swiperContainer}>
            <Swiper
                spaceBetween={10}
                slidesPerView={2.5}
                className={styles.productsCarouselSliderBlock}
            >
                {mobileDataArray.map((item) => (
                    <SwiperSlide key={item.name.en}>
                        <div className={styles.mobileSubCategoriesRoundContainer}>
                            <div 
                                className={styles.mobileImageContainer} 
                                onClick={() => handleSubcategoryClick(item.name, item.image)}
                            >
                                <img src={item.image} alt={item.name[currentLanguage]} className={styles.subCategoriesImage} />
                            </div>
                            <div className={styles.subCategoriesTextContainer}>
                                <p className={styles.subCategoriesText}>{item.name[currentLanguage]}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );

    // Updated desktop version mapping
    const renderDesktopSubcategories = subcategoriesArray.map((subcategory: Subcategory) => (
        <div key={subcategory.name.en} className={styles.subCategoriesGrid}>
            <div
                className={styles.subCategoriesRoundContainer}
                onClick={() => handleSubcategoryClick(subcategory.name, subcategory.imgUrl)}
            >
                <div className={styles.imageContainer}>
                    <img 
                        src={subcategory.imgUrl} 
                        alt={subcategory.name[currentLanguage]} 
                        className={styles.subCategoriesImage} 
                    />
                </div>
                <div className={styles.subCategoriesTextContainer}>
                    <p className={styles.subCategoriesText}>
                        {subcategory.name[currentLanguage]}
                    </p>
                </div>
            </div>
        </div>
    ));

    return (
        <>
            {subcategoriesArray && subcategoriesArray.length > 0 ? (
                <div className={styles.subCategoriesSection}>
                    <div className={styles.subCategory}>
                        <h1 className={styles.subCategoriesTitle}>
                            {categoryFromURL ? `${categoryFromURL} ${t('categories.productsCountLabel')}` : t('categories.productsCountLabel')}
                        </h1>
                    </div>
                    {isMobile ? (
                        <MobileSwipper />
                    ) : (
                        <div className={styles.rowCategories}>
                            {renderDesktopSubcategories}
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
};

export default SubCategoriesSection;
