import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { TRisk } from '../../../types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { userAtom } from 'stores/UserProfile';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import { StepIndicator } from 'components/counter/counter';
import styles from '../style.module.scss';
import { useTranslation } from 'react-i18next';
export const SPF_Risks: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const [selectedRisks, setSelectedRisks] = useState<TRisk[]>([]);
  const {t} = useTranslation()
  // const risks: TRisk[] = [
  //   'Allergy or Allergen Sensitivity',
  //   'Cancers',
  //   'Immunotoxicity',
  //   'Skin Sensitivity',
  //   'Endocrine Disruptor',
  //   'To Define'
  // ];
  const riskMapping: Record<TRisk, string> = {
    'Allergy or Allergen Sensitivity': 'spfForm.risks.options.allergy',
    'Cancers': 'spfForm.risks.options.cancers',
    'Immunotoxicity': 'spfForm.risks.options.immunotoxicity',
    'Skin Sensitivity': 'spfForm.risks.options.skinSensitivity',
    'Endocrine Disruptor': 'spfForm.risks.options.endocrineDisruptor',
    'To Define': 'spfForm.risks.options.toDefine',
  };

  const risks: TRisk[] = Object.keys(riskMapping) as TRisk[];

  const handleRiskSelect = (risk: TRisk) => {
    setSelectedRisks((prev) => (prev.includes(risk) ? prev.filter((r) => r !== risk) : [...prev, risk]));
  };

  useEffect(() => {
    if (user && user.SPF && Array.isArray(user.SPF.risks)) {
      setSelectedRisks(user.SPF.risks);
    } else {
      console.warn('No valid risks found or user is null.');
    }
  }, [user]);

  const goToNext = () => {
    if (selectedRisks.length) {
      setSkinProfile({ ...skinProfile, risks: selectedRisks,
        textToRender: {
          ...skinProfile?.textToRender,
          risks: selectedRisks.map((risk) => riskMapping[risk]), 
        },
       });
      navigate('/spf-form/spf-resume');
    }
  };

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <div className={styles.groupOne}>
            <StepIndicator currentStep={5} />
            <div className={styles.titleContainer}>
              <h1 className={styles.TitlesSpf}>{t('spfForm.risks.title')}</h1>
            </div>
            <div className={styles.subtitleContainer}>
              <h5 className={styles.SubtitleSpf}>{t('spfForm.age.subtitle')}</h5>
            </div>
          </div>

          <div className={styles.groupTwoLongList}>
            <div className={styles.inputGroup}>
              {risks.map((risk) => (
                <button
                  key={risk}
                  className={`${styles.inputButtons} ${selectedRisks.includes(risk) ? styles.selected : ''}`}
                  onClick={() => handleRiskSelect(risk)}
                >
                  {t(riskMapping[risk])}
                </button>
              ))}
            </div>
          </div>

        </div>
        <div className={styles.groupThree}>
          <div className={`${styles.spfbuttonContainer} ${!selectedRisks.length ? styles.disabled : ''}`}>
            <button
              className={styles.nextButtonForm}
              disabled={!selectedRisks.length}
              onClick={goToNext}
            >
              {t('common.nextButton')}
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};
