import './style.scss';
import Background from 'assets/images/formBackground2.png';
import FontImg from 'assets/textFont/latestTrends.svg';
import latetTrendsFont from 'assets/textFont/Latest trends1.svg';
import latetTrendsFont2 from 'assets/textFont/Latest trends2.svg';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface LatestTrendsProps {
  isComingSoonPage?: boolean;
}

export const LatestTrends: React.FC<LatestTrendsProps> = ({ isComingSoonPage = false }) => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const handleSeeAllArticles = () => {
    navigate('/feed');
  };

  return (
    <div className="LatestTrendsContainer" style={{ backgroundImage: `url(${Background})` }}>
      {window.innerWidth > 768 ? (
        <img src={FontImg} alt="Latest Trends" className="LatestTrendsContainerTextIMG" />
      ) : (
        <>
          <img src={latetTrendsFont} alt="Latest Trends" className="LatestTrendsContainerTextIMG" />
          <img src={latetTrendsFont2} alt="Latest Trends" className="LatestTrendsContainerTextIMG" />
        </>
      )}

      {!isComingSoonPage && (<p className="LatestTrendsContainerHeader">{t('homepage.latestTrends.description')}</p>)}
      {isComingSoonPage && (<p className="LatestTrendsContainerHeader">{t('homepage.latestTrends.editorialsDescription')}</p>)}
      {!isComingSoonPage && (<div className="LatestTrendsContainerButton">
        <button className="LatestTrendsContainerButtonText" onClick={handleSeeAllArticles}>{t('homepage.latestTrends.seeAll')}</button>
      </div>)}
    </div>
  );
};
