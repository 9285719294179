import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import categoriesApi from 'service/API/categoriesApi';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

interface Category {
    name: {
        [key: string]: string;
    };  
    imgUrl: string;
    smallImgUrl: string;
}

const CategoriesSection = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const {t} = useTranslation()
    const {currentLanguage} = useCurrentLanguage();
    const navigate = useNavigate();
    useEffect(() => {
        categoriesApi.getAllCategories().then((res) => {
            setCategories(res || []);
        }).catch((err: any) => {
            console.error("Failed to fetch categories:", err);
        });
    }, []);

    // Handle navigation on category click
    const handleCategoryClick = (category: string, categoryImgUrl: string) => {
        const encodedCategory = encodeURIComponent(category);
        navigate(`/categories?category=${encodedCategory}`, {
            state: { categoryImgUrl },
        });
    };

    if (categories.length === 0) {
        return null;
    }

    return (
        <div className={styles.categoriesSection}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <h1 className={styles.title}>{t('categories.title')}</h1>
                </div>
                <div className={styles.countContainer}>
                    <p className={styles.count}>{t('categories.count', { count: categories.length })}</p>

                </div>
            </div>
            <div className={styles.categoriesContainer}>
                <div className={styles.categoriesGrid}>
                    {categories.length > 0 ? (
                        categories.map((category, index) => {
                            if(!category.imgUrl) return null;
                            return (
                            <div key={index} className={styles.category}>
                                <img
                                    className={styles.categoryImage}
                                    src={category.smallImgUrl}
                                    alt={category.name[currentLanguage]}
                                    onClick={() => handleCategoryClick(category.name['en'], category.imgUrl)}
                                />
                            </div>
                            )
                        })
                    ) : (
                        <p className={styles.noCategoriesText}>{t('categories.noCategories')}</p>
                    )}
                </div>
            </div>
        </div>
    );

};

export default CategoriesSection;
