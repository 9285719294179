import api from './apiConfig';

export default class CollectionApi {
    static async getTilesByName(names: string[]) {
        try {
            const response = await api.post('/collections/get-collections', {
                names: names
            });
            return response.data.categories;
        } catch (error) {
            console.error('Error fetching collections:', error);
            return [];
        }
    }
}