import React, { useState, useRef } from 'react';
import styles from './style.module.scss';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { config } from 'config';
import { Autoplay, Navigation } from 'swiper/modules';
import TextHeadSection from '../../../components/homepage/dynamicHeadSection/textHeadSection/textHeadSection';

SwiperCore.use([Navigation, Autoplay]);

interface HeadSectionProps {
  carousel: string[];
}

export const ShopHeadSection: React.FC<HeadSectionProps> = ({ carousel }) => {
  const [activeSlide, setActiveSlide] = useState(0);    // Start at 0 for the first slide
  const swiperInstance = useRef<SwiperCore | null>(null);

  // Filter out empty URLs
  const validCarousel = carousel.filter((url) => url && url.trim() !== '');

  // Handler for the buttons
  const handleButtonClick = (slideIndex: number) => {
    if (swiperInstance.current) {
      // Go directly to the zero-based index
      swiperInstance.current.slideTo(slideIndex);
    }
    setActiveSlide(slideIndex);
  };

  const imageNames: string[] = validCarousel
    .map((url) => {
      try {
        const urlObj = new URL(url);
        const pathSegments = urlObj.pathname.split('/');
        const filename = pathSegments[pathSegments.length - 1];
        const nameWithoutExt =
          filename.substring(0, filename.lastIndexOf('.')) || filename;
        // Remove trailing digits
        const cleanName = nameWithoutExt.replace(/\d+$/, '');
        return cleanName;
      } catch (error) {
        console.error(`Invalid URL: ${url}`, error);
        return '';
      }
    })
    .filter((name) => name !== '');

  const renderImages = (
    <>
      <div className={styles.sliderContainer}>
        <Swiper
          onSwiper={(swiper: SwiperCore) => {
            swiperInstance.current = swiper;
          }}
          loop={true}
          spaceBetween={0}
          slidesPerView={1}
          // autoplay={{
          //   delay: config.REACT_APP_DELAY,
          //   disableOnInteraction: false,
          // }}
          onSlideChange={(swiper: SwiperCore) => setActiveSlide(swiper.realIndex)}
          modules={[Autoplay, Navigation]}
        >
          {/* Buttons */}
          <div className={styles.navigationButtonsContainer}>
            {validCarousel.map((_, index) => (
              <button
                key={index}
                className={`
                    ${styles.dynamicButton} 
                    ${activeSlide === index ? '' : styles.blurred}
                  `}
                onClick={() => handleButtonClick(index)}
              >
                {String(index + 1).padStart(2, '0')}
              </button>
            ))}
          </div>

          {/* Slides */}
          {validCarousel.map((img, index) => (
            <SwiperSlide key={index}>
              <div
                className={styles.slideContent}
                style={{ backgroundImage: `url(${img})` }}
              >
                <TextHeadSection imagesName={imageNames} index={index} />
              </div>

            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );

  return (
    <>
      <HomepageNavbar />
      <div className={styles.headerBlockHome}>
        {renderImages}
      </div>
    </>
  );
};
