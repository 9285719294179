import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { CarouselService } from 'components/carousels/service/carouselService';
import { brandOfTheWeek } from 'utils/brandOfTheWeek';
import { useTranslation } from 'react-i18next';




export const BestSellersBrandSection: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isShopPage = location.pathname.includes('/shop');
    const onClickHandler = () => {
        navigate(`/brand/${encodeURIComponent(brandOfTheWeek)}`);
    };
    const { t } = useTranslation();

    return (
        <div className={styles.bestsellersContainer} style={{ backgroundColor: '#F7F6E7' }}>
            <div className={styles.UpperSectionBlock}>
                <div className={styles.headerBrandingContainer}>
                    <p className={styles.headerBranding}>{t('homepage.bestSellersBrandSection.header', { brand: brandOfTheWeek })}</p>
                </div>
                {!isShopPage && (
                    <div className={styles.bestsellersDescriptionContainer}>
                        <p className={`${styles.bestsellersDescription} ${styles.withPseudoQuotes}`}>
                            {t('homepage.bestSellersBrandSection.description')}
                        </p>
                    </div>
                )}
            </div>

            <div className={styles.bestsellersCarouselContainer}>
                <CarouselService carouselLayout='GENERAL' brandName={brandOfTheWeek} quantity={5} paginationLocation={"up"} slideStyles={{
                    desktop: {
                        relatedProductSlide: { height: '67vh', width: '25.5vw', pointerEvents: 'all' },
                        imageContainer: { maxHeight: '50vh', }
                    },
                    mobile: {
                        relatedProductSlide: { height: '51vh', width: '73vw', pointerEvents: 'all' },
                        imageContainer: {}
                    }
                }} />
            </div>
            <div className={styles.buttonContainer}>
                <button onClick={onClickHandler} className={styles.BrandShopButton}>{t('homepage.bestSellersBrandSection.shopProducts')}</button>
            </div>

        </div>
    );
};
