import { useQuery, UseQueryResult } from '@tanstack/react-query';
import activesApi from '../service/API/activesApi';
import { Active, ActivesApiResponse } from '../types/TInterfaces';

const fetchMostSearchedActives = async (): Promise<ActivesApiResponse> => {
    const response = await activesApi.getMostSearchedActives();
    if (!response.data) return { actives: [] };
    if (Array.isArray(response.data)) return { actives: response.data };
    return response.data;
};

export const useMostSearchedActives = (): UseQueryResult<ActivesApiResponse, Error> => {
    return useQuery({
        queryKey: ['mostSearchedActives'],
        queryFn: fetchMostSearchedActives,
    });
};

