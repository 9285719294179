import styles from './style.module.scss';
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import userApi from 'service/API/UserApi';
import ProductsApi from 'service/API/ProductsApi';
import filledFav from 'assets/icons/favFullIcon.svg';
import SearchLoader from 'components/animation/searchLoader/searchLoader';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { useFetchFavorites } from 'hooks/fetchFavorites';
import { GridProducts } from 'components/grid/gridProducts';
import { useTranslation } from 'react-i18next';

export const Favorites = () => {
    const [user] = useAtom(userAtom);
    const [favoritesCount, setFavoritesCount] = useState(0);
    const navigate = useNavigate();
    const [favoritesProducts, setFavoritesProducts] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = window.innerWidth < 768;
    const {t} = useTranslation()

    // e.g. userApi call returns an array of product IDs [id1, id2, id3...]
    const fetchUserFavorites = async () => {
        setIsLoading(true);
        const res = await userApi.getUserFavoritesProducts();

        if (res && Array.isArray(res)) {
            // 2. Pass them in a SINGLE call to get full product details
            const productsResponse = await ProductsApi.getProductsByArrayOfIds(res);
            if (productsResponse.success && productsResponse.data) {
                setFavoritesProducts(productsResponse.data);
                setFavoritesCount(productsResponse.data.length);
            }
        }

        setIsLoading(false);
    };


    useEffect(() => {
        fetchUserFavorites();
    }, []);

    const handleRemoveFavorite = async (productId: string) => {
        // 1) Optimistically remove from local state
        setFavoritesProducts((prev) => prev.filter((p) => p._id !== productId));
        setFavoritesCount((prevCount) => Math.max(prevCount - 1, 0));

        // 2) Notify the server
        try {
            await userApi.updateUserFavorite(productId, 'remove');
        } catch (error) {
            console.error('Failed to remove favorite', error);
            // 3) If needed, revert local removal or show error
        }
    };

    const debouncedRemoveFavorite = debounce(handleRemoveFavorite, 300); // Debounce function with 300ms delay


    const desktopLayout = (
        <>
            <HomepageNavbar />
            <div className={styles.desktopContainer}>
                <div className={styles.topSection}>
                    <div className={styles.menuTopSection}>
                        {/* PLACE HOLDER DONT REMOVE */}
                    </div>
                    <div className={styles.contentTopSection}>
                        <h3 className={styles.userName}>{user?.name}</h3>
                        <h3 className={styles.userName}>{user?.lastName}</h3>
                    </div>
                </div>

                <div className={styles.bottomSection}>
                    <div className={styles.menuSection}>
                        <ProfileMenu />
                    </div>

                    <div className={styles.contentSection}>
                        <>
                            <div className={styles.allProductTitleContainer}>
                                <h1 className={styles.allProductMainTitle}>
                                    {t('profile.favorites.title')}
                                </h1>
                            </div>
                            <div className={styles.allProductTitle} style={{ height: isLoading || favoritesProducts.length === 0 ? '100%' : '0%' }}>
                                <h3 className={styles.allProductTitleText}>{t('profile.favorites.allProductsCount', {count: favoritesCount})}</h3>
                            </div>
                            {isLoading ? (
                                <div className={styles.loaderContainer}>
                                    <SearchLoader />
                                </div>
                            ) : favoritesProducts.length > 0 ? (
                                <div className={styles.alignGrid}>
                                    <GridProducts products={favoritesProducts} isFavoritePage={true} />
                                </div>
                            ) : (
                                <div className={styles.noProductsFound}>
                                    <div className={styles.noFavoritesContainer}>
                                        <h3 className={styles.noFavoritesText}>{t('profile.favorites.noFavorites')}</h3>
                                        <h3 className={styles.noFavoritesSubText}>{t('profile.favorites.findFavorites')}</h3>
                                        <div className={styles.noFavoritesButtonContainer} onClick={() => navigate('/bestsellers')}>
                                            <button className={styles.noFavoritesButton} >{t('profile.favorites.discoverShop')}</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </div >
            </div>
        </>
    )

    const mobileLayout = (
        <div className={styles.mobileContainer}>
            <HomepageNavbar />
            <div className={styles.mobileHeaderSection}>
                <h1 className={styles.mobileBigTitle}>{t('profile.favorites.title')}</h1>
                <h3 className={styles.mobileTitle}>{t('profile.favorites.allProducts')}</h3>
            </div>
            {isLoading ? (
                <div className={styles.loaderContainer}>
                    <SearchLoader />
                </div>
            ) : favoritesProducts.length > 0 ? (
                <div className={styles.gridContainer}>
                    {favoritesProducts.map((product) => (
                        <div key={product.id} className={styles.imageItem}>
                            <div className={styles.upperSection}>
                                <div className={styles.justDroppedContainer}>
                                    <p className={styles.justDropped}>{t('profile.favorites.justDropped')}dropped</p>
                                </div>
                                <div className={styles.favoriteIcon}>
                                    <img src={filledFav} alt={product.name} className={styles.faveIcon} onClick={() => debouncedRemoveFavorite(product._id)} />
                                </div>

                            </div>
                            <img src={product.imgUrl} alt={product.name} className={styles.faveImage} onClick={() => navigate(`/product/${product._id}`)} />
                            <div className={styles.productInfo}>
                                <h3 className={styles.brand}>{product.brand}</h3>
                                <h3 className={styles.title}>{product.title.length > 21 ? product.title.substring(0, 21) + '...' : product.title}</h3>
                                <h3 className={styles.price}>€{product.price}({product.quantity}ml)</h3>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.noProductsFound}>
                    <div className={styles.noFavoritesContainer}>
                        <h3 className={styles.noFavoritesText}>{t('profile.favorites.noFavorites')}</h3>
                        <h3 className={styles.noFavoritesSubText}>{t('profile.favorites.findFavorites')}</h3>
                        <div className={styles.noFavoritesButtonContainer} onClick={() => navigate('/bestsellers')}>
                            <button className={styles.noFavoritesButton} >{t('profile.favorites.discoverShop')}</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )

    return (isMobile ? mobileLayout : desktopLayout)
}