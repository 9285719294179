import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import notFound from '../../assets/images/notExistLabel.svg';
import image1 from '../../assets/images/notFound1.png';
import image2 from '../../assets/images/notFound2.png';
import styles from './ErrorPage.module.scss';
import { useTranslation } from 'react-i18next';


interface ErrorPageProps {
    message?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ message }) => {
    const [backgroundImage, setBackgroundImage] = useState<string>('');
    const navigate = useNavigate();
    const {t} = useTranslation()

    useEffect(() => {
        const sessionKey = "lastImageIndex";

        const lastImageIndex = parseInt(sessionStorage.getItem(sessionKey) || "-1", 10);

        const nextIndex = (lastImageIndex + 1) % 2;
        const images = [image1, image2];
        const nextImage = images[nextIndex];

        setBackgroundImage(nextImage);

        sessionStorage.setItem(sessionKey, nextIndex.toString());
    }, []);

    return (
        <div className='error-page'>
            <HomepageNavbar />
            <div className={styles.errorPageContainer} style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat' }}>
                <div className={styles.imageContainer}>
                    <img src={notFound} alt="notFoundImage" width={360} height={89} />
                    <button className={styles.Button} onClick={() => { navigate('/') }}><span>{t('errorPage.backToWebsite')}</span></button>
                </div>
            </div >
        </div>
    );
}

export default ErrorPage;