import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../counter/style.scss';
import { useTranslation } from 'react-i18next';

interface StepIndicatorProps {
  currentStep: number;
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({ currentStep }) => {
  const navigate = useNavigate();
  const {t} = useTranslation()

  // Define the steps with their route paths and labels
  const steps = [
    { number: 1, route: '/spf-form/spf-gender' },
    { number: 2, route: '/spf-form/spf-age' },
    { number: 3, route: '/spf-form/spf-skin-type' },
    { number: 4, route: '/spf-form/spf-concerns' },
    { number: 5, route: '/spf-form/spf-risks' },
    { number: 6, route: '/spf-form/spf-resume' },
  ];

  // const handleStepClick = (route: string) => {
  //   navigate(route);
  // };

  return (
    <div className="step-indicator">
      {steps.map((step) => (
        <span
          key={step.number}
          className={`step ${currentStep === step.number ? 'active' : ''}`}
        // onClick={() => handleStepClick(step.route)}
        // style={{ cursor: 'pointer' }} // Visual cue that the item is clickable
        >
          {currentStep === step.number
            ? t('spfForm.steps.step', {stepNumber: step.number.toString().padStart(2, '0') })  : step.number.toString().padStart(2, '0')}
        </span>
      ))}
    </div>
  );
};
