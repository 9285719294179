import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Active } from '../../../types/TInterfaces';
import { ShopProduct } from 'types/ShopInterfaces';
import activesApi from '../../../service/API/activesApi';
import productsApi from 'service/API/ProductsApi';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ActiveRelatedProducts } from 'components/carousels/ActiveRelatedProducts';
import { ImageWithFallback } from './ImageWithFallback';
import Loader from '../../../components/animation/searchLoader/searchLoader';
import { activePageLoadingAtom } from 'stores/UserProfile';
import './style.scss';
import activesBg from '../../../assets/images/activesListBg.png';
import whiteDot from '../../../assets/icons/whiteDot.svg';
import blackDot from '../../../assets/icons/blackDot.svg';
import { CarouselService } from 'components/carousels/service/carouselService';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';
import {config} from '../../../config'
import { useWindowSize } from 'hooks/useWindowSize';
import { fallBackToClipboard } from 'service/serviceFunctions';

const ActiveImageSection = React.memo(({ active, activesBg, currentLanguage }: { active: Active | null; activesBg: string, currentLanguage: string }) => (
    <>
        <ImageWithFallback
            src={active?.image || activesBg}
            alt={active?.active_name || ''}
            className="article-image"
        />
        <div className="article-image-overlay"></div>
        <div className="article-meta">
            <div className="article-header">
                <h1 className="article-title">{formatActiveName(active?.active_name)}
                </h1>
                <div className="article-category">
                    {active?.functions_tags?.[0]?.translations  && active?.functions_tags?.map((func) => (
                        <span key={func.id} className="function-badge">
                            {func.translations[currentLanguage as 'en' | 'fr']}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    </>
));

const formatActiveName = (name: string | undefined) => {
    if (!name) return '';
    const words = name.toLowerCase().split(' ');
    return words
        .map((word, index) =>
            index === 0 ? word.toUpperCase() : word.charAt(0).toUpperCase() + word.slice(1)
        )
        .join(' ');
};

export const OneActive: React.FC = () => {
    const [relatedProducts, setRelatedProducts] = useState<ShopProduct[]>([]);
    const { activeId } = useParams<{ activeId: string }>();
    const [selectedMenuItems, setSelectedMenuItems] = useState<Set<string>>(new Set(['benefits', 'how_to_use', 'combinations']));
    const [active, setActive] = useState<Active | null>(null);
    const [activePageLoading, setActivePageLoading] = useAtom(activePageLoadingAtom);
    const [isCombinations, setIsCombinations] = useState(false);
    const [canBePaired, setCanBePaired] = useState<string[]>([]);
    const [cannotBePaired, setCannotBePaired] = useState<string[]>([]);
    const [canBePairedData, setCanBePairedData] = useState<{ id: string; active_name: string }[]>([]);
    const [cannotBePairedData, setCannotBePairedData] = useState<{ id: string; active_name: string }[]>([]);
    const { t } = useTranslation()
    const { currentLanguage } = useCurrentLanguage()
    const navigate = useNavigate();
    const { isMobile } = useWindowSize();
    

    const updateLoader = (key: keyof typeof activePageLoading, value: boolean) => {
        setActivePageLoading((prev) => {
            const newStates = { ...prev, [key]: value };
            newStates.loader = newStates.fetchActive || newStates.fetchProducts;
            return newStates;
        });
    };

    const fetchActiveById = async (activeId: string) => {
        updateLoader('fetchActive', true);
        try {
            const response = await activesApi.getActiveById(activeId);
            if (response.success === false) {
                navigate('/notFound');
                return;
            }

            if (response.success && response.data) {
                const activeData = response.data;

                if (activeData.element_of_group?.length > 0) {
                    const elementOfGroupIds = activeData.element_of_group.map((element: any) => element.id);
                    const relatedActivesResponse = await fetchActivesByElementOfGroupId(elementOfGroupIds);

                    if (relatedActivesResponse?.data) {
                        activeData.element_of_group = activeData.element_of_group.map((element: any) => {
                            const relatedActive = relatedActivesResponse.data.find(
                                (active: any) => active.notion_id === element.id
                            );

                            return relatedActive
                                ? {
                                    ...element,
                                    group_elements: relatedActive.group_elements || [],
                                    synonyms: relatedActive.synonyms || [],
                                }
                                : element;
                        });
                    }
                }

                if (
                    (Array.isArray(activeData.can_be_paired) && activeData.can_be_paired.length > 0) ||
                    (Array.isArray(activeData.not_pair_with) && activeData.not_pair_with.length > 0)
                ) {
                    setIsCombinations(true);

                    // Extracting only IDs from can_be_paired and not_pair_with
                    const pairedIds = activeData.can_be_paired.map(item => String(item.id));
                    const notPairedIds = activeData.not_pair_with.map(item => String(item.id));

                    setCanBePaired(pairedIds);
                    setCannotBePaired(notPairedIds);
                }


                setActive(activeData);
            } else {
                console.error(response.message || 'Failed to fetch active');
            }
        } catch (err) {
            console.error('Error fetching active:', err);
        } finally {
            updateLoader('fetchActive', false);
        }
    };

    const fetchProductsByActiveName = async () => {
        if (!active) return;

        updateLoader('fetchProducts', true);
        try {
            const groupElementsSynonyms =
                active.group_elements?.flatMap((element) => element.synonyms?.map((syn) => syn.active_name)) || [];

            const response = await productsApi.getProductsByActiveName(
                active.active_name,
                active.group_elements?.map((el) => el.active_name) || [],
                [...(active.synonyms?.map((syn) => syn.active_name) || []), ...groupElementsSynonyms].filter(Boolean) as string[]
            );

            if (response.success && response.data) {
                setRelatedProducts(response.data);
            } else {
                console.error(response.message || 'Failed to fetch products');
            }
        } catch (err) {
            console.error('Error fetching products by active name:', err);
        } finally {
            updateLoader('fetchProducts', false);
        }
    };

    const fetchActivesByElementOfGroupId = async (elementOfGroupIds: string[]) => {
        try {
            const response = await activesApi.getActivesByElementOfGroupId(elementOfGroupIds);
            if (response.success && response.data) {
                return { success: true, data: response.data as Active[] };
            } else {
                console.error(response.message || 'Failed to fetch actives');
                return { success: false, data: null };
            }
        } catch (err) {
            console.error('Failed to fetch actives by elementOfGroupId:', err);
            return { success: false, data: null };
        }
    };

    useEffect(() => {
        if (activeId) {
            fetchActiveById(activeId);
        }
    }, [activeId]);

    useEffect(() => {
        if (active) {
            fetchProductsByActiveName();
        }
    }, [active]);

    useEffect(() => {
        if (cannotBePaired && cannotBePaired.length > 0) {
            activesApi.getActivesCombinationData(cannotBePaired)
                .then(response => {
                    if (response.success) {
                        setCannotBePairedData(response.data || []);
                    } else {
                        setCannotBePairedData([]);
                    }
                })
                .catch(err => {
                    console.error('Error fetching actives combination data:', err);
                });
        }
    }, [cannotBePaired]);

    useEffect(() => {
        if (canBePaired && canBePaired.length > 0) {
            activesApi.getActivesCombinationData(canBePaired)
                .then(response => {
                    if (response.success) {
                        setCanBePairedData(response.data || []);
                    } else {
                        setCanBePairedData([]);
                    }
                })
                .catch(err => {
                    console.error('Error fetching actives combination data:', err);
                });
        }
    }, [canBePaired]);

    const getContentForKey = useCallback(
        (key: string) => {
            if (!active) return null;
            switch (key) {
                case 'description':
                    return active.translations[currentLanguage].description;
                case 'benefits':
                    return active.translations[currentLanguage].benefits;
                case 'how_to_use':
                    return active.translations[currentLanguage].how_to_use;
                // case 'add_to_routine':
                //     return active.translations[currentLanguage].add_to_routine;
                // case 'products':
                //     return relatedProducts.length > 0 ? (
                //         <div className='carouselContainer'>
                //             <CarouselService isActivePage={true} products={relatedProducts} carouselLayout='ACTIVE_PAGE_PRODUCTS' quantity={5} paginationLocation={"none"} slideStyles={{
                //                 desktop: {
                //                     relatedProductSlide: { height: '35vh', width: '12.5vw', pointerEvents: 'all' },
                //                     imageContainer: { maxHeight: '16vh', }
                //                 },
                //                 mobile: {
                //                     relatedProductSlide: { height: '25vh', width: '36vw', pointerEvents: 'all' },
                //                     imageContainer: {}
                //                 }
                //             }} />
                //         </div>
                // ) : (
                //     <div className="no-products-message">
                //         <p>t('actives.noProductsMessage.comingSoon', { activeName: active.active_name })</p >
                //         <p>
                //             Meanwhile, discover our{' '}
                //             <Link to="/bestsellers" className="bestsellers-link">
                //                 bestsellers
                //             </Link>
                //         </p>
                //     </div >
                // );
                case 'combinations':
                    if (isCombinations) { 
                        return renderCombinationsSection
                    }
                    return null;
                default:
                    return null;
            }
        },
        [active, relatedProducts, canBePairedData, cannotBePairedData]
    );

    const handleMenuClick = useCallback((key: string) => {
        setSelectedMenuItems(prev => {
            const newSelectedMenuItems = new Set(prev);
            newSelectedMenuItems.has(key) ? newSelectedMenuItems.delete(key) : 
            newSelectedMenuItems.add(key);
            newSelectedMenuItems.delete('add_to_routine');
            return newSelectedMenuItems;
        });
    }, []);

    // Define dynamic menu items based on content
    const menuDefinitions = [
        // {key: 'description', label: 'Description', hasContent: !!active?.description },
        { key: 'benefits', label: t('actives.menuItems.benefits'), hasContent: !!active?.translations[currentLanguage].benefits },
        { key: 'how_to_use', label: t('actives.menuItems.howToUse'), hasContent: !!active?.translations[currentLanguage].how_to_use },
        // { key: 'add_to_routine', label: t('actives.menuItems.addToRoutine'), hasContent: !!active?.translations[currentLanguage].add_to_routine },
        { key: 'combinations', label: t('actives.menuItems.combinations'), hasContent: isCombinations },
        // { key: 'products', label: 'Products', hasContent: true }, // Always show 'Products' section
    ].filter(item => item.hasContent);

    const renderCombinationsSection = (
        <>
            <div className="combinationsContainer">
                {canBePairedData.length > 0 && (
                    <div className="goodForSkinContainer">
                        <div className="combinationsTitleContainer">
                            <span className="combinationsTitle">{t('actives.combinationsTitle.goodForSkin')}</span>
                        </div>
                        <div className="goodActiveContainer">
                            {canBePairedData.map((goodActive, index) => (
                                <div key={goodActive.id} className="goodActiveItemContainer" onClick={() => navigate(`/feed/actives/${goodActive.id}`)}>
                                    <div>
                                        <img src={blackDot} alt='blackDot' className='blackDotImg' />
                                        <span className='mainActiveGoodText'>{active?.active_name}</span>
                                    </div>
                                    <div>
                                        <span className='goodActiveItem'>+   {goodActive.active_name}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {cannotBePairedData.length > 0 && (<div className="goodForSkinContainer">
                    <div className="combinationsTitleContainer">
                        <span className='combinationsTitle'>{t('actives.combinationsTitle.dangerous')}</span>
                        <div className='badActiveContainer'>
                            {cannotBePairedData.map((badActive, index) => (
                                <div key={badActive.id} className="badActiveItemContainer" onClick={() => navigate(`/feed/actives/${badActive.id}`)}>
                                    <div>
                                        <img src={whiteDot} alt='blackDot' className='blackDotImg' />
                                        <span className='mainActiveBadText'>{active?.active_name}</span>
                                    </div>
                                    <div>
                                        <span className='badActiveItem'>+   {badActive.active_name}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>)}
            </div>
        </>
    );

    const share = async () => {
        const title = active?.active_name
        const message = `${t('actives.share.message')} \n ${title} \n`
        const url = `${config.REACT_APP_BASE_FRONTEND_URL}/feed/actives/${activeId}`
    
        if (navigator.share) {
          try {
            await navigator.share ({
              text : message,
              url
            })
          } catch (shareError) {
            console.error ('Error sharing article:', shareError)
            fallBackToClipboard(message, url, t)
          }
        } else {
          fallBackToClipboard(message, url, t)
        }
      }

    return (
        <div className="article-detail-container">
            <HomepageNavbar />
            {activePageLoading.loader ? (
                <div className="loaderContainer">
                    <div className="loaderContainer_animation">
                        <Loader />
                    </div>
                </div>
            ) : (
                <>
                    <div className="article-image-section">
                        <ActiveImageSection active={active} activesBg={activesBg} currentLanguage={currentLanguage} />
                        <div className="article-image-overlay"></div>                     
    
                        {/* Render buttons here if it's mobile */}
                        {isMobile && (
                            <div className="article-favorite-button-container">
                                <button
                                    className="article-favorite-share-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        share()
                                    }}
                                >
                                    {t('common.share')}
                                </button>
                                <button
                                    className="article-favorite-share-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    SAVE
                                </button>
                            </div>
                        )}
                    </div>
    
                    <div className="article-content-section">
                        {/* Render buttons here if it's NOT mobile */}
                        {!isMobile && (
                            <div className="article-favorite-button-container">
                                <button
                                    className="article-favorite-share-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        share()
                                    }}
                                >
                                    {t('common.share')}
                                </button>
                                <button
                                    className="article-favorite-share-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    SAVE
                                </button>
                            </div>
                        )}
    
                        <div className="article-middle-text">
                            {active?.translations[currentLanguage].description ? (
                                <>
                                    <h2 className="active-desc-header">{t('actives.articleTitle', { activeName: active?.active_name })}</h2>
                                    <h2 className="active-desc">{active.translations[currentLanguage].description}</h2>
                                </>
                            ) : (
                                <h2 className="active-desc-header">{t('actives.articleDescHeader', { activeName: active?.active_name })}</h2>
                            )}
                        </div>
                        
                        <div className="menu-container">
                            {menuDefinitions.map((item, index) => (
                                <div key={item.key} style={{ position: 'relative' }}>
                                    <div className="menu-item" onClick={() => handleMenuClick(item.key)}>
                                        <span className="menu-number">{String(index + 1).padStart(2, '0')}</span> {item.label}{' '}
                                        <span className="menu-plus">
                                            {selectedMenuItems.has(item.key) ? '-' : '+'}
                                        </span>
                                    </div>
                                    {selectedMenuItems.has(item.key) && (
                                        <div className="menu-item-text-section">
                                            {getContentForKey(item.key)}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
