import React from 'react';
import styles from './styles.module.scss';

interface SwitchMethodButtonProps {
  onClick: () => void;
  text?: string;
  icon: string;
}

function SwitchMethodButton({ onClick, text, icon }: SwitchMethodButtonProps) {
  return (
    <button className={styles.switchMethodButton} onClick={onClick}>
      <img src={icon} />
      {text && text}
    </button>
  );
}

export default SwitchMethodButton;
