import { config } from "../config";

export const brandOfTheWeek: string = (() => {
    const brandList = config.REACT_APP_BRAND_OF_THE_MONTH;
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // 0-indexed (0 = January, 11 = December)
    const day = now.getDate();

    // Determine the number of days in the current month
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const numBrands = brandList.length;

    // If no brands exist, return an empty string (or a default value)
    if (numBrands === 0) return '';

    // If only one brand exists, return it.
    if (numBrands === 1) return brandList[0];

    let currentStart = 1;

    // Evenly split the month among brands if each segment would have at least 7 days.
    if (Math.floor(daysInMonth / numBrands) >= 7) {
        const baseSplit = Math.floor(daysInMonth / numBrands);
        const remainder = daysInMonth % numBrands;
        for (let i = 0; i < numBrands; i++) {
            // Distribute extra days among the first few brands.
            const extra = i < remainder ? 1 : 0;
            const segmentLength = baseSplit + extra;
            const startDay = currentStart;
            const endDay = currentStart + segmentLength - 1;
            if (day >= startDay && day <= endDay) {
                return brandList[i];
            }
            currentStart = endDay + 1;
        }
        // Fallback
        return brandList[numBrands - 1];
    } else {
        // Otherwise, assign sequential 7-day blocks until the month ends.
        for (let i = 0; i < numBrands; i++) {
            const startDay = currentStart;
            let endDay = currentStart + 6; // 7-day block
            if (endDay > daysInMonth) {
                endDay = daysInMonth;
            }
            if (day >= startDay && day <= endDay) {
                return brandList[i];
            }
            currentStart = endDay + 1;
            // If we've run out of days, break.
            if (currentStart > daysInMonth) break;
        }
        // Fallback: return the last brand if today's day isn't matched.
        return brandList[numBrands - 1];
    }
})();
