import React, { useEffect, useState, useRef } from 'react';
import { ShopHeadBestsellers } from 'components/shopPage/ShopHeadBestsellers/ShopHeadBestsellers';
import styles from './styles.module.scss';
import SearchLoader from 'components/animation/searchLoader/searchLoader';
import { GridProducts } from 'components/grid/gridProducts';
import { FooterSection } from "components/homepage/FooterSection/FooterSection";
import { CategoriesFilter } from 'pages/Categories/CategoriesFilter/CategoriesFilter';
import { ShopProduct } from 'types/ShopInterfaces';
import useAllProducts from 'hooks/allProducts';
import CategoriesSection from 'pages/categoriesSection/categoriesSection';
import { useTranslation } from 'react-i18next';

// Helper function to sanitize the search term

export const ShopAll: React.FC = () => {
    const { allProducts } = useAllProducts();
    const [filteredProducts, setFilteredProducts] = useState<ShopProduct[]>([]);
    const [products, setProducts] = useState<ShopProduct[]>([]);
    const gridProductsRef = useRef<HTMLDivElement>(null);
    const {t} = useTranslation()

    useEffect(() => {
        fetchProducts();
    }, [allProducts]);

    const handleFilteredProducts = (products: ShopProduct[]) => {
        setFilteredProducts(products);
    };

    const fetchProducts = async () => {
        if (allProducts) {
            setProducts(allProducts);
        }
    };



    return (
        <>

            <div className={styles.collectionPage}>
                {/* Header Section with Dynamic Background and Header Advice */}
                <ShopHeadBestsellers
                    bestsellerHeaderAdvice={t('common.shopAll')} // Format sanitized search term
                />
                <CategoriesSection />

            </div>

            <div className={styles.collectionPageContent}>
                {/* Categories Filter */}
                <div className={styles.categoriesFilter}>
                    <CategoriesFilter
                        products={allProducts || []}
                        onFilteredProducts={handleFilteredProducts}
                        targetComponentRef={gridProductsRef}
                    />
                </div>
                {/* Display Filtered Products */}
                <div className={styles.gridProductsContainer}>
                    <GridProducts products={filteredProducts} />
                </div>
            </div>

            {/* Footer Section */}
            <div ref={gridProductsRef}>
                <FooterSection />
            </div>
        </>
    );
};
