import { useEffect, useRef, useState } from "react";
import { useWindowSize } from 'hooks/useWindowSize';
import useRandomProducts from "hooks/randomProducts";
import { useFavoriteHandler } from 'utils/faveoritUtil';
import { useFetchFavorites } from 'hooks/fetchFavorites';
import userApi from 'service/API/UserApi';

import { GeneralCarousel } from "../generalCarousel/generalCarousel";
import { ScrollNumberIndicator } from "shared/ScrollNumberIndicator/ScrollNumberIndicator";

import styles from "./carouselService.module.scss";
import { ProductsCarousel } from "../productsCarousel/ProductsCarousel";
import { ShopProduct } from "types/ShopInterfaces";
import { useAtom } from "jotai";
import { componentLoadingStatusAtom } from "stores/UserProfile";

interface CarouselService {
    products?: ShopProduct[];
    quantity: number;
    carouselLayout: 'RECENTLY_VIEWED' | 'SIMILAR_PRODUCTS' | 'GENERAL' | 'RECOMMANDED_PRODUCTS' | 'ACTIVE_PAGE_PRODUCTS';
    brandName?: string;
    paginationLocation?: string;
    slideStyles?: { desktop?: { relatedProductSlide?: React.CSSProperties, imageContainer?: React.CSSProperties }, mobile?: { relatedProductSlide?: React.CSSProperties, imageContainer?: React.CSSProperties } };
    isMiniature?: boolean;
    isActivePage?: boolean;
}

enum SlidesPerView {

    HOMEPAGE_MOBILE = 1.1,
    HOMEPAGE_TABLET = 1.1,
    HOMEPAGE_DESKTOP = 3.5,
    // Recently Viewed
    RECENTLY_VIEWED_MOBILE = 3,
    RECENTLY_VIEWED_TABLET = 3,
    RECENTLY_VIEWED_DESKTOP = 4.5,
    // Similar Products
    SIMILAR_PRODUCTS_MOBILE = 3,
    SIMILAR_PRODUCTS_TABLET = 3,
    SIMILAR_PRODUCTS_DESKTOP = 4.5,
    // Active Page
    ACTIVE_PAGE_MOBILE = 3,
    ACTIVE_PAGE_TABLET = 3,
    ACTIVE_PAGE_DESKTOP = 3.2
}

// Alternative implementation as a dictionary
const SlidesPerViewDict = {
    GENERAL: {
        MOBILE_SLIDES_PER_VIEW: SlidesPerView.HOMEPAGE_MOBILE,
        MOBILE_SPACE_BETWEEN: 10,
        TABLET_SLIDES_PER_VIEW: SlidesPerView.HOMEPAGE_TABLET,
        TABLET_SPACE_BETWEEN: 10,
        DESKTOP_SLIDES_PER_VIEW: SlidesPerView.HOMEPAGE_DESKTOP,
        DESKTOP_SPACE_BETWEEN: 5
    },
    RECENTLY_VIEWED: {
        MOBILE_SLIDES_PER_VIEW: SlidesPerView.RECENTLY_VIEWED_MOBILE,
        MOBILE_SPACE_BETWEEN: 17,
        TABLET_SLIDES_PER_VIEW: SlidesPerView.RECENTLY_VIEWED_TABLET,
        TABLET_SPACE_BETWEEN: 10,
        DESKTOP_SLIDES_PER_VIEW: SlidesPerView.RECENTLY_VIEWED_DESKTOP,
        DESKTOP_SPACE_BETWEEN: 5
    },
    SIMILAR_PRODUCTS: {
        MOBILE_SLIDES_PER_VIEW: SlidesPerView.SIMILAR_PRODUCTS_MOBILE,
        MOBILE_SPACE_BETWEEN: 17,
        TABLET_SLIDES_PER_VIEW: SlidesPerView.SIMILAR_PRODUCTS_TABLET,
        TABLET_SPACE_BETWEEN: 10,
        DESKTOP_SLIDES_PER_VIEW: SlidesPerView.SIMILAR_PRODUCTS_DESKTOP,
        DESKTOP_SPACE_BETWEEN: 1
    },
    RECOMMANDED_PRODUCTS: {
        MOBILE_SLIDES_PER_VIEW: SlidesPerView.SIMILAR_PRODUCTS_MOBILE,
        MOBILE_SPACE_BETWEEN: 17,
        TABLET_SLIDES_PER_VIEW: SlidesPerView.SIMILAR_PRODUCTS_TABLET,
        TABLET_SPACE_BETWEEN: 10,
        DESKTOP_SLIDES_PER_VIEW: SlidesPerView.SIMILAR_PRODUCTS_DESKTOP,
        DESKTOP_SPACE_BETWEEN: 5
    },
    ACTIVE_PAGE_PRODUCTS: {
        MOBILE_SLIDES_PER_VIEW: SlidesPerView.ACTIVE_PAGE_MOBILE,
        MOBILE_SPACE_BETWEEN: 17,
        TABLET_SLIDES_PER_VIEW: SlidesPerView.ACTIVE_PAGE_TABLET,
        TABLET_SPACE_BETWEEN: 10,
        DESKTOP_SLIDES_PER_VIEW: SlidesPerView.ACTIVE_PAGE_DESKTOP,
        DESKTOP_SPACE_BETWEEN: 20
    }
}

export const CarouselService: React.FC<CarouselService> = ({
    products,
    carouselLayout,
    quantity,
    brandName,
    paginationLocation,
    slideStyles,
    isMiniature,
    isActivePage
}) => {
    // Hooks
    const { isMobile } = useWindowSize();
    const sliderRef = useRef<any>(null);
    const { randomProducts, loading } = useRandomProducts(quantity, brandName);
    const { favorites, setFavorites, handleFavoriteToggle } = useFavoriteHandler(userApi);

    // State
    const [activeGroup, setActiveGroup] = useState(1);
    const [indicatorContainer, setIndicatorContainer] = useState<string | null>();
    const [componentStatuses, setComponentStatuses] = useAtom(componentLoadingStatusAtom);

    // Constants
    const productsWithImage = products ? products.filter(product => product?.imgUrl) : randomProducts.filter(product => product?.imgUrl);
    const mobileSlidesPerView = SlidesPerViewDict[carouselLayout].MOBILE_SLIDES_PER_VIEW;
    const desktopSlidesPerView = SlidesPerViewDict[carouselLayout].DESKTOP_SLIDES_PER_VIEW;
    const tabletSlidesPerView = SlidesPerViewDict[carouselLayout].TABLET_SLIDES_PER_VIEW;

    // Effects
    useEffect(() => {
        let containerClass = "";
        if (paginationLocation === "up") {
            containerClass = ` ${styles.indicatorContainerShopPageUp}`;
        } else if (paginationLocation === "down") {
            containerClass = ` ${styles.indicatorContainerShopPageDown}`;
        } else if (paginationLocation === "none") {
            containerClass = ` ${styles.indicatorContainerShopPageNone}`;
        }
        setIndicatorContainer(containerClass);
    }, [paginationLocation]);

    // update loading status
    useEffect(() => {
        if (!loading) {
            setComponentStatuses((prev: any) => ({ ...prev, ProductsCarousel: true }));
        }
    }, [loading, setComponentStatuses,]);

    // Fetch favorites
    useFetchFavorites(setFavorites);

    // Handlers
    const handleFavoriteClick = (productId: string) => {
        handleFavoriteToggle(productId);
    };

    const handleSlideChange = (activeIndex: number) => {
        // Only update active group if it's not from a direct number click
        if (!sliderRef.current?.swiper?.params?.preventActiveGroupUpdate) {
            const slidesPerView = isMobile ? mobileSlidesPerView : desktopSlidesPerView;
            const groupIndex = Math.floor(activeIndex / slidesPerView) + 1;
            setActiveGroup(groupIndex);
        }
    };

    const handleNumberClick = (groupIndex: number) => {
        setActiveGroup(groupIndex);
        if (sliderRef.current?.swiper) {
            // Set flag to prevent active group update from slide change
            sliderRef.current.swiper.params.preventActiveGroupUpdate = true;
            const slidesPerView = isMobile ? mobileSlidesPerView : desktopSlidesPerView;
            sliderRef.current.swiper.slideTo((groupIndex - 1) * slidesPerView);

            // Reset flag after animation completes
            setTimeout(() => {
                if (sliderRef.current?.swiper) {
                    sliderRef.current.swiper.params.preventActiveGroupUpdate = false;
                }
            }, 300); // Adjust timeout to match slide transition duration
        }
    };

    // Helper functions
    const getIndicatorCount = () => {
        const slidesPerView = isMobile ? mobileSlidesPerView : desktopSlidesPerView;
        return Math.ceil(productsWithImage.length / slidesPerView);
    };

    // Render functions
    const renderIndicator = () => (
        <div className={`${styles.indicatorContainer} ${indicatorContainer}`}>
            <ScrollNumberIndicator
                count={getIndicatorCount()}
                activeNumber={activeGroup}
                fontSize={12}
                color="black"
                onNumberClick={handleNumberClick}

            />
        </div>
    );

    const getCarouselLayout = () => {
        if (carouselLayout === 'GENERAL') {
            return (
                <GeneralCarousel
                    relatedProducts={productsWithImage}
                    onSlideChange={handleSlideChange}
                    ref={sliderRef}
                    favorites={favorites}
                    handleFavoriteClick={handleFavoriteClick}
                    slideParams={SlidesPerViewDict["GENERAL"]}
                    slideStyles={isMobile ? slideStyles?.mobile : slideStyles?.desktop}
                />)
        } else {
            return (
                <ProductsCarousel
                    relatedProducts={productsWithImage}
                    onSlideChange={handleSlideChange}
                    slideParams={SlidesPerViewDict[carouselLayout]}
                    slideStyles={isMobile ? slideStyles?.mobile : slideStyles?.desktop}
                    isMobile={isMobile}
                    isMiniature={isMiniature}
                    ref={sliderRef}
                    favorites={favorites}
                    handleFavoriteClick={handleFavoriteClick}
                    isActivePage={isActivePage}
                />


            )

        }
    }
    return (
        <div className={styles.carouselContainer}>
            {renderIndicator()}
            {getCarouselLayout()}
        </div>
    );
};