import React from 'react';
import styles from './EmptyOrders.module.scss';
import eggShape from '../../../../../assets/icons/blackVector.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const EmptyOrders = () => {
    const navigate = useNavigate();

    const handleShopClick = () => {
        navigate('/shop');
    }
    const {t} = useTranslation()

    return (
        <div className={styles.emptyOrdersContainer}>
            <div className={styles.contentContainer}>
                <div className={styles.imageContainer}>
                    <img src={eggShape} alt="Egg Shape" className={styles.eggShapeImage} />
                    <div className={styles.textContainer}>
                        <h4 className={styles.title}>{t('profile.myOrders.emptyOrders.noHistory')}</h4>
                        <p className={styles.subTitle}>{t('profile.myOrders.emptyOrders.readyToReveal')}</p>
                        <button className={styles.btn} onClick={handleShopClick}>{t('profile.myOrders.emptyOrders.startShopping')}</button>
                        <p className={styles.favoritesBtn}>{t('profile.myOrders.emptyOrders.viewFavorites')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

