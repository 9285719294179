import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { FreeMode } from 'swiper/modules';
import styles from './generalCarousel.module.scss';
import favFull from '../../../assets/icons/favFullIcon.svg';
import favEmpty from '../../../assets/icons/favEmptyIcon.svg';
import 'swiper/css';
import dragCursorSvg from "../../../assets/cursors/dragCursor.svg"
import shopCursorSvg from "../../../assets/cursors/mouse_shop.svg"
import saveCursorSvg from "../../../assets/cursors/mouse_save.svg"
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';
import { ShopProduct } from 'types/ShopInterfaces';


interface GeneralCarouselProps {
    relatedProducts: ShopProduct[];
    onSlideChange: (groupIndex: number) => void;
    favorites?: { [key: string]: boolean };
    handleFavoriteClick?: (productId: string) => void;
    slideParams: { MOBILE_SLIDES_PER_VIEW: number, MOBILE_SPACE_BETWEEN: number, TABLET_SLIDES_PER_VIEW: number, TABLET_SPACE_BETWEEN: number, DESKTOP_SLIDES_PER_VIEW: number, DESKTOP_SPACE_BETWEEN: number };
    slideStyles?: { relatedProductSlide?: React.CSSProperties, imageContainer?: React.CSSProperties };
}

export const GeneralCarousel = forwardRef<any, GeneralCarouselProps>(
    ({ relatedProducts, onSlideChange, favorites, handleFavoriteClick, slideParams, slideStyles }, ref) => {
        const breakpoints = {
            0: {
                slidesPerView: slideParams.MOBILE_SLIDES_PER_VIEW,
                spaceBetween: slideParams.MOBILE_SPACE_BETWEEN,
            },
            400: {
                slidesPerView: slideParams.TABLET_SLIDES_PER_VIEW,
                spaceBetween: slideParams.TABLET_SPACE_BETWEEN,
            },
            769: {
                slidesPerView: slideParams.DESKTOP_SLIDES_PER_VIEW,
                spaceBetween: slideParams.DESKTOP_SPACE_BETWEEN,
            },
        }
        const { t } = useTranslation()
        const { currentLanguage } = useCurrentLanguage()
        const cursorContainerRef = useRef<HTMLDivElement>(null);
        const cursor1Ref = useRef<HTMLImageElement>(null);
        const cursor2Ref = useRef<HTMLImageElement>(null);
        const timeoutRef = useRef<NodeJS.Timeout | null>(null);
        const cursor3Ref = useRef<HTMLImageElement>(null);
        const [cursorState, setCursorState] = useState<"idle" | "dragging" | "moving" | "shop">("idle");
        const isMouseStoppedRef = useRef(false);
        const [hoveredProduct, setHoveredProduct] = useState<string | null>(null);
        const handleMouseMove = (e: React.MouseEvent) => {
            if (cursorState === "dragging") return;

            if (timeoutRef.current) clearTimeout(timeoutRef.current);

            const target = e.target as HTMLElement;
            const isFavoriteIcon = target.closest(`.${styles.favoriteIconContainer}`);
            const isProductCard = target.closest(`.${styles.relatedProductCard}`);

            setCursorState("moving");
            isMouseStoppedRef.current = false;

            timeoutRef.current = setTimeout(() => {
                isMouseStoppedRef.current = true;
                if (!isFavoriteIcon) {
                    setCursorState(isProductCard ? "shop" : "idle");
                }
            }, 500);

            if (cursorContainerRef.current) {
                cursorContainerRef.current.style.display = "block";
                cursorContainerRef.current.style.left = `${e.clientX}px`;
                cursorContainerRef.current.style.top = `${e.clientY}px`;
            }

            if (cursor1Ref.current && cursor2Ref.current && cursor3Ref.current) {
                if (isFavoriteIcon) {
                    cursor1Ref.current.style.opacity = "0";
                    cursor2Ref.current.style.opacity = "0";
                    cursor3Ref.current.style.opacity = "1"; // Favorite
                } else if (isProductCard && isMouseStoppedRef.current) {
                    cursor1Ref.current.style.opacity = "0";
                    cursor2Ref.current.style.opacity = "1"; // Shop
                    cursor3Ref.current.style.opacity = "0";
                } else {
                    cursor1Ref.current.style.opacity = "1"; // Drag
                    cursor2Ref.current.style.opacity = "0";
                    cursor3Ref.current.style.opacity = "0";
                }
            }
        };

        useEffect(() => {
            if (cursor1Ref.current && cursor2Ref.current && cursor3Ref.current) {
                if (cursorState === "shop") {
                    cursor1Ref.current.style.opacity = "0";
                    cursor2Ref.current.style.opacity = "1"; // Shop
                    cursor3Ref.current.style.opacity = "0";
                } else if (cursorState === "dragging") {
                    cursor1Ref.current.style.opacity = "1"; // Drag
                    cursor2Ref.current.style.opacity = "0";
                    cursor3Ref.current.style.opacity = "0";
                } else if (cursorState === "idle") {
                    cursor1Ref.current.style.opacity = "1"; // Default
                    cursor2Ref.current.style.opacity = "0";
                    cursor3Ref.current.style.opacity = "0";
                }
            }
        }, [cursorState]);

        const handleMouseDown = () => {
            setCursorState("dragging");
            if (cursor1Ref.current) cursor1Ref.current.style.opacity = "1";
            if (cursor2Ref.current) cursor2Ref.current.style.opacity = "0";
            if (cursor3Ref.current) cursor3Ref.current.style.opacity = "0";
        };

        const handleMouseUp = () => {
            setCursorState("idle");
        };

        const handleMouseLeave = () => {
            if (cursorContainerRef.current) {
                cursorContainerRef.current.style.display = "none";
            }
            setCursorState("idle");
        };

        useEffect(() => {
            const handleGlobalMouseUp = () => setCursorState("idle");

            document.addEventListener("mouseup", handleGlobalMouseUp);
            return () => {
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                document.removeEventListener("mouseup", handleGlobalMouseUp);
            };
        }, []);

        const renderFavoriteIcon = (
            favorites: { [key: string]: boolean },
            productId: string,
            handleFavoriteClick: (productId: string) => void,
        ) => (
            <div
                className={styles.favoriteIconContainer}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleFavoriteClick(productId)
                }}
            >
                <img className={styles.favoriteIcon} src={favorites[productId] ? favFull : favEmpty} alt="favorite" />
            </div>
        )

        const renderProductDetails = (product: ShopProduct) => (
            <div className={styles.productInfo}>
                <p className={styles.productBrand}>{product.brand}</p>
                <p className={styles.productTitle}>
                    {(product.translations[currentLanguage]?.title?.length ?? 0) > 21 ? `${product.translations[currentLanguage]?.title?.substring(0, 21)}...` : product.translations[currentLanguage]?.title}
                </p>
                <p className={styles.productPrice}>
                    €{product.price?.toFixed(2)} | ({product.quantity}ml)
                </p>
            </div>
        )

        const createCustomStyles = () => {
            const defaultRelatedProductSlideStyles: React.CSSProperties = {}
            const defaultImageContainerStyles: React.CSSProperties = {}
            return {
                relatedProductSlideStyles: slideStyles?.relatedProductSlide ?? defaultRelatedProductSlideStyles,
                imageContainerStyles: slideStyles?.imageContainer ?? defaultImageContainerStyles,
            }
        }

        const renderProductCard = (product: ShopProduct, index: number) => {
            const { relatedProductSlideStyles, imageContainerStyles } = createCustomStyles()

            return (
                <SwiperSlide key={index} className={styles.swiperSlideContainer}>
                    <div
                        className={styles.relatedProductSlide}
                        onPointerMove={handleMouseMove}
                        onPointerDown={handleMouseDown}
                        onPointerUp={handleMouseUp}
                        onMouseLeave={() => {
                            handleMouseLeave();
                            setHoveredProduct(null);
                        }}
                        onMouseMove={handleMouseMove}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseEnter={() => setHoveredProduct(product._id)}
                        style={relatedProductSlideStyles}
                    >
                        <div
                            className={styles.relatedProductCard}
                        >
                            <div className={styles.justDroppedContainer}>
                                <p className={styles.justDroppedText}>{t('carousels.generalCarousel.justDropped')}</p>
                            </div>

                            {favorites && handleFavoriteClick && renderFavoriteIcon(favorites, product._id, handleFavoriteClick)}

                            <div style={{ width: '100%', height: '100%' }}>
                                <Link to={`/product/${product._id}`} className={styles.productLink}>
                                    <div className={styles.imageContainer} style={{ height: '90%', width: '100%' }}>
                                        <img
                                            src={hoveredProduct === product._id ? product.textureImgUrl || product.imgUrl : product.imgUrl || "/placeholder.svg"}
                                            alt={product.translations[currentLanguage]?.title}
                                            className={styles.productImage}
                                        />
                                    </div>
                                </Link>
                            </div>

                            {renderProductDetails(product)}
                        </div>
                    </div>
                </SwiperSlide>
            )
        }

        return (
            <div
                className={styles.relatedProducts}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
            >
                <Swiper
                    spaceBetween={10}
                    breakpoints={breakpoints}
                    loop={true}
                    modules={[FreeMode]}
                    className={styles.relatedProductsSwiper}
                    onSlideChange={(swiper: any) => {
                        onSlideChange(swiper.realIndex)
                    }}
                    ref={ref}
                >
                    {relatedProducts.map((product, index) => renderProductCard(product, index))}
                </Swiper>
                <div ref={cursorContainerRef} className={styles.cursorContainer}>
                    <img ref={cursor1Ref} src={dragCursorSvg} alt="" className={styles.cursor} />
                    <img ref={cursor2Ref} src={shopCursorSvg} alt="" className={styles.cursor} />
                    <img ref={cursor3Ref} src={saveCursorSvg} alt="" className={styles.cursor} />
                </div>
            </div>
        )
    },
)