import React from 'react';
import styles from './ProductCard.module.scss';
import { IProductInOrder } from 'types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

interface ProductCardProps {
    product: IProductInOrder;
}

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
    const navigate = useNavigate();

    const unitPrice = parseFloat(product.unitPrice);
    const unitTax = parseFloat(product.unitTax);
    const totalPrice = (unitPrice + unitTax).toFixed(2);

    const onDetailesClick = (productId: string) => {
        navigate(`/product/${productId}`);
    };
    const {currentLanguage} = useCurrentLanguage()
    const {t} = useTranslation()

    return (
        <div className={styles.productCard}>
            <div className={styles.left}>
                <img src={product.product.imgUrl} className={styles.image} alt={product.product.translations?.[currentLanguage]?.title} />
            </div>
            <div className={styles.middle}>
                <div className={styles.brand}>{product.product.brand}</div>
                <div className={styles.title}>{product.product.translations?.[currentLanguage]?.title}</div>
                <div className={styles.quantity}>{product.product.quantity}ML</div>
            </div>
            <div className={styles.right}>
                <div className={styles.price}>€{totalPrice}</div>
                <button
                    className={styles.detailsBtn}
                    onClick={() => onDetailesClick(product.product._id)}
                >
                    {t('productDetails.details')}
                </button>
            </div>
        </div>
    );
};
