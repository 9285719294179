import { useEffect } from 'react';
import UserApi from 'service/API/UserApi';
import { useAtom } from 'jotai';
import { userAtom } from 'stores/UserProfile';

/**
 * A simple hook to fetch user's favorite articles on mount.
 *
 * @param setFavorites - a setter function to update your local "favorites" state
 * @example
 *   const [favorites, setFavorites] = useState<{ [articleId: string]: boolean }>({});
 *   useFetchFavoriteArticles(setFavorites);
 */
export function useFetchFavoriteArticles(
    setFavorites: (favorites: { [key: string]: boolean }) => void,
) {
    const [user] = useAtom(userAtom);
    useEffect(() => {
        if (user) {
            UserApi.getUserFavoritesArticles()
                .then((response) => {
                    if (response && Array.isArray(response)) {
                        const favoritesMap = response.reduce(
                            (acc: { [key: string]: boolean }, articleId: string) => {
                                acc[articleId] = true;
                                return acc;
                            },
                            {}
                        );
                        setFavorites(favoritesMap);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching favorite articles:', error);
                });
        }
    }, [setFavorites, user]);
}
