import api from './apiConfig';

export default class categoriesApi {
    static async getAllCategories(): Promise<any> {
        try {
            const response = await api.get('/categories/get-all-categories');
            return response.data;
        } catch (error) {
            console.error('Error fetching categories:', error);
            throw error;
        }
    }

    static async getSubcategories(category: string): Promise<any> {
        try {
            const response = await api.get(`/categories/get-subcategories/${category}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching subcategories:', error);
            throw error;
        }
    }
}
