import useAllProducts from "hooks/allProducts";
import { useEffect, useState, useRef } from "react";
import { ShopHeadBestsellers } from "components/shopPage/ShopHeadBestsellers/ShopHeadBestsellers";
import styles from './components/bestsellers.module.scss'
import { GridProducts } from "components/grid/gridProducts";
import { FooterSection } from "components/homepage/FooterSection/FooterSection";
import { CategoriesFilter } from "pages/Categories/CategoriesFilter/CategoriesFilter";
import SubCategoriesSection from "pages/Categories/SubCaregoriesSection/subCategories";
import { ShopProduct } from "types/ShopInterfaces";

export const ShopBestsellers = () => {
  const { allProducts } = useAllProducts();
  const [filteredProducts, setFilteredProducts] = useState(allProducts || []);
  const [products, setProducts] = useState<ShopProduct[]>([]);
  const gridProductsRef = useRef<HTMLDivElement>(null);

  const fetchProducts = async () => {
    if (allProducts) {
      setProducts(allProducts);
    }
  };

  const handleFilteredProducts = (products: ShopProduct[]) => {
    setFilteredProducts(products);
  };

  useEffect(() => {
    fetchProducts();
  }, [allProducts]);

  if (!allProducts || allProducts.length === 0) {
    return <div className="brand-loader">
    </div>
  }

  return (
    <>
      <div className={styles.collectionPage}>
        <ShopHeadBestsellers
        />
      </div>

      <div className={styles.collectionPageContent}>
        <div className={styles.collectionPageFilter}>
          <CategoriesFilter
            products={products}
            onFilteredProducts={handleFilteredProducts}
            targetComponentRef={gridProductsRef}
          />
        </div>

        <div ref={gridProductsRef} className={styles.collectionPageGrid}>
          <GridProducts products={filteredProducts} />
        </div>
      </div>
      <FooterSection />
    </>
  );
};
