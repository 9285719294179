import api from './apiConfig';
import { getErrorMessage } from 'service/serviceFunctions';
import { searchSuggestion, ShopProduct } from '../../types/ShopInterfaces';

export interface Brand {
  brand: string;
}

// Define the response type to include averageScore
interface ApiResponse {
  success: boolean;
  data?: ShopProduct[]; // This should remain as an array of ShopProduct
  averageScore?: number; // Ensure this is a number
  message?: string;
}

export default class productsApi {
  static async getProductsByActiveName(activeName: string, groupElements?: string[], synonyms?: string[]): Promise<{ success: boolean; data?: ShopProduct[]; message?: string }> {
    try {
      const response = await api.post(`/products/by-active-name`, {
        activeName,
        groupElements: groupElements || [],
        synonyms: synonyms || [],
      });

      if (!response.data.products) {
        return { success: false, message: 'No products found for the given active name' };
      }
      // Filter out products with null imgUrl
      const validProducts = response.data.products.filter((product: ShopProduct) => product.imgUrl !== "" && product.brand !== "Torriden");
      return { success: true, data: validProducts };
    } catch (error) {
      console.error('API error while fetching products by active name:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }


  static async getAllBrands(categories?: string[]): Promise<{ success: boolean; data?: Brand[]; message?: string }> {
    try {
      // Prepare the query string
      const query = categories ? `?categories=${categories.join(',')}` : '';

      // Make the API call with the query string
      const response = await api.get(`/products/brands${query}`);
      return { success: true, data: response.data.brands };
    } catch (error) {
      console.error('API JS error while fetching all brands:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }


  static async getRandomProducts(quantity?: number, subcategory?: string): Promise<{ success: boolean, data?: ShopProduct[], message?: any }> {
    try {
      let url = `/products/random/${quantity}`;
      if (subcategory) { url += `/${subcategory}`; }
      const response = await api.get(url);
      if (response.data.products.length === 0) {
        return { success: false, message: "No products found" };
      }
      // Simplified filter to only check if imgUrl exists
      const validProducts = response.data.products.filter((product: ShopProduct) =>
        product.imgUrl
      );
      return { success: true, data: validProducts };
    } catch (error) {
      console.error('API JS error while fetching random products:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getProductById(productId: string): Promise<{ success: boolean, data?: ShopProduct, message?: string }> {
    try {
      const response = await api.get(`/products/${productId}`);
      if (!response.data.product) {
        return { success: false, message: "Product not found" };
      }
      return { success: true, data: response.data.product };
    } catch (error) {
      console.error('API JS error while fetching product by ID:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getAllProducts(subcategory?: string): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      let url = `/products/category`;

      if (subcategory) { url += `/${subcategory}`; }
      else { url += `/undefined`; }

      const response = await api.get(url);
      // Simplified filter to only check if imgUrl exists
      const validProducts = response.data.products.filter((product: ShopProduct) =>
        product.imgUrl
      );
      return { success: true, data: validProducts };
    } catch (error) {
      console.error('API JS error while fetching all products:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getProductsByBrand(
    brandName: string,
    withScore?: boolean
  ): Promise<{ success: boolean; data?: ShopProduct[]; message?: string; averageScore?: number }> {
    try {

      // Replace forward slash with a different character
      const encodedBrandName = brandName.replace(/\//g, '---');

      const response = await api.get(`/products/brand/${encodedBrandName}`, {
        params: { withScore: withScore || false },
      });

      const { products, averageScore } = response.data; // destructure

      const validProducts = products.filter(
        (product: ShopProduct) => product.imgUrl
      );

      return {
        success: true,
        data: validProducts,
        averageScore, // <— pass averageScore back to caller
      };
    } catch (error) {
      console.error("API error while fetching products by brand:", getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async checkProductAvailability(sku: string): Promise<{ success: boolean, available: boolean, message?: string }> {
    try {
      if (!sku) {
        throw new Error("Product ID is required");
      }

      const payload = {
        product: sku
      };

      const response = await api.post(`/products/checkProductAvailability`, payload);

      const isAvailable = response.data.available > 0;
      return { success: true, available: isAvailable };
    } catch (error) {
      console.error('Error checking product availability:', getErrorMessage(error));
      return { success: false, available: false, message: getErrorMessage(error) };
    }
  }

  static async searchByNameInciArticle(query: string): Promise<{ success: boolean, data: any, message?: string }> {
    try {
      const response = await api.get(`/products/search/${query}`);
      return { success: true, data: response.data.suggestionsResults };
    } catch (error) {
      return { success: false, data: [], message: getErrorMessage(error) };
    }
  }

  static async updateSearchCounter(productId: string): Promise<{ success: boolean, message?: string }> {
    try {
      const response = await api.post(`/products/update-search-counter/${productId}`);
      return { success: true, message: response.data.message };
    } catch (error) {
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getMostSearchedProducts(): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      const response = await api.get('/products/most-searched');
      return { success: true, data: response.data.products };
    } catch (error) {
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getProductsByArrayOfIds(ids: string[]): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      const response = await api.post('/products/fetch-products-by-ids', { ids });
      return { success: true, data: response.data.products };
    } catch (error) {
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getSimilarProductsByInci(inciList: string[]): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      const response = await api.post('/products/fetch-similar-products-by-inci', { inci_list: inciList });
      return { success: true, data: response.data.similarProducts };
    } catch (error) {
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async fetchSimilarProducts(productId: string): Promise<{ similarProducts: any[] }> {
    try {
      const response = await api.post(`/products/fetch-similar-products`, { productId });

      if (response.status === 200) {
        return {
          similarProducts: response.data.similarProducts || [],
        };
      } else {
        return {
          similarProducts: [],
        };
      }
    } catch (error) {
      return {
        similarProducts: [],
      };
    }
  }
  static async getBrandProductsCount(brandName: string): Promise<{ success: boolean, data?: number, message?: string }> {
    try {
      // Need to encode because Mid/night's / messes up the url
      // Replace forward slash with a different character
      const encodedBrandName = brandName.replace(/\//g, '---');
      const response = await api.get(`/products/brand/${encodedBrandName}/products-count`);
      return { success: true, data: response.data.count };
    } catch (error) {
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getCollectionProducts(searchQuery: string): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      const response = await api.get(`/products/collection/${searchQuery}`);
      return { success: true, data: response.data.products };
    } catch (error) {
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getCategories(): Promise<{ success: boolean, data?: string[], message?: string }> {
    try {
      const response = await api.get('/products/categories');
      return { success: true, data: response.data.categories };
    } catch (error) {
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getProductsByCategory(category: string): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      const response = await api.get(`products/get-products-by-category/${category}`);
      return { success: true, data: response.data.products };
    } catch (error) {
      return { success: false, message: getErrorMessage(error) };
    }
  }


  static async getSubmenusProductsObject(): Promise<{ success: boolean, data?: any, message?: string }> {
    try {
      const response = await api.get(`/products/submenus-products-fetch`);
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, message: getErrorMessage(error) };
    }
  }
}