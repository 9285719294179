import { useQuery, UseQueryResult } from '@tanstack/react-query';
import productsApi from '../service/API/ProductsApi';
import { ShopProduct } from '../types/ShopInterfaces';

const fetchAllProducts = async (subcategory?: string): Promise<ShopProduct[]> => {
    const data = await productsApi.getAllProducts(subcategory);
    
    if (!data.success) {
        throw new Error(data.message || 'Failed to fetch products');
    }
    return data.data || [];
};

interface UseAllProductsResult {
    allProducts: ShopProduct[] | undefined;
    allProductsIsLoading: boolean;
    allProductsError: unknown;
}

export const useAllProducts = (subcategory?: string): UseAllProductsResult => {
    const { data, isLoading, error }: UseQueryResult<ShopProduct[], Error> = useQuery({
        queryKey: ['allProducts', subcategory],
        queryFn: () => fetchAllProducts(subcategory),
        staleTime: 5 * 24 * 60 * 60 * 1000,
        gcTime: 5 * 24 * 60 * 60 * 1000,
    });
    return { allProducts: data, allProductsIsLoading: isLoading, allProductsError: error };
};

export default useAllProducts;