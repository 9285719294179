import React from 'react';
import { useNavigate } from 'react-router-dom';
import StartDiagnosiseBg from '../../../../../assets/images/StartDiagnosiseBg.jpeg'
import './style.scss';
import { useTranslation } from 'react-i18next';

export const StartDiagnosis: React.FC = () => {
    const navigate = useNavigate();

    const startDiagnosis = () => {
        navigate('/spf-form');
    };

    const {t} = useTranslation()

    return (
        <div className="start-diagnosis-container">
            <img src={StartDiagnosiseBg} alt="Start Diagnosise" className="start-diagnosis-image" />
            <h4 className="start-diagnosis-title">
                {t('profile.startDiagnosis.start')}<br />{t('profile.startDiagnosis.withOli')}
            </h4>
            <button className="start-diagnosis-button" onClick={startDiagnosis}>{t('profile.startDiagnosis.startDiagnosis')}</button>
        </div>
    );
};