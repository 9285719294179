import React, { useState, useEffect } from 'react';
import userService from '../../../../../service/UserService';
import { userAtom } from '../../../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from 'utils/inputValidationUtil';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ProfileMenu } from 'pages/ProfilePage/ProfileMenu/ProfileMenu';
import styles from './AccountForm.module.scss';
import { useTranslation } from 'react-i18next';

export const AccountForm: React.FC = () => {
    const [user, setUser] = useAtom(userAtom);
    const [formData, setFormData] = useState({
        name: user?.name || '',
        lastName: user?.lastName || '',
        email: user?.email || '',
    });
    const [error, setError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [editClicked, setEditClicked] = useState(false);
    const isMobile = window.innerWidth < 768;
    const navigate = useNavigate();
    const {t} = useTranslation()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === 'email') {
            setEmailError(validateEmail(value) ? null : t('profileSettings.errors.invalidEmail'));
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleEdit = () => {
        setEditClicked(true)
    }

    const handleCancel = () => {
        navigate('/profile')
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!formData.name || !formData.lastName || !formData.email) {
            setError(t('profileSettings.errors.fillAllFields'));
            return;
        }

        if (emailError) {
            setError(t('profileSettings.errors.fixErrors'));
            return;
        }

        if (!user) {
            setError(t('profileSettings.errors.userNotFound'));
            return;
        }

        const result = await userService.updateUser(user, setUser, formData, navigate);

        if (result.success) {
            navigate(-1);
        } else {
            setError(result.message);
        }
    };

    const calculateAge = (birthDateString: string | undefined): string => {
        if (!birthDateString) return t('profileSettings.notAvailable');

        const birthDate = new Date(birthDateString);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return `${age} yo`;
    };

    const mobileLayout = (
        <div className={styles.mobileContainer}>
            <HomepageNavbar />

            <div className={styles.mobileHeaderSection}>
                <h3 className={styles.mobileTitle}>{t('profileSettings.accountInfo.title')}</h3>
                {!editClicked && (
                    <button className={styles.mobileEditBtn} onClick={handleEdit}>
                        {t('profileSettings.editButton')}
                    </button>
                )}
            </div>

            {editClicked ? (
                <form onSubmit={handleSubmit} className={styles.mobileForm}>
                    <div className={styles.formField}>
                        <input
                            type="text"
                            name="name"
                            placeholder={t('profileSettings.forms.fields.firstName')}
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.formField}>
                        <input
                            type="text"
                            name="lastName"
                            placeholder={t('profileSettings.forms.fields.lastName')}
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.formField}>
                        <input
                            type="email"
                            name="email"
                            placeholder={t('profileSettings.forms.fields.email')}
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {emailError && <p className={styles.errorMessage}>{emailError}</p>}
                    </div>
                    <div className={styles.btnsContainer}>
                        <button type="submit" className={styles.submitButton}>
                            {t('profileSettings.accountForm.saveChanges')}
                        </button>
                        <button className={styles.cancelBtn} onClick={handleCancel}>{t('common.cancel')}</button>
                    </div>
                </form>
            ) : (
                <>
                    <div className={styles.mobileUserInfo}>
                        <p>{user?.name}</p>
                        <p>{user?.lastName}</p>
                        <p>{calculateAge(user?.SPF?.age)}</p>
                    </div>

                    <div className={styles.mobileContactInfo}>
                        <p>{user?.email}</p>
                        <p>{user?.phoneNumber?.number}</p>
                    </div>
                </>
            )}
        </div>
    );

    const desktopLayout = (
        <div className={styles.desktopContainer}>
            <HomepageNavbar />
            <div className={styles.topSection}>
                <div className={styles.menuTopSection}>
                    {/* PLACE HOLDER DONT REMOVE */}
                </div>
                <div className={styles.contentTopSection}>
                    <h3 className={styles.userName}>{user?.name}</h3>
                    <h3 className={styles.userName}>{user?.lastName}</h3>
                </div>
            </div>

            <div className={styles.bottomSection}>
                <div className={styles.menuSection}>
                    <ProfileMenu />
                </div>
                <div className={styles.contentSection}>
                    <button className={styles.accountBackButton} onClick={handleBack}>
                        {t('profileSettings.forms.fields.backButton')}
                    </button>
                    <div className={styles.formHeader}>
                        <h5>{t('profileSettings.accountForm.editTitle')}</h5>
                    </div>
                    {error && <p className={styles.accountErrorMessage}>{error}</p>}
                    <form className={styles.accountForm} onSubmit={handleSubmit}>
                        <div className={styles.formField}>
                            <input
                                type="text"
                                name="name"
                                placeholder={t('profileSettings.forms.fields.firstName')}
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formField}>
                            <input
                                type="text"
                                name="lastName"
                                placeholder={t('profileSettings.forms.fields.lastName')}
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formField}>
                            <input
                                type="email"
                                name="email"
                                placeholder={t('profileSettings.forms.fields.email')}
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {emailError && <p className={styles.accountErrorMessage}>{emailError}</p>}
                        </div>
                        <button type="submit" className={styles.submitButton}>
                            {t('profileSettings.accountForm.saveChanges')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );

    return isMobile ? mobileLayout : desktopLayout;
};
