import React, { useState, useEffect, useRef } from 'react';
import './style.scss';

import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { componentLoadingStatusAtom } from 'stores/UserProfile';
import { config } from 'config';
import { useAllActives } from 'hooks/useNotionActives';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

export const ActivePreview: React.FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
    // Global loading status
    const [componentStatuses, setComponentStatuses] = useAtom(componentLoadingStatusAtom);

    // Fetch actives data
    const { allActives, allActivesIsLoading, allActivesError } = useAllActives();

    // States
    const [selectedActiveIndex, setSelectedActiveIndex] = useState(0);
    const [fade, setFade] = useState(false);
    const [indexActive, setIndexActive] = useState(0);
    const { t } = useTranslation()
    const { currentLanguage } = useCurrentLanguage()

    const activePreviewInterval = useRef<NodeJS.Timeout | null>(null);



    // NEW STATE: Filtered list of the actives we want
    const [filteredActives, setFilteredActives] = useState<any[]>([]);

    const navigate = useNavigate();
    const isMobile = window.innerWidth < 768;

    // Desired actives in the order you want:
    const desiredActiveNames = ['Retinaldehyde', 'Vitamin C', 'Niacinamide'];

    // Once actives load, filter + sort them to only keep the desired ones
    useEffect(() => {
        if (allActives && allActives.length > 0) {
            // 1. Filter only Betaine, AHAs, Arbutin
            const filtered = allActives.filter((active: any) =>
                desiredActiveNames.includes(active.active_name)
            );

            // 2. Sort them in the exact order of desiredActiveNames
            const sorted = filtered.sort((a: any, b: any) => {
                return (
                    desiredActiveNames.indexOf(a.active_name) -
                    desiredActiveNames.indexOf(b.active_name)
                );
            });

            // (Optional) If you only want 3, you could do sorted.slice(0, 3).
            // In this case we have exactly 3, so we don't really need slice().
            setFilteredActives(sorted);
            setSelectedActiveIndex(0);
        }
    }, [allActives]);

    // Update the global loading status once all actives are done loading
    useEffect(() => {
        if (!allActivesIsLoading) {
            setComponentStatuses((prev: any) => ({ ...prev, activePreview: true }));
        }
    }, [allActivesIsLoading, setComponentStatuses]);

    const restartRotation = (startIndex: number) => {
        let index = startIndex;
        const MAX_INDEX_COUNT = 1000;

        if (!filteredActives || filteredActives.length === 0) return;

        //  Clear previous interval before starting a new one
        if (activePreviewInterval.current) {
            clearInterval(activePreviewInterval.current);
        }

        activePreviewInterval.current = setInterval(() => {
            setFade(true);
            setTimeout(() => {
                const newIndex = (index + 1) % filteredActives.length;
                setSelectedActiveIndex(newIndex);
                setIndexActive(newIndex);
                setFade(false);



                index = (index + 1) % MAX_INDEX_COUNT;
            }, 300);
        }, config.REACT_APP_DELAY);
    };

    // Auto-rotate among the filtered actives
    useEffect(() => {
        restartRotation(0); // Start from the first active on load

        return () => {
            if (activePreviewInterval.current) {
                clearInterval(activePreviewInterval.current);
            }
        };
    }, [filteredActives]);

    // If still loading or there's an error, return nothing (or loader)
    if (allActivesIsLoading || !allActives) return null;
    if (allActivesError) {
        console.error('Failed to load actives preview:', allActivesError);
        return null;
    }

    // If no filtered actives, nothing to show
    if (filteredActives.length === 0) {
        return null;
    }

    const selectedActive = filteredActives[selectedActiveIndex];
    if (!selectedActive) return null;

    // Use the active’s image for the background
    const backgroundImageUrl = selectedActive.image || '';

    // Helper to get the active's id (modify as needed)
    const getActiveId = (active: any): string => active._id || '';

    const handleActiveClick = (activeId: string) => {
        // Navigate to detail page or do whatever
        window.location.href = `feed/actives/${activeId}`;
    };

    const handleSeeAllActives = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        navigate('/feed/actives');
    };

    const handleStoryClick = (index: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        // Show the clicked active immediately
        setSelectedActiveIndex(index);
        setIndexActive(index);

        // Restart auto-rotation from the clicked index
        restartRotation(index);
    };



    return (
        <div
            onClick={() => handleActiveClick(getActiveId(selectedActive))}
            className="articlePriviewContainer"
        >
            {/* Desktop view: image on top */}
            {!isMobile && (
                <div
                    className="articlePreviewImg"
                    style={{
                        backgroundImage: `url(${backgroundImageUrl})`,
                        transition: 'background-image 1s ease-in-out',
                    }}
                    onClick={() => handleActiveClick(getActiveId(selectedActive))}
                />
            )}

            <div className={`APcontentContainer ${fade ? 'fade' : ''}`}>
                <div className="storiesContainer-loggedOut">
                    {filteredActives.map((active: any, index: number) => (
                        <div
                            key={getActiveId(active) || index}
                            onClick={(event) => handleStoryClick(index, event)}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                className={`storyIMG-container ${index === selectedActiveIndex ? 'selected' : ''
                                    }`}
                            >
                                {indexActive === index && (
                                    <svg className="circle-border" width="75" height="75">
                                        <circle cx="37.5" cy="37.5" r="36" />
                                    </svg>
                                )}

                                <div
                                    className="storyIMG"
                                    style={{
                                        backgroundImage: `url(${active.image || ''})`,
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleActiveClick(getActiveId(active));
                                    }}
                                />
                            </div>
                            <p className="storyNumber">0{index + 1}</p>
                        </div>
                    ))}
                </div>

                {/* Mobile view: image after the "stories" */}
                {isMobile && (
                    <div
                        className="articlePreviewImg"
                        style={{
                            backgroundImage: `url(${backgroundImageUrl})`,
                            transition: 'background-image 1s ease-in-out',
                        }}
                        onClick={() => handleActiveClick(getActiveId(selectedActive))}
                    />
                )}

                <div className="textAndButtonContainer">
                    <div
                        className="textBlock"
                        onClick={() => handleActiveClick(getActiveId(selectedActive))}
                    >
                        <p className="skinCareHeader">
                            {selectedActive.active_name || ''}
                        </p>
                        <p className='skinCareText'>{selectedActive.translations?.[currentLanguage]?.description || ''}</p>

                    </div>

                    <div className="seeAllActivesContainer">
                        <button className="APHomeblackButton" onClick={handleSeeAllActives}>
                            <p>{t('homepage.actives.seeAll')}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
