import api from './apiConfig';

export default class subscribedUserApi {
    static async subscribe(email: string) {
        try {
            const response = await api.post('/subscribed-users/subscribe', { email });
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
    static async checkIfSubscribed(email: string): Promise<boolean> {
        try {
            const response = await api.post('/subscribed-users/check', { email });
            return response.data.isSubscribed;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

}
