// src/hooks/useCollectionProducts.ts

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import productsApi from '../service/API/ProductsApi';
import { ShopProduct } from '../types/ShopInterfaces';

interface ApiResponse {
    success: boolean;
    data?: {
        products: ShopProduct[];
    };
    message?: string;
}

interface UseCollectionProductsResult {
    products: ShopProduct[];
    loading: boolean;
    error: any;
}

export const useCollectionProducts = (search: string): UseCollectionProductsResult => {
    const {
        data,
        isLoading,
        error,
    }: UseQueryResult<ShopProduct[], Error> = useQuery({
        queryKey: ['collectionProducts', search],
        queryFn: async () => {
            const response = (await productsApi.getCollectionProducts(search)) as ApiResponse;
            if (response.success && response.data?.products) {
                return response.data.products;
            } else {
                throw new Error(response.message || 'Failed to fetch products');
            }
        },
        enabled: !!search, // Only run the query if search is not empty
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    return {
        products: data || [],
        loading: isLoading,
        error,
    };
};

export default useCollectionProducts;
