import React from 'react';
import styles from './styles.module.scss';

interface GeneralButtonProps {
  text: string;
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
}
function GeneralButton({ text, icon, onClick, disabled }: GeneralButtonProps) {
  return (
    <button className={`${styles.generalButton} ${disabled ? styles.disabled : ''}`} onClick={onClick} disabled={disabled}>
      {icon && <img className={styles.icon} src={icon} alt={text} />}
      {text}
    </button>
  );
}

export default GeneralButton;
