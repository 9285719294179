export const setLocalStorageItem = (key: string, value: any) => {
    try {
        const serializedValue = typeof value === 'string' ? value : JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Error setting item to local storage:', error);
    }
};


export const getLocalStorageItem = (key: string) => {
    try {
        const serializedValue = localStorage.getItem(key);
        return serializedValue ? JSON.parse(serializedValue) : null;
    } catch (error) {
        console.error('Error getting item from local storage:', error);
        return null;
    }
};

export const insertProductId = (productId: string) => {
    const key = "recentlyViewed";

    try {
        const serializedValue = localStorage.getItem(key);
        const recentlyViewed: string[] = serializedValue ? JSON.parse(serializedValue) : [];

        const updatedRecentlyViewed = recentlyViewed.filter((id) => id !== productId);

        updatedRecentlyViewed.unshift(productId);

        if (updatedRecentlyViewed.length > 14) {
            updatedRecentlyViewed.pop();
        }

        localStorage.setItem(key, JSON.stringify(updatedRecentlyViewed));
    } catch (error) {
        console.error("Error updating recently viewed items in local storage:", error);
    }
};

export const isRecentlyViewedExists = (): boolean => {
    const key = "recentlyViewed";

    try {
        const serializedValue = localStorage.getItem(key);
        const recentlyViewed: string[] = serializedValue ? JSON.parse(serializedValue) : [];

        return recentlyViewed.length > 0;
    } catch (error) {
        console.error("Error checking recently viewed items in local storage:", error);
        return false;
    }
};


export const clearLocalStorage = (): void => {
    localStorage.clear();
};