import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Link, useLocation } from 'react-router-dom';
import useRandomProducts from 'hooks/randomProducts';
import './mobileCarousel.scss';
import smalleggShape from '../../assets/icons/smalleggShape.svg';
import backdot from '../../assets/icons/blackproductdot.svg';
import productsApi from 'service/API/ProductsApi';
import { getLocalStorageItem } from 'utils/localStoragUtil';
import { ShopProduct } from 'types/ShopInterfaces';
import { useCurrentLanguage } from 'hooks/useLanguage';

interface MobileCarouselProps {
    onSlideChange: (groupIndex: number) => void;
    carouselId: number;
    brandName?: string;
    isRecommanded?: boolean;
    isMiniature?: boolean;
    productId?: string;
    isRecentlyViewed?: boolean
    products?: ShopProduct[]
}

export const MobileCarousel: React.FC<MobileCarouselProps> = ({
    onSlideChange,
    carouselId,
    brandName,
    isRecommanded,
    isMiniature = false,
    productId = '',
    isRecentlyViewed,
    products
}) => {
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
    const location = useLocation();
    const {currentLanguage} = useCurrentLanguage()



    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const currentProductId = pathParts[pathParts.length - 1];
        setSelectedProduct(currentProductId);
    }, [location.pathname]);

    const handleSlideChange = (swiper: any) => {
        const groupIndex = Math.floor(swiper.activeIndex / 3) + 1;
        onSlideChange(groupIndex);
    };

    // Utility function to truncate title to 4 words
    const truncateTitle = (title: string) => {
        const words = title.split(' ');
        if (words.length > 3) {
            return words.slice(0, 3).join(' ') + '...';
        }
        return title;
    };

    return (
        <Swiper
            modules={[FreeMode]}
            slidesPerView={3} // Show 3 slides at a time
            spaceBetween={10} // Spacing between slides
            freeMode={{ enabled: false }} // Disable free mode for strict slide boundaries
            onSlideChange={handleSlideChange}
            className="product-carousel-swiper"
        >
            {products?.map((item, index) => (
                <SwiperSlide key={index} className="product-slide">
                    <div
                        className={`product-container ${isMiniature && selectedProduct === item._id ? 'selected' : ''}`}
                    >
                        <Link to={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="carousel-image-container">
                                <img
                                    className="carousel-image"
                                    src={item.imgUrl}
                                    alt={item?.translations?.[currentLanguage]?.title || 'Product Image'}
                                />
                            </div>
                        </Link>
                        {isMiniature && selectedProduct === item._id && (
                            <div className="egg-shape-container">
                                <img src={smalleggShape} alt="egg shape" className="egg-shape" />
                                <div
                                    className="black-dot"
                                    style={{
                                        position: 'absolute',
                                        bottom: '80px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        width: '10px',
                                        height: '10px',
                                        backgroundImage: `url(${backdot})`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                ></div>
                            </div>
                        )}

                        {!isMiniature && (
                            <>
                                <div className='productDataContainer'>
                                    <p className="mobile-productBrand">{item.brand}</p>
                                    <p className="mobile-productTitle" title={item.translations?.[currentLanguage]?.title || ''}>{truncateTitle(item.translations?.[currentLanguage]?.title || '')}</p>
                                    <p className="mobile-productPrice">€{item.price?.toFixed(2)} | ({item.quantity}ml)</p>
                                </div>
                            </>
                        )}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
