// src/routes/Routes.tsx
import { Brands } from 'components/brands/brands';
import { useAtom } from 'jotai';
import { ActivesList } from 'pages/Feed/ActivesList/ActivesList';
import { ArticleDetail } from 'pages/Feed/ArticleDetail/ArticleDetail';
import { DossiersList } from 'pages/Feed/DossiersList/DossiersList';
import { Feed } from 'pages/Feed/Feed';
import { OneActive } from 'pages/Feed/OneActive/OneActive';
import { HotBrands } from 'pages/HotBrands/HotBrands';
import { LogInPage } from 'pages/LogInPage/LogInPage';
import { ProductDetail } from 'pages/ProductDetails/ProductDetails';
import { ProfileSettings } from 'pages/ProfilePage/ProfileSettings/ProfileSettings';
import { ProfileVouchers } from 'pages/ProfilePage/ProfileVouchers/ProfileVouchers';
import { ShopBestsellers } from 'pages/ShopBestsellers/ShopBestsellers';
import { ShopPage } from 'pages/ShopPage/ShopPage';
import { SignUpPage } from 'pages/SignUpPage/SignUpPage';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { config } from '../config';
import { CheckoutPage } from '../pages/checkout/checkout';
import { ComingSoonPage } from '../pages/ComingSoonPage/ComingSoonPage';
import { SkinTypeFormStack } from '../pages/Form/Form';
import { Homepage } from '../pages/homepage/homepage';
import { LoginUserHomePage } from '../pages/loginUserHomePage/loginUserHomePage';
import { Favorites } from '../pages/ProfilePage/Favorites/Favorites';
import globalService from '../service/GlobalService';
import { ShippingForm } from 'pages/ProfilePage/ProfileSettings/components/AddressForm/AddressForm';
import { ChangePassword } from 'pages/ProfilePage/ProfileSettings/components/ChangePassword/ChangePassword';
import { SkinResume } from 'pages/ProfilePage/SkinResume/SkinResume';
// import { Brands } from 'components/brands/brands';
import { userAtom } from 'stores/UserProfile';
import ScrollToTop from 'components/navbars/utils/ScrollToTop';
//import { TermsOfUse } from 'pages/ProfilePage/ProfileSettings/components/TermsOfUse/TermsOfUse';
import { AccountForm } from 'pages/ProfilePage/ProfileSettings/components/AccountForm/AccountForm';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import PaymentSuccess from 'pages/checkout/paymentSuccess';
import { ForgetPassword } from 'pages/ForgetPassword/ForgetPassword'
import { ResetPassword } from 'pages/ForgetPassword/ResetPassword'
import { ResetSuccessful } from 'pages/ForgetPassword/ResetSuccessful';
import { MyOrders } from 'pages/ProfilePage/MyOrders/MyOrders';
import { OrderPage } from 'pages/ProfilePage/MyOrders/components/OrderPage/OrderPage';
import useResizeObserver from '../hooks/resizeObserver';
import { ProfileHomePage } from 'pages/ProfilePage/ProfileHomePage/ProfileHomePage';
import HomepageLoader from 'components/animation/homepageloader';
import SavedArticles from 'pages/ProfilePage/savedArticles/savedArticles';
import { LoginPopup } from 'components/popups/loginPopUp/loginPopup';
import { BillingForm } from 'pages/ProfilePage/ProfileSettings/components/BillingForm/BillingFrom';
import { CollectionPage } from 'pages/Collection/CollectionPage/CollectionPage';
import { CategoriesPage } from 'pages/Categories/CategoriesPage/CategoriesPage';
import { BrandsHomePage } from 'components/brandsHomePage/brandsHomePage';
import { ShopAll } from 'pages/shopAll/shopAll';
import { Interceptor } from 'service/interceptor';
import { tokenExpiredAtom } from 'stores/TokenExpired';
import { EditPage } from 'components/edit/EditPage';
import FaqPage from 'pages/FAQ/FaqPage';
import TermsOfUsePage from 'pages/TermsOfUsePage/TermsOfUsePage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage/PrivacyPolicyPage';
import IngredientsScanPage from 'pages/IngredientsScanPage/IngredientsScanPage';

const AppRoutes: React.FC = () => {
  const [resizeRef, dimensions] = useResizeObserver();
  const [user, setUser] = useAtom(userAtom);
  const [tokenExpired, setTokenExpired] = useAtom(tokenExpiredAtom);

  useEffect(() => {
    globalService.loadUserFromStorage(setUser);
  }, [setUser]);

  if (config.REACT_APP_COMING_SOON === 'true') {
    return (
      <Routes>
        <Route path="/" element={<ComingSoonPage />} />
      </Routes>
    );
  } else {
    return (
      <div ref={resizeRef} style={{ width: '100%', height: '100%' }}>
        {tokenExpired && <LoginPopup onClose={() => setTokenExpired(false)} />}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />

          <Route element={<Interceptor />} path='/' >
            {/*<Route path="/" element={user ? <LoginUserHomePage /> : < Homepage />} />*/}

            <Route path="/shop" element={<ShopPage />} />
            <Route path="/hot-brands" element={<HotBrands />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/login" element={<LogInPage />} />
            <Route path="login/forgot-password" element={<ForgetPassword />} />
            <Route path="/change_pw/:token" element={<ResetPassword />} />
            <Route path="/reset-done" element={<ResetSuccessful />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/bestsellers" element={<ShopBestsellers />} />
            <Route path="/brand/:brandName" element={<BrandsHomePage />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/feed" element={<Feed />} />
            <Route path="/*" element={<ErrorPage />} />
            <Route path="/notFound" element={<ErrorPage />} />
            <Route path="/feed/dossier/:dossierId" element={<DossiersList />} />
            <Route path="/feed/actives" element={<ActivesList />} />
            <Route path="/feed/actives/:activeId" element={<OneActive />} />
            <Route path="/feed/article/:articleId" element={<ArticleDetail />} />
            <Route path="/spf-form/*" element={<SkinTypeFormStack />} />
            <Route path="/profile" element={<ProfileHomePage />} />
            <Route path="/profile/settings" element={<ProfileSettings />} />
            <Route path="/profile/vouchers" element={<ProfileVouchers />} />
            <Route path="/profile/settings/add-address" element={<ShippingForm />} />
            <Route path="/profile/settings/add-billing" element={<BillingForm />} />
            <Route path="/profile/settings/edit-account" element={<AccountForm />} />
            <Route path="/profile/settings/add-address/:addressId" element={<ShippingForm />} />
            <Route path="/profile/settings/add-billing/:addressId" element={<BillingForm />} />
            <Route path="/profile/settings/change-password" element={<ChangePassword />} />
            <Route path="/collection/products" element={<CollectionPage />} />

            {/* THIS ROUTE IS CURRENTLY NOT IN USE -> THE TERMS IS NOW A LINK TO EXTERNAL FILE */}
            <Route path="/legal/terms" element={<TermsOfUsePage />} />
            <Route path="/legal/privacy" element={<PrivacyPolicyPage />} />

            <Route path="/profile/my-orders" element={<MyOrders />} />
            <Route path="/profile/my-orders/:id" element={<OrderPage />} />
            <Route path="/profile/favorites" element={<Favorites />} />
            <Route path="/profile/saved-articles" element={<SavedArticles />} />
            <Route path="/login-popup" element={<LoginPopup onClose={() => { }} />} />
            <Route path="/order/:id" element={<OrderPage />} />
            <Route path="/justdropped" element={<ErrorPage message='Coming soon! This feature is currently in development and will be available shortly' />} />
            <Route path="/recommendations" element={<ErrorPage message="Coming soon! This feature is currently in development and will be available shortly" />} />
            <Route path="/success" element={<PaymentSuccess />} />
            <Route path="/categories/" element={<CategoriesPage />} />
            <Route path="shopAll/" element={<ShopAll />} />
            <Route path="editPage" element={<EditPage />} />
            <Route path="/ingredients-scan" element={<IngredientsScanPage />} />
          </Route>
        </Routes>
      </div >
    );
  }
};

export default AppRoutes;