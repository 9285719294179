import { useQuery, UseQueryResult } from '@tanstack/react-query';
import productsApi from '../service/API/ProductsApi';
import { ShopProduct } from '../types/ShopInterfaces';

const fetchMostSearchedProducts = async (): Promise<ShopProduct[]> => {
    const data = await productsApi.getMostSearchedProducts();
    if (!data.success) {
        throw new Error(data.message || 'Failed to fetch products');
    }
    return data.data || [];
};

interface UseMostSearchedProductsResult {
    mostSearchedProducts: ShopProduct[] | undefined;
    mostSearchedProductsIsLoading: boolean;
    mostSearchedProductsError: unknown;
}

export const useMostSearchedProducts = (): UseMostSearchedProductsResult => {
    const { data, isLoading, error }: UseQueryResult<ShopProduct[], Error> = useQuery({
        queryKey: ['mostSearchedProducts'],
        queryFn: () => fetchMostSearchedProducts(),
        staleTime: 5 * 24 * 60 * 60 * 1000,
        gcTime: 5 * 24 * 60 * 60 * 1000,
    });
    return { mostSearchedProducts: data, mostSearchedProductsIsLoading: isLoading, mostSearchedProductsError: error };
};

export default useMostSearchedProducts;