import { atom } from 'jotai'

interface LoadingState {
  isLoading: boolean
  pendingRequests: number
}

export const loadingAtom = atom<LoadingState>({ isLoading: false, pendingRequests: 0 })

export const setLoadingAtom = atom(
  null,
  (get, set, updateFn: (prev: LoadingState) => LoadingState) => {
    const currentState = get(loadingAtom)
    set(loadingAtom, updateFn(currentState))
  }
)