import { useQuery } from '@tanstack/react-query';
import api from '../service/API/apiConfig';
import { NotionArticle, NotionDossier, NotionActives } from './useNotionArticles.types';

const fetchDossiers = async (): Promise<NotionDossier[]> => {
  try {
    const response = await api.get('/notion/get-all-dossiers');

    const data = response.data.map((dossier: NotionDossier) => ({
      id: dossier.notion_id,
      properties: {
        Name: {
          title: [
            {
              en: { plain_text: dossier.translations.en.name },
              fr: { plain_text: dossier.translations.fr.name }
            }
          ]
        },
        Image: { files: [{ file: { url: dossier.imageUrl } }] },
        '📰 Articles': { relation: dossier.relatedArticles?.map((id: string) => ({ id })) || [] },
      }
    }));
    return data;
  } catch (error) {
    console.error('Error fetching dossiers:', error);
    throw error;
  }
};

const fetchActives = async (): Promise<NotionActives[]> => {
  const response = await api.get('/notion/actives');
  return response.data;
};

const fetchArticlesByDossierId = async (notionId: string) => {
  const response = await api.get(`/articles/get-article-by-dossierId?notionId=${notionId}`);
  return response.data.articles;
};

export const useArticlesByDossier = (notionId: string) => {
  return useQuery({
    queryKey: ["articles", notionId], // Unique key for caching
    queryFn: () => fetchArticlesByDossierId(notionId),
    enabled: !!notionId, // Prevents fetching if notionId is not set
  });
};

export const useNotionActives = () => {
  return useQuery<NotionActives[], Error>({
    queryKey: ['notionActives'],
    queryFn: fetchActives,
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });
};

export const useNotionArticles = (pagination?: { page: number, limit: number }) => {
  return useQuery({
    queryKey: ['notionArticles', pagination],
    queryFn: async () => {
      const response = await api.get('/articles/get-all-articles', {
        params: { pagination }
      });
      return response.data.articles;
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
    retry: 1,
    refetchOnWindowFocus: false,
  });
};

export const useNotionDossiers = () => {
  return useQuery<NotionDossier[], Error>({
    queryKey: ['notionDossiers'],
    queryFn: fetchDossiers,
    staleTime: 10 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
    select: (data) => {
      return data;
    }
  });
};
