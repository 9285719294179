import { IIngredient, IProcessedData } from 'types/IIngredientsScanner';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface RenderTableProps {
  processedData: IProcessedData | null;
  onIngredientSelect: (ingredient: IIngredient) => void;
}

export default function RenderTable({ processedData, onIngredientSelect }: RenderTableProps) {
  const { t } = useTranslation();
  if (!processedData) return null;

  const renderFunction = (ingredient: IIngredient) => {
    
    // If ingredient is null, it means it doesn't exist in our DB
    if (!ingredient) {
      return t('ingredientsScan.results.ingredientsFound.ingredientNotInInciFormat');
    }
    
    // For existing ingredients that have no functions
    return ingredient.functions?.length ? ingredient.functions.join(', ') : '-';
  };

  return (
    <div className={styles.tableWrapper}>
      {/* Table Header */}
      <div className={styles.tableHeader}>
        <div className={styles.headerRow}>
          <div className={styles.headerCell}>{t('ingredientsScan.results.ingredientsFound.ingredientName')}</div>
          <div className={styles.headerCell}>{t('ingredientsScan.results.ingredientsFound.whatItDoes')}</div>
        </div>
      </div>

      {/* Table Body */}
      <div className={styles.tableBody}>
        {processedData.results.map((result, index) => {
          const ingredientData = result.ingredient;
          return (
            <div key={ingredientData ? ingredientData._id : result.cleaned} className={`${styles.tableRow} ${index % 2 === 0 ? styles.evenRow : styles.oddRow}`}>
              <div 
                className={`${styles.ingredientName} ${!ingredientData ? styles.ingredientNameNotInInciFormat : ''}`} 
                onClick={() => ingredientData && onIngredientSelect(ingredientData)}
              >
                {ingredientData ? ingredientData.inci_name.toLowerCase() : result.cleaned.toLowerCase()}
              </div>
              <div className={styles.functions}>{renderFunction(ingredientData)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
