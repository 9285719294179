import React from 'react';
import styles from './styles.module.scss';
import arrow from '../../../assets/icons/rightArrowIcon.svg';
import plusIcon from '../../../assets/icons/plusIcon.svg';
import minusIcon from '../../../assets/icons/minusIcon.svg';
import vIcon from '../../../assets/icons/vIcon.svg';
import emptyVIcon from '../../../assets/icons/emptyCheckBox.svg';
import xIcon from '../../../assets/icons/xIconCheckbox.svg';

interface FilterSectionProps {
    label: string;
    items: string[];
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handleFilterClick: (item: string) => void;
    activeFilters: string[];
    isMobile: boolean;
    isDontShowMe: boolean;
}

const FilterSection: React.FC<FilterSectionProps> = ({
    label,
    items,
    show,
    setShow,
    handleFilterClick,
    activeFilters,
    isMobile,
    isDontShowMe,
}) => {
    // Mobile filter section that opens as an overlay/modal
    const mobileFilterSection = isMobile && show && (
        <div className={styles.mobileOverlay}>
            <div className={styles.mobileHeader}></div>
            <ul className={styles.mobileFilterList}>
                {items.map((item, index) => (
                    <li
                        key={index}
                        className={styles.mobileFilterItem}
                        onClick={() => handleFilterClick(item)}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );

    return (
        <div className={styles.filterSection}>
            <div className={styles.upperSectionMobile} onClick={() => setShow(!show)}>
                <h3
                    className={styles.filterTitle}
                >
                    {label}
                </h3>
                {!isMobile && (
                    <img
                        src={show ? minusIcon : plusIcon}
                        className={styles.optionImage}
                        alt={show ? 'Collapse' : 'Expand'}
                    />
                )}

                {isMobile && (
                    <div className={styles.arrowContainer}>
                        <img
                            src={arrow}
                            className={`${styles.arrowIcon} ${show ? styles.rotate : ''}`}
                            alt="Expand"
                        />
                    </div>
                )}
            </div>
            {/* For non-mobile devices, we directly show the list */}
            {!isMobile && show && (
                <ul className={styles.filterList}>
                    <div className={styles.filterListCheckboxAndText}>
                        {items.map((item, index) => {
                            const isActive = activeFilters.includes(item);
                            return (
                                <li
                                    key={index}
                                    className={styles.filterItem}
                                    onClick={() => handleFilterClick(item)}
                                >
                                    <img
                                        src={
                                            isActive
                                                ? isDontShowMe
                                                    ? xIcon
                                                    : vIcon
                                                : emptyVIcon
                                        }
                                        className={styles.checkboxIcon}
                                        alt={isActive ? (isDontShowMe ? 'X Icon' : 'Checked') : 'Unchecked'}
                                    />
                                    {item}
                                </li>
                            );
                        })}
                    </div>
                </ul>
            )}
            {/* Render mobile filter section overlay */}
            {mobileFilterSection}
        </div>
    );
};

export default FilterSection;
