import { IAddress, ShippingAddress } from '../../../../../types/TInterfaces';

export const convertIAddressToShippingAddress = (address: IAddress): ShippingAddress => {
    return {
        _id: address._id,
        addressName: address.addressName,
        first_name: address.firstName || '',
        last_name: address.lastName || '',
        phone: address.phoneNumber?.number || '',
        street: address.street,
        postalCode: address.postalCode,
        city: address.city,
        country: address.country,
        addressComplement: address.addressComplement || '',
    };
};
