interface ProcessedImage {
  imageSrc: string;
  file: File;
}

/**
 * Processes a video frame to enhance image quality for ingredient scanning.
 * The function applies grayscale conversion, high contrast enhancement, and sharpening
 * to optimize for text recognition while maintaining performance.
 * 
 * @param video - The HTMLVideoElement containing the frame to process
 * @returns {ProcessedImage} Object containing:
 *   - imageSrc: Base64 string of the processed image
 *   - file: File object of the processed image in JPEG format
 * @throws {Error} If canvas context cannot be obtained or image capture fails
 */
export const processImage = (video: HTMLVideoElement): ProcessedImage => {
  // Create two canvases: one for the original image and one for the enhanced version
  const originalCanvas = document.createElement('canvas');
  const enhancedCanvas = document.createElement('canvas');
  
  // Set canvas dimensions to match video dimensions
  // Limit maximum dimensions for better performance
  const MAX_DIMENSION = 1920;
  const scale = Math.min(1, MAX_DIMENSION / Math.max(video.videoWidth, video.videoHeight));
  const width = Math.floor(video.videoWidth * scale);
  const height = Math.floor(video.videoHeight * scale);
  
  [originalCanvas, enhancedCanvas].forEach(canvas => {
    canvas.width = width;
    canvas.height = height;
  });

  // Draw the original frame to the first canvas for reference
  const originalContext = originalCanvas.getContext('2d');
  if (!originalContext) throw new Error('Could not get canvas context');
  originalContext.drawImage(video, 0, 0, width, height);

  // Set up the enhanced canvas for image processing
  const context = enhancedCanvas.getContext('2d');
  if (!context) throw new Error('Could not get canvas context');
  
  // Draw the initial frame to the enhanced canvas
  context.drawImage(video, 0, 0, width, height);

  // Extract pixel data for processing
  const imageData = context.getImageData(0, 0, width, height);
  const data = imageData.data;

  // Apply contrast enhancement optimized for text recognition
  // contrast > 1 increases contrast, < 1 decreases it
  // Higher contrast (1.5-1.8) works better for grayscale text
  const contrast = 1.7;
  const intercept = 128 * (1 - contrast);
  
  // Process pixels in chunks for better performance
  const CHUNK_SIZE = 1000;
  for (let i = 0; i < data.length; i += CHUNK_SIZE * 4) {
    const end = Math.min(i + CHUNK_SIZE * 4, data.length);
    for (let j = i; j < end; j += 4) {
      // First convert to grayscale
      const gray = (data[j] + data[j + 1] + data[j + 2]) / 3;
      
      // Then apply contrast enhancement to the grayscale value
      const enhancedGray = contrast * gray + intercept;
      
      // Apply the enhanced grayscale value to all channels
      data[j] = data[j + 1] = data[j + 2] = enhancedGray;
    }
  }

  // Apply image sharpening using a convolution kernel
  // The kernel is a 3x3 matrix that enhances edge detection
  // Increased sharpening for better text edge detection
  const sharpenAmount = 1.0; // controls sharpening intensity (0.0 to 1.0)
  const kernel = [
    0, -sharpenAmount, 0,
    -sharpenAmount, 1 + 4 * sharpenAmount, -sharpenAmount,
    0, -sharpenAmount, 0
  ];

  // Create a temporary canvas for the convolution operation
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = width;
  tempCanvas.height = height;
  const tempContext = tempCanvas.getContext('2d');
  if (!tempContext) {
    throw new Error('Could not get temp canvas context');
  }

  // Copy the contrast-enhanced image to the temporary canvas
  tempContext.putImageData(imageData, 0, 0);
  
  // Apply the sharpening convolution kernel
  const tempImageData = tempContext.getImageData(0, 0, width, height);
  const tempData = tempImageData.data;
  const pixels = new Uint8ClampedArray(data.length);

  // Process each pixel using the convolution kernel
  // Skip the edges to avoid out-of-bounds access
  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      // Process each color channel (RGB)
      for (let c = 0; c < 3; c++) {
        let sum = 0;
        // Apply the 3x3 kernel to the surrounding pixels
        for (let ky = -1; ky <= 1; ky++) {
          for (let kx = -1; kx <= 1; kx++) {
            const idx = ((y + ky) * width + (x + kx)) * 4 + c;
            sum += tempData[idx] * kernel[(ky + 1) * 3 + (kx + 1)];
          }
        }
        const idx = (y * width + x) * 4 + c;
        pixels[idx] = sum;
      }
      pixels[(y * width + x) * 4 + 3] = 255; // Set alpha channel to fully opaque
    }
  }

  // Apply the processed pixels back to the enhanced canvas
  context.putImageData(new ImageData(pixels, width, height), 0, 0);

  // Debug logs: Uncomment to view original and processed images in console
  // These logs create visual previews of the images in the browser console
  // Useful for debugging image processing results
  /*
  console.group('Image Processing Debug');
  console.log('Original Image:');
  console.log(`%c+`, `font-size: 1px; padding: ${height/2}px ${width/2}px; background: url(${originalCanvas.toDataURL()}) no-repeat; background-size: contain;`);
  console.log('Enhanced Image:');
  console.log(`%c+`, `font-size: 1px; padding: ${height/2}px ${width/2}px; background: url(${enhancedCanvas.toDataURL()}) no-repeat; background-size: contain;`);
  console.groupEnd();
  */

  // Convert the processed image to a high-quality JPEG
  const imageSrc = enhancedCanvas.toDataURL('image/jpeg', 1.0);
  if (!imageSrc) {
    throw new Error('Failed to capture image');
  }

  // Convert the base64 image data to a File object
  const base64Data = imageSrc.split(',')[1];
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  // Convert base64 string to byte array
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  // Create a File object from the processed image
  const byteArray = new Uint8Array(byteArrays);
  const blob = new Blob([byteArray], { type: 'image/jpeg' });
  const file = new File([blob], 'scanned-ingredients.jpg', { type: 'image/jpeg' });

  return { imageSrc, file };
}; 