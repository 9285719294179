import api from './apiConfig';

export default class notionsApi {
    static async getNotionsActiveById(id: string): Promise<{ success: boolean; data?: any; message?: string }> {
        try {
            const response = await api.get(`/actives/${id}`);
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, message: 'Failed to fetch notions' };
        }

    }

    static async getActiveByNotionId(notionId: string): Promise<{ success: boolean; data?: any; message?: string }> {
        try {
            const response = await api.get(`/actives/by-notion-id/${notionId}`);
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, message: 'Failed to fetch notion active' };
        }
    }
}
