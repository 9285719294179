import { IProcessedData, IScanData } from 'types/IIngredientsScanner';
import api from './apiConfig';

export const IngredientsApi = {
    analyzeText: async (text: string): Promise<string[]> => {
        const response = await api.post('/ingredients/analyze', { text });
        return response.data.ingredients;
    },

    analyzeIngredients: async (data: IScanData): Promise<IProcessedData> => {
        const response = await api.post('/ingredients/cosing-ingredients', { type: data.type, data: data.data });
        return response.data;
    },
    processImage: async (base64Img: string) => {
        const response = await api.post('/vision-api/process-image', {base64Img});
        return response;
    },
    rateProduct: async (ingredients: string[]) => {
        const response = await api.post('/rating-system/rate-product', {inci_list: ingredients});
        return response;
    }
}; 