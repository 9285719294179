import './App.css';
import ReactGA from 'react-ga4';
import AppRoutes from './router/Routes';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { config } from './config';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Initialize GA4
ReactGA.initialize(config.REACT_APP_GA_ID || 'G-SE8BHSSFC1');

// Currently using the test env of stripe. Change it to REACT_APP_STRIPE_PUBLISHABLE_LIVE
// when you want to go live.
const stripePublicKey = config.REACT_APP_STRIPE_SECRET;

if (!stripePublicKey) {
  throw new Error("Stripe public key is not defined. Check your environment variables.");
}

const stripePromise = loadStripe(stripePublicKey);

function App() {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <Elements stripe={stripePromise}>
      <AppRoutes />
    </Elements>
  );
}

export default App;
