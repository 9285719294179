import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { TSkinType } from '../../../types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { StepIndicator } from 'components/counter/counter';
import styles from '../style.module.scss';
import { useTranslation } from 'react-i18next';

export const SPF_SkinType: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 440;
  const {t} = useTranslation()

  const skinTypeMapping: Record<TSkinType, string> = {
    Normal: 'spfForm.skinType.options.normal',
    Combination: 'spfForm.skinType.options.combination',
    Oily: 'spfForm.skinType.options.oily',
    'Dryness and/or dehydrated': 'spfForm.skinType.options.dryness',
    Sensitive: 'spfForm.skinType.options.sensitive',
    'I don’t know': 'spfForm.skinType.options.unknown',
  };
  
  // Get keys from TSkinType for correct comparison
  const skinTypes: TSkinType[] = Object.keys(skinTypeMapping) as TSkinType[];

  const handleSkinTypeSelect = (skinType: TSkinType, translationKey: string) => {
    setSkinProfile({ ...skinProfile, skinType, textToRender: {...skinProfile?.textToRender, skinType: translationKey}  });
    
  };

  const goToNext = () => {
    if (skinProfile?.skinType) {
      navigate('/spf-form/spf-concerns');
    }
  };

  return (
    <div
      className={styles.headerBlockFormsSkin}
      style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}
    >
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <div className={styles.groupOne}>

            <StepIndicator currentStep={3} />
            <div className={styles.titleContainer}>
              <h1 className={styles.TitlesSpf}>{t('spfForm.skinType.title')}</h1>
            </div>
            <div className={styles.subtitleContainer}>

              <h5 className={styles.SubtitleSpf}>
                {t('spfForm.age.subtitle')}
              </h5>
            </div>
          </div>
          <div className={!isMobile ? styles.groupTwo : styles.groupTwoLongList}>
            <div className={styles.inputGroup}>
              {skinTypes.map((type) => (
                <button
                  key={type}
                  className={`${styles.inputButtons} ${skinProfile?.skinType === type ? styles.selected : ''
                    }`}
                  onClick={() => handleSkinTypeSelect(type, skinTypeMapping[type])}
                >
                  {t(skinTypeMapping[type])}
                </button>
              ))}
            </div>
          </div>
          <div className={styles.groupThree}>
            <div className={`${styles.spfbuttonContainer} ${!skinProfile?.skinType ? styles.disabled : ''}`}>
              <button className={styles.nextButtonForm} disabled={!skinProfile?.skinType} onClick={goToNext}>
                {t('common.nextButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};