import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import styles from './checkout.module.scss';
import emptyCart from '../../assets/icons/emptyCart.svg';
import selectedIcon from '../../assets/images/selectShipping.svg';
import unselectedCheckbox from '../../assets/icons/unselectedCheckbox.svg';
import vIcon from '../../assets/images/vIcon.png';
import xIcon from '../../assets/images/xIcon.png';

import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { AddressCard } from './adressCard';
import AllVouchersModal from './AllVouchersModal';

import { DiscountCalculationResult, Voucher, IProductInCart, ShippingAddress, RelayPoint, DeliveryService, IAnonymousUser, IUser } from 'types/TInterfaces';

import { userAtom } from '../../stores/UserProfile';
import userService from '../../service/UserService';
import deliveryApi from '../../service/API/deliveryApi';
import MapboxApi from 'service/API/mapboxApi';
import checkoutApi from '../../service/API/checkoutApi';
import { loadLocalCart, removeFromLocalCart, updateLocalCartItemQuantity } from '../../service/localCartService';

import { debounce } from 'utils/debounceUtil';
import { calculateVoucherDiscount } from 'utils/voucherUtils';

import { CountryCode } from 'libphonenumber-js';
import { ShopProduct } from 'types/ShopInterfaces';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';


export const CheckoutPage = () => {
  const location = useLocation();
  const {t} = useTranslation()
  const [userVouchers, setUserVouchers] = useState<Voucher[]>([]);
  const [showAllVouchers, setShowAllVouchers] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newAddressErrors, setNewAddressErrors] = useState<{ [key: string]: string }>({});
  const [billingAddressErrors, setBillingAddressErrors] = useState<{ [key: string]: string }>({});
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [postalCodeError, setPostalCodeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [billingAddressPhoneError, setBillingAddressPhoneError] = useState('');
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [newAddress, setNewAddress] = useState<ShippingAddress & { _id?: string }>({
    addressName: '',
    first_name: '',
    last_name: '',
    phone: '',
    street: '',
    postalCode: '',
    city: '',
    country: '',
    addressComplement: '',
    asDefault: false,
    type: '',
  });

  // logged in user
  const [user, setUser] = useAtom(userAtom);
  const [cartItems, setCartItems] = useState<IProductInCart[]>([]);
  const [products, setProducts] = useState<ShopProduct[]>([]);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [tempQuantity, setTempQuantity] = useState<number | null>(null);

  // anonymous user
  const [isAnonymousUser, setIsAnonymousUser] = useState(!user || Object.keys(user).length === 0);
  const [userEmail, setUserEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryForPhoneInput, setCountryForPhoneInput] = useState('FR');
  const [billingAddressPhone, setBillingAddressPhone] = useState('');

  // payment & shipping service + country handler
  const [deliveryCountries, setDeliveryCountries] = useState<Pick<DeliveryService, 'country'>[]>([]);
  const [allShippingDeliveryServices, setAllShippingDeliveryServices] = useState<DeliveryService[]>([]);
  const [filteredShippingServices, setFilteredShippingServices] = useState<DeliveryService[]>([]);
  const [selectedShippingService, setSelectedShippingService] = useState<DeliveryService | null>(null);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
  const [relayPointsData, setRelayPointsData] = useState<RelayPoint[]>([]);
  const [selectedRelayPoint, setSelectedRelayPoint] = useState<RelayPoint | null>(null);
  const [suggestions, setSuggestions] = useState<Array<{ id: string; place_name: string, country: string }>>([]);
  const [billingSuggestions, setBillingSuggestions] = useState<Array<{ id: string; place_name: string, country: string }>>([]);

  // selected address for shipping/delivery
  const [deliveryAddress, setDeliveryAddress] = useState<ShippingAddress[]>([]);
  const [shipping, setShipping] = useState<ShippingAddress[]>([]);
  const [billing, setBilling] = useState<ShippingAddress[]>([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [selectedBillingAddressIndex, setSelectedBillingAddressIndex] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<ShippingAddress>(deliveryAddress[selectedAddressIndex]);
  const [billingAddress, setBillingAddress] = useState<ShippingAddress>(billing[selectedBillingAddressIndex]);
  const [billingAddressCheckbox, setBillingAddressCheckbox] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  // apply voucher
  const [voucherCode, setVoucherCode] = useState('');
  const [appliedVoucher, setAppliedVoucher] = useState<Voucher | null>(null);
  const [discountResult, setDiscountResult] = useState<DiscountCalculationResult>({
    isApplicable: false,
    finalAmount: 0,
    discountAmount: 0,
    message: ''
  });
  const [voucherError, setVoucherError] = useState('');

  // checking if the screen is mobile size
  const [isMobile, setIsMobile] = useState(false);
  const [addressSectionVisible, setAddressSectionVisible] = useState({ shipping: true, billing: true });

  const {currentLanguage} = useCurrentLanguage()

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    setShipping(deliveryAddress.filter((address) => address.type === 'shipping'));
  }, [deliveryAddress]);

  useEffect(() => {
    setBilling(deliveryAddress.filter((address) => address.type === 'billing'));
  }, [deliveryAddress]);

  useEffect(() => {
    setIsAnonymousUser(!user || Object.keys(user).length === 0);
  }, [user]);

  useEffect(() => {
    if (user) {
      setSelectedAddress(shipping[selectedAddressIndex]);
    }
  }, [selectedAddressIndex, shipping]);

  useEffect(() => {
    if (selectedShippingService?.service === 'Relay/Lockers' && !selectedRelayPoint) {
      setIsCheckoutDisabled(true);
    } else {
      setIsCheckoutDisabled(false);
    }
  }, [selectedShippingService, selectedRelayPoint]);

  const checkVoucherApplicability = useCallback(() => {
    if (appliedVoucher) {
      const { subtotal, productTax } = calculateCartSubtotal(cartItems, selectedShippingService);
      const totalBeforeDiscount = String(subtotal + productTax);
      const newDiscountResult = calculateVoucherDiscount(appliedVoucher, parseFloat(totalBeforeDiscount));

      if (!newDiscountResult.isApplicable) {
        setAppliedVoucher(null);
        setDiscountResult({
          isApplicable: false,
          finalAmount: 0,
          discountAmount: 0,
          message: ''
        });
        setVoucherError(newDiscountResult.message ?? t('checkout.voucherErrors.notApplicable'));
      } else {
        setDiscountResult(newDiscountResult);
        setTotalPriceWithTax(newDiscountResult.finalAmount);
        setVoucherError('');
      }
    }
  }, [cartItems, selectedShippingService, appliedVoucher]);

  useEffect(() => {
    checkVoucherApplicability();
  }, [cartItems, checkVoucherApplicability]);

  useEffect(() => {
    if (location.state?.voucherCode && location.state?.shouldApplyVoucher) {
      setVoucherCode(location.state.voucherCode);
      // Waiting for total price to be calculated
      setTimeout(async () => {
        try {
          if (user) {
            const totalBeforeDiscount = calculateTotalAmountIncludingAllTax(cartItems, selectedShippingService);
            // Only proceed if we have a valid total
            if (parseFloat(totalBeforeDiscount) > 0) {
              const response = await userService.applyVoucher(user, setUser, location.state.voucherCode);
              if (response.success && response.voucher) {
                const discountResult = calculateVoucherDiscount(response.voucher, parseFloat(totalBeforeDiscount));

                if (discountResult.isApplicable) {
                  setAppliedVoucher(response.voucher);
                  setDiscountResult(discountResult);
                  setTotalPriceWithTax(discountResult.finalAmount);
                } else {
                  setVoucherError(discountResult.message ?? t('checkout.voucherErrors.notApplicable'));
                }
              }
            }
          }
        } catch (error) {
          console.error('Error auto-applying voucher:', error);
        }
      }, 1000);
      window.history.replaceState({}, document.title);
    }
  }, [location.state, user, cartItems, selectedShippingService]);

  useEffect(() => {
    const fetchUserVouchers = async () => {
      if (user) {
        const response = await userService.getUserVouchers(user, setUser);
        if (response.success) {
          setUserVouchers(response.vouchers);
        }
      }
    };

    fetchUserVouchers();
  }, [user]);

  const handleRemoveVoucher = () => {
    setVoucherCode('');
    setAppliedVoucher(null);
    setDiscountResult({
      isApplicable: false,
      finalAmount: 0,
      discountAmount: 0,
      message: ''
    });
  };

  const handleVoucherSelect = (code: string) => {
    setVoucherCode(code);
    setShowAllVouchers(false);
    handleApplyVoucher(code);
  };

  const handleApplyVoucher = async (code?: string) => {
    setVoucherError('');
    const voucherToApply = code || voucherCode;

    if (!voucherToApply.trim()) {
      setVoucherError(t('checkout.voucherErrors.enterCode'));
      return;
    }

    try {
      let addResponse;
      let response;

      if (user) {
        addResponse = await userService.addVoucher(user, setUser, voucherToApply);
      } else {
        addResponse = await userService.AnonymousAddVoucher(userEmail, voucherToApply);
      }

      if (!addResponse.success && !addResponse.message.includes('Voucher already added.')) {
        setVoucherError(addResponse.message);
        return;
      }

      if (user) {
        response = await userService.applyVoucher(user, setUser, voucherToApply);
      } else {
        response = await userService.AnonymousApplyVoucher(userEmail, voucherToApply);
      }

      if (response.success && response.voucher) {
        const totalBeforeDiscount = calculateTotalAmountIncludingAllTax(cartItems, selectedShippingService);
        const discountResult = calculateVoucherDiscount(response.voucher, parseFloat(totalBeforeDiscount));

        if (!discountResult.isApplicable) {
          setVoucherError(discountResult.message ?? t('checkout.voucherErrors.notApplicable'));
          return;
        }

        try {
          if (user) {
            await userService.addVoucher(user, setUser, voucherToApply);
          } else {
            // For anonymous user, we might do something else if needed.
          }
        } catch (error) {
          console.error('Error when trying to add voucher to profile for registered user.');
        }

        setAppliedVoucher(response.voucher);
        setDiscountResult(discountResult);
        setTotalPriceWithTax(discountResult.finalAmount);
        setVoucherError('');
      } else {
        setVoucherError(response.message || t('checkout.voucherErrors.invalidCode'));
      }
    } catch (error) {
      console.error('Error applying voucher:', error);
      setVoucherError(t('checkout.voucherErrors.failedToApply'));
    }
  };

  const extractCountryCode = (input: string): string | null => {
    const regex = /^([A-Z]{2})\s?/;
    const match = input.match(regex);
    return match ? match[1] : null;
  };

  const handleServiceClick = async (service: DeliveryService) => {
    if (service.service.toLowerCase().includes('relay')) {
      try {
        const countryCode = extractCountryCode(selectedAddress.country) ?? '';
        const response = await deliveryApi.fetchRelayPoints(selectedAddress.postalCode, countryCode);
        setRelayPointsData(response);
      } catch (error) {
        console.error('Failed to fetch Relay/Lockers data', error);
      }
    } else {
      setSelectedRelayPoint(null);
    }
    setSelectedShippingService(service);
  };

  const checkForErrors = () => {
    const hasValidationErrors = Object.values(validationErrors).some((error) => error !== '');
    const hasEmailError = emailError !== '';
    const hasPhoneError = phoneError !== '';
    const hasPostalCodeError = postalCodeError !== '';
    return hasValidationErrors || hasEmailError || hasPhoneError || hasPostalCodeError;
  };

  const validateSelectedShippinService = () => {
    return selectedShippingService ? false : true;
  };

  const validateBilling = () => {
    if (!billingAddress) {
      return false;
    }

    const hasErrors = Object.values(billingAddressErrors).some(
      (value) => value !== '' && value !== null && value !== undefined
    );
    let isEmpty =
      billingAddress.first_name === '' ||
      billingAddress.last_name === '' ||
      billingAddress.postalCode === '' ||
      billingAddress.street === '' ||
      billingAddress.city === '' ||
      billingAddress.country === '';

    if (billingAddressCheckbox) {
      isEmpty = isEmpty || billingAddress.phone === '';
    } else {
      isEmpty = isEmpty || billingAddressPhone === '';
    }
    return hasErrors || isEmpty;
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      console.error('User is not logged in');
      return;
    }

    try {
      const accessToken = localStorage.getItem('accessToken');
      const userWithToken = { ...user, token: accessToken };

      if (isEditing) {
        const addressId = newAddress?._id;
        if (!addressId) {
          console.error('No address ID found for editing');
          alert(t('checkout.formErrors.failedEditAddress'));
          return;
        }
        const result = await userService.updateDeliveryAddress(
          userWithToken,
          setUser,
          newAddress,
          addressId,
          navigate
        );
        setIsEditing(false);
        if (result?.success) {
          const userDataResult = await userService.getUserData(userWithToken, setUser);
          if (!userDataResult.success) {
            console.error('Failed to fetch updated user data');
          }
          setIsEditing(false);
        } else {
          throw new Error(result?.message || t('checkout.formErrors.failedUpdateAddress'));
        }
      } else {
        const result = await userService.addDeliveryAddress(userWithToken, setUser, newAddress, navigate);
        if (result.success && result.address) {
          setDeliveryAddress([...deliveryAddress, result.address]);
        } else {
          throw new Error(result.message || t('checkout.formErrors.failedAddAddress'));
        }
      }

      setNewAddress({
        addressName: '',
        _id: '',
        first_name: '',
        last_name: '',
        phone: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
        addressComplement: '',
        asDefault: false,
        type: '',
      });
      setIsAdding(false);
      // Reset visibility
      setAddressSectionVisible({ shipping: true, billing: true });
    } catch (error) {
      console.error('Error saving address:', error);
      alert(t('checkout.formErrors.failedEditAddress'))
    }
  };

  const handleEdit = (index: number, type?: string) => {
    setIsEditing(true);
    setSelectedAddressIndex(index);
    setSelectedBillingAddressIndex(index);

    let addressToEdit;
    if (type === 'shipping') {
      addressToEdit = shipping[index];
    } else if (type === 'billing') {
      addressToEdit = billing[index];
    }

    setNewAddress({
      addressName: '',
      _id: addressToEdit?._id || '',
      first_name: addressToEdit?.first_name || '',
      last_name: addressToEdit?.last_name || '',
      phone: addressToEdit?.phone || '',
      street: addressToEdit?.street || '',
      postalCode: addressToEdit?.postalCode || '',
      city: addressToEdit?.city || '',
      country: addressToEdit?.country || '',
      addressComplement: addressToEdit?.addressComplement || '',
      type: type || '',
    });
    setPhone(addressToEdit?.phone || '');
    if (type === 'shipping') {
      setAddressSectionVisible({ shipping: true, billing: false });
    } else if (type === 'billing') {
      setAddressSectionVisible({ shipping: false, billing: true });
    }
  };

  // Clear fields for adding a new address
  const handleAddNewAddress = (type?: string) => {
    setNewAddress({
      addressName: '',
      first_name: '',
      last_name: '',
      phone: '',
      street: '',
      postalCode: '',
      city: '',
      country: '',
      addressComplement: '',
      asDefault: false,
      type: type || '',
    });
    setIsAdding(true);
    setIsEditing(false);

    // Hide the opposite section based on the type
    if (type === 'shipping') {
      setAddressSectionVisible({ shipping: true, billing: false });
    } else if (type === 'billing') {
      setAddressSectionVisible({ shipping: false, billing: true });
    }
  };

  // Selection from existing address cards
  const handleSelectAddress = (index: number) => {
    setSelectedAddressIndex(index);
    setSelectedCountry(deliveryAddress[index].country);
    setSelectedShippingService(null);
  };

  const handleSelectBillingAddress = (index: number) => {
    setSelectedBillingAddressIndex(index);
    setSelectedCountry(billing[index].country);
  };

  useEffect(() => {
    if (user) {
      const userCarts = user.cart;
      const userProducts = userCarts.map((item) => item.product);
      if (user.addresses.length > 0) {
        const formattedAddresses = user.addresses.map((address) => ({
          _id: address._id,
          addressName: '',
          first_name: address.firstName || '',
          last_name: address.lastName || '',
          phone: address.phoneNumber?.number || '',
          street: address.street || '',
          postalCode: address.postalCode || '',
          city: address.city || '',
          country: address.country || '',
          addressComplement: address.addressComplement || '',
          type: address.type || '',
        }));
        setDeliveryAddress(formattedAddresses);
        setUserEmail(user?.email ?? '');
        setSelectedCountry(formattedAddresses[0].country);
      }
      setProducts(userProducts);
      setCartItems(userCarts);
    } else {
      const localCart = loadLocalCart();
      const userProducts = localCart.map((item) => item.product);
      setProducts(userProducts);
      setCartItems(localCart);
    }
  }, [user]);

  useEffect(() => {
    if (user?.addresses.length === 0 && !isAdding) {
      setIsAdding(true);
    }
  }, [user]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const { uniqueCountries, allServices } = await deliveryApi.fetchDeliveryServices();
      setDeliveryCountries(uniqueCountries);
      setAllShippingDeliveryServices(allServices);
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const filteredServices = allShippingDeliveryServices
        .filter((service) => service.country === selectedCountry)
        .sort((a, b) => a.price - b.price);

      setFilteredShippingServices(filteredServices);

    } else {
      setFilteredShippingServices([]);
    }
  }, [selectedCountry, allShippingDeliveryServices]);

  // Update country
  const handleCountryChangeForNewAddress = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const country = event.target.value;
    setNewAddress({ ...newAddress, country });
  };

  const handleCountryChangeForBillingAddress = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const country = event.target.value;
    setBillingAddress({ ...billingAddress, country });
  };

  // --------------------- CALCULATIONS OF PRICES -------------------------

  // All calculation start
  const getTotalItems = () => cartItems.reduce((total, item) => total + item.quantity, 0);

  // const selectedShipmentWithoutTax = (selectedShippingService: DeliveryService | null): number => {
  //   const taxRate = selectedShippingService?.tax ?? 0;
  //   const shippingPrice = selectedShippingService?.price ?? 0;
  //   const taxPrice = shippingPrice / (1 + taxRate / 100);
  //   return taxPrice;
  // };

  const calculateTotalAmountIncludingAllTax = useCallback(
    (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
      const { subtotal, productTax } = calculateCartSubtotal(cartItems, selectedShippingService);
      const shippingPrice = selectedShippingService?.price ?? 0;
      let totalAmount = subtotal + productTax + shippingPrice;

      if (appliedVoucher && discountResult.isApplicable) {
        const newDiscountResult = calculateVoucherDiscount(appliedVoucher, subtotal + productTax);
        totalAmount = newDiscountResult.finalAmount + shippingPrice;
      }

      return totalAmount.toFixed(2);
    },
    [appliedVoucher, discountResult]
  );

  useEffect(() => {
    setTotalPriceWithTax(
      parseFloat(calculateTotalAmountIncludingAllTax(cartItems, selectedShippingService))
    );
  }, [cartItems, selectedShippingService, calculateTotalAmountIncludingAllTax]);

  const calculateTotalAmountIncludingTax = (
    cartItems: IProductInCart[],
    selectedShippingService: DeliveryService | null
  ): string => {
    const { subtotal, productTax } = calculateCartSubtotal(cartItems, selectedShippingService);
    let totalAmount = subtotal + productTax;
    // if (appliedVoucher && discountResult.isApplicable) {
    //   const newDiscountResult = calculateVoucherDiscount(appliedVoucher, totalAmount);
    //   totalAmount = newDiscountResult.finalAmount;
    // }
    return totalAmount.toFixed(2);
  };

  const calculateCartSubtotal = (
    cartItems: IProductInCart[],
    selectedShippingService: DeliveryService | null
  ): { subtotal: number; productTax: number } => {
    const taxRate = selectedShippingService?.tax ?? 0;
    let subtotal = 0;
    let productTax = 0;

    cartItems.forEach((item: IProductInCart) => {
      if (item.product.price !== undefined) {
        const priceBeforeVAT = item.product.price / (1 + taxRate / 100);
        const tax = item.product.price - priceBeforeVAT;
        subtotal += priceBeforeVAT * item.quantity;
        productTax += tax * item.quantity;
      }
    });

    subtotal = Math.round(subtotal * 100) / 100;
    productTax = Math.round(productTax * 100) / 100;
    subtotal = subtotal > 0.0 ? parseFloat(subtotal.toFixed(2)) : 0.0;
    productTax = productTax > 0.0 ? parseFloat(productTax.toFixed(2)) : 0.0;

    return { subtotal, productTax };
  };

  const calculateShippingCost = (
    selectedShippingService: DeliveryService | null
  ): { shippingPrice: string; shippingTax: string; totalShippingCost: string } => {
    if (!selectedShippingService || !selectedShippingService.price || !selectedShippingService.tax) {
      return { shippingPrice: '0.00', shippingTax: '0.00', totalShippingCost: '0.00' };
    }

    const taxRate = selectedShippingService.tax / 100;
    const totalShippingCost = selectedShippingService.price;
    const shippingPrice = totalShippingCost / (1 + taxRate);
    const shippingTax = totalShippingCost - shippingPrice;

    return {
      shippingPrice: shippingPrice.toFixed(2),
      shippingTax: shippingTax.toFixed(2),
      totalShippingCost: totalShippingCost.toFixed(2),
    };
  };

  // const calculatePrice = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
  //   const { subtotal } = calculateCartSubtotal(cartItems, selectedShippingService);
  //   return subtotal.toFixed(2);
  // };

  // const calculateTaxes = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
  //   let shippingTax = 0;
  //   const { productTax } = calculateCartSubtotal(cartItems, selectedShippingService);
  //   if (selectedShippingService?.tax) {
  //     const taxRate = selectedShippingService?.tax / 100;
  //     const totalShippingCost = selectedShippingService?.price;
  //     const shippingPrice = totalShippingCost / (1 + taxRate);
  //     // free shipping
  //     if (parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService)) >= selectedShippingService.freeshipping) {
  //       shippingTax = 0;
  //     } else {
  //       shippingTax = Number((totalShippingCost - shippingPrice).toFixed(2));
  //     }
  //     return (productTax + shippingTax).toFixed(2);
  //   }
  //   return productTax.toFixed(2);
  // };

  // ----------------------------------------------

  const isLoggedUserFormValid = () => {
    const requiredFields = [
      'first_name',
      'last_name',
      'phone',
      'street',
      'postalCode',
      'city',
      'country',
    ];

    const areFieldsFilled = requiredFields.every(
      (field) => newAddress[field as keyof typeof newAddress]
    );

    const isPhoneValid = validatePhone(newAddress.phone || '');
    const isPostalCodeValid =
      validatePostalCode(newAddress.postalCode || '') && newAddress.postalCode.length <= 12;
    const areLengthsValid =
      (newAddress.first_name || '').length <= 38 &&
      (newAddress.last_name || '').length <= 38 &&
      (newAddress.street || '').length <= 38 &&
      (newAddress.city || '').length <= 38;

    return areFieldsFilled && isPhoneValid && isPostalCodeValid && areLengthsValid;
  };

  const handleCheckout = async () => {
    const countryCode = selectedAddress.country.split(' ')[0];
    let selectedUser: IUser | IAnonymousUser;
    const isFreeShipping =
      selectedShippingService && totalPriceWithTax >= selectedShippingService.freeshipping;
    const shippingCost =
      selectedShippingService && !isFreeShipping
        ? calculateShippingCost(selectedShippingService)
        : { shippingPrice: '0.00', shippingTax: '0.00', totalShippingCost: '0.00' };

    // remove shipping service fee if isFreeShipping
    const adjustedTotalPrice =
      isFreeShipping && selectedShippingService
        ? parseFloat((totalPriceWithTax - selectedShippingService.price).toFixed(2))
        : parseFloat(totalPriceWithTax.toFixed(2));

    let checkoutData = {
      products: cartItems.map((item) => {
        const taxRate = selectedShippingService?.tax ?? 0;
        let unitPrice = item.product.price ? item.product.price / (1 + taxRate / 100) : 0;
        let unitTax = unitPrice * (taxRate / 100);

        if (appliedVoucher) {
          if (appliedVoucher.voucherID.discountType === "percentage") {
            unitPrice = unitPrice * (1 - (appliedVoucher.voucherID.discountValue / 100))
            unitTax = unitTax * (1 - (appliedVoucher.voucherID.discountValue / 100))
          }
        }

        return {
          sku: item.product.sku,
          quantity: item.quantity,
          unitPrice: unitPrice.toFixed(2),
          unitTax: unitTax.toFixed(2),
        };
      }),
      amount: adjustedTotalPrice,
      additional_discount:
        appliedVoucher && discountResult.isApplicable
          ? discountResult.discountAmount.toFixed(2)
          : '0.00',
      voucher: appliedVoucher,
      shipping: selectedShippingService
        ? {
          shippingPrice: shippingCost.shippingPrice,
          shippingTax: shippingCost.shippingTax,
          totalShippingCost: shippingCost.totalShippingCost,
          shippingMethod: selectedShippingService.service,
        }
        : null,
      delivery: {
        firstName: selectedAddress.first_name,
        lastName: selectedAddress.last_name,
        phoneNumber: selectedAddress.phone,
        street: selectedAddress.street,
        city: selectedAddress.city,
        postalCode: selectedAddress.postalCode,
        country: countryCode,
        addressComplement: selectedAddress.addressComplement,
      },
      billing: {
        firstName: billingAddress.first_name,
        lastName: billingAddress.last_name,
        phoneNumber: billingAddress.phone,
        street: billingAddress.street,
        city: billingAddress.city,
        postalCode: billingAddress.postalCode,
        country: extractCountryCode(billingAddress.country),
        addressComplement: billingAddress.addressComplement,
      },
      userId: user ? user._id : '',
      userEmail: userEmail,
    };

    if (user && !isAnonymousUser) {
      checkoutData.userId = user._id ?? '';
      selectedUser = user;
    } else {
      const anonymousUser: IAnonymousUser = {
        email: userEmail,
        created_at: new Date(),
        cart: cartItems,
        addresses: {
          firstName: selectedAddress.first_name,
          lastName: selectedAddress.last_name,
          phoneNumber: selectedAddress.phone,
          street: selectedAddress.street,
          city: selectedAddress.city,
          postalCode: selectedAddress.postalCode,
          country: selectedAddress.country,
          addressComplement: selectedAddress.addressComplement,
        },
        anonymous: true,
      };
      checkoutData.userEmail = anonymousUser.email;
      selectedUser = anonymousUser;
    }

    const stateToSave = {
      selectedShippingService,
      selectedAddress,
      billingAddress,
      selectedAddressIndex,
      selectedBillingAddressIndex,
      billingAddressCheckbox,
      relayPointsData,
      selectedRelayPoint,
      ...(userEmail && { userEmail }),
    };

    sessionStorage.setItem('checkoutState', JSON.stringify(stateToSave));

    const result = await checkoutApi.handlePayment(
      adjustedTotalPrice,
      selectedUser,
      checkoutData,
      selectedRelayPoint,
      userEmail,
      currentLanguage
    );

    if (!result?.success) {
      setErrorMessage(
        t('checkout.formErrors.struggleToProcessRequest')
      );
      navigate('/checkout');
    } else {
      setErrorMessage('');
    }
  };

  const removeItemFromCart = async (id: string) => {
    if (user) {
      const product = cartItems.find((item) => item.product._id === id);
      if (!product) {
        console.error('Product not found in cart.');
        return;
      }
      const result = await userService.removeFromCart(user, setUser, product, navigate);
      if (result.success) {
        const updatedCartItems = cartItems.filter((item) => item.product._id !== id);
        updateCart(updatedCartItems);
      } else {
        console.error('Failed to remove product from cart:', result.message);
      }
    } else {
      const updatedCart = removeFromLocalCart(id);
      updateCart(updatedCart);
      const updatedCartProducts = updatedCart.map((item) => item.product);
      setProducts(updatedCartProducts);
    }
  };

  const updateCart = (updatedCartItems: typeof cartItems) => {
    setCartItems(updatedCartItems);
  };

  const handleEditQuantity = (id: string) => {
    setEditingItemId(id);
    const item = cartItems.find((item) => item.product._id === id);
    if (item) {
      setTempQuantity(item.quantity);
    }
  };

  const changeItemQuantity = (id: string, newQuantity: number) => {
    setTempQuantity(Math.max(1, newQuantity));
  };

  const handleApproveChange = async (id: string) => {
    const product = cartItems.find((item) => item.product._id === id);
    if (!product) return;

    if (user) {
      const updatedCartItems = cartItems.map((item) =>
        item.product._id === id ? { ...item, quantity: tempQuantity! } : item
      );
      updateCart(updatedCartItems);
      setEditingItemId(null);

      const result = await userService.updateCart(user, setUser, product.product, tempQuantity!, navigate);
      if (!result.success) {
        console.error('Failed to update cart on the server:', result.message);
      }
    } else {
      const updatedCart = updateLocalCartItemQuantity(id, tempQuantity!);
      updateCart(updatedCart);
      setEditingItemId(null);
    }
    checkVoucherApplicability();
  };

  const renderItemActions = (item: IProductInCart) => {
    if (!item?.product) {
      return null;
    }

    return editingItemId === item.product._id ? (
      <div className={styles.quantityControlsContainer}>
        <div className={styles.plusMinusButtonsContainer}>
          <button
            onClick={() =>
              changeItemQuantity(item?.product._id, tempQuantity! - 1)
            }
          >
            -
          </button>
          <span>{tempQuantity}</span>
          <button
            onClick={() =>
              changeItemQuantity(item?.product._id, tempQuantity! + 1)
            }
          >
            +
          </button>
        </div>
        <button onClick={() => handleApproveChange(item?.product._id)}>
          {t('common.approve')}
        </button>
      </div>
    ) : (
      <div className={styles.buttonsControlsContainer}>
        <button
          onClick={() => handleEditQuantity(item.product._id)}
          style={{ fontWeight: 700 }}
        >
          {t('common.change')}
        </button>
        <button onClick={() => removeItemFromCart(item.product._id)}>
          {t('common.delete')}
        </button>
      </div>
    );
  };

  const isFormValid = () => {
    if (!selectedAddress) {
      return false;
    }
    const isShippingValid =
      !!(
        userEmail &&
        selectedAddress.first_name &&
        selectedAddress.last_name &&
        selectedAddress.phone &&
        selectedAddress.street &&
        selectedAddress.postalCode &&
        selectedAddress.city &&
        selectedAddress.country
      );
    return isShippingValid;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    return isValidPhoneNumber(phone);
  };

  const validatePostalCode = (postalCode: string) => {
    return /^[0-9]*$/.test(postalCode);
  };

  const validateMaxLength = (value: string, maxLength: number) => {
    return value.length <= maxLength;
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setShowSuggestions(true);
    let updatedAddress = { ...selectedAddress };

    if (name === 'email') {
      setUserEmail(value);
      // Show icons only if user typed something:
      if (value.length > 0) {
        setEmailError(validateEmail(value) ? '' : t('checkout.formErrors.invalidEmail'));
      } else {
        setEmailError('');
      }
    } else if (name === 'postalCode') {
      if (!validateMaxLength(value, 12)) {
        setPostalCodeError(t('checkout.formErrors.invalidPostal'));
      } else {
        updatedAddress = { ...updatedAddress, [name]: value };
        setSelectedAddress(updatedAddress);
        // Show icons only if user typed something:
        if (value.length > 0) {
          setPostalCodeError(
            validatePostalCode(value) ? '' : t('checkout.formErrors.postalOnlyNumbers')
          );
        } else {
          setPostalCodeError('');
        }
      }
    } else if (['first_name', 'last_name', 'street', 'city', 'addressComplement'].includes(name)) {
      if (!validateMaxLength(value, 38)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: t('checkout.formErrors.38CharsOrLess'),
        }));
      } else {
        updatedAddress = { ...updatedAddress, [name]: value };
        setSelectedAddress(updatedAddress);
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));

        // Only fetch suggestions for street if typed at least 4 chars
        if (value.length > 3 && name === 'street') {
          debouncedSuggestAddress(value, true);
        } else {
          setSuggestions([]);
        }
      }
    } else {
      updatedAddress = { ...updatedAddress, [name]: value };
      setSelectedAddress(updatedAddress);
      if (name === 'country') {
        setSelectedCountry(value);
      }
    }
  };

  const debouncedSuggestAddress = useCallback(
    debounce(async (value: string, formFlag?: boolean) => {
      if (value) {
        try {
          const results = await MapboxApi.getAddressSuggestions(value);
          if (results) {
            if (isAnonymousUser) {
              formFlag ? setSuggestions(results) : setBillingSuggestions(results);
            } else {
              isAdding || isEditing ? setSuggestions(results) : setBillingSuggestions(results);
            }
          } else {
            if (isAnonymousUser) {
              formFlag ? setSuggestions([]) : setBillingSuggestions([]);
            } else {
              isAdding || isEditing ? setSuggestions([]) : setBillingSuggestions([]);
            }
          }
        } catch (error) {
          console.error('Error fetching address from the provided details:', error);
        }
      }
    }, 1000),
    [isAnonymousUser, isAdding, isEditing]
  );

  const handlePhoneChange = (value: string | undefined) => {
    const phoneValue = value || '';
    setPhone(phoneValue);

    if (isAnonymousUser) {
      setSelectedAddress((prevAddress) => ({
        ...prevAddress,
        phone: phoneValue,
      }));
    } else {
      setNewAddress((prevAddress) => ({
        ...prevAddress,
        phone: phoneValue,
      }));
    }

    // Show icons only if user typed something:
    if (phoneValue.length > 0) {
      setPhoneError(validatePhone(phoneValue) ? '' : t('checkout.formErrors.invalidPhone'));
    } else {
      setPhoneError('');
    }
  };

  useEffect(() => {
    const phone = billingAddress?.phone;
    setBillingAddressPhone(phone);
  }, [billingAddress]);

  const handleBillingAddressPhoneChange = (value: string | undefined) => {
    setBillingAddressPhone(value || '');

    // Show icons only if user typed something:
    if ((value || '').length > 0) {
      setBillingAddressPhoneError(
        validatePhone(value || '') ? '' : t('checkout.formErrors.invalidPhone')
      );
    } else {
      setBillingAddressPhoneError('');
    }

    setBillingAddress((prevAddress) => ({
      ...prevAddress,
      phone: value || '',
    }));
  };

  const handleNewAddressChange = (field: string, value: string) => {
    setShowSuggestions(true);
    let error = '';

    if (field === 'phone') {
      // Show icons only if user typed something:
      if (value.length > 0) {
        error = validatePhone(value)
          ? ''
          : t('checkout.formErrors.phoneOnlyNumbers');
      } else {
        error = '';
      }
    } else if (field === 'postalCode') {
      if (!validatePostalCode(value)) {
        error = t('checkout.formErrors.postalOnlyNumbers')
      } else if (!validateMaxLength(value, 12)) {
        error = t('checkout.formErrors.invalidPostal');
      }
    } else if (['first_name', 'last_name', 'street', 'city', 'addressComplement'].includes(field)) {
      if (!validateMaxLength(value, 38)) {
        error = t('checkout.formErrors.field38CharsOrLess', { field: field.replace('_', ' ') });
      }
    }
    
    // For street suggestions if typed at least 4 chars
    if (value.length > 3 && field === 'street') {
      debouncedSuggestAddress(value);
    } else {
      setSuggestions([]);
    }

    if (isAdding || isEditing) {
      setNewAddressErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error,
      }));
      let updatedAddress = { ...newAddress, [field]: value };
      setNewAddress(updatedAddress);
    } else {
      setBillingAddressErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error,
      }));
      let updatedAddress = { ...billingAddress, [field]: value };
      setBillingAddress(updatedAddress);
    }
  };

  const handleBillingAddressSuggestionSelect = (place_name: string, country: string) => {
    const result = splitAddress(place_name);
    const formattedCountry = getCountry(country);
    if (result) {
      const { street, zipCode, city } = result;
      setBillingAddress((prev) => ({
        ...prev,
        street,
        postalCode: zipCode,
        city,
        country: formattedCountry as string
      }));
    }
  };

  const handleAddressSuggestionSelect = (place_name: string, country: string) => {
    const splittedAddress = splitAddress(place_name);
    const formattedCountry = getCountry(country);
    if (splittedAddress) {
      const { street, zipCode, city } = splittedAddress;
      if (isAnonymousUser) {
        setSelectedAddress((prev) => ({
          ...prev,
          street,
          postalCode: zipCode,
          city,
          country: formattedCountry as string
        }));
        setSelectedCountry(formattedCountry as string);
      } else {
        setNewAddress((prev) => ({
          ...prev,
          street,
          postalCode: zipCode,
          city,
          country: formattedCountry as string
        }));
      }
      setShowSuggestions(false);
    }
  };

  // NOTE: not translating this, not sure if text is shown to users
  const splitAddress = (place_name: string) => {
    try {
      if (!place_name || typeof place_name !== 'string') {
        throw new Error('Invalid input: place_name must be a non-empty string');
      }
      const parts = place_name.split(',');
      if (parts.length < 2) {
        throw new Error('Invalid input: address does not have enough parts');
      }

      const street = parts[0].trim();
      const cityAndZipcode = parts[1].trim();
      if (!street) {
        throw new Error('Invalid input: street is empty');
      }

      const zipCodeMatch = cityAndZipcode.match(/^\d+/);
      const zipCode = zipCodeMatch ? zipCodeMatch[0] : '';
      const city = cityAndZipcode.replace(zipCode, '').trim();

      if (!zipCode || !city) {
        throw new Error('Invalid input: zip code or city is missing');
      }

      return {
        street,
        zipCode,
        city,
      };
    } catch (error) {
      console.error('Error splitting address:', error);
      return null;
    }
  };
// NOTE: same
  const getCountry = (country: string) => {
    try {
      if (!country || typeof country !== 'string') {
        throw new Error('Invalid input: countryName must be a non-empty string');
      }

      const match = deliveryCountries.find((entry) =>
        entry.country.includes(`${country}`)
      );

      if (!match) {
        throw new Error(`Country not found for: ${country}`);
      }

      return match.country;
    } catch (error) {
      console.error('Error finding country:', error);
      return null;
    }
  };

  useEffect(() => {
    const savedState = sessionStorage.getItem('checkoutState');

    if (savedState) {
      const parsedState = JSON.parse(savedState);

      setSelectedShippingService(parsedState.selectedShippingService as DeliveryService);
      setSelectedAddress(parsedState.selectedAddress as ShippingAddress);
      setPhone(parsedState.selectedAddress.phone);

      if (!user) {
        setSelectedCountry(parsedState.selectedAddress.country);
      }

      setBillingAddress(parsedState.billingAddress as ShippingAddress);
      setBillingAddressCheckbox(parsedState.billingAddressCheckbox as boolean);

      setSelectedAddressIndex(parsedState.selectedAddressIndex as number);
      setSelectedBillingAddressIndex(parsedState.selectedBillingAddressIndex as number);

      setSelectedRelayPoint(parsedState.selectedRelayPoint);
      setRelayPointsData(parsedState.relayPointsData);

      if (parsedState.userEmail) {
        setUserEmail(parsedState.userEmail);
      }

      sessionStorage.removeItem('checkoutState');
    }
  }, [user]);

  useEffect(() => {
    if (billingAddressCheckbox) {
      if (selectedAddress) {
        setBillingAddress((prevBillingAddress) => ({
          ...prevBillingAddress,
          first_name: selectedAddress.first_name,
          last_name: selectedAddress.last_name,
          phone: selectedAddress.phone,
          street: selectedAddress.street,
          city: selectedAddress.city,
          country: selectedAddress?.country?.split(' ')[0],
          postalCode: selectedAddress.postalCode,
          addressComplement: selectedAddress.addressComplement,
          addressName: selectedAddress.addressName || prevBillingAddress?.addressName || 'Billing Address',
        }));
      }
    } else {
      if (user) {
        setBillingAddress({ ...billingAddress, ...billing[selectedBillingAddressIndex] });
      }
    }
  }, [selectedAddress, billingAddressCheckbox]);

  const anonymousForm = () => {
    return (
      <>
        <form className={styles.addressForm}>
          {/* ---------------- EMAIL ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="email">
              {t('checkout.labels.email')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="email"
                className={`${styles.formInput} ${styles.emailInput}`}
                type="email"
                name="email"
                value={userEmail}
                onChange={handleInputChange}
                required
              />
              {/** Show icon only if user typed something */}
              {userEmail.length > 0 ? (
                emailError ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {emailError && <p className={styles.errorMessage}>{emailError}</p>}
          </div>

          {/* ---------------- FIRST NAME ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="first_name">
            {t('checkout.labels.firstName')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="first_name"
                className={`${styles.formInput} ${styles.firstNameInput}`}
                type="text"
                name="first_name"
                value={selectedAddress?.first_name || ''}
                onChange={handleInputChange}
              />
              {/** Show icon only if user typed something */}
              {selectedAddress?.first_name?.length > 0 ? (
                validationErrors.first_name ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {validationErrors.first_name && (
              <p className={styles.errorMessage}>{validationErrors.first_name}</p>
            )}
          </div>

          {/* ---------------- LAST NAME ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="last_name">
            {t('checkout.labels.lastName')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="last_name"
                className={`${styles.formInput} ${styles.lastNameInput}`}
                type="text"
                name="last_name"
                value={selectedAddress?.last_name || ''}
                onChange={handleInputChange}
              />
              {/** Show icon only if user typed something */}
              {selectedAddress?.last_name?.length > 0 ? (
                validationErrors.last_name ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {validationErrors.last_name && (
              <p className={styles.errorMessage}>{validationErrors.last_name}</p>
            )}
          </div>

          {/* ---------------- PHONE NUMBER ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="phone">
            {t('checkout.labels.phoneNumber')}
            </label>
            <div className={styles.inputContainer}>
              <PhoneInput
                id="phone"
                className={`${styles.phoneInput}`}
                defaultCountry={countryForPhoneInput as CountryCode}
                international
                value={phone}
                onChange={(val) => {
                  setPhone(val || '');
                  setSelectedAddress((prev) => ({
                    ...prev,
                    phone: val || ''
                  }));
                  if ((val || '').length > 0) {
                    setPhoneError(
                      validatePhone(val || '') ? '' : t('checkout.formErrors.invalidPhone')
                    );
                  } else {
                    setPhoneError('');
                  }
                }}
              />
              {/** Show icon only if user typed something */}
              {selectedAddress?.phone?.length > 0 ? (
                phoneError ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {phoneError && <p className={styles.errorMessage}>{phoneError}</p>}
          </div>

          {/* ---------------- STREET / ADDRESS ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="street">
            {t('checkout.labels.address')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="street"
                className={`${styles.formInput} ${styles.streetInput}`}
                type="text"
                name="street"
                value={selectedAddress?.street || ''}
                onChange={handleInputChange}
              />
              {/** Show icon only if user typed something */}
              {selectedAddress?.street?.length > 0 ? (
                validationErrors.street ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {validationErrors.street && (
              <p className={styles.errorMessage}>{validationErrors.street}</p>
            )}
          </div>

          {showSuggestions && suggestions.length > 0 && selectedAddress?.street?.length > 3 && (
            <div className={styles.suggestionsContainer}>
              {suggestions.map((addressObj, index) => (
                <p
                  key={index}
                  className={styles.suggestionItem}
                  onClick={() => handleAddressSuggestionSelect(addressObj.place_name, addressObj.country)}
                >
                  {`${addressObj.place_name}, ${addressObj.country}`}
                </p>
              ))}
            </div>
          )}

          {/* ---------------- CITY / STATE ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="city">
              {t('checkout.labels.state')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="city"
                className={`${styles.formInput} ${styles.cityInput}`}
                type="text"
                name="city"
                value={selectedAddress?.city || ''}
                onChange={handleInputChange}
              />
              {/** Show icon only if user typed something */}
              {selectedAddress?.city?.length > 0 ? (
                validationErrors.city ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {validationErrors.city && <p className={styles.errorMessage}>{validationErrors.city}</p>}
          </div>

          {/* ---------------- COUNTRY ---------------- */}
          <div className={styles.formInputBox}>{renderCountrySelect(true)}</div>

          {/* ---------------- ZIP CODE ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="postalCode">
            {t('checkout.labels.zipCode')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="postalCode"
                className={`${styles.formInput} ${styles.postalCodeInput}`}
                type="text"
                name="postalCode"
                value={selectedAddress?.postalCode || ''}
                onChange={handleInputChange}
              />
              {/** Show icon only if user typed something */}
              {selectedAddress?.postalCode?.length > 0 ? (
                postalCodeError ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {postalCodeError && <p className={styles.errorMessage}>{postalCodeError}</p>}
          </div>

          {/* ---------------- ADDRESS DETAILS ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="addressComplement">
              {t('checkout.labels.addressDetails')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="addressComplement"
                className={`${styles.formInput} ${styles.addressComplementInput}`}
                type="text"
                name="addressComplement"
                value={selectedAddress?.addressComplement || ''}
                onChange={handleInputChange}
              />
              {/** Show icon only if user typed something */}
              {selectedAddress?.addressComplement?.length > 0 ? (
                validationErrors.addressComplement ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {validationErrors.addressComplement && (
              <p className={styles.errorMessage}>{validationErrors.addressComplement}</p>
            )}
          </div>
        </form>
        <div className={styles.billingAddressCheckboxContainer}>
          <input
            type="checkbox"
            id="shippingCheckbox"
            name="shippingCheckbox"
            checked={billingAddressCheckbox}
            onChange={handlebillingAddressCheckboxChange}
            style={{ display: 'none' }}
          />
          <label htmlFor="shippingCheckbox" className={styles.billingAddressCheckboxText}>
            <img
              src={billingAddressCheckbox ? selectedIcon : unselectedCheckbox}
              alt="checkbox"
              style={{ cursor: 'pointer' }}
            />
            {t('checkout.labels.useForBilling')}
          </label>
        </div>
      </>
    );
  };

  const billingAddressForm = () => {
    return (
      <>
        <div className={styles.sectionBillingShippingBox}>
          <h3 className={styles.sectionBillingTitle}>{t('checkout.labels.billingAddress')}</h3>
        </div>
        <form className={styles.addressForm}>
          {/* ---------------- FIRST NAME ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="firstName">
            {t('checkout.labels.firstName')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="firstName"
                className={styles.formInput}
                type="text"
                value={billingAddress.first_name || ''}
                onChange={(e) => handleNewAddressChange('first_name', e.target.value)}
              />
              {billingAddress.first_name?.length > 0 ? (
                billingAddressErrors.first_name ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {billingAddressErrors.first_name && (
              <p className={styles.errorMessage}>{billingAddressErrors.first_name}</p>
            )}
          </div>

          {/* ---------------- LAST NAME ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="lastName">
            {t('checkout.labels.lastName')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="lastName"
                className={styles.formInput}
                type="text"
                value={billingAddress.last_name || ''}
                onChange={(e) => handleNewAddressChange('last_name', e.target.value)}
              />
              {billingAddress.last_name?.length > 0 ? (
                billingAddressErrors.last_name ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {billingAddressErrors.last_name && (
              <p className={styles.errorMessage}>{billingAddressErrors.last_name}</p>
            )}
          </div>

          {/* ---------------- PHONE NUMBER ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="phone">
            {t('checkout.labels.phoneNumber')}
            </label>
            <div className={styles.inputContainer}>
              <PhoneInput
                id="phone"
                className={`${styles.phoneInput}`}
                defaultCountry={countryForPhoneInput as CountryCode}
                international
                value={billingAddressPhone || ''}
                onChange={handleBillingAddressPhoneChange}
              />
              {billingAddress.phone?.length > 0 ? (
                billingAddressPhoneError ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {billingAddressPhoneError && (
              <p className={styles.errorMessage}>{billingAddressPhoneError}</p>
            )}
          </div>

          {/* ---------------- STREET ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="street">
            {t('checkout.labels.address')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="street"
                className={styles.formInput}
                type="text"
                value={billingAddress.street || ''}
                onChange={(e) => handleNewAddressChange('street', e.target.value)}
              />
              {billingAddress.street?.length > 0 ? (
                billingAddressErrors.street ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {billingAddressErrors.street && (
              <p className={styles.errorMessage}>{billingAddressErrors.street}</p>
            )}
          </div>

          {billingSuggestions?.length > 0 && billingAddress.street?.length > 3 && (
            <div className={styles.suggestionsContainer}>
              {billingSuggestions.map((addressObj, index) => (
                <p
                  key={index}
                  className={styles.suggestionItem}
                  onClick={() => handleBillingAddressSuggestionSelect(addressObj.place_name, addressObj.country)}
                >
                  {`${addressObj.place_name}, ${addressObj.country}`}
                </p>
              ))}
            </div>
          )}

          {/* ---------------- CITY / STATE ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="city">
            {t('checkout.labels.state')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="city"
                className={styles.formInput}
                type="text"
                value={billingAddress.city || ''}
                onChange={(e) => handleNewAddressChange('city', e.target.value)}
              />
              {billingAddress.city?.length > 0 ? (
                billingAddressErrors.city ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {billingAddressErrors.city && (
              <p className={styles.errorMessage}>{billingAddressErrors.city}</p>
            )}
          </div>

          {/* ---------------- COUNTRY ---------------- */}
          {renderCountrySelect()}

          {/* ---------------- ZIP CODE ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="postalCode">
            {t('checkout.labels.zipCode')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="postalCode"
                className={styles.formInput}
                type="text"
                value={billingAddress.postalCode || ''}
                onChange={(e) => handleNewAddressChange('postalCode', e.target.value)}
              />
              {billingAddress.postalCode?.length > 0 ? (
                billingAddressErrors.postalCode ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {billingAddressErrors.postalCode && (
              <p className={styles.errorMessage}>{billingAddressErrors.postalCode}</p>
            )}
          </div>

          {/* ---------------- ADDRESS COMPLEMENT ---------------- */}
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="addressComplement">
            {t('checkout.labels.addressDetails')}
            </label>
            <div className={styles.inputContainer}>
              <input
                id="addressComplement"
                className={styles.formInput}
                type="text"
                value={billingAddress.addressComplement || ''}
                onChange={(e) => handleNewAddressChange('addressComplement', e.target.value)}
              />
              {billingAddress.addressComplement?.length > 0 ? (
                billingAddressErrors.addressComplement ? (
                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                ) : (
                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                )
              ) : null}
            </div>
            {billingAddressErrors.addressComplement && (
              <p className={styles.errorMessage}>{billingAddressErrors.addressComplement}</p>
            )}
          </div>
        </form>
      </>
    );
  };

  const renderCountrySelect = (formFlag?: boolean) => {
    let handleChange;
    let address;

    if (isAnonymousUser) {
      handleChange = formFlag
        ? (e: React.ChangeEvent<HTMLSelectElement>) => handleInputChange(e)
        : handleCountryChangeForBillingAddress;
      address = formFlag ? selectedAddress : billingAddress;
    } else {
      handleChange = isAdding || isEditing ? handleCountryChangeForNewAddress : handleCountryChangeForBillingAddress;
      address = isAdding || isEditing ? newAddress : billingAddress;
    }

    return (
      <div className={styles.formInputBox}>
        <label className={styles.formInputLabel} htmlFor="country">
          {t('checkout.labels.country')}
        </label>
        <select
          id="country"
          name="country"
          value={address?.country || ''}
          onChange={handleChange}
          className={`${styles.formInput} ${styles.customSelect}`}
        >
          <option value=""></option>
          {deliveryCountries.map((countryObj, index) => (
            <option key={index} value={countryObj.country}>
              {countryObj.country}
            </option>
          ))}
        </select>
      </div>
    );
  };

  useEffect(() => {
    if (!stripe || !elements) {
      setIsCheckoutDisabled(true);
      return;
    }
    setIsCheckoutDisabled(false);
  }, [stripe, elements]);

  const handlebillingAddressCheckboxChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setBillingAddressCheckbox(event.target.checked);
    }
  };

  // Example of resetting on back button
  const handleBack = () => {
    setIsAdding(false);
    setIsEditing(false);
    setAddressSectionVisible({ shipping: true, billing: true });
  };

  return (
    <div className={styles.checkoutWithHeaderContainer}>
      <HomepageNavbar />
      <>
        <div className={styles.checkoutContainer}>
          <div className={styles.checkoutForm}>
            <div className={styles.checkoutPaymentContainer}>
              <h2 className={styles.title}>{t('checkout.checkoutAndPayment')}</h2>

              {/* --------- ANONYMOUS OR LOGGED-IN SHIPPING ADDRESS SECTION --------- */}
              {isAnonymousUser ? (
                <div className={styles.shippingSection}>{anonymousForm()}</div>
              ) : (
                <div className="addressbook_container">
                  <div className="addressHeader">
                    {addressSectionVisible.shipping && (
                      <div className={styles.sectionShippingBox}>
                        <h3 className={styles.sectionTitle}>{t('checkout.shippingTo')}</h3>
                        {!isAdding && !isEditing && (
                          <button
                            className={styles.addAddressButton}
                            onClick={() => handleAddNewAddress('shipping')}
                          >
                            + {t('checkout.address')}
                          </button>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="containerForm">
                    {!isEditing && !isAdding && (
                      <div className={styles.addressCards}>
                        {shipping.length === 0 ? (
                          <p className={styles.noItemsTitle}>{t('checkout.noAddress')}</p>
                        ) : (
                          shipping.map((address: ShippingAddress, index: number) => (
                            <div
                              key={index}
                              className={styles.addressCardWrapper}
                              onClick={() => handleSelectAddress(index)}
                              style={{ cursor: 'pointer' }}
                            >
                              <AddressCard
                                shippingAddress={address}
                                isSelected={selectedAddressIndex === index}
                                onEdit={() => handleEdit(index, 'shipping')}
                              />
                            </div>
                          ))
                        )}
                      </div>
                    )}

                    {deliveryAddress.length > 0 && !isAdding && !isEditing && (
                      <div className={styles.billingAddressCheckboxContainer}>
                        <input
                          type="checkbox"
                          id="shippingCheckbox"
                          name="shippingCheckbox"
                          checked={billingAddressCheckbox}
                          onChange={handlebillingAddressCheckboxChange}
                          style={{ display: 'none' }}
                        />
                        <label
                          htmlFor="shippingCheckbox"
                          className={styles.billingAddressCheckboxText}
                        >
                          <img
                            src={billingAddressCheckbox ? selectedIcon : unselectedCheckbox}
                            alt="checkbox"
                            style={{ cursor: 'pointer' }}
                          />
                          {t('checkout.labels.useForBilling')}
                        </label>
                      </div>
                    )}

                    {!billingAddressCheckbox && addressSectionVisible.billing && (
                      <>
                        <div className={styles.sectionShippingBox}>
                          <h3 className={styles.sectionTitle}>BILLING TO</h3>
                          {!isAdding && !isEditing && (
                            <button
                              className={styles.addAddressButton}
                              onClick={() => handleAddNewAddress('billing')}
                            >
                              + {t('checkout.bilingAddress')}
                            </button>
                          )}
                        </div>
                        <div className="containerForm">
                          {!isEditing && !isAdding && (
                            <div className={styles.addressCards}>
                              {billing.map((address: ShippingAddress, index: number) => (
                                <div
                                  key={index}
                                  className={styles.addressCardWrapper}
                                  onClick={() => handleSelectBillingAddress(index)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <AddressCard
                                    shippingAddress={address}
                                    isSelected={selectedBillingAddressIndex === index}
                                    onEdit={() => handleEdit(index, 'billing')}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {isAdding && (
                      <>
                        <div className={styles.formHeader}>
                          <h3 className={styles.formTitle}>{t('checkout.addAddress')}</h3>
                          <span
                            className={styles.backText}
                            onClick={handleBack}
                            style={{ cursor: 'pointer' }}
                          >
                            &lt; {t('common.back')}
                          </span>
                        </div>
                        <form className={styles.addressForm} onSubmit={handleFormSubmit}>
                          {/* FIRST NAME */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="firstName">
                            {t('checkout.labels.firstName')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="firstName"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.first_name}
                                onChange={(e) => handleNewAddressChange('first_name', e.target.value)}
                              />
                              {newAddress.first_name.length > 0 ? (
                                newAddressErrors.first_name ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.first_name && (
                              <p className={styles.errorMessage}>{newAddressErrors.first_name}</p>
                            )}
                          </div>

                          {/* LAST NAME */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="lastName">
                            {t('checkout.labels.lastName')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="lastName"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.last_name}
                                onChange={(e) => handleNewAddressChange('last_name', e.target.value)}
                              />
                              {newAddress.last_name.length > 0 ? (
                                newAddressErrors.last_name ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.last_name && (
                              <p className={styles.errorMessage}>{newAddressErrors.last_name}</p>
                            )}
                          </div>

                          {/* PHONE NUMBER */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="phone">
                            {t('checkout.labels.phoneNumber')}
                            </label>
                            <div className={styles.inputContainer}>
                              <PhoneInput
                                id="phone"
                                className={styles.phoneInput}
                                defaultCountry={countryForPhoneInput as CountryCode}
                                international
                                value={newAddress.phone}
                                onChange={(val) => {
                                  setNewAddress((prev) => ({ ...prev, phone: val || '' }));
                                  if ((val || '').length > 0) {
                                    setPhoneError(
                                      validatePhone(val || '')
                                        ? ''
                                        :  t('checkout.formErrors.invalidPhone')
                                    );
                                  } else {
                                    setPhoneError('');
                                  }
                                }}
                              />
                              {newAddress.phone.length > 0 ? (
                                phoneError ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {phoneError && <p className={styles.errorMessage}>{phoneError}</p>}
                          </div>

                          {/* STREET */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="street">
                            {t('checkout.labels.address')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="street"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.street}
                                onChange={(e) => handleNewAddressChange('street', e.target.value)}
                              />
                              {newAddress.street.length > 0 ? (
                                newAddressErrors.street ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.street && (
                              <p className={styles.errorMessage}>{newAddressErrors.street}</p>
                            )}
                          </div>

                          {showSuggestions &&
                            suggestions.length > 0 &&
                            newAddress.street.length > 3 && (
                              <div className={styles.suggestionsContainer}>
                                {suggestions.map((addressObj, index) => (
                                  <p
                                    key={index}
                                    className={styles.suggestionItem}
                                    onClick={() =>
                                      handleAddressSuggestionSelect(addressObj.place_name, addressObj.country)
                                    }
                                  >
                                    {`${addressObj.place_name}, ${addressObj.country}`}
                                  </p>
                                ))}
                              </div>
                            )}

                          {/* CITY */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="city">
                            {t('checkout.labels.state')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="city"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.city}
                                onChange={(e) => handleNewAddressChange('city', e.target.value)}
                              />
                              {newAddress.city.length > 0 ? (
                                newAddressErrors.city ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.city && (
                              <p className={styles.errorMessage}>{newAddressErrors.city}</p>
                            )}
                          </div>

                          {renderCountrySelect()}

                          {/* POSTAL CODE */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="postalCode">
                            {t('checkout.labels.zipCode')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="postalCode"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.postalCode}
                                onChange={(e) =>
                                  handleNewAddressChange('postalCode', e.target.value)
                                }
                              />
                              {newAddress.postalCode.length > 0 ? (
                                newAddressErrors.postalCode ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.postalCode && (
                              <p className={styles.errorMessage}>{newAddressErrors.postalCode}</p>
                            )}
                          </div>

                          {/* ADDRESS COMPLEMENT */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="addressComplement">
                            {t('checkout.labels.addressDetails')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="addressComplement"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.addressComplement}
                                onChange={(e) =>
                                  handleNewAddressChange('addressComplement', e.target.value)
                                }
                              />
                              {newAddress.addressComplement.length > 0 ? (
                                newAddressErrors.addressComplement ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.addressComplement && (
                              <p className={styles.errorMessage}>
                                {newAddressErrors.addressComplement}
                              </p>
                            )}
                          </div>

                          <div className="checkbox-wrapper-48">
                            <label
                              className={`${styles.formInputLabel} ${styles.checkboxLabel}`}
                            >
                              <input
                                type="checkbox"
                                name="asDefault"
                                checked={newAddress.asDefault}
                                onChange={(e) =>
                                  setNewAddress({ ...newAddress, asDefault: e.target.checked })
                                }
                              />
                              <span style={{ marginLeft: '8px' }}>
                                {t('checkout.saveAsDefault')}
                              </span>
                            </label>
                          </div>

                          <div className={styles.formButtonContainer}>
                            <button
                              className={styles.formButton}
                              type="submit"
                              disabled={!isLoggedUserFormValid()}
                            >
                              {t('checkout.saveChanges')}
                            </button>
                          </div>
                        </form>
                      </>
                    )}

                    {isEditing && (
                      <>
                        <div className={styles.formHeader}>
                          <h3 className={styles.formTitle}>{t('checkout.editAddress')}</h3>
                          <span
                            className={styles.backText}
                            onClick={handleBack}
                            style={{ cursor: 'pointer' }}
                          >
                            &lt; {t('common.back')}
                          </span>
                        </div>
                        <form className={styles.addressForm} onSubmit={handleFormSubmit}>
                          {/* FIRST NAME */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="firstName">
                            {t('checkout.labels.firstName')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="firstName"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.first_name}
                                onChange={(e) =>
                                  handleNewAddressChange('first_name', e.target.value)
                                }
                              />
                              {newAddress.first_name.length > 0 ? (
                                newAddressErrors.first_name ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.first_name && (
                              <p className={styles.errorMessage}>{newAddressErrors.first_name}</p>
                            )}
                          </div>

                          {/* LAST NAME */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="lastName">
                            {t('checkout.labels.lastName')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="lastName"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.last_name}
                                onChange={(e) =>
                                  handleNewAddressChange('last_name', e.target.value)
                                }
                              />
                              {newAddress.last_name.length > 0 ? (
                                newAddressErrors.last_name ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.last_name && (
                              <p className={styles.errorMessage}>{newAddressErrors.last_name}</p>
                            )}
                          </div>

                          {/* PHONE NUMBER */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="phone">
                            {t('checkout.labels.phoneNumber')}
                            </label>
                            <div className={styles.inputContainer}>
                              <PhoneInput
                                id="phone"
                                className={styles.phoneInput}
                                defaultCountry={countryForPhoneInput as CountryCode}
                                international
                                value={phone}
                                onChange={handlePhoneChange}
                              />
                              {phone?.length > 0 ? (
                                phoneError ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {phoneError && <p className={styles.errorMessage}>{phoneError}</p>}
                          </div>

                          {/* STREET */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="street">
                            {t('checkout.labels.street')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="street"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.street}
                                onChange={(e) =>
                                  handleNewAddressChange('street', e.target.value)
                                }
                              />
                              {newAddress.street.length > 0 ? (
                                newAddressErrors.street ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.street && (
                              <p className={styles.errorMessage}>{newAddressErrors.street}</p>
                            )}
                          </div>

                          {showSuggestions &&
                            suggestions.length > 0 &&
                            newAddress.street.length > 3 && (
                              <div className={styles.suggestionsContainer}>
                                {suggestions.map((addressObj, index) => (
                                  <p
                                    key={index}
                                    className={styles.suggestionItem}
                                    onClick={() =>
                                      handleAddressSuggestionSelect(addressObj.place_name, addressObj.country)
                                    }
                                  >
                                    {`${addressObj.place_name}, ${addressObj.country}`}
                                  </p>
                                ))}
                              </div>
                            )}

                          {/* POSTAL CODE */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="postalCode">
                            {t('checkout.labels.postalCode')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="postalCode"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.postalCode}
                                onChange={(e) =>
                                  handleNewAddressChange('postalCode', e.target.value)
                                }
                              />
                              {newAddress.postalCode.length > 0 ? (
                                newAddressErrors.postalCode ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.postalCode && (
                              <p className={styles.errorMessage}>{newAddressErrors.postalCode}</p>
                            )}
                          </div>

                          {/* CITY */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="city">
                            {t('checkout.labels.city')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="city"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.city}
                                onChange={(e) => handleNewAddressChange('city', e.target.value)}
                              />
                              {newAddress.city.length > 0 ? (
                                newAddressErrors.city ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.city && (
                              <p className={styles.errorMessage}>{newAddressErrors.city}</p>
                            )}
                          </div>

                          {renderCountrySelect()}

                          {/* ADDRESS COMPLEMENT */}
                          <div className={styles.formInputBox}>
                            <label className={styles.formInputLabel} htmlFor="addressComplement">
                            {t('checkout.labels.addressDetails')}
                            </label>
                            <div className={styles.inputContainer}>
                              <input
                                id="addressComplement"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.addressComplement}
                                onChange={(e) =>
                                  handleNewAddressChange('addressComplement', e.target.value)
                                }
                              />
                              {newAddress.addressComplement.length > 0 ? (
                                newAddressErrors.addressComplement ? (
                                  <img src={xIcon} alt="Error" className={styles.inputXIcon} />
                                ) : (
                                  <img src={vIcon} alt="Success" className={styles.inputVIcon} />
                                )
                              ) : null}
                            </div>
                            {newAddressErrors.addressComplement && (
                              <p className={styles.errorMessage}>
                                {newAddressErrors.addressComplement}
                              </p>
                            )}
                          </div>

                          <button className={styles.formButton} type="submit">
                           {t('checkout.saveChanges')}
                          </button>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              )}

              {/* --------- BILLING ADDRESS FORM FOR ANONYMOUS IF NOT USING SHIPPING ADDRESS --------- */}
              <div className="billing-address-form">
                {isAnonymousUser && !billingAddressCheckbox && !isAdding && !isEditing && (
                  billingAddressForm()
                )}
              </div>
            </div>

            {/* --------- SHIPPING SERVICES SECTION --------- */}
            <div className={styles.shippingSectionService}>
              <div className={styles.sectionShippingBox}>
                <h3 className={styles.sectionTitle}>{t('checkout.shipping')}</h3>
              </div>
              <div className={styles.shippingOptions}>
                {filteredShippingServices.length > 0 ? (
                  <ul className={styles.shippingList}>
                    {filteredShippingServices.map((service, index) => (
                      <li key={index} className={styles.shippingItem}>
                        <label
                          className={styles.labelContainer}
                          onClick={() => handleServiceClick(service)}
                          style={{ cursor: 'pointer' }}
                        >
                          {selectedShippingService?.service === service.service ? (
                            <div className={styles.selectedIcon}>
                              <img src={selectedIcon} alt="selected" />
                            </div>
                          ) : (
                            <div className={styles.unselectedIcon}>
                              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="12" fill="rgba(204, 185, 167, 0.5)" />
                              </svg>
                            </div>
                          )}

                          <div>
                            <div className={styles.priceServicePrice}>
                              <span className={styles.servicePrice}>€{service.price}</span> <span> — </span>
                              <span
                                className={`${styles.serviceName} ${selectedShippingService?.service === service.service ? styles.selectedServiceName : ''
                                  }`}
                              >
                                {service.service}
                              </span>
                            </div>
                            <span className={styles.serviceDelivery}>
                              {t('checkout.deliveryBetween')} {service.timedelivery}
                            </span>
                          </div>
                        </label>

                        {/* Sub-options for Relay/Lockers */}
                        {selectedShippingService?.service === service.service && service.service.toLowerCase().includes('relay') && (
                          <div className={styles.subOptions}>
                            {relayPointsData.length === 0 ? (
                              <p>{t('checkout.noRelayPoints')}</p>
                            ) : (
                              <ul className={styles.indentedRelayPoints}>
                                {relayPointsData.map((point, idx) => (
                                  <li key={idx}>
                                    <label
                                      className={styles.labelContainer}
                                      onClick={() => setSelectedRelayPoint(point)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {selectedRelayPoint?.Num === point.Num ? (
                                        <div
                                          className={styles.selectedIcon}
                                          style={{ width: '24px', height: '29px', marginRight: '10px' }}
                                        >
                                          <img
                                            src={selectedIcon}
                                            alt="selected"
                                            style={{ width: '20px', height: '20px' }}
                                          />
                                        </div>
                                      ) : (
                                        <div className={styles.unselectedIcon}>
                                          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="12" cy="12" r="8" fill="rgba(204, 185, 167, 0.5)" />
                                          </svg>
                                        </div>
                                      )}
                                      {` ${point.LgAdr1}, ${point.LgAdr3}, ${point.Ville}, ${point.Pays}`}
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        )}

                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={styles.noItemsTitle}>{t('checkout.noAddressSelected')}</p>
                )}
              </div>
              {isMobile && (
                <button
                  className={`${styles['checkout-btn']} ${styles['desktop-checkout-btn']}`}
                  onClick={handleCheckout}
                  disabled={!isFormValid() || checkForErrors() || isCheckoutDisabled || validateBilling()}
                >
                  {t('checkout.confirmOrder')}
                </button>
              )}
              {isMobile && errorMessage && (
                <p className="error-message" style={{ textAlign: 'center', marginTop: '15px' }}>
                  {errorMessage}
                </p>
              )}
            </div>
          </div>
          {/* checkout. */}
          {/* --------- ORDER SUMMARY --------- */}
          <div className={styles.orderSummary}>
            <div className={styles.sectionShippingBox}>
              <h3 className={styles.sectionTitle}>{t('checkout.summary.package')} ({getTotalItems()})</h3>
            </div>
            <div className={styles.productListScroll}>
              {products.map((product, index) => (
                <div key={index} className={styles.product}>
                  <img src={product.imgUrl} alt={product.translations?.[currentLanguage]?.title} className={styles.productImage} />
                  <div className={styles.productDetails}>
                    <h4 className={styles.productBrand}>{product.brand}</h4>
                    <div className={styles.productDesc}>
                      {product ? (
                        <>
                          <p className={styles.productDescTitle}>{product.translations?.[currentLanguage]?.title}</p>
                          <p className={styles.productDescSize}>{product.quantity}ml</p>
                        </>
                      ) : (
                        <p>{t('checkout.summary.package')}</p>
                      )}
                    </div>
                  </div>
                  <div className={styles.priceAndActionsContainer}>
                    <div className={styles.priceContainer}>
                      <p className={styles.productPrice}>
                        {cartItems[index]?.quantity && product?.price
                          ? `${cartItems[index].quantity} * €${Number(product.price).toFixed(2)}`
                          : ''}
                      </p>
                    </div>
                    {renderItemActions(cartItems[index])}
                  </div>
                </div>
              ))}
            </div>

            {cartItems && cartItems.length > 0 ? (
              <div className={styles.cartFooter}>
                <div className={styles.totalSection}>
                  <div className={styles.voucherInputSection}>
                    <div className={styles.voucherHeader}>
                      <span>{t('checkout.summary.voucherQuestion')}</span>
                      <span className={styles.seeAll} onClick={() => setShowAllVouchers(true)}>
                      {t('checkout.summary.seeAll')}
                      </span>
                    </div>
                    <span className={styles.voucherTitleCode}>{t('checkout.summary.voucherCode')}</span>
                    <div className={styles.voucherInputWrapper}>
                      <input
                        type="text"
                        value={voucherCode}
                        onChange={(e) => setVoucherCode(e.target.value)}
                        placeholder={t('checkout.summary.addVoucher')}
                        className={styles.voucherInput}
                        disabled={!!appliedVoucher}
                      />
                      {appliedVoucher ? (
                        <button onClick={handleRemoveVoucher} className={styles.applyButton}>
                          {t('common.delete')}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleApplyVoucher()}
                          className={styles.applyButton}
                          disabled={!user && !userEmail}
                        >
                          {t('checkout.summary.apply')}
                        </button>
                      )}
                    </div>
                    {voucherError && <p className={styles.errorMessage}>{voucherError}</p>}
                  </div>

                  <div className={styles.sumupDetails}>
                    <div className={styles.totalRow}>
                      <span className={styles.subtotalDiv} style={{ fontSize: '14px' }}>
                        <span>{t('checkout.summary.subtotal', {totalItems: getTotalItems()})}</span>
                      </span>
                      <span className={styles.subtotalDivPrice}>
                        €{calculateTotalAmountIncludingTax(cartItems, selectedShippingService)}
                      </span>
                    </div>

                    {appliedVoucher && discountResult.isApplicable && (
                      <div className={styles.totalRow}>
                        <span>{t('checkout.summary.discount')}</span>
                        <span className={styles.subtotalDivPrice}>
                          {appliedVoucher?.voucherID?.discountType === 'percentage'
                            ? `-${appliedVoucher.voucherID.discountValue}%`
                            : `-€${appliedVoucher.voucherID.discountValue}`
                          }
                        </span>
                        <span className={styles.subtotalDivPrice}>
                          -€{discountResult.discountAmount.toFixed(2)}
                        </span>
                      </div>
                    )}

                    <div className={styles.totalRow}>
                      <span>{t('checkout.summary.shipment')}</span>
                      <span className={styles.subtotalDivPrice}>
                        {selectedShippingService ? (
                          parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService)) >=
                            selectedShippingService.freeshipping ? (
                            <>
                              <span className={styles.strikethroughPrice}>
                                €{selectedShippingService.price.toFixed(2)}
                              </span>
                              <br />
                              <span>€0.00</span>
                            </>
                          ) : (
                            <span>€{selectedShippingService.price.toFixed(2)}</span>
                          )
                        ) : (
                          <span>€0.00</span>
                        )}
                      </span>
                    </div>

                    {/* <div className={styles.totalRow}>
                      <span>TAXES</span>
                      <span className={styles.subtotalDivPrice}>
                        €{calculateTaxes(cartItems, selectedShippingService)}
                      </span>
                    </div> */}

                    <div className={styles.totalRow}>
                      <strong className={styles.totalPrice}>{t('checkout.summary.total')}</strong>
                      <strong className={styles.totalPrice}>
                        {selectedShippingService &&
                          parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService)) >=
                          selectedShippingService.freeshipping
                          ? `€${(totalPriceWithTax - selectedShippingService.price).toFixed(2)}`
                          : `€${calculateTotalAmountIncludingAllTax(
                            cartItems,
                            selectedShippingService
                          )}`}
                      </strong>
                    </div>
                  </div>
                  {!isMobile && (
                    <div className={styles.buttonContainer}>
                      <button
                        className={`${styles['checkout-btn']} ${styles['desktop-checkout-btn']}`}
                        onClick={handleCheckout}
                        disabled={!isFormValid() || checkForErrors() || isCheckoutDisabled || validateBilling() || validateSelectedShippinService()}
                      >
                        {t('checkout.summary.confirmOrder')}
                      </button>
                    </div>
                  )}
                  {!isMobile && errorMessage && (
                    <p className="error-message" style={{ textAlign: 'center' }}>
                      {errorMessage}
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className={styles.emptyCartContainer}>
                  <img src={emptyCart} alt="Empty Cart" className={styles.emptyCartImg} />
                  <p className={styles.emptyCartText}>
                  {t('checkout.summary.emptyCart')}
                  </p>
                </div>
                <div className={styles.buttonContainer}>
                  <button className={`${styles['checkout-btn']} ${styles['desktop-checkout-btn']}`}
                    onClick={() => navigate('/bestsellers')}>
                    {t('common.discoverBestsellers')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </>

      <AllVouchersModal
        isOpen={showAllVouchers}
        onClose={() => setShowAllVouchers(false)}
        vouchers={userVouchers || []}
        onSelectVoucher={handleVoucherSelect}
      />
    </div >
  );
};

export default CheckoutPage;