import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ProductsApi from '../../../service/API/ProductsApi';
import { ShopProduct } from 'types/ShopInterfaces';
import { ShopHeadBestsellers } from 'components/shopPage/ShopHeadBestsellers/ShopHeadBestsellers';


import styles from './style.module.scss';
import { CategoriesFilter } from '../CategoriesFilter/CategoriesFilter';
import { SubCategoriesSection } from '../SubCaregoriesSection/subCategories';
import { GridProducts } from 'components/grid/gridProducts';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import { useCurrentLanguage } from 'hooks/useLanguage';
import { categoryTranslations } from 'utils/categoryTransUtils';

export const CategoriesPage = () => {
    const location = useLocation();
    const [products, setProducts] = useState<ShopProduct[]>([]);
    const [category, setCategory] = useState<string | null>(null);
    const [filteredProducts, setFilteredProducts] = useState<ShopProduct[]>(products);
    const { categoryImgUrl } = location.state || {};
    const { currentLanguage } = useCurrentLanguage();
    const gridProductsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoryFromURL = searchParams.get('category');

        if (categoryFromURL) {
            setCategory(decodeURIComponent(categoryFromURL.toLowerCase()));
            const fetchProductsByCategory = async () => {
                try {
                    const response = await ProductsApi.getProductsByCategory(categoryFromURL);
                    setProducts(response.data || []);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            };
            fetchProductsByCategory();
        }
    }, [location.search]);


    const handleFilteredProducts = (products: ShopProduct[]) => {
        setFilteredProducts(products);
    };

    const getTranslatedCategory = () => {
        if (!category) return '';
        return categoryTranslations[category as keyof typeof categoryTranslations]?.[currentLanguage] || category;
    };
    

    return (
        <>
            <div className={styles.collectionPage}>
                <ShopHeadBestsellers
                    backgroundImage={categoryImgUrl}
                    bestsellerHeaderAdvice={getTranslatedCategory() || ''}
                />
                <div className={styles.subCategoriesContainer}>
                    <div className={styles.subCategoriesGrid}>
                        <div className={styles.subCategory}>
                            <SubCategoriesSection />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.collectionPageContent}>
                <div className={styles.filter}>
                    <CategoriesFilter
                        products={products}
                        onFilteredProducts={handleFilteredProducts}
                        targetComponentRef={gridProductsRef}
                    />
                </div>
                <div className={styles.collectionPageGrid}>
                    <GridProducts products={filteredProducts} />
                </div>
            </div>
            <div ref={gridProductsRef}>
                <FooterSection />
            </div>
        </>
    );
};
