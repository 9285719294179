import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import SignUpBackgroudImage from '../../assets/images/headSectionBackground.jpg';
import blackLogo from '../../assets/icons/blackLogo.svg';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useTranslation } from 'react-i18next';

export const ResetSuccessful = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate('/login');
    };

    return (
        <>
            <div className={styles.forgetPasswordHeaderBlock} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
                <HomepageNavbar />
                <div className={styles.forgetPasswordContainer}>
                    <div className={styles.headSection}>
                        <img className={styles.olisLogo} alt="Group" src={blackLogo} />
                        <h1 className={styles.forgetPasswordTitle} style={{ lineHeight: '1.3' }}>{t('login.forgetPassword.resetSuccessful')}</h1>
                        <button onClick={navigateToLogin} className={styles.forgetPasswordButton}>{t('login.signIn')}</button>
                    </div>
                </div>
            </div>
        </>
    );
};
