import { useMostSearchedActives } from "../../../hooks/getMostSearchedActives";
import { Active } from "../../../types/TInterfaces";
import styles from './styles.module.scss';
import mostSearchedActives from 'assets/images/mostSearchedActive.png';
import mostSearchedActivesMobile from 'assets/images/mobileActivePage.png';
import defaultImage from 'assets/images/activeDefault.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Ensure Swiper's styles are included
import { useState, useEffect } from 'react';
import zero from '../../../assets/numbers/10.png'
import one from '../../../assets/numbers/01.png'
import two from '../../../assets/numbers/02.png'
import three from '../../../assets/numbers/03.png'
import four from '../../../assets/numbers/04.png'
import five from '../../../assets/numbers/05.png'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
export const MostSearchedActivesPage = () => {
    const [selectedActive, setSelectedActive] = useState<Active>({} as Active);
    const numberImages = [zero, one, two, three, four, five];
    const isMobile = window.innerWidth < 768;
    const navigate = useNavigate();
    const { data } = useMostSearchedActives();
    const { t } = useTranslation();

    const actives = data ? data.actives : null;

    // Set the default active once the actives are loaded
    useEffect(() => {
        if (actives && actives.length > 0) {
            setSelectedActive(actives[0]);
        }
    }, [actives]); // Dependency array includes actives to run effect when actives change

    if (!actives) return <div>{t('feed.mostSearched.noActivesFound')}</div>;

    if (!Array.isArray(actives)) {
        console.error("Expected 'actives' to be an array, received:", actives);
        return <div>{t('feed.mostSearched.invalidDataFormat')}</div>;
    }

    const handleActiveClick = (active: Active) => {
        setSelectedActive(active);
    };

    const handleRead = () => {
        navigate(`/feed/actives/${selectedActive._id}`);
    }

    // Sort actives by searchCounter from highest to lowest
    actives.sort((a: Active, b: Active) => b.searchCounter - a.searchCounter);

    const activeList = actives.map((active: Active, index: number) => (
        <div className={styles.mostSearchedActive} key={active._id}>
            <p onClick={() => handleActiveClick(active)}
                className={`${styles.mostSearchedActiveName} ${active._id === selectedActive._id ? styles.isSelected : ''}`}>
                {active.active_name}
            </p>
        </div>
    ));

    const activeImage = (
        <div className={styles.mostSearchedActiveContainer} onClick={handleRead}>
            <div className={styles.titleContainer}>
                <p className={styles.title}>{t('feed.mostSearched.mostSearchedTitle')}</p>
            </div>
            <div className={styles.indexContainer}>
                <p className={styles.index}> 0{actives.findIndex(active => active._id === selectedActive._id) + 1}</p>
            </div>
            <div className={styles.activeImageContainer}>
                <img
                    className={styles.activeImage}
                    src={selectedActive.image || defaultImage}
                    alt={selectedActive.active_name}
                />
            </div>
            <div className={styles.activeTextContainer}>
                <div className={styles.numberContainer}>
                    {/* Always show the zero image */}
                    <img
                        src={zero}
                        alt="Zero"
                        className={styles.numberImage}
                    />
                    {/* Dynamically show the corresponding number image */}
                    <img
                        src={
                            numberImages[actives.findIndex(active => active._id === selectedActive._id) + 1] || zero
                        }
                        alt={`Number ${String(actives.findIndex(active => active._id === selectedActive._id) + 1).padStart(2, '0')}`}
                        className={styles.numberImage}
                    />
                </div>
                <p className={styles.activeName}>{selectedActive.active_name}</p>
            </div>
        </div>
    );


    return (
        <div
            className={styles.mostSearchedActivesPage}
            style={{ backgroundImage: `url(${isMobile ? mostSearchedActivesMobile : mostSearchedActives})` }}
        >
            <div className={styles.mostSearchedTitleContainer}>
                {activeList}
            </div>
            <div className={styles.activeImageContainer}>
                {activeImage}
            </div>
        </div>
    );
};
