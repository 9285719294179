import api from './apiConfig';
import { Active } from '../../types/TInterfaces';

export default class activesApi {
    static async getAllActives(): Promise<{ success: boolean; data?: Active[]; message?: string }> {
        try {
            const response = await api.get('/actives');

            if (!response.data || !response.data.actives) {
                return { success: false, message: 'No actives found' };
            }

            return { success: true, data: response.data.actives };
        } catch (error) {
            console.error('API error while fetching all actives:', error);
            return {
                success: false,
                message: (error as Error).message || 'Failed to fetch actives'
            };
        }
    }

    static async getActiveById(activeId: string): Promise<{ success: boolean; data?: Active; message?: string }> {
        try {
            const response = await api.get(`/actives/${activeId}`);
            if (!response.data) {
                return { success: false, message: 'Active not found' };
            }
            return { success: true, data: response.data };
        } catch (error) {
            console.error('API error while fetching active by ID:', error);
            return {
                success: false,
                message: (error as Error).message || 'Failed to fetch active',
            };
        }
    }

    static async getActivesByElementOfGroupId(
        elementOfGroupIds: string[]
    ): Promise<{ success: boolean; data?: Active[]; message?: string }> {
        try {
            const response = await api.post('/actives/by-element-of-group-id', {
                elementOfGroupIds,
            });

            if (!response.data || !response.data.actives) {
                return { success: false, message: 'No actives found for the given elementOfGroupIds' };
            }

            return { success: true, data: response.data.actives };
        } catch (error) {
            console.error('API error while fetching actives by elementOfGroupId:', error);
            return {
                success: false,
                message: (error as Error).message || 'Failed to fetch actives by elementOfGroupId',
            };
        }
    }

    static async updateSearchCounter(activeId: string): Promise<{ success: boolean; message?: string }> {
        try {
            await api.post(`/actives/update-search-active/${activeId}`);
            return { success: true };
        } catch (error) {
            console.error('API error while updating search counter:', error);
            return { success: false, message: 'Failed to update search counter' };
        }
    }

    static async getMostSearchedActives(): Promise<{ success: boolean; data?: Active[]; message?: string }> {
        try {
            const response = await api.get('/actives/most-searched-actives');
            return { success: true, data: response.data };
        } catch (error) {
            console.error('API error while fetching most searched actives:', error);
            return { success: false, message: 'Failed to fetch most searched actives' };
        }
    }

    static async getActivesCombinationData(notionIds: string[]): Promise<{ success: boolean; data?: { id: string, active_name: string }[]; message?: string }> {
        try {
            // Send a POST request with the notionIds array
            const response = await api.post('/actives/getActivesCombinationData', { notion_ids: notionIds });

            return { success: true, data: response.data.actives };
        } catch (error) {
            console.error('API error while fetching actives combination data:', error);
            return { success: false, message: 'Failed to fetch actives combination data' };
        }
    }
    static async getActiveGroupe(): Promise<{ success: boolean; data?: { id: string, active_name: string }[]; message?: string }> {
        try {
            // Send a GET request to the /actives/getActiveGroupe endpoint
            const response = await api.get('/actives/getActiveGroupe');

            return { success: true, data: response.data };
        } catch (error) {
            console.error('API error while fetching active group data:', error);
            return { success: false, message: 'Failed to fetch active group data' };
        }
    }
}
