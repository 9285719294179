import styles from './style.module.scss';
import { useAtom } from 'jotai';
import { userAtom } from '../../stores/UserProfile';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SignUpBackgroudImage from '../../assets/images/headSectionBackground.jpg';
import blackLogo from '../../assets/icons/blackLogo.svg';
import UserApi from 'service/API/UserApi';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

export const ForgetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const {t} = useTranslation()
    const {currentLanguage} = useCurrentLanguage()

    // Check for errors passed via state in the route
    useEffect(() => {
        const state = location.state as { errorMessage?: string; returnPath?: string };
        if (state?.errorMessage) {
            setError(state.errorMessage);
        }
    }, [location]);

    // Reset error on input change
    const resetError = (currentError: string | null, updateError: (value: string | null) => void) => {
        if (currentError) {
            updateError(null);
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        resetError(error, setError);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await UserApi.forgotPassword(email, currentLanguage);
            if (response.success) {
                setEmailSent(true); // Set emailSent to true when successful
                setError(null);
            } else {
                setError(response.message || t('login.forgetPassword.resetError'));
            }
        } catch (err: any) {
            console.error('Reset password error:', err);
            setError(t('login.forgetPassword.resetError'));
        }
    };

    return (
        <>
            <div className={styles.forgetPasswordHeaderBlock} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
                <HomepageNavbar />
                <div className={styles.forgetPasswordContainer}>
                    <div className={styles.headSection}>
                        <img className={styles.olisLogo} alt="Group" src={blackLogo} />
                        <h1 className={styles.forgetPasswordTitle}>
                            {emailSent ? t('login.forgetPassword.emailSent') : t('login.forgetPassword.title')}
                        </h1>
                    </div>

                    {!emailSent && ( // Render the form only if the email hasn't been sent
                        <div className={styles.formContainer}>
                            <form onSubmit={handleSubmit}>
                                <div className={styles.forgetPasswordInput} style={{ position: 'relative', marginBottom: '8px' }}>
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder={t('login.emailPlaceholder')}
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                </div>
                                {error &&
                                    <div className={styles.errorContainer}>
                                        <p className={styles.errorMessage}>{error}</p>
                                    </div>
                                }
                                <button
                                    type="submit"
                                    className={styles.forgetPasswordButton}
                                    disabled={!email}
                                >
                                    {t('login.forgetPassword.resetButton')}
                                </button>
                            </form>
                        </div>
                    )}

                    {!emailSent && (
                        <p className={styles.forgetPasswordText}>
                            {t('login.needAccount')}{' '}
                            <a href="/signup" className={styles.forgetPasswordLink}>
                            {t('login.signUp')}
                            </a>
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};
