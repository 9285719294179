import React, { useState } from 'react';
import inviteFriendsBg from '../../../../../assets/images/InviteFriendsBg.jpeg';
import styles from './InviteFriends.module.scss';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import userService from '../../../../../service/UserService';
import { useTranslation } from 'react-i18next';
import {config} from '../../../../../config'

export const InviteFriends: React.FC = () => {
    const [user, setUser] = useAtom(userAtom);
    const [loading, setLoading] = useState(false);
    // Phone number input is currently not used
    const [phoneNumber, setPhoneNumber] = useState('');
    const {t} = useTranslation()
    const [voucherCode, setVoucherCode] = useState<string | null>(null);

    const handleInvite = async () => {
        if (!user) return;

        setLoading(true);
        try {
            const response = await userService.generateFriendVoucher(user, setUser);
            if (response.success) {
                setVoucherCode(response.voucher.code);

                const expirationDate = new Date(response.voucher.expirationDate);
                const formattedDate = expirationDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });

                const message = t('inviteFriends.invitationMessage', { url:config.REACT_APP_BASE_FRONTEND_URL , code: response.voucher.code, formattedDate: formattedDate  })

                const fallBackToClipboard = () => {
                    navigator.clipboard.writeText(`${message}\n${config.REACT_APP_BASE_FRONTEND_URL}`)
                    .then(() => {
                        alert(t('inviteFriends.fallbackMessage', { message }));
                    });
                }
                

                if (navigator.share) {
                    try {
                        await navigator.share({
                            title: t('inviteFriends.messageTitle'),
                            text: message,
                            url: `${config.REACT_APP_BASE_FRONTEND_URL}`
                        });
                    } catch (shareError) {
                        console.error('Error sharing:', shareError);
                        fallBackToClipboard()
                    }
                } else {
                    // Fallback: If Web Share API is not supported, display the invitation message.
                    fallBackToClipboard()
                }
            }
        } catch (error) {
            console.error('Error generating voucher:', error);
        } finally {
            setLoading(false);
        }
    };

    

    return (
        <div className={styles.inviteFriendsContainer}>
            <img src={inviteFriendsBg} alt="Invite Friends" className={styles.inviteImage} />
            <h4 className={styles.inviteTitle}>{t('inviteFriends.title')}</h4>
            <p className={styles.inviteText}>{t('inviteFriends.description')}</p>
            {/*
            <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter friend's phone number"
                className={styles.phoneInput}
            />
            */}
            <button
                className={styles.inviteButton}
                onClick={handleInvite}
                disabled={loading}
            >
                {loading ? t('inviteFriends.generatingButton') : t('inviteFriends.sendButton')}
            </button>
            {voucherCode && (
                <p className={styles.voucherCode}>
                    {t('inviteFriends.voucherCode', { voucherCode })}
                </p>
            )}
        </div>
    );
};
