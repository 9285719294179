import React from 'react';
import styles from './styles.module.scss';
import xIconFilter from '../../../assets/icons/xIconFilter.svg';
import { useTranslation } from 'react-i18next';

interface SavedFiltersProps {
    activeFilters: string[];
    handleFilterClick: (filter: string) => void;
    isDontShowMe: Boolean;
    handleClearAllClick: () => void;
}

const SavedFilters: React.FC<SavedFiltersProps> = ({
    activeFilters,
    handleFilterClick,
    handleClearAllClick,
    isDontShowMe,
}) => {
    const {t} = useTranslation()
    if (activeFilters.length === 0) return null;

    return (
        <div className={styles.savedFiltersContainer}>
            {activeFilters.map((filter, index) => (
                <div key={index} className={`${styles.filterChip} ${isDontShowMe ? styles.dontShowMeFilterChipBackground : ''}`}>
                    <span className={styles.filterChipText}>{filter}</span>
                    <button
                        className={styles.removeButton}
                        onClick={() => handleFilterClick(filter)}
                    >
                        <img
                            src={xIconFilter}
                            alt="Remove filter"
                            className={styles.xIconFilter}
                        />
                    </button>
                </div>
            ))}
            <button
                className={`${styles.clearAll} ${isDontShowMe ? styles.clearAllTextColor : ''}`}
                onClick={() => handleClearAllClick()}
            >
                {t('categories.clearAllButtonLabel')}
            </button>
        </div>
    );
};

export default SavedFilters;
