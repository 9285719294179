import React from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import blackLogo from '../../../assets/icons/blackLogo.svg';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import profileSelectionIcon from '../../../assets/images/profileSelectionIcon.png';
import styles from '../style.module.scss';
import { useTranslation } from 'react-i18next';

export const SPF_WelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const [user] = useAtom(userAtom);
  const {t} = useTranslation()

  const goToForm = () => {
    navigate('/spf-form/spf-gender');
  };

  const maybeLater = () => {
    navigate('/profile/vouchers');
  };

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.welcomeformContentContainer}>
          <img className={styles.groupWelcome} alt="Group" src={profileSelectionIcon} />
          <div className={styles.titleWelcomeContainer}>
            <h1 className={styles.titleWelcome}>
              {t('spfForm.welcomePage.title')} <br /> {user?.name?.toUpperCase() || ''}
            </h1>
          </div>
          <div className={styles.subtitleWelcomeContainer}>
            <p className={styles.subtitleWelcome}>{t('spfForm.welcomePage.subtitle')}</p>
          </div>


        </div>
        <div className={styles.buttons}>
          <div className={styles.welcomeButtonContainer}>
            <button className={styles.nextButtonForm} onClick={goToForm}>
              {t('spfForm.welcomePage.createProfileButton')}
            </button>
          </div>
          <div className={styles.maybeLaterButtonContainer}>
            <button className={styles.maybeLaterButton} onClick={maybeLater}>
              {t('spfForm.welcomePage.maybeLaterButton')}
            </button>
          </div>
        </div>

      </div>

    </div>
  );
};
