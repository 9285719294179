import IframePage from 'components/IframePage/IframePage'
import React from 'react'
import { staticIframeUrls } from 'utils/staticUrlUtil'

function PrivacyPolicyPage() {
    const url = staticIframeUrls.privacy
  return (
    <IframePage url={url} />
  )
}

export default PrivacyPolicyPage