import React, { useState, useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './expandProducts.module.scss';
import blackdot from '../../../../assets/icons/blackDot.svg'
import ProductsApi from "../../../../service/API/ProductsApi"
import SearchLoader from '../../../animation/searchLoader/searchLoader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ProductsResult {
    title?: string;
    imgUrl: string;
    brand: string;
    _id: string;
    price: number;
    quantity: number;
}

interface ExpandProps {
    onClose: () => void;
    products: any[];
    category: string;
    subCategory: string;
}

const ExpandProductsHeader: React.FC<{ handleClose: () => void, totalCount: number, isLoading: boolean, isMobile: boolean }> =
    ({ handleClose, totalCount, isLoading, isMobile }) => {
        const {t} = useTranslation()
        return <div className={styles.menuHeaderContainer}>
            {!isLoading && !isMobile && (
                <div className={styles.menuHeaderResultsContainer}>
                    <p className={styles.menuHeaderResultsText}>{t('menu.expandProducts.results', { count: totalCount })}</p>
                </div>
            )}

            {isMobile && (
                <button className={styles.menuBacktoMenuButton} onClick={handleClose}>
                   {t('menu.expandProducts.backToMenu')}
                </button>
            )}
            <button onClick={handleClose} className={styles.menuCloseButton}>
                <p className={styles.menuContainerCloseText}>{t('common.close')}</p>
            </button>
        </div>
    }


const OptionsNav: React.FC<{
    activeOption: string,
    handleOptionClick: (option: string) => void,
    counts: { products: number }
}> = ({ activeOption, handleOptionClick, counts }) => {
    const {t} = useTranslation()
    return <div className={styles.optionsContainer}>
        <button
            onClick={() => handleOptionClick('PRODUCTS')}
            className={activeOption === 'PRODUCTS' ? styles.activeOption : styles.nonActiveOption}
        >
            {activeOption === 'PRODUCTS' && <img src={blackdot} alt="Active" className={styles.optionIcon} />}
            {t('menu.expandProducts.products')} {activeOption === 'PRODUCTS' ? '' : `(${counts.products})`}
        </button>
    </div>
};

const ProductItem: React.FC<{ product: ProductsResult, onProductClick: (product: ProductsResult) => void }> =
    ({ product, onProductClick }) => (
        <div className={styles.productItem} onClick={() => onProductClick(product)}>
            <Link to={`/product/${product._id}`} className={styles.productLink}>
                <div className={styles.productImageContainer}>
                    <img
                        src={product.imgUrl}
                        alt={product.title}
                        className={styles.productImage}
                        onClick={(e) => {
                            e.stopPropagation();
                            ProductsApi.updateSearchCounter(product._id);
                        }}
                    />
                    <div className={styles.productInfo}>
                        <p className={styles.productBrand}>{product.brand}</p>
                        <p className={styles.productTitle}>
                            {(product?.title?.length ?? 0) > 20 ? `${product?.title?.substring(0, 17)}...` : product?.title}
                        </p>
                        <p className={styles.productPrice}>
                            €{product.price?.toFixed(2)} | ({product?.quantity}ml)
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );

const ProductsSection: React.FC<{
    isLoading: boolean,
    products: ProductsResult[],
    showAllProducts: boolean,
    handleSeeMore: () => void,
    handleProductClick: (product: ProductsResult) => void,
    isMobile: boolean
}> = ({ isLoading, products, showAllProducts, handleSeeMore, handleProductClick, isMobile }) => {
    return (
        <>
            {isLoading ? (
                <div className={styles.menuLoaderContainer}>
                    <SearchLoader />
                </div>
            ) : (
                <div className={`${styles.menuProductContainer}`}>
                    <div className={`${styles.productList}`}>
                        {products
                            .filter((product, index, self) =>
                                product?.title &&
                                self.findIndex(p => p._id === product._id) === index
                            )
                            .slice(0, products.length)
                            .map((product, index) => (
                                product &&
                                <div >
                                    <ProductItem
                                        key={product._id || index}
                                        product={product}
                                        onProductClick={handleProductClick}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            )}
        </>
    );
};

const ExpandProducts = forwardRef<HTMLDivElement, ExpandProps>(
    ({ onClose, products, category, subCategory }, ref) => {
        const [activeOption, setActiveOption] = useState('PRODUCTS');
        const [productsResult, setProductsResult] = useState<ProductsResult[]>(products);
        const [totalCount, setTotalCount] = useState(0);
        const [showAllProducts, setShowAllProducts] = useState(false);
        const isMobile = window.innerWidth < 768;
        const [isLoading, setIsLoading] = useState(false);
        const navigate = useNavigate();
        const { t } = useTranslation();

        useEffect(() => {
            setTotalCount(productsResult.length);
        }, [productsResult]);

        const handleSeeAllButton = () => {
            const encodedSubCategory = encodeURIComponent(subCategory);
            const encodedCategory = encodeURIComponent(category);
            navigate(`/collection/products?search=${encodedCategory}=${encodedSubCategory}`);
        };

        return (
            <div ref={ref} className={`${styles.menuRightSection}`}>
                <div className={styles.expendContainer}>
                    <ExpandProductsHeader handleClose={onClose} totalCount={totalCount} isLoading={isLoading} isMobile={isMobile} />
                    <div className={styles.subcategoryHeaderContainer}>
                        <h3 className={styles.subcategoryHeader}>{subCategory}</h3>
                    </div>
                    <OptionsNav
                        activeOption={activeOption}
                        handleOptionClick={setActiveOption}
                        counts={{ products: productsResult.length }}
                    />
                    <div className={styles.productsContainer}>
                        <ProductsSection
                            isLoading={isLoading}
                            products={productsResult}
                            showAllProducts={showAllProducts}
                            handleSeeMore={() => setShowAllProducts(!showAllProducts)}
                            handleProductClick={(product) => navigate(`/ product / ${product._id}`)}
                            isMobile={isMobile}
                        />
                    </div>
                </div >
                <button
                    onClick={handleSeeAllButton}
                    className={styles.seeAllButton}
                >
                    {t('menu.expandProducts.seeAllProducts')}
                </button>
            </div >
        );
    });

export default ExpandProducts;
