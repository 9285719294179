import { useEffect, useLayoutEffect } from 'react';
import { useAtom } from 'jotai';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { loadingAtom } from 'stores/Loading';
import api from './API/apiConfig';
import LoadingComponent from 'components/loading/loadingComponent';

export function Interceptor() {
  const [loadingState, setLoadingState] = useAtom(loadingAtom);
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const requestInterceptor = api.interceptors.request.use((config) => {
      const excludedPaths = ['/checkout', '/login', '/signup', '/profile', '/success', '/notFound', '/spf-form', '/ingredients-scan'];

      const spfForm = location.pathname.startsWith('/spf-form/');
      const profile = location.pathname.startsWith('/profile/');
      const actives = location.pathname.startsWith('/actives/');
      const login = location.pathname.startsWith('/login/');
      const signup = location.pathname.startsWith('/signup/');
      const hasCartModal = !!document.querySelector('.cart-modal');
      const hasErrorPage = !!document.querySelector('.error-page');
      const hasMenuOpened = !!document.querySelector('.visible-menu');
      // const hasHomePageContainer = !!document.querySelector(".homepageContainer");
      const isFavoriteQuery = config.url?.includes('favorite');
      const isAddtoBasketQuery = config.url?.includes('addtocart');
      const carouselInActives = !!document.querySelector('.carouselContainer') && location.pathname.startsWith('/feed/actives/');

      const isExcluded = excludedPaths.includes(location.pathname) || profile || spfForm || login || signup || actives || hasCartModal || hasErrorPage || hasMenuOpened || isFavoriteQuery || isAddtoBasketQuery || carouselInActives;

      if (!isExcluded) {
        setLoadingState((prev) => {
          if (!prev.isLoading) {
            return {
              isLoading: true,
              pendingRequests: 1,
            };
          }
          return {
            ...prev,
            pendingRequests: prev.pendingRequests + 1,
          };
        });
      }

      return config;
    });

    const responseInterceptor = api.interceptors.response.use(
      (response) => {
        setLoadingState((prev) => {
          const newPending = prev.pendingRequests - 1;
          return {
            isLoading: newPending > 0,
            pendingRequests: newPending,
          };
        });

        return response;
      },
      (error) => {
        setLoadingState((prev) => {
          const newPending = prev.pendingRequests - 1;
          return {
            isLoading: newPending > 0,
            pendingRequests: newPending,
          };
        });

        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.request.eject(requestInterceptor);
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [location.pathname, setLoadingState]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    if (loadingState.isLoading) {
      timeout = setTimeout(() => {
        // const lastPage = location.state?.from || "/";
        // navigate(lastPage);
      }, 12000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [loadingState.isLoading, location.state?.from, navigate]);

  return (
    <>
      <LoadingComponent loading={loadingState.isLoading} />
      <Outlet />
    </>
  );
}
