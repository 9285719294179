import React, { useState } from 'react';
import styles from './SelectedVoucherModal.module.scss';
import { useNavigate } from 'react-router-dom';
import { validateVoucher } from 'utils/voucherUtils';
import userService from 'service/UserService';
import { useAtom } from 'jotai';
import { userAtom } from 'stores/UserProfile';
import { useTranslation } from 'react-i18next';

interface SelectedVoucherModalProps {
    isOpen: boolean;
    onClose: () => void;
    voucher: any;
    onVoucherDeleted?: () => void;
}

const SelectedVoucherModal: React.FC<SelectedVoucherModalProps> = ({ isOpen, onClose, voucher, onVoucherDeleted }) => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [user] = useAtom(userAtom);
    const {t} = useTranslation()

    if (!isOpen) return null;

    const validationResult = validateVoucher(voucher);


    const canDeleteVoucher = (voucher: any) => {
        return (
            voucher.status === 'used' ||
            new Date(voucher.voucherID.expirationDate) < new Date() ||
            !voucher.voucherID.active
        );
    };

    const handleDelete = async () => {
        if (!user || !user._id || !canDeleteVoucher(voucher)) {
            return;
        }

        try {
            const response = await userService.deleteVoucher(user._id ?? '', voucher._id);
            if (response.success) {
                onVoucherDeleted?.();
                onClose();
            } else {
                setError(response.message || t('profileVouchers.selectedVoucherModal.failedToDelete'));
            }
        } catch (error) {
            setError(t('profileVouchers.selectedVoucherModal.failedToDelete'));
        }
    };

    const handleApplyVoucher = () => {
        if (!validationResult.isValid) {
            setError(validationResult.message);
            return;
        }

        navigate('/checkout', {
            state: {
                voucherCode: voucher.voucherID.code,
                shouldApplyVoucher: true
            }
        });
    };

    const isVoucherInactive = (voucher: any) => {
        return (
            voucher.status === 'used' ||
            new Date(voucher.voucherID.expirationDate) < new Date() ||
            !voucher.voucherID.active
        );
    };

    const getBackgroundStyle = (imageUrl: string | undefined) => imageUrl ? { backgroundImage: `url(${imageUrl})` } : { backgroundColor: '#5D5246' };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div>
                    <div className={styles.voucherHeaderContainer}>
                        <div className={styles.voucherHeader}>
                            <button
                                className={styles.deleteButton}
                                onClick={handleDelete}
                                style={{
                                    opacity: canDeleteVoucher(voucher) ? 1 : 0.5,
                                    cursor: canDeleteVoucher(voucher) ? 'pointer' : 'not-allowed'
                                }}
                            >
                                {t('common.delete')}
                            </button>
                            <button className={styles.closeButton} onClick={onClose}>{t('common.cancel')}</button>
                        </div>
                    </div>
                    <div
                        className={styles.voucherImageContainer}
                        style={getBackgroundStyle(voucher.voucherID.imageUrl)}
                    >
                        <div className={styles.discountText}>
                            {voucher.voucherID.discountType === 'percentage'
                                ? t('profileVouchers.percentageOff', {value: voucher.voucherID.discountValue})
                                : t('profileVouchers.euroOff', {value: voucher.voucherID.discountValue})
                            }
                        </div>
                    </div>
                    <div className={styles.voucherContent}>
                        <h2 className={styles.codeHeader}>{voucher.voucherID.code}</h2>
                        <p className={styles.expHeader}>{t('profileVouchers.selectedVoucherModal.expirationPrefix', {date: new Date(voucher.voucherID.expirationDate).toLocaleDateString('en-GB')})}</p>
                        <hr className={styles.styledHr} />
                        <p className={styles.styledDescription}>{voucher.voucherID.description}</p>
                    </div>
                </div>
                <button
                    className={styles.applyButton}
                    onClick={handleApplyVoucher}
                >
                    {t('profileVouchers.selectedVoucherModal.applyVoucher')}
                    {error && <p className={styles.errorMessage}>{error}</p>}
                </button>

            </div>
        </div>
    );
};

export default SelectedVoucherModal;