// CombineSection.tsx

import React, { useEffect } from 'react';

import styles from './combine.module.scss';
import './styles.scss';
import { Banner1 } from 'components/homepage/Banner1/Banner1';
import { Banner2 } from 'components/homepage/Banner2/Banner2';
import { Banner3 } from 'components/homepage/Banner3/Banner3';
import { Banner4 } from 'components/homepage/Banner4/Banner4';

interface Banner {
    title?: string;
    description?: string;
    image?: string;
    link?: string;
}

export interface CombineSectionProps {
    banners?: Banner[];
    brandName?: string;
    magnetScrollingStyle: {
        skincareJourney: React.CSSProperties;
        browsingThrough: React.CSSProperties;
        saferIngredients: React.CSSProperties;
    };
    sectionsRef: React.MutableRefObject<HTMLDivElement[]>;
}

const bannerComponents: { [key: number]: React.FC<any> } = {
    1: Banner1,
    2: Banner2,
    3: Banner3,
    4: Banner4,
    // Add more mappings if there are more banners
};

export const CombineSection: React.FC<CombineSectionProps> = ({ banners = [], brandName, sectionsRef }) => {
    // Helper function to determine if a banner should be rendered
    const shouldRenderBanner = (banner?: Banner): boolean => {
        return banner && banner.title ? banner.title.trim().length > 0 : false;
    };

    // Helper function to extract banner number from image URL
    const extractBannerNumber = (imageUrl?: string): number | null => {
        if (!imageUrl) return null;
        const regex = /(\d+)(?=\.\w+$)/; // Matches digits before the file extension
        const match = imageUrl.match(regex);
        return match ? parseInt(match[1], 10) : null;
    };

    const isBrandPage: boolean = window.location.pathname.includes('/brand');


    const refMapping = isBrandPage ? [3, 4, 5] : [3, 4, 5, 6];
    return (
        <>
            <div className={styles.space}>

            </div>
            <div className={styles.combineSection}>
                {banners.map((banner, index) => {
                    const bannerNumber = extractBannerNumber(banner.image);
                    const BannerComponent = bannerNumber ? bannerComponents[bannerNumber] : null;

                    // Skip rendering if the banner is Banner4 and it's a brand page
                    if (bannerNumber === 4 && isBrandPage) {
                        return null;
                    }

                    if (!BannerComponent) {
                        // Handle cases where the banner number doesn't match any component
                        console.warn(`No Banner component found for banner number: ${bannerNumber}`);
                        return null;
                    }

                    // Determine if the banner should be rendered with its props or default
                    if (shouldRenderBanner(banner)) {
                        return (
                            <div
                                key={index}
                                ref={(el) => {
                                    if (el && refMapping[index] !== undefined) {
                                        sectionsRef.current[refMapping[index]] = el;
                                    }
                                }}
                                className={`banner${bannerNumber}`}
                            >
                                <BannerComponent
                                    key={index}
                                    title={banner.title!}
                                    description={banner.description}
                                    image={banner.image}
                                    brandName={brandName}
                                    link={banner.link}
                                />
                            </div>
                        );
                    } else if (banner) {
                        return <BannerComponent key={index} brandName={brandName} />;
                    } else {
                        // If banner data is undefined or null, render the default Banner component
                        return <BannerComponent key={index} brandName={brandName} />;
                    }
                })}
            </div>
        </>
    );
};
