import React, { useState } from 'react';
import ScannerStep from 'components/IngredientsScan/ScannerStep/ScannerStep';
import LoadingStep from 'components/IngredientsScan/LoadingStep/LoadingStep';
import ResultsStep from 'components/IngredientsScan/ResultsStep/ResultsStep';
import RootLayout from 'components/IngredientsScan/RootLayout/RootLayout';
import { IProcessedData, IScanData, ScanSteps } from 'types/IIngredientsScanner';
import { IngredientsApi } from 'service/API/IngredientsApi';
import ErrorModal from 'components/IngredientsScan/ScannerStep/ErrorModal/ErrorModal';

function IngredientsScanPage() {
  const [currentStep, setCurrentStep] = useState<ScanSteps>(ScanSteps.SCANNER);
  const [ingredientsScore, setIngredientsScore] = useState<number>(0);
  const [processedData, setProcessedData] = useState<IProcessedData | null>(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleScanData = async (data: IScanData) => {
    setCurrentStep(ScanSteps.LOADING);

    try {
      // handle types differently
      if (data.type === 'IMAGE' && data.data instanceof File) {
        const base64Img = await convertToBase64(data.data);
        // Extract text from image
        const extractedText = await IngredientsApi.processImage(base64Img);

        if (!extractedText || extractedText.data.extractedText.trim() === '') {
          setIsErrorModalOpen(true);
          setCurrentStep(ScanSteps.SCANNER);
          return;
        }

        const textArr = extractedText.data.extractedText?.split(',');

        const result = await IngredientsApi.analyzeIngredients({ type: data.type, data: textArr });
        const ingredientArr = result?.results?.map((item: any) => item.original) || [''];
        await getScore(ingredientArr || ['']);

        if (!result?.results || result.results.length === 0 || !result.results.some((ingredient: any) => ingredient.original && ingredient.original.trim() !== '')) {
          setIsErrorModalOpen(true);
          setCurrentStep(ScanSteps.SCANNER);
          return;
        }
        setProcessedData(result);
      } else {
        // Process the data differently based on type
        const result = data.type === 'MANUAL' ? await IngredientsApi.analyzeIngredients(data) : null;
        await getScore(data.data as string[]);
        if (result) {
          setProcessedData(result);
        }
      }

      setCurrentStep(ScanSteps.RESULTS);
    } catch (error) {
      console.error('Error processing scan data:', error);
      setIsErrorModalOpen(true);
      setCurrentStep(ScanSteps.SCANNER);
    }
  };

  const handleFileUpload = async (file: File) => {
    try {
      setCurrentStep(ScanSteps.LOADING);

      // Convert to base64 and send to backend
      const base64Img = await convertToBase64(file);
      const extractedText = await IngredientsApi.processImage(base64Img);

      const result = await IngredientsApi.analyzeIngredients({ type: 'IMAGE', data: extractedText.data.extractedText });
      const ingredientArr = result?.results?.map((item: any) => item.original) || [''];
      await getScore(ingredientArr || ['']);
      setProcessedData(result);
      setCurrentStep(ScanSteps.RESULTS);
    } catch (error) {
      console.error('Error processing image:', error);
      setCurrentStep(ScanSteps.SCANNER);
    }
  };

  const handleBackStep = () => {
    switch (currentStep) {
      case ScanSteps.SCANNER:
        window.history.back();
        break;
      case ScanSteps.RESULTS:
        setCurrentStep(ScanSteps.SCANNER);
        setProcessedData(null);
        break;
      default:
        break;
    }
  };

  //this function is for fetching the score from the backend
  async function getScore(ingredients: string[]) {
    try {
      const result = await IngredientsApi.rateProduct(ingredients);

      setIngredientsScore(result?.data?.score);
    } catch (error) {
      console.error('Error getting score:', error);
    }
  }

  const renderStep = () => {
    switch (currentStep) {
      case ScanSteps.SCANNER:
        return <ScannerStep onDataCapture={handleScanData} onFileUpload={handleFileUpload} />;
      case ScanSteps.LOADING:
        return <LoadingStep />;
      case ScanSteps.RESULTS:
        return <ResultsStep processedData={processedData} score={ingredientsScore} />;
      default:
        return <ScannerStep onDataCapture={handleScanData} onFileUpload={handleFileUpload} />;
    }
  };

  return (
    <RootLayout onBack={handleBackStep} showBackButton={currentStep !== ScanSteps.SCANNER && currentStep !== ScanSteps.LOADING}>
      {renderStep()}
      <ErrorModal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
    </RootLayout>
  );
}

export default IngredientsScanPage;
