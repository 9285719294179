import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

interface HowItWorksButtonProps {
  onClick: () => void;
}

function HowItWorksButton({ onClick }: HowItWorksButtonProps) {
  const { t } = useTranslation();
  return (
    <span onClick={onClick} className={styles.howItWorksButton}>
      {t('ingredientsScan.landing.howItWorks')}
    </span>
  );
}

export default HowItWorksButton;
