import React from 'react';
import { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { CarouselService } from 'components/carousels/service/carouselService';
import { ShopProduct } from 'types/ShopInterfaces';
import productsApi from 'service/API/ProductsApi';
import { getLocalStorageItem } from 'utils/localStoragUtil';
import { useWindowSize } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

export const RecentlyViewed: React.FC = () => {
  const { isMobile } = useWindowSize();
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<ShopProduct[]>([]);
  const [showRecentlyViewed, setShowRecentlyViewed] = useState(false);
  const {t} = useTranslation()

  useEffect(() => {
    if (recentlyViewedProducts.length > 0) {
      setShowRecentlyViewed(true);
    } else {
      setShowRecentlyViewed(false);
    }
  }, [recentlyViewedProducts]);
  // fetch recently viewed products
  useEffect(() => {
    const fetchRecentlyViewedProductsData = async () => {
      try {
        const recentlyViewedIds = getLocalStorageItem('recentlyViewed')
        const response = await productsApi.getProductsByArrayOfIds(recentlyViewedIds);

        if (response.success) {
          setRecentlyViewedProducts(response?.data as ShopProduct[]);
        } else {
          setRecentlyViewedProducts([]);
        }

      } catch (error) {
        console.error('Error fetching similar products:', error);
      }
    };

    fetchRecentlyViewedProductsData();
  }, []);

  return (
    <>
      {showRecentlyViewed && (
        <>
          {isMobile && (
            <p className={styles.mobileHeader}>{t('productDetails.recentlyViewed')}</p>
          )}

          <div className={styles.recommandedContainer} style={{ backgroundColor: '#F7F6E7' }}>
            <div className={styles.BSheaderBlock}>
              {!isMobile && (
                <p className={styles.header}>{t('productDetails.recentlyViewed')}</p>
              )}
            </div>


            <div className={styles.carouselContainer}>
              <CarouselService products={recentlyViewedProducts} carouselLayout='RECENTLY_VIEWED' quantity={10} paginationLocation={isMobile ? "none" : "down"} slideStyles={{
                desktop: {
                  relatedProductSlide: { height: '49.6vh', width: '19.7vw', pointerEvents: 'all' },
                  imageContainer: { maxHeight: '32vh' }
                },
                mobile: {
                  relatedProductSlide: { height: '24vh', width: '32vw', pointerEvents: 'all' },
                  //relatedProductSlide: { height: '28vh', width: '32vw', pointerEvents: 'all' },
                  imageContainer: { maxHeight: '50vh' }
                }
              }} />
            </div>

          </div>
        </>
      )}
    </>
  );
};
