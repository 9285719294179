import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import GeneralButton from 'components/IngredientsScan/GeneralButton';

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ErrorModal({ isOpen, onClose }: ErrorModalProps) {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <>
      <div className={styles.overlay} onClick={onClose} />
      <div className={styles.modal}>
        <h2>{t('ingredientsScan.error.title')}</h2>
        <p>{t('ingredientsScan.error.noIngredients')}</p>
        <div className={styles.buttonContainer}>
          <GeneralButton onClick={onClose} text={t('ingredientsScan.error.tryAgain')} />
        </div>
      </div>
    </>
  );
}

export default ErrorModal;