import react, { useState } from 'react';
import styles from './styles.module.scss';
import useRandomProducts from 'hooks/randomProducts';
import Dot from 'assets/icons/blackDot.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Grid } from 'swiper/modules';
import { GridProducts } from 'components/grid/gridProducts';
import { useTranslation } from 'react-i18next';

export const ShopPageGrid: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { randomProducts } = useRandomProducts(8);
    const [hoveredItem, setHoveredItem] = useState<number | null>(null);
    return (
        <div className={styles.recommendedBlock}>
            <div className={styles.leftSideContainer}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div className={styles.flexContainer} style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Dot} alt="dot" className={styles.dotIcon} />
                        <p className={styles.recTextMain}>{t('shop.selectedByOli')}</p>
                    </div>
                </div>
                <p className={styles.discover}>{t('shop.discoverFullSelection')}</p>

                <button className={styles.APhomeblackButton} style={{ cursor: 'pointer' }} onClick={() => navigate('/shopAll')}>
                {t('shop.shopAll')}
                </button>
            </div>

            <div className={styles.rightSideContainer}>
                {/* {
                        randomProducts?.map((item, index) => {
                            return (
                                <Link to={`/product/${item._id}`} className={styles.productContainer} key={index}
                                    onMouseEnter={() => setHoveredItem(index)}
                                    onMouseLeave={() => setHoveredItem(null)}
                                >
                                    <div>
                                        <p className={styles.recText}>Just Dropped</p>

                                    </div>
                                    <div className={styles.productImage} style={{
                                        backgroundImage: `url(${window.innerWidth < 768
                                            ? item.imgUrl
                                            : hoveredItem === index && item.textureImgUrl
                                                ? item.textureImgUrl
                                                : item.imgUrl
                                            })`,
                                    }} />
                                    <div className={styles.productShopInfo}>
                                        <p className={styles.productShopBrand}>{item.brand}</p>
                                        <p className={styles.productShopTitle}>
                                            {item.title && item.title.length > 20
                                                ? `${item.title.substring(0, 20)}...`
                                                : item.title}
                                        </p>
                                        <p className={styles.productShopPrice}>€{item.price} ({item.quantity} ml)</p>

                                    </div>
                                </Link>
                            )
                        })} */}
                <GridProducts products={randomProducts} />
            </div>

        </div>
    )
}