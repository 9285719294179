import { useState, useCallback } from 'react';

interface Favorites {
    [key: string]: boolean;
}

/**
 * Hook for handling product favorites
 * Works fine for showing the popup
 */

export const useFavoriteHandler = (userApi: any) => {
    const [favorites, setFavorites] = useState<Favorites>({});
    const inProgressRequests = new Set<string>();

    const handleFavoriteToggle = useCallback(
        async (productId: string) => {
            // Prevent duplicate requests for the same product
            if (inProgressRequests.has(productId)) return;

            inProgressRequests.add(productId);

            const isCurrentlyFavorite = favorites[productId] || false;

            // Optimistically update the UI
            setFavorites((prev) => ({
                ...prev,
                [productId]: !isCurrentlyFavorite,
            }));

            try {
                const result = await userApi.updateUserFavorite(
                    productId,
                    isCurrentlyFavorite ? 'remove' : 'add'
                );

                // Handle missing token scenario
                if (result?.tokenMissing) {
                    // Revert the favorite state if token is missing
                    setFavorites((prev) => ({
                        ...prev,
                        [productId]: isCurrentlyFavorite,
                    }));
                }
            } catch (error) {
                console.error('[useFavoriteHandler] Failed to update favorite:', error);

                // Revert the favorite state to `false` on error
                setFavorites((prev) => ({
                    ...prev,
                    [productId]: false,
                }));
            } finally {
                // Ensure the productId is removed from in-progress requests
                inProgressRequests.delete(productId);
            }
        },
        [favorites, userApi]
    );

    return { favorites, setFavorites, handleFavoriteToggle };
};

/**
 * Hook for handling article favorites
 * Add extra logs to confirm tokenMissing is returned.
 */
export const useFavoriteHandlerArticle = (userApi: any) => {
    const [favorites, setFavorites] = useState<Favorites>({});
    const inProgressRequests = new Set();

    const handleFavoriteToggle = useCallback(
        async (articleId: string) => {
            if (inProgressRequests.has(articleId)) return;

            inProgressRequests.add(articleId);

            const isCurrentlyFavorite = favorites[articleId] || false;
            setFavorites((prev) => ({
                ...prev,
                [articleId]: !isCurrentlyFavorite,
            }));

            try {
                const result = await userApi.updateUserFavoriteArticle(
                    articleId,
                    isCurrentlyFavorite ? 'remove' : 'add'
                );

                // Log the API result to see if tokenMissing is returned

                // If server signals missing token => show popup, revert favorite
                if (result && result.tokenMissing) {
                    setFavorites((prev) => ({
                        ...prev,
                        [articleId]: isCurrentlyFavorite,
                    }));
                }
            } catch (error) {
                console.error('[useFavoriteHandlerArticle] Failed to update favorite:', error);
                setFavorites((prev) => ({
                    ...prev,
                    [articleId]: isCurrentlyFavorite,
                }));
            } finally {
                inProgressRequests.delete(articleId);
            }
        },
        [favorites, userApi]
    );

    return { favorites, setFavorites, handleFavoriteToggle };
};
