import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import { ProductLoader } from '../../../pages/ProductDetails/ProductLoader';
import './style.scss';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { NotionArticle } from 'hooks/useNotionArticles.types';
import { useFavoriteHandlerArticle } from '../../../utils/faveoritUtil';
import userApi from 'service/API/UserApi';
import articleApi from 'service/API/articleApi';
import { useFetchFavoriteArticles } from 'hooks/fetchFavoritesArticles';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';
import LoadingComponent from 'components/loading/loadingComponent';
import {config} from '../../../config'
import { fallBackToClipboard } from 'service/serviceFunctions';


export const ArticleDetail: React.FC = () => {
  const isMobile = window.innerWidth < 768;
  const { articleId } = useParams<{ articleId: string }>();
  // const { data: articles, isLoading, error } = useNotionArticles();
  const articlesContainerRef = useRef<HTMLDivElement>(null);
  const [hideNavbar, setHideNavbar] = useState(false);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [article, setArticle] = useState<NotionArticle>()
  const { favorites, setFavorites, handleFavoriteToggle } = useFavoriteHandlerArticle(userApi);
  useFetchFavoriteArticles(setFavorites);
  const navigate = useNavigate();
  const { t } = useTranslation()
  //TODO: get currentLanguage from context
  //  const { currentLanguage } = useLanguage();
  const { currentLanguage } = useCurrentLanguage();
  useEffect(()=>{
    const getArticle = async () => {
      try {
        const article = await articleApi.getArticlesByIds([articleId!])
        if (article.length === 0){
          navigate('/feed')
        }
        setArticle(article[0])
      } catch (error) {
        console.log('Error fetching the article :', error)
      }
      
    }
    getArticle()
  },[articleId])
  useEffect(() => {
    const container = articlesContainerRef.current;
    if (!container) return;
    const handleScroll = () => {
      const currentScrollTop = container.scrollTop;

      // If scrolling down beyond 50 pixels, hide the navbar.
      if (currentScrollTop > prevScrollTop && currentScrollTop > 50) {
        setHideNavbar(true);
      } else if (currentScrollTop < prevScrollTop) {
        setHideNavbar(false);
      }
      setPrevScrollTop(currentScrollTop);
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [prevScrollTop]);

  if (!article) {
    return null;
  };

  const getListItemType = (type: string, index: number, blocks: any[], block: any) => {
    // For numbered lists, we need to count from the last non-numbered item
    if (type === 'numbered_list_item') {
      let count = 1;
      for (let i = index - 1; i >= 0; i--) {
        const prevBlock = blocks[i];
        // Break if we hit a different type that's not a paragraph
        if (prevBlock.type !== 'numbered_list_item' && prevBlock.type !== 'paragraph') {
          break;
        }
        // Only increment count for actual numbered items
        if (prevBlock.type === 'numbered_list_item') {
          count++;
        }
      }
      return { prefix: `${count}. `, style: {} };
    }

    // Handle blocks with explicit marginLeft
    if (block.marginLeft) {
      return { prefix: type === 'bulleted_list_item' ? '• ' : '', style: { marginLeft: block.marginLeft } };
    }

    // Handle paragraphs that should inherit margin
    if (type === 'paragraph' && index > 0) {
      const previousBlock = blocks[index - 1];
      if (previousBlock.marginLeft) {
        return { prefix: '', style: { marginLeft: previousBlock.marginLeft } };
      }
    }

    // Handle regular bullet points
    if (type === 'bulleted_list_item') {
      return { prefix: '• ', style: {} };
    }

    return { prefix: '', style: {} };
  };

  const renderContent = (content: any[]) => {
    return content.map((block, index) => {
      switch (block.type) {
        case 'paragraph':
        case 'heading_1':
        case 'heading_2':
        case 'heading_3':
        case 'bulleted_list_item':
        case 'numbered_list_item':
        case 'quote':
          const { prefix, style } = getListItemType(block.type, index, content, block);
          return (
            <p
              key={index}
              className="article-paragraph"
              style={getListItemType(block.type, index, content, block).style}
            >
              {block.content?.map((textBlock: any, i: number) => {
                return (
                  <span
                    key={i}
                    style={{
                      ...textBlock.bold ? { fontWeight: 'bold' } : {},
                      ...textBlock.italic ? { fontStyle: 'italic' } : {},
                      ...textBlock.underline ? { textDecoration: 'underline' } : {},
                      ...textBlock.color !== 'default' ? { color: textBlock.color } : {},
                      ...textBlock.fontSize !== 'default' ? { fontSize: textBlock.fontSize } : {},
                    }}
                  >
                    {i === 0 ? `${getListItemType(block.type, index, content, block).prefix}${textBlock.text}` : textBlock.text}
                  </span>
                );
              })}
            </p>
          );
        case 'image':
          return (
            <figure key={index} className="article-image-container">
              <img src={block.url} alt={block.caption} className="article-content-image" />
              {block.caption && <figcaption className="article-image-caption">{block.caption}</figcaption>}
            </figure>
          );
        default:
          return null;
      }
    });
  };

  const share = async (articleId : string) => {
    const title = article.translations[currentLanguage].properties.Title.title[0]?.plain_text
    const message = `${t('articles.shareArticle.message')} \n ${title} \n`
    const url = `${config.REACT_APP_BASE_FRONTEND_URL}/feed/article/${articleId}`

    if (navigator.share) {
      try {
        await navigator.share ({
          text : message,
          url
        })
      } catch (shareError) {
        console.error ('Error sharing article:', shareError)
        fallBackToClipboard(message, url, t)
      }
    } else {
      fallBackToClipboard(message, url, t)
    }
  }


  return (
    isMobile ? (
      <div className="mobile-article-detail-container">
        <HomepageNavbar />
        <div className="mobile-article-image-section">
          <img src={article.translations[currentLanguage].properties.Image.files[0]?.url} alt={article.translations[currentLanguage].properties.Title.title[0]?.plain_text} className="mobile-article-image" />
          <div className="mobile-article-image-overlay"></div>
          <div className="article-favorite-button-container">
          <button
              className="article-favorite-share-button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                share(article._id);
              }}
            >
              {t('common.share')}
            </button>
            <button
              className="article-favorite-share-button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleFavoriteToggle(article._id);
              }}
            >
              {favorites[article._id] ? t('articles.removeFromFavorites') : t('articles.saveArticle')}
            </button>
          </div>
          <div className="mobile-article-meta">
            <span className="mobile-article-category">{article.translations[currentLanguage].properties.Categories.multi_select[0]?.name || t('articles.defaultCategory')}</span>
            <h1 className="mobile-article-title-detail">{article.translations[currentLanguage].properties.Title.title[0]?.plain_text}</h1>
          </div>
        </div>
        <div className="mobile-article-content-section">
          <div className="mobile-article-content">{renderContent(article.translations[currentLanguage].content)}</div>
        </div>
      </div>
    ) : (
      <div className="article-detail-container">
        {/* <FeedPageNavbar hideNavbar={hideNavbar} /> */}
        <HomepageNavbar />

        <div
          className="article-image-section">
          <img src={article.translations[currentLanguage].properties.Image.files[0]?.url} alt={article.translations[currentLanguage].properties.Title.title[0]?.plain_text} className="article-image" />
          <div className="article-image-overlay"></div>
          <div className="article-meta">
            <span className="article-categor">{article.translations[currentLanguage].properties.Categories.multi_select[0]?.name || t('articles.defaultCategory')}</span>
            <h1 className="article-title-detail">{article.translations[currentLanguage].properties.Title.title[0]?.plain_text}</h1>
          </div>
        </div>
        <div
          ref={articlesContainerRef}

          className="article-content-section">
          <div className="article-favorite-button-container">
          <button
              className="article-favorite-share-button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                share(article._id);
              }}
            >
              {t('common.share')}
            </button>
            <button
              className="article-favorite-share-button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleFavoriteToggle(article._id);
              }}
            >
              {favorites[article._id] ? t('articles.removeFromFavorites') : t('articles.saveArticle')}
            </button>
          </div>
          <div className="article-content">{renderContent(article.translations[currentLanguage].content)}</div>
        </div>

      </div>
    )
  );
};
