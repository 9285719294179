import React, { useEffect, useState, forwardRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode } from 'swiper/modules';
import styles from './ComingSoonProductsCarousel.module.scss';
import { useAtom } from 'jotai';
import { componentLoadingStatusAtom } from 'stores/UserProfile';
import useRandomProducts from 'hooks/randomProducts';
import { useWindowSize } from 'hooks/useWindowSize';
import { useCurrentLanguage } from 'hooks/useLanguage';

interface ComingSoonProductsCarouselProps {
    onSlideChange: (groupIndex: number) => void;
    carouselId: number;
}

export const ComingSoonProductsCarousel = forwardRef<any, ComingSoonProductsCarouselProps>(
    ({ onSlideChange, carouselId }, ref) => {
        const { isMobile } = useWindowSize();
        const { randomProducts, loading } = useRandomProducts(carouselId);
        const [hoveredItem, setHoveredItem] = useState<number | null>(null);
        const [productCount, setProductCount] = useState(0);
        const [componentStatuses, setComponentStatuses] = useAtom(componentLoadingStatusAtom);
        const {currentLanguage} = useCurrentLanguage()


        useEffect(() => {
            if (!loading) {
                setComponentStatuses((prev: any) => ({ ...prev, ProductsCarousel: true }));
            }
        }, [loading, setComponentStatuses]);

        useEffect(() => {
            setProductCount(randomProducts.length);
        }, [randomProducts]);

        const handleMouseEnter = (index: number) => setHoveredItem(index);
        const handleMouseLeave = () => setHoveredItem(null);

        return (
            <Swiper
                spaceBetween={30}
                modules={[FreeMode]}
                slidesPerView={randomProducts.length < 4 ? 3 : (isMobile ? 1.2 : 4)}
                onSlideChange={(swiper: any) => {
                    const groupIndex = Math.floor(swiper.activeIndex / 3) + 1;
                    onSlideChange(groupIndex);
                }}
                loop={false}
                className={styles.productsCarouselSliderBlock}
                ref={ref}
                style={{ marginLeft: productCount > 4 ? 0 : 60 }}
            >
                {randomProducts?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className={styles.productContainer}>
                            <div className={styles.justDroppedContainer}>
                                {/* <p className={styles.justDroppedText}>Just dropped</p> */}
                            </div>
                            <div
                                className={styles.clickableImage}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img
                                    src={
                                        isMobile
                                            ? item.imgUrl
                                            : hoveredItem === index && item.textureImgUrl
                                                ? item.textureImgUrl
                                                : item.imgUrl
                                    }
                                    className={styles.carouselImage}
                                    alt={item.translations?.[currentLanguage]?.title}
                                />
                            </div>
                            <div className={styles.productInfo}>
                                <p className={styles.productBrand}>{item.brand}</p>
                                <p className={styles.productTitle}>{item.translations?.[currentLanguage]?.title}</p>
                                {/* <p className={styles.productPrice}>€{item.price?.toFixed(2)} ({item.quantity}ml)</p> */}
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    }
);
