import React from 'react';
import styles from './CautionBox.module.scss';
import infoImg from '../../../../../assets/images/infoCircle3.png';
import { useTranslation } from 'react-i18next';

export const CautionBox: React.FC = () => {
    const {t} = useTranslation()
    return (
        <div className={styles.cautionBox}>
            <div className={styles.imageSection}>
                <img src={infoImg} alt="Caution" className={styles.cautionImage} />
            </div>
            <div className={styles.textSection}>
                <p className={styles.cautionText}>{t('profileSettings.cautionBox')}</p>
            </div>
        </div>
    );
};
