import React, { useEffect, useState, useRef } from 'react';
import styles from './Menu.module.scss';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { userAtom } from '../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { handleLogOut } from '../utils/authUtils';
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import { useCurrentLanguage } from 'hooks/useLanguage';

import userService from 'service/UserService';
import Search from './search/search';
import ExpandProducts from './expandProducts/expandProducts'
import productsApi from 'service/API/ProductsApi';
import categoriesApi from 'service/API/categoriesApi';

import plusIcon from '../../../assets/icons/plusIcon.svg';
import minusIcon from '../../../assets/icons/minusIcon.svg';
import blackdot from '../../../assets/icons/blackDot.svg'
import arrow from '../../../assets/icons/arrow.svg'
import { useTranslation } from 'react-i18next';
import { subcategoryTranslations } from './SubcategoryTranslations/SubcategoryTranslations';

interface MenuProps {
    isVisible: boolean;
    toggleVisibility: () => void;
}

interface Product {
    _id: string;
    brand: string;
    imgUrl: string;
    title: string;
}

interface Category {
    name: {
        [key: string]: string;
    };
    imgUrl: string;
    smallImgUrl: string;
    subcategories: Array<{ [subcategoryName: string]: any }>;
}

type ProductsWithCategories = {
    [category: string]: {
        [subcategory: string]: Product[];
    };
};



const Menu: React.FC<MenuProps> = ({ isVisible, toggleVisibility }) => {
    const navigate = useNavigate();

    const { data: articles } = useNotionArticles({ page: 1, limit: 5 });
    const isMobile = window.innerWidth < 1260;
    const { currentLanguage } = useCurrentLanguage()

    const [productsWithCategories, setProductsWithCategories] = useState<ProductsWithCategories>({});
    const [isProductsScreenExpanded, setIsProductsScreenExpanded] = useState(false);
    const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = useState(false);
    const [activeCategorySubmenus, setActiveCategorySubmenus] = useState<Record<number, boolean>>({});
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const [user, setUser] = useAtom(userAtom);
    const [isSearchActive, setIsSearchActive] = React.useState(false);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number | null>(null);
    const [selectedSubItemIndex, setSelectedSubItemIndex] = useState<number | null>(null);
    const [categories, setCategories] = useState<Category[]>([]);
    const menuContainerRef = useRef<HTMLDivElement | null>(null);
    const expandProductsRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation()

    const isAnonymousUser = !user || Object.keys(user).length === 0;
    const location = useLocation();

    const menuItems = [
        { title: 'menu.search', target: '', onClick: () => setIsSearchActive(true) },
        { title: 'common.shop', target: '/shop' },
        { title: 'menu.hotBrands', target: '/hot-brands' },
        { title: 'menu.bestsellers', target: '/bestsellers' },
        { title: 'menu.editorials', target: '/feed' },
        ...(user && !userService.isAnonymousUser(user) ? [{ title: 'menu.profile', target: '/profile' }] : []),
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await productsApi.getSubmenusProductsObject();

                if (response.success) {
                    setProductsWithCategories(response.data);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        categoriesApi.getAllCategories().then((res) => {
            setCategories(res || []);
        }).catch((err: any) => {
            console.error("Failed to fetch categories:", err);
        });
    }, []);

    const categoryOptions = categories.map((category) => {
        // Always use English name as the key for productsWithCategories
        const englishName = category.name.en;
        const displayName = category.name[currentLanguage];

        // Get subcategories in English
        const englishSubItems = productsWithCategories[englishName]
            ? Object.keys(productsWithCategories[englishName])
            : [];

        // Translate subcategories to current language
        const translatedSubItems = englishSubItems.map(subItem => {
            if (currentLanguage === 'en') return subItem;
            return subcategoryTranslations[subItem]?.[currentLanguage] || subItem;
        });

        return {
            title: displayName,
            imgUrl: category.imgUrl,
            subItems: translatedSubItems,
            // Store original English names for reference when clicking
            originalSubItems: englishSubItems
        };
    });


    const toggleSubMenu = () => {
        setIsCategoriesMenuOpen((prev) => !prev);
    };

    const toggleCategorySubmenu = (index: number) => {
        setActiveCategorySubmenus((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const handleSubcategoryClick = (categoryIndex: number, subItemIndex: number) => {
        const category = categoryOptions[categoryIndex];
        if (!category) return;

        const englishSubcategory = category.originalSubItems[subItemIndex];
        const englishCategory = categories.find(cat =>
            cat.name[currentLanguage] === category.title
        )?.name.en;

        if (englishCategory && englishSubcategory &&
            productsWithCategories[englishCategory] &&
            productsWithCategories[englishCategory][englishSubcategory]) {
            setSelectedCategoryIndex(categoryIndex);
            setSelectedSubItemIndex(subItemIndex);
            setSelectedProducts(productsWithCategories[englishCategory][englishSubcategory]);
            setIsProductsScreenExpanded(true);
        }
    };

    const logout = () => handleLogOut(setUser, navigate);

    useEffect(() => {
        document.body.style.overflow = isVisible ? 'hidden' : 'auto';
    }, [isVisible]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isProductsScreenExpanded) {
                if (
                    menuContainerRef.current &&
                    !menuContainerRef.current.contains(event.target as Node) &&
                    expandProductsRef.current &&
                    !expandProductsRef.current.contains(event.target as Node) && isVisible
                ) {
                    toggleVisibility();
                    setIsProductsScreenExpanded(false);
                }
            } else {
                if (
                    menuContainerRef.current &&
                    !menuContainerRef.current.contains(event.target as Node) && isVisible
                ) {
                    toggleVisibility();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isVisible, toggleVisibility, isProductsScreenExpanded]);

    const handleShopAllCategory = (displayTitle: string, categoryImgUrl: string) => {
        // Find the category with matching display title to get its English name
        const categoryData = categories.find(cat => cat.name[currentLanguage] === displayTitle);
        const englishTitle = categoryData?.name.en;

        if (!englishTitle) return; // Guard clause in case category is not found

        const encodedCategory = encodeURIComponent(englishTitle);
        const targetPath = `/categories?category=${encodedCategory}`;
        const currentPath = `${location.pathname}${location.search}`;

        if (currentPath === targetPath) {
            toggleVisibility();
        } else {
            navigate(targetPath, {
                state: { categoryImgUrl },
            });
        }
    };

    const handleNavigate = (url: string) => {
        window.open(url, '_blank', 'noopener noreferrer');
    }

    const handleNavigation = (item: { title: string; target: string; onClick?: () => void }) => {
        if (t(item.title, { lng: 'en' }).toLowerCase() !== 'shop') {
            const targetPath = item.target.toLowerCase();
            const currentPath = location.pathname.toLowerCase();

            if (currentPath === targetPath) {
                toggleVisibility();
            } else {
                item.onClick ? item.onClick() : navigate(item.target);
            }
        }
    };
    const handleArticleClick = (articleId: string) => () => {
        window.location.href = `/feed/article/${articleId}`; // Navigates & reloads the page
    };

    return (
        <div className={`${isVisible ? 'visible-menu' : ''}`}>
            <div className={`${styles.menuOverlay} ${isVisible ? styles.visible : ''}`}>
                {isSearchActive ? (
                    <Search
                        isSearchActive={isSearchActive}
                        setIsSearchActive={setIsSearchActive}
                        isMobile={isMobile}
                        toggleVisibility={toggleVisibility}
                        isMenuVisible={isVisible}
                    />
                ) : isProductsScreenExpanded && isMobile ? (
                    <ExpandProducts
                        key={JSON.stringify(selectedProducts)}
                        products={selectedProducts}
                        onClose={() => setIsProductsScreenExpanded(false)}
                        category={categoryOptions[selectedCategoryIndex as number]?.title}
                        subCategory={categoryOptions[selectedSubItemIndex as number]?.subItems[selectedSubItemIndex as number]}
                    />
                ) : (
                    <div className={`${styles.menuContainer} ${isVisible ? styles.visible : ''}`}
                        style={{
                            borderTopRightRadius: (isProductsScreenExpanded || isMobile) ? '0' : '10px',
                            borderBottomRightRadius: (isProductsScreenExpanded || isMobile) ? '0' : '10px',
                        }}
                        ref={menuContainerRef}
                    >
                        <div className={styles.menuTopButtonContainer}>
                            {isMobile && (
                                <div className={styles.menuLoginButtonContainer}>
                                    <p
                                        className={styles.menuLoginButtonText}
                                        onClick={() => {
                                            if (isAnonymousUser) {
                                                navigate('/signup');
                                            } else {
                                                logout();
                                            }
                                        }}
                                    >
                                        {isAnonymousUser ? t('navbar.sign_up') : t('navbar.log_out')}
                                    </p>
                                </div>
                            )}
                            <div className={styles.menuCloseButtonContainer}>
                                <button onClick={toggleVisibility} className={styles.menuCloseButton}>
                                    <img src={blackdot} alt="blackdot" className={styles.menuCloseButtonDot} />
                                    <p className={styles.menuCloseButtonText}>{t('common.close')}</p>
                                </button>
                            </div>
                        </div>
                        {!isSearchActive && (
                            <div className={styles.menuOptionsContainer}>
                                <ul>
                                    {menuItems.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {/* Menu item wrapper */}
                                            <div>
                                                <li onClick={() => handleNavigation(item)}>
                                                    <div
                                                        className={styles.menuOptionsItem}
                                                        onClick={(e) => {
                                                            if (t(item.title, { lng: 'en' }).toLowerCase() === 'shop') {
                                                                toggleSubMenu();
                                                            }
                                                        }}
                                                    >
                                                        {t(item.title)}
                                                        {t(item.title, { lng: 'en' }).toLowerCase() === 'shop' && (
                                                            <img
                                                                src={isCategoriesMenuOpen ? minusIcon : plusIcon}
                                                                alt={isCategoriesMenuOpen ? 'Minus Icon' : 'Plus Icon'}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    toggleSubMenu();
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </li>
                                            </div>


                                            {/* Submenu for 'shop' */}
                                            {t(item.title, { lng: 'en' }).toLowerCase() === 'shop' && isCategoriesMenuOpen && (
                                                <div className={styles.subMenuContainer}>
                                                    <ul>
                                                        <div
                                                            className={styles.menuOptionsItem}
                                                            style={{ marginLeft: '30px' }}
                                                            onClick={() => {
                                                                const targetPath = '/shopAll'.toLowerCase();
                                                                const currentPath = location.pathname.toLowerCase();

                                                                if (currentPath === targetPath) {
                                                                    toggleVisibility();
                                                                } else {
                                                                    navigate('/shopAll');
                                                                }
                                                            }}
                                                        >
                                                            <li>{t('common.shopAll')}</li>
                                                            <img
                                                                src={arrow}
                                                                alt='arrow'
                                                            />
                                                        </div>
                                                        {categoryOptions.map((category, categoryIndex) => {
                                                            if (!category.imgUrl) return null;
                                                            return (
                                                                <li key={categoryIndex}>
                                                                    {/* Category item with icon */}
                                                                    <div
                                                                        className={styles.menuOptionsItem}
                                                                        style={{ marginLeft: '30px' }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            toggleCategorySubmenu(categoryIndex);
                                                                        }}
                                                                    >
                                                                        {category.title}
                                                                        <img
                                                                            src={activeCategorySubmenus[categoryIndex] ? minusIcon : plusIcon}
                                                                            alt={activeCategorySubmenus[categoryIndex] ? 'Minus Icon' : 'Plus Icon'}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                toggleCategorySubmenu(categoryIndex);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    {/* Subcategory list if submenu is active */}
                                                                    {activeCategorySubmenus[categoryIndex] && (
                                                                        <div className={styles.subSubMenuContainer}>
                                                                            <ul className={styles.subcategoryList}>
                                                                                <div>
                                                                                    <li className={styles.menuSubOptionsItem} style={{ marginLeft: '54px' }} onClick={() => handleShopAllCategory(category.title, category.imgUrl)}>{t('common.shopAll')}</li>
                                                                                </div>
                                                                                {category.subItems.sort().map((subItem, subItemIndex) => (
                                                                                    <li
                                                                                        className={styles.menuSubOptionsItem}
                                                                                        style={{
                                                                                            marginLeft: (selectedSubItemIndex === subItemIndex && selectedCategoryIndex === categoryIndex) ? '36px' : '54px',
                                                                                        }}
                                                                                        key={subItemIndex}
                                                                                        onClick={() => handleSubcategoryClick(categoryIndex, subItemIndex)}
                                                                                    >
                                                                                        {selectedSubItemIndex === subItemIndex && selectedCategoryIndex === categoryIndex && (
                                                                                            <img src={blackdot} alt="blackdot" className={styles.menuCloseButtonDot} />
                                                                                        )}
                                                                                        {subItem.charAt(0).toUpperCase() + subItem.slice(1)}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    )}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!isMobile && (
                            <div className={styles.trendingContainer}>
                                <p className={styles.trendingContainerText}>{t('menu.trending')}</p>
                            </div>
                        )}

                        {!isSearchActive && (
                            <div className={styles.articlesContainer}>
                                {articles
                                    ?.filter((article: any) => {
                                        // Check if there's a file array and at least one URL
                                        return article?.translations?.[currentLanguage]?.properties?.Image?.files?.[0]?.url
                                    })
                                    ?.slice(0, 2)
                                    .map((article: any) => (
                                        <div
                                            key={article.id}
                                            className={styles.articleCard}
                                            style={{
                                                backgroundImage: `url(${article?.translations?.[currentLanguage]?.properties?.Image?.files?.[0]?.url})`,
                                            }}
                                            onClick={handleArticleClick(article.id)}
                                        >
                                            <div className={styles.dossiersContainer}>
                                                {article?.translations?.[currentLanguage]?.dossierNames?.length > 0 &&
                                                    article?.translations?.[currentLanguage]?.dossierNames?.map((name: string, index: number) => (
                                                        <div key={index} className={styles.dossiersBlock}>
                                                            <p className={styles.dossiersBlockText}>
                                                                <span>{name.toUpperCase()}</span>
                                                            </p>
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className={styles.articleHeaderContainer}>
                                                <p className={styles.articleHeader}>
                                                    {article?.translations?.[currentLanguage]?.properties?.Title?.title?.[0]?.plain_text}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}

                        <div className={styles.menuSocialMediaContainer}>
                            <a className={styles.menuSocialMediaContainerItem} onClick={() => handleNavigate("https://www.instagram.com/olis.lab")}>{t('socialMedia.instagram')}</a>
                            <a className={styles.menuSocialMediaContainerItem} onClick={() => handleNavigate("https://www.tiktok.com/@olis_lab")}>{t('socialMedia.tiktok')}</a>
                            <a className={styles.menuSocialMediaContainerItem} onClick={() => handleNavigate("https://www.linkedin.com/company/olis-lab")}>{t('socialMedia.linkedin')}</a>
                        </div>
                    </div>
                )
                }
                {isProductsScreenExpanded && !isSearchActive && !isMobile && (<ExpandProducts key={JSON.stringify(selectedProducts)} products={selectedProducts} onClose={() => setIsProductsScreenExpanded(false)} category={categoryOptions[selectedCategoryIndex as number]?.title} subCategory={categoryOptions[selectedSubItemIndex as number]?.subItems[selectedSubItemIndex as number]} ref={expandProductsRef} />)}
            </div>
        </div>
    );
};

export default Menu;