// ShopHeadBestsellers.js
import styles from './style.module.scss';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import HeaderBackgroundImage from 'assets/images/101.jpg'; // Default background image
import { useTranslation } from 'react-i18next';

interface ShopHeadBestsellersProps {
  backgroundImage?: string;
  brandsCount?: number;
  bestsellerHeaderAdvice?: string;
  bestsellerHeaderRightText?: string;
  hotBrandsHeaderAdvice?: string;
  hotBrandCounterText?: string;
  descriptionText?: string;
}

export const ShopHeadBestsellers = ({
  backgroundImage = HeaderBackgroundImage,
  brandsCount,
  // NOTE: since I dont understand what text is used in the turnery operators in the component, Im removing the default values and using the values in the component to use the t function. If it breaks this is probably the reason
  bestsellerHeaderAdvice ,
  bestsellerHeaderRightText ,
  hotBrandsHeaderAdvice ,
  hotBrandCounterText, // Will set default based on brandsCount below
  descriptionText,
}: ShopHeadBestsellersProps) => {
  const effectiveBackgroundImage = backgroundImage;
  const hasBrandsCount = typeof brandsCount === 'number';
  const {t} = useTranslation()

  const effectiveBestsellerHeaderAdvice = bestsellerHeaderAdvice || t('brands.bestsellerHeaderAdvice');
  const effectiveHotBrandsHeaderAdvice = hotBrandsHeaderAdvice || t('brands.hotBrandsHeaderAdvice');

  // If hotBrandCounterText is not provided, set it based on brandsCount

  const effectiveHotBrandCounterText = hotBrandCounterText
    || (hasBrandsCount ? `${brandsCount} ${t('brands.brands')}` : t('brands.finestSelection'));
  

  const effectiveBestsellerHeaderRightText = bestsellerHeaderRightText
    || (hasBrandsCount ? `${brandsCount} ${t('brands.brands')}` : t('brands.finestSelection'));

  return (
    <div
      className={styles.shopHeaderBlock}
      style={{
        backgroundImage: `url(${effectiveBackgroundImage})`,
      }}
    >
      <HomepageNavbar />
      <div className={styles.shopHeaderBlockContent}>
        <div className={styles.bestsellerHeaderAdviceContainer}>
          {hasBrandsCount ? (
            <p className={styles.bestsellerHeaderAdvice}>{t('brands.hotBrands')}</p>
          ) : effectiveBestsellerHeaderAdvice.includes('/') ? (
            <p className={styles.bestsellerHeaderAdvice}>
              <a href={`/categories?category=${effectiveBestsellerHeaderAdvice.split('/')[0]}`} className={styles.clickablePart}>
                {effectiveBestsellerHeaderAdvice.split('/')[0]}
              </a>
              {effectiveBestsellerHeaderAdvice.substring(effectiveBestsellerHeaderAdvice.indexOf('/'))}
            </p>
          ) : (
            <p className={styles.bestsellerHeaderAdvice}>{effectiveBestsellerHeaderAdvice}</p>
          )}
          {descriptionText && (
            <div className={styles.descriptionTextContainer}>
              <p className={styles.descriptionText}>{descriptionText}</p>
            </div>
          )}
        </div>

        {!hasBrandsCount ? (
          <div className={styles.bestsellerHeaderRightTextContainer}>
            <p className={styles.bestsellerHeaderRightText}>
              {effectiveBestsellerHeaderRightText}
            </p>
          </div>
        ) : (
          <div className={styles.hotbrandcounter}>
            <p className={styles.hotbrandcountertext}>
              {effectiveHotBrandCounterText}
            </p>
          </div>
        )}

        {hasBrandsCount && (
          <div className={styles.hotBrandsHeaderAdviceContainer}>
            <p className={styles.hotBrandsHeaderAdvice}>
            {effectiveHotBrandsHeaderAdvice}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
