import { useEffect } from 'react';
import userApi from 'service/API/UserApi';
import { useAtom } from 'jotai';
import { userAtom } from 'stores/UserProfile';

/**
 * A simple hook to fetch user favorites and update local state.
 *
 * @param setFavorites - A setter function to update your local "favorites" state.
 */
export function useFetchFavorites(
    setFavorites: (favorites: { [key: string]: boolean }) => void
) {
    const [user] = useAtom(userAtom);
    useEffect(() => {
        if (user) {
            userApi
                .getUserFavoritesProducts()
                .then((response) => {
                    if (response && Array.isArray(response)) {
                        // Convert array of product IDs -> { productId: true }
                        const favoritesMap = response.reduce(
                            (acc: { [key: string]: boolean }, productId: string) => {
                                acc[productId] = true;
                                return acc;
                            },
                            {}
                        );
                        setFavorites(favoritesMap);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching favorite products:', error);
                });
        } else {
            // If no user, optionally reset or keep favorites empty
            setFavorites({});
        }
    }, [user, setFavorites]);
}
