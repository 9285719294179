interface CategoryTranslations {
    [key: string]: {
        en: string;
        fr: string;
    };
}

export const categoryTranslations: CategoryTranslations = {
    "skin care": { en: "Skin Care", fr: "Soins de la peau" },
    "body care": { en: "Body Care", fr: "Soins du corps" },
};

export const subcategoryTranslations: CategoryTranslations = {
    "moisturisers": { en: "Moisturisers", fr: "Crèmes hydratantes" },
    "cleansers": { en: "Cleansers", fr: "Nettoyants" },
    "serums": { en: "Serums", fr: "Sérums" },
    "toners": { en: "Toners", fr: "Tonifiants" },
    "sun care": { en: "Sun Care", fr: "Protection solaire" },
    "lip care": { en: "Lip Care", fr: "Soin des lèvres" },
    "essences": { en: "Essences", fr: "Essences" },
    "mask": { en: "Mask", fr: "Masque" },
};