import React from 'react';
import styles from './OrderTotal.module.scss';
import { IOrder } from 'types/TInterfaces';
import { ProductLoader } from "pages/ProductDetails/ProductLoader";
import { useTranslation } from 'react-i18next';

interface OrderInfoProps {
    order: IOrder | null;
}

export const OrderTotal: React.FC<OrderInfoProps> = ({ order }) => {

    const { t } = useTranslation();

    if (!order) {
        return <ProductLoader />;
    }

    const getFormattedShippingCost = (cost: number | string): string => {
        const shippingCost = typeof cost === 'number' ? cost : parseFloat(String(cost));
        return !isNaN(shippingCost) ? shippingCost.toFixed(2) : '0.00';
    };

    const getSubtotal = (): string => {
        const subtotal = parseFloat(order.totalPrice.toFixed(2)) - parseFloat(getFormattedShippingCost(order.shipping.totalShippingCost));
        return subtotal.toFixed(2);
    };

    const itemsCount = (order: IOrder): number => {
        return order.products.reduce((total, product) => total + product.quantity, 0);
    };

    return (
        <div className={styles.orderTotalContainer}>
            <div className={styles.orderTotal}>
                <div className={styles.row}>
                    <span className={styles.label}>{t('profile.myOrders.orderTotal.subtotal', { count: itemsCount(order) })}</span>
                    <span className={styles.price}>€{getSubtotal()}</span>
                </div>
                <div className={styles.row}>
                    <span className={styles.label}>{t('profile.myOrders.orderTotal.processing')}</span>
                    <span className={styles.price}>€{getFormattedShippingCost(order.shipping.totalShippingCost)}</span>
                </div>
                {(order.discountAmount ?? 0) > 0 && (
                    <div className={styles.row}>
                        <span className={styles.label}>{t('profile.myOrders.orderTotal.discount')}</span>
                        <span className={`${styles.price} ${styles.discountPrice}`}>-€{order.discountAmount?.toFixed(2)}</span>
                    </div>
                )}
                <div className={`${styles.totalRow}`}>
                    <span className={styles.label}>{t('profile.myOrders.orderTotal.total')}</span>
                    <span className={styles.price}>€{order.totalPrice.toFixed(2)}</span>
                </div>
            </div>
        </div>
    );
};
