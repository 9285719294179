import { Link } from 'react-router-dom';
import Userapi from '../../../../service/API/UserApi'
import { useEffect, useState } from 'react';
import productsApi from '../../../../service/API/ProductsApi';
import styles from './style.module.scss';
import { ShopProduct } from '../../../../types/ShopInterfaces';
import { useNavigate } from 'react-router-dom';
import noLikedProductsImage from '../../../../assets/images/mobileEmptyProduct.png';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

export const FavoriteProductSection = () => {
    const [favoriteProducts, setFavoriteProducts] = useState<ShopProduct[]>([]);
    const navigate = useNavigate();

    const {currentLanguage} = useCurrentLanguage()
    const {t} = useTranslation()
    useEffect(() => {
        fetchTwoProducts();
    }, []);

    const fetchTwoProducts = async () => {
        const res = await Userapi.getUserFavoritesProducts();
        if (res.length > 0) {
            const products = await productsApi.getProductsByArrayOfIds(res);
            setFavoriteProducts(products.data || []);
        }
    };

    const handleSeeAll = () => {
        navigate('/profile/favorites');
    };

    const renderProducts = (
        <div className={styles.favoriteProductImagesContainer}>
            {favoriteProducts.slice(0, 3).map((product, index) => (
                <div key={product.id} className={`${styles.favoriteProductCard} ${index === 0 ? styles.large : styles.small}`}>
                    <img
                        className={styles.favoriteProductCardImage}
                        src={product.imgUrl}
                        alt={product.translations?.[currentLanguage]?.title}
                    />
                </div>
            ))}
        </div>
    )

    const renderNoProducts = (
        <div className={styles.noLikedArticles}>
            <div
                className={styles.noLikedArticlesImage}
                style={{ backgroundImage: `url(${noLikedProductsImage})` }}
            >
                <div className={styles.backwidth}>
                    <p className={styles.noLikedArticlesText}>
                       {t('favoriteProductSection.noProductsMessage')}
                    </p>
                </div>
            </div>
        </div>
    );


    const renderContent = () => {
        if (favoriteProducts.length === 0) {
            return renderNoProducts;
        } else {
            return renderProducts;
        }
    };

    return (
        <div className={styles.favoriteProductSection}>
            <div className={styles.favoriteProductContainer}>
                <h1 className={styles.favoriteProductTitle}>{t('favoriteProductSection.title')}</h1>
                <p className={styles.favoriteProductSeeAll} onClick={handleSeeAll}>{t('favoriteProductSection.seeAllButton')}</p>
            </div>
            {renderContent()}
        </div>
    );
};