import React, { useState, useEffect, useCallback, useRef } from 'react';
import './style.scss';
import CountBackground from 'assets/icons/cartCountBackground.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { userAtom } from '../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { handleLogOut } from '../utils/authUtils';
import WhiteLogo from 'assets/icons/whiteLogo.svg';
import Cart from '../../cart/cart';
import { IProductInCart } from '../../../types/TInterfaces';
import { loadLocalCart } from '../../../service/localCartService';
import { cartItemsAtom } from '../../../stores/UserProfile';
import productsApi from 'service/API/ProductsApi';
import Menu from '../Menu/Menu';
import { useTranslation } from 'react-i18next';

interface HomepageNavbarProps {
  customBackAction?: () => void;
  showCustomBack?: boolean;
}

export const HomepageNavbar = ({ customBackAction, showCustomBack }: HomepageNavbarProps) => {
  const [user, setUser] = useAtom(userAtom);
  const [cartItems, setCartItems] = useAtom(cartItemsAtom);
  const [menuVisible, setMenuVisible] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation<string>();

  const menuRef = useRef<HTMLDivElement | null>(null);
  const cartRef = useRef<HTMLDivElement | null>(null);

  const [isMobile, setIsMobile] = useState(false);
  const [freshProducts, setFreshProducts] = useState<{ [productId: string]: any }>({});
  const [lastScrollPos, setLastScrollPos] = useState(0);

  // Page checks
  const isProductPage = location.pathname.includes('/product');
  const isCheckoutPage = location.pathname.includes('/checkout');
  const isLoginPage = location.pathname.includes('/login');
  const isBestsellersPage = location.pathname.includes('/bestsellers');
  const isSignUpPage = location.pathname.includes('/signup');
  const isSignUpForm = location.pathname.includes('/spf-form/');
  const isSpfForm = location.pathname.includes('/spf-form');
  const isFeedPage = location.pathname.includes('/feed');
  const isShopPage = location.pathname.includes('/shop');
  const isProfilePage = location.pathname.includes('/profile');
  const isDossierPage = location.pathname.includes('/feed');
  const isArticlePage = location.pathname.includes('/feed/article');
  const isBrandPage = location.pathname.includes('/brand');
  const isHomePage = location.pathname === '/';

  /**
   *  Scroll-based navbar show/hide
   */
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      // Hide navbar if scrolling down beyond 50px, show if scrolling up
      if (currentScrollPos > lastScrollPos && currentScrollPos > 50) {
        setIsNavbarVisible(false);
      } else if (currentScrollPos < lastScrollPos) {
        setIsNavbarVisible(true);
      }
      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollPos]);

  /**
   *  Mobile check
   */
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   *  Load cart items from user or local storage
   */
  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setCartItems(user.cart || []);
    } else {
      setCartItems(loadLocalCart());
    }
  }, [user, setCartItems]);

  /**
   *  Close cart or menu if user clicks outside
   */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // For cart
      if (cartVisible) {
        const isClickOnOverlay = (event.target as Element).classList.contains('cart-overlay');
        if (
          cartRef.current &&
          !cartRef.current.contains(event.target as Node)
          || isClickOnOverlay
        ) {
          setCartVisible(false);
        }
      }
      // For menu
      if (menuVisible) {
        const isClickOnOverlay = (event.target as Element).classList.contains('menu-overlay');
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target as Node)
          || isClickOnOverlay
        ) {
          setMenuVisible(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible, cartVisible]);

  /**
   *  Logout
   */
  const logout = () => handleLogOut(setUser, navigate);

  /**
   *  Toggling cart visibility
   */
  const toggleCart = () => {
    setCartVisible(!cartVisible);
    fetchFreshProducts();

  };

  /**
   *  Fetch fresh product data for the cart
   */
  const fetchFreshProducts = async () => {
    try {
      // 1) If user is logged in, gather IDs from user.cart
      //    Otherwise, gather from local storage
      let productIds: string[] = [];

      if (user && Object.keys(user).length > 0) {
        productIds = (user.cart || []).map((item: IProductInCart) => item.product._id);
      } else {
        const localCart = loadLocalCart();
        productIds = localCart.map((item) => item.product._id);
      }

      if (productIds.length === 0) return;

      // 2) Fetch fresh data from the server
      const freshData = await productsApi.getProductsByArrayOfIds(productIds);
      const freshProductsMap = freshData.data?.reduce(
        (acc: any, product: any) => {
          acc[product._id] = product;
          return acc;
        },
        {}
      );

      setFreshProducts(freshProductsMap || {});

      // 3) Update the cart
      if (user && Object.keys(user).length > 0 && freshProductsMap) {
        // If user is logged in
        const updatedCart = user.cart.map((cartItem: IProductInCart) => {
          const freshProd = freshProductsMap[cartItem.product._id];
          if (freshProd) {
            return {
              ...cartItem,
              product: {
                ...cartItem.product,
                title: freshProd.title,
                brand: freshProd.brand,
                imgUrl: freshProd.imgUrl,
                price: freshProd.price,
                quantity: freshProd.quantity,
              },
            };
          }
          return cartItem;
        });
        const updatedUser = { ...user, cart: updatedCart };
        setUser(updatedUser);
        setCartItems(updatedCart);
      } else {
        // If user is NOT logged in
        const localCart = loadLocalCart();
        const updatedLocalCartItems = localCart.map((cartItem) => {
          const freshProd = freshProductsMap[cartItem.product._id];
          if (freshProd) {
            return {
              ...cartItem,
              product: {
                ...cartItem.product,
                price: freshProd.price, // only updating price (or any other fields you want)
              },
            };
          }
          return cartItem;
        });

        localStorage.setItem('localCart', JSON.stringify(updatedLocalCartItems));
        setCartItems(updatedLocalCartItems);
      }
    } catch (error) {
      console.error('Error fetching fresh product data:', error);
    }
  };



  /**
   *  Callback to update cart items
   */
  const updateCartItems = useCallback(
    (
      newCartItems:
        | IProductInCart[]
        | ((prevItems: IProductInCart[]) => IProductInCart[])
    ) => {
      setCartItems(newCartItems);
    },
    [setCartItems]
  );

  const cartCount = cartItems.reduce((total, item) => total + item.quantity, 0);

  const handleBackClick = () => {
    if (showCustomBack && customBackAction) {
      customBackAction();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div className={`navbar ${isNavbarVisible ? 'visible' : 'hidden'}`}>
        {/* LEFT LOGO + BACK BUTTON */}
        <div
          className="navbar-left-logo"
        >
          <Link to="/">
            <img className="navbar-logo" src={WhiteLogo} alt="White Logo" />
          </Link>
          {(!isLoginPage &&
            !isShopPage &&
            !isProfilePage &&
            !isHomePage &&
            !isSignUpPage &&
            !isBestsellersPage &&
            ((isSpfForm && isSignUpForm) ||
              isProductPage ||
              isCheckoutPage ||
              isDossierPage ||
              isArticlePage ||
              isBrandPage ||
              isFeedPage ||
              showCustomBack)) && (
            <button onClick={handleBackClick} className="back-button-home">
              &lt; {t('common.back')}
            </button>
          )}
        </div>

        <span style={{marginLeft: '300px'}}>

        </span>
  
        {/* MENU BUTTON (on the right side) */}
        <span
          onClick={() => setMenuVisible(!menuVisible)}
          className="navbar-item-menu"
          style={{ marginRight: '10%' }}
        >
          {t('navbar.menu')}
        </span>

        
  
        {/* RIGHT NAV ITEMS */}
        <nav className="navbar-right">
          {!isShopPage && (
            <Link to={'/shop'} className="navbar-item">
              {t('navbar.shop')}
            </Link>
          )}
  
          {user && Object.keys(user).length > 0 ? (
            !isMobile &&
            !isLoginPage &&
            !isSignUpPage &&
            !isSpfForm &&
            !isCheckoutPage && (
              <Link to="#" onClick={logout} className="navbar-item">
                {t('navbar.log_out')}
              </Link>
            )
          ) : (
            !isMobile &&
            !isLoginPage &&
            !isSignUpPage &&
            !isSpfForm &&
            !isCheckoutPage && (
              <Link to={'/signup'} className="navbar-item">
                {t('navbar.sign_up')}
              </Link>
            )
          )}
  
          {isSignUpForm ? null : ( // Hide cart if signUpForm?
            ((isMobile && isCheckoutPage) || !isCheckoutPage) && (
              <span onClick={toggleCart} className="navbar-item cart-container">
                {t('navbar.cart')}
                <span
                  className="cart-count"
                  style={{ backgroundImage: `url(${CountBackground})` }}
                >
                  {cartCount}
                </span>
              </span>
            )
          )}
        </nav>
      </div>
  
      {/* CART */}
      <div ref={cartRef}>
        <Cart
          isOpen={cartVisible}
          onClose={() => setCartVisible(false)}
          cartItems={cartItems}
          setCartItems={updateCartItems}
        />
      </div>
  
      {/* MENU */}
      <div ref={menuRef}>
        <Menu
          isVisible={menuVisible}
          toggleVisibility={() => setMenuVisible(!menuVisible)}
        />
      </div>
    </>
  );
};
