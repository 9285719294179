import React, { useState } from 'react';
import { useCurrentLanguage } from 'hooks/useLanguage';
import WorldIcon from 'assets/icons/worldIcon.svg'
import DropdownArrow from 'assets/icons/dropdownArrow.svg'
import styles from './LanguageSelector.module.scss';

const LANGUAGES = [
  { code: 'en', label: 'EN' },
  { code: 'fr', label: 'FR' },
];

const LanguageSelector = () => {
  const { currentLanguage, setCurrentLanguage } = useCurrentLanguage();
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageSelect = (langCode: string) => {
    setCurrentLanguage(langCode as 'en' | 'fr');
    setIsOpen(false);
  };

  return (
    <div className={`${styles.container} ${isOpen ? styles.open : ''}`}>
      <div className={styles.languageOptions}>
        <button 
          className={styles.languageButton} 
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={WorldIcon} alt="World" className={styles.worldIcon} />
          <span>{currentLanguage.toUpperCase()}</span>
          <img src={DropdownArrow} alt="Arrow" className={styles.arrowIcon} />
        </button>

        {LANGUAGES.map((lang) => (
          lang.code !== currentLanguage && (
            <button
              key={lang.code}
              className={styles.languageButton}
              onClick={() => handleLanguageSelect(lang.code)}
            >
              <span>{lang.label}</span>
            </button>
          )
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;