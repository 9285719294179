import React, { useEffect, useState } from "react";
import styles from './text.module.scss';
import { useLocation, useNavigate } from "react-router-dom";
import { brandOfTheWeek } from "utils/brandOfTheWeek";
import scrollDownVector from '../../../../assets/icons/whiteVector.svg';
import CollectionApi from "service/API/collectionApi";
import { useTranslation } from "react-i18next";
import { config } from "config";
import { useAtom } from 'jotai';
import { userAtom } from '../../../../stores/UserProfile';

interface Tile {
    id: string;
    name: string;
    description: string;
    image: string;
    products: any[];
    text: string;
}

interface TextHeadSectionProps {
    index: number;
    logos?: string[];
    imagesName: string[];
}

const TextHeadSection: React.FC<TextHeadSectionProps> = ({ imagesName, index, logos = [] }) => {
    const navigate = useNavigate();
    const brandName = brandOfTheWeek;
    const location = useLocation();
    const isShopPage = location.pathname.includes('/shop');
    const [tile, setTile] = useState<Tile[]>([]);
    const { t } = useTranslation()
    const [showText, setShowText] = useState<Boolean>(false); // Controls text appearance
    const [user, setUser] = useAtom(userAtom);

    const TILES_NAMES = ['THE COLLAGEN EDIT']

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const collections = await CollectionApi.getTilesByName(TILES_NAMES);
                // const currentMonth = new Date().toLocaleString('en-US', { month: 'long' });
                const currentMonth= 'April'
                if (collections && Array.isArray(collections)) {
                    // Update the image of the first tile if it exists
                    if (collections.length > 0) {
                        collections[0] = {
                            ...collections[0],
                            image: `${config.REACT_APP_AWS_S3_BUCKET}/dynamicHomePage/${currentMonth}/shopBackground/3/edit.avif`
                        };
                    }
                    setTile(collections);
                }
            } catch (error) {
                console.error("Error fetching collections:", error);
                setTile([]);
            }
        };

        fetchCollections();
    }, []);

    useEffect(() => {
        setShowText(false); // Hide text immediately on index change

        const timeout = setTimeout(() => {
            setShowText(true); // Show text after 500ms delay
        }, 500);

        return () => clearTimeout(timeout); // Cleanup timeout
    }, [index]);

    // Ensure index is within bounds
    if (index < 0 || index >= imagesName.length) {
        console.error("Index out of bounds for imagesName array.");
        return null;
    }

    // Get the current imageName based on the index
    const currentImageName = imagesName[index];

    const handleButtonClick = (brand?: string) => {
        const encodedBrand = brand ? encodeURIComponent(brand) : "";
        switch (currentImageName) {
            case 'MainImage':
                navigate('/feed/article/9195cb41-3603-43db-b8e9-a0789df9fb80');
                break;
            case 'week':
                navigate(`/brand/${encodedBrand}`); break;
            case 'lastImage':
                user ? navigate('/profile/vouchers') : navigate ('/signup');
                break;
            case 'olisEditNew':
                navigate('/shopAll');
                break;
            case 'edit':
                navigate('/editPage', { state: { tile: tile[0] } });
                break;
            default:
                navigate('/feed');
                break;
        }
    };

    const renderButton = () => {
        let buttonText = t('common.readMore');
        if (currentImageName === 'week') {
            buttonText = t('textHeadSection.shopBrand', { brandName });
        } else if (currentImageName === 'lastImage') {
            buttonText = user ?t('common.vouchers') : t('common.registerNow');
        } else if (currentImageName === 'olisEditNew') {
            buttonText = t('common.shopAll');
        } else if (currentImageName === 'edit') {
            buttonText = `${t('common.shop')} ${tile[0]?.name}`;
        }

        return (
            <div className={styles.buttonContainer}>
                <button onClick={() => handleButtonClick(currentImageName === 'week' ? brandName : undefined)}
                    className={`${styles.button}`}>
                    {buttonText}
                </button>
            </div>
        );
    };

    const scrollDownSection = (
        <div className={styles.scrollDownSection}>
            <div className={styles.imageWrapper}>
                <img
                    src={scrollDownVector}
                    alt="scroll down"
                    className={styles.scrollDownVector}
                />
                <p className={styles.overlayText}>{t('common.scrollDown')}</p>
            </div>
        </div>
    );

    // Define the sections with unique imageName identifiers
    const sections: { imageName: string; content: JSX.Element }[] = [
        {
            imageName: 'MainImage',
            content: (
                <div className={styles.middleContainer}>
                    <h1 className={styles.indexZeroTitle}>{t('textHeadSection.introducingOli')}</h1>
                    <div className={styles.indexZeroTextContainer}>
                        <span className={styles.indexZeroText}>
                            {t('textHeadSection.introducingOliText')}
                        </span>
                    </div>
                    <div className={styles.buttonLocation}>
                        {renderButton()}
                    </div>
                    {scrollDownSection}
                </div>
            )
        },
        {
            imageName: 'week',
            content: (
                <div className={styles.weekContainer}>

                    <div className={styles.middleContainerOne}>
                        <h1 className={styles.indexOneTitle}>{t('textHeadSection.discoverBeautyEssentials')}</h1>
                        <img src={logos[0]} alt="logo" className={styles.indexOneTitleImage} />
                        <h1 className={styles.indexOneTitleRight}>{t('textHeadSection.uncoverOliSelection')}</h1>

                    </div>
                    <div className={styles.buttonLocationWeek}>
                        {renderButton()}
                    </div>
                    {scrollDownSection}
                </div>


            )
        },
        {
            imageName: 'lastImage',
            content: (
                <>
                    <div className={styles.middleContainerTwo}>
                        <div className={styles.indexTwoTitleContainer}>
                            <h1 className={!isShopPage ? styles.titleIndexTwo : styles.titleIndexTwoShop}>{t('textHeadSection.tenPercentOff')}</h1>
                        </div>
                        <div className={styles.indexTwoTextContainer}>
                            <span className={`${!isShopPage ? styles.indexTwoText : styles.indexTwoTextShop}`}>
                                {t('textHeadSection.tenPercentOffText')}
                            </span>
                            {renderButton()}
                        </div>
                    </div>
                    <div className={styles.lastImageVector}>
                        {scrollDownSection}
                    </div>
                </>
            )
        }, {
            imageName: 'edit',
            content: (
                <>
                    <div className={styles.middleContainer}>
                        <h1 className={styles.editTitle}>The collagen edit</h1>
                        <div className={styles.editTextContainer}>
                            <span className={styles.editText}>Boost bounce and firm your glow with our collagen-activating favourites.</span>
                        </div>
                        <div className={styles.buttonLocation}>
                            {renderButton()}
                        </div>
                        {scrollDownSection}
                    </div>
                </>
            )
        }, {
            imageName: 'olisEditNew',
            content: (
                <>
                    <div className={styles.middleContainer}>
                        <h1 className={styles.editTitle}>{t('textHeadSection.olisEdit')}</h1>
                        <div className={styles.editTextContainer}>
                            <span className={styles.editText}>
                                {t('textHeadSection.olisEditText')}                           </span>
                        </div>
                        <div className={styles.buttonLocation}>
                            {renderButton()}
                        </div>
                        {scrollDownSection}
                    </div>
                </>
            )
        }
    ];


    // Find the section that matches the currentImageName
    const matchedSection = sections.find(section => section.imageName === currentImageName);

    if (!matchedSection) {
        // console.warn(`No section found for imageName: ${currentImageName}`);
        return null;
    }

    return (
        <div className={styles.textHeadSection}>
            {showText && matchedSection.content} {/* Delayed appearance of content */}
        </div>

    );
};

export default TextHeadSection;
