import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';
import userApi from 'service/API/UserApi';
import { NotionArticle } from 'hooks/useNotionArticles.types';
import articleApi from 'service/API/articleApi';
import filledFav from 'assets/icons/whitFullFavIcon.svg';
import eggShape from '../../../assets/icons/blackVector.svg';

import { Link, useNavigate } from 'react-router-dom';
import { useFetchFavoriteArticles } from 'hooks/fetchFavoritesArticles';
import { debounce } from 'utils/debounceUtil';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';
const SavedArticles = () => {
    const [articles, setArticles] = useState<NotionArticle[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const isMobile = window.innerWidth < 768;
    const [user] = useAtom(userAtom);
    //TODO: get currentLanguage from context
    const {currentLanguage} = useCurrentLanguage();
    const handleFavorite = async (articleId: string) => {
        await userApi.updateUserFavoriteArticle(articleId, 'remove');
        fetchUserFavorites();
    }
    const debouncedRemoveFavorite = debounce(handleFavorite, 300); // Debounce function with 300ms delay
    const {t} = useTranslation() 


    const fetchUserFavorites = async () => {
        setIsLoading(true);
        try {
            const res = await userApi.getUserFavoritesArticles();
            const response = await articleApi.getArticlesByArrayOfIds(res);

            if (response.success && Array.isArray(response.data)) {
                setArticles(response.data as NotionArticle[]);
            } else {
                console.error('Invalid response structure:', response);
                setArticles([]);
            }
        } catch (error) {
            console.error('Error fetching favorite articles:', error);
            setArticles([]);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (user) {
            fetchUserFavorites();
        }
    }, []);



    const desktopLayout = (
        <>
            <HomepageNavbar />
            <div className={styles.desktopContainer}>
                <div className={styles.topSection}>
                    <div className={styles.menuTopSection}>
                        {/* PLACE HOLDER DONT REMOVE */}
                    </div>
                    <div className={styles.contentTopSection}>
                        <h3 className={styles.userName}>{user?.name}</h3>
                        <h3 className={styles.userName}>{user?.lastName}</h3>
                    </div>
                </div>

                <div className={styles.bottomSection}>
                    <div className={styles.menuSection}>
                        <ProfileMenu />
                    </div>
                    <div className={styles.contentSection}>
                        <div className={styles.articlesContainer}>
                            <div className={styles.articlesTitleContainer}>
                                <h1 className={styles.title}>{t('profile.savedArticles.title')}</h1>
                            </div>
                            {!isLoading && articles.length === 0 ? (
                                <div className={styles.noArticlesFound}>
                                    <img src={eggShape} alt="Egg Shape" className={styles.eggShapeImage} />
                                    <div className={styles.noArticlesTextContainer}>
                                        <h3 className={styles.noArticlesTitle}>{t('profile.savedArticles.noArticles')}</h3>
                                        <p className={styles.noArticlesSubtitle}>{t('profile.savedArticles.readyToReveal')}</p>
                                    </div>
                                    <div className={styles.noArticlesButtonContainer} onClick={() => navigate('/feed')}>
                                        <button className={styles.noArticlesButton} >disover the feed</button>
                                    </div>
                                </div>
                            ) : (
                                articles.map((article) => (
                                    <div className={styles.articleCard} onClick={() => navigate(`/feed/article/${article.id}`)} style={{ backgroundImage: `url(${article.translations[currentLanguage].properties?.Image?.files[0]?.url})` }}>
                                        <div className={styles.headerContainer}>
                                            <div className={styles.dossierContainer}>
                                                <h3 className={styles.dossierTitle}>{article.translations[currentLanguage].dossierNames.slice(0, 1).join(', ')}</h3>
                                            </div>
                                            <div className={styles.articleFavIcon}>
                                                <img
                                                    className={styles.articleFavIconImg}
                                                    src={filledFav}
                                                    alt="favorite"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        debouncedRemoveFavorite(article._id);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.articleInfo}>
                                            <h3 className={styles.articleTitle}>{article.translations[currentLanguage].properties?.Title?.title[0]?.plain_text}</h3>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
    const mobileLayout = (
        <>
            <div>
                <div className={styles.mobileContainer}>
                    <HomepageNavbar />
                    <div className={styles.mobileHeaderSection}>
                        <div className={styles.articlesContainer}>
                            <div className={styles.articlesTitleContainer}>
                                <h1 className={styles.title}>{t('profile.savedArticles.title')}</h1>
                            </div>
                            {!isLoading && articles.length === 0 ? (
                                <div className={styles.noArticlesFound}>
                                    <img src={eggShape} alt="Egg Shape" className={styles.eggShapeImage} />
                                    <div className={styles.noArticlesTextContainer}>
                                        <h3 className={styles.noArticlesTitle}>{t('profile.savedArticles.noArticles')}</h3>
                                        <p className={styles.noArticlesSubtitle}>{t('profile.savedArticles.readyToReveal')}</p>
                                    </div>
                                    <div className={styles.noArticlesButtonContainer} onClick={() => navigate('/feed')}>
                                        <button className={styles.noArticlesButton} >{t('profile.savedArticles.discoverFeed')}</button>
                                    </div>
                                </div>
                            ) : (
                                articles.map((article) => (
                                    <div className={styles.articleCard} onClick={() => navigate(`/feed/article/${article.id}`)} style={{ backgroundImage: `url(${article.translations[currentLanguage].properties?.Image?.files[0]?.url})` }}>
                                        <div className={styles.headerContainer}>
                                            <div className={styles.dossierContainer}>
                                                <h3 className={styles.dossierTitle}>{article.translations[currentLanguage].dossierNames.slice(0, 1).join(', ')}</h3>
                                            </div>
                                            <div className={styles.articleFavIcon}>
                                                <img
                                                    className={styles.articleFavIconImg}
                                                    src={filledFav}
                                                    alt="favorite"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleFavorite(article._id);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.articleInfo}>
                                            <h3 className={styles.articleTitle}>{article.translations[currentLanguage].properties?.Title?.title[0]?.plain_text}</h3>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    return (
        isMobile ? mobileLayout : desktopLayout
    );
};

export default SavedArticles;