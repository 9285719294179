import React, { useState } from 'react';
import styles from './ComingSoonPage.module.scss';
import whiteLogo from '../../assets/icons/whiteLogo.svg'
import { ComingSoonCarousel } from './ComingSoonProductsCarousel/ComingSoonCarouselShell'
import celimax from '../../assets/brands/Celimax/celimax_desktop.png'
import midnight from '../../assets/brands/Midnight/midnight_desktop.png';
import agentnateur from '../../assets/brands/AgentNateur/an_coming_soon.png'
import nuTexture from '../../assets/brands/NuTexture/nt_coming_soon.png'
import hyeja from '../../assets/brands/Hyeja/heyja_desktop.png'
import ameon from '../../assets/brands/Ameon/a_coming_soon.png'
import celimaxTitle from '../../assets/brands/Celimax/celimax_title.png'
import midnightTitle from '../../assets/brands/Midnight/midnight_title.png';
import agentnateurTitle from '../../assets/brands/AgentNateur/an_title_coming_soon.png'
import nuTextureTitle from '../../assets/brands/NuTexture/NuTexture_title.png'
import hyejaTitle from '../../assets/brands/Hyeja/hyeja_title.png'
import ameonTitle from '../../assets/brands/Ameon/aemon_title.png'
import { LatestTrends } from 'components/homepage/LatestTrends/LatestTrends';
import { BrownBox } from './brownBox';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import userApi from 'service/API/UserApi';
import subscribedUserApi from 'service/API/subscribedUserApi';

/** 
 * A simple regex-based email validation. 
 * Replace this or import your own "validateEmail" utility as you see fit.
 */
const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
};

export const ComingSoonPage = () => {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    /**
     * Tracks whether the user has clicked the "Join newsletter" button.
     * We only show validation errors after this is true.
     */
    const [hasClickedSubscribe, setHasClickedSubscribe] = useState(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        // Clear any previous error whenever the user changes input
        setError(false);
        setErrorMessage('');
    };

    const handleButtonClick = async () => {
        // Once clicked, we'll consider "subscribe" attempt made
        setHasClickedSubscribe(true);

        // Validate only on subscribe attempt
        if (!validateEmail(email)) {
            setError(true);
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        // If the email is valid, check if already subscribed
        const isSubscribed = await subscribedUserApi.checkIfSubscribed(email);
        if (isSubscribed) {
            setError(true);
            setErrorMessage('You are already subscribed to our newsletter.');
            return;
        }

        // Otherwise, do subscription
        userApi.newsletterSubscribe(email);
        subscribedUserApi.subscribe(email);
        setIsSubmitted(true);
    };

    return (
        <div className={styles.comingSoonPage}>
            {/* TOP SECTION */}
            <div className={styles.topSection}>
                <div className={styles.topSectionContentContainer}>
                    <div className={styles.logoContainer}>
                        <img src={whiteLogo} alt='logo' />
                    </div>
                    <div className={styles.titleContainer}>
                        <h3 className={styles.title}>COMING SOON</h3>
                    </div>
                    <div className={styles.textContainer}>
                        <span className={styles.text}>
                            Sign up to have exclusive access to our launch preview & introductory offer
                        </span>
                    </div>

                    {/* SUBSCRIBE FORM CONTAINER */}
                    <div className={`${styles.formContainer} ${isSubmitted ? styles.right : ''}`}>
                        {isSubmitted ? (
                            // If already subscribed successfully
                            <div className={styles.successContainer}>
                                <div className={styles.successMessage}>
                                    <span>Thank you for subscribing!</span>
                                </div>
                            </div>
                        ) : (
                            // The subscription form
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault(); // prevent page refresh
                                    handleButtonClick();
                                }}
                            >
                                <div className={styles.emailInputContainer}>
                                    <input
                                        className={styles.emailInput}
                                        placeholder="ENTER YOUR EMAIL"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                    <button className={styles.submitBtn} type="submit">
                                        Join newsletter
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>

                    {/* Show error message ONLY after user clicks subscribe */}
                    {error && hasClickedSubscribe && (
                        <div className={styles.errorMessage}>{errorMessage}</div>
                    )}
                </div>
            </div>

            {/* BOTTOM SECTION */}
            <div className={styles.bottomSection}>
                <div className={styles.productSelectionContainer}>
                    <h3 className={styles.subTitle}>product selection</h3>
                    <span className={styles.productSelectionText}>
                        At Oli’s Lab, we believe that skincare is more than just a routine;
                        it is an essential part of self-care. Here is a sneak peek of just
                        a few of the products you can expect to find here..
                    </span>
                </div>
            </div>

            <div>
                <ComingSoonCarousel />
            </div>

            <div className={styles.bottomSection}>
                <div className={styles.comingBrandsContainer}>
                    <div className={styles.comingBrandsHeadContainer}>
                        <h3 className={styles.subTitle}>Brands coming soon</h3>
                        <span className={styles.productSelectionText}>
                            Here are some innovative brands we carefully selected
                        </span>
                    </div>
                    <div className={styles.brandsImagesContainer}>
                        {/* Example brand images */}
                        <div className={styles.brandImage}>
                            <img src={celimax} alt="Brand 1" />
                            <img className={styles.imgBrandLogo} src={celimaxTitle} alt="Brand title 1" />
                        </div>
                        <div className={styles.brandImage}>
                            <img src={midnight} alt="Brand 2" />
                            <img className={styles.imgBrandLogo} src={midnightTitle} alt="Brand title 2" />
                        </div>
                        <div className={styles.brandImage}>
                            <img src={hyeja} alt="Brand 3" />
                            <img className={styles.imgBrandLogo} src={hyejaTitle} alt="Brand title 3" />
                        </div>
                        <div className={styles.brandImage}>
                            <img src={nuTexture} alt="Brand 4" />
                            <img className={styles.imgBrandLogo} src={nuTextureTitle} alt="Brand title 4" />
                        </div>
                        <div className={styles.brandImage}>
                            <img src={agentnateur} alt="Brand 5" />
                            <img className={styles.imgBrandLogo} src={agentnateurTitle} alt="Brand title 5" />
                        </div>
                        <div className={styles.brandImage}>
                            <img src={ameon} alt="Brand 6" />
                            <img className={styles.imgBrandLogo} src={ameonTitle} alt="Brand title 6" />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.latestTrendsContainer}>
                <LatestTrends isComingSoonPage={true} />
            </div>
            <div>
                <BrownBox />
            </div>
            <div>
                <FooterSection isComingSoonPage={true} />
            </div>
        </div>
    );
};
