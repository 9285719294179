import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styles from './styles.module.scss';
import { ShopProduct } from 'types/ShopInterfaces';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { userAtom } from '../../stores/UserProfile';

interface Tile {
    preTitle: string;
    title: string;
    subTitle: string;
    image: string;
    link?: string;
    products?: ShopProduct[];
}

interface EditSectionProps {
    tiles: Tile[];
    isHomePage?: boolean;
}

export const EditSection: React.FC<EditSectionProps> = ({ tiles, isHomePage }) => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const isMobile = window.innerWidth < 768;
    const isLargeScreen = window.innerWidth >= 1500;
    const isBrandPage = window.location.pathname.includes('/brand');
    const [user, setUser] = useAtom(userAtom);
    const handleNavigation = (tile: Tile) => {
        if (tile.title.includes('BEST')) {
            navigate('/bestsellers');
        } else if (tile.title.includes('10%')) {
            user ? navigate('/profile/vouchers') : navigate ('/signup');
        } else if (isBrandPage) {
            if (tile.link && tile.link.trim()) {
                // If link is a non-empty string, navigate to /feed/article/<link>
                navigate(`/feed/article/${tile.link.trim()}`);
            } else {
                // Otherwise, navigate to /feed
                navigate('/feed');
            }

        } else {
            navigate('/editPage', { state: { tile } });
        }
    };

    return (
        <div className={styles.editSectionContainer}>
            <Swiper
                spaceBetween={isMobile ? 0 : isLargeScreen ? 500 : 300}
                breakpoints={{
                    640: {
                        slidesPerView: 1.1,
                    },
                    768: {
                        slidesPerView: 1.2,
                    },
                    1024: {
                        slidesPerView: 2.5,
                    },
                }}
                loop={true}
                centeredSlides={true}
                className={styles.swiperContainer}
            >
                {[...tiles, ...tiles].map((tile, index) => (
                    <SwiperSlide key={index}>
                        <div className={styles.tilesSection}>
                            <div className={styles.tileBackground}>
                                <img src={tile.image} className={styles.tileImage} style={{ objectFit: 'cover' }} />
                                <div className={styles.tileContent}>
                                    <span className={styles.preTitle}>{tile.preTitle}</span>
                                    <h2 className={styles.title}>{tile.title}</h2>
                                    <p className={styles.subTitle}>{tile.subTitle}</p>
                                </div>
                                <div className={styles.buttonContainer}>
                                    <button
                                        className={styles.button}
                                        onClick={() => handleNavigation(tile)} // Always clickable
                                    >
                                        {tile.title.includes('10%')
                                            ? user ?t('common.vouchers') : t('common.registerNow')
                                            : isHomePage
                                                ? `${t('common.shopAll')} ${tile.title}`
                                                : t('common.readMore')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
