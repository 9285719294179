import { useTranslation } from 'react-i18next'
import './style.scss'
import Dot from 'assets/icons/blackDot.svg'

export const AdviceSection = () => {
    const {t} = useTranslation()
    return (
        <div className="AdviceSectionContainer">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <p className='adviceHeader'>
                {t('homepage.adviceSection.header')}
                </p>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <div style={{ backgroundImage: `url(${Dot})`, height: 8.34, width: 9.02, objectFit: 'contain', marginRight: 10, marginTop: 3.5 }} />
                    <p className='createPerfRoutineText'>{t('homepage.adviceSection.createRoutine')}</p>
                </div>
            </div>

            <div>
                <div className='lowerContainer'>
                    <div style={{ backgroundImage: `url(${Dot})`, marginBottom: 10, height: 8.34, width: 9.02, objectFit: 'contain', marginRight: 10, marginTop: -1 }} />
                    <p className='AdviceSectionContainerDescription'>{t('homepage.adviceSection.description')}</p>
                </div>
            </div>

        </div>
    )
}