import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';

interface Tile {
  image: string;
  name: string;
  description: string;
  route?: string; // Optional navigation route
  pageTitle?: string;
  headerBackground?: string;
  text?: string;
  products?: any[];
}

interface PopularCategoriesProps {
  tiles: Tile[];
}

export const PopularCategories: React.FC<PopularCategoriesProps> = ({ tiles }) => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;
  const [localTiles, setLocalTiles] = useState<Tile[]>(tiles);
  const [user, setUser] = useAtom(userAtom);
  const { t } = useTranslation()

  useEffect(() => {
    setLocalTiles(tiles);
  }, [tiles])

  const handleNavigation = (tile: Tile) => {
    navigate('/editPage', { state: { tile } });
  };

  const handleItem = (name: string, tile: Tile) => {
    if (name.includes('10%')) {
      user ? navigate('/profile/vouchers') : navigate ('/signup');;
    } else {
      navigate('/editPage', { state: { tile } });
    }
  };

  return (
    <div className={styles.PopularCategoriesContainer}>
      <div className={styles.PopularCategoriesHeader}>
        <p className={styles.PopularCategoriesHeaderText}>{t('categories.popularCategories')}</p>
        <p className={styles.PopularCategoriesHeaderText}>{t('categories.count', { count: localTiles.length })}</p>
      </div>
      <Swiper
        spaceBetween={10}
        slidesPerView={isMobile ? 1.2 : 3}
        className={styles.PopularCategoriesCardsContainer}
      >
        {localTiles.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className={styles.PopularCategoriesCard}
              style={{ backgroundImage: `url(${item.image})`, cursor: 'pointer' }}
              onClick={() => {
                if (item.route) {
                  navigate(item.route, {
                    state: {
                      customTitle: item.pageTitle,
                      headerBackground: item.headerBackground
                    }
                  });
                }
              }}
            >
              {/* Fixed syntax here: */}

              <div
                className={styles.PopularCategoriesCardContent}
                onClick={() => handleItem(item.name, localTiles[index])}
              >
                <p className={styles.exporOur}>{item.text}</p>
                <p className={styles.PopularCategoriesCardText}>
                  {item.name}
                </p>

              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
