import axios from 'axios';
import { getDefaultStore } from 'jotai';
import { tokenExpiredAtom } from 'stores/TokenExpired';
import { userAtom } from 'stores/UserProfile';
import { config } from '../../config'

const api = axios.create({
  baseURL: config.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      error.response?.data?.error !== 'Login failed! Check authentication credentials'
    ) {
      handle401Error();
    }
    return Promise.reject(error);
  }
);

function handle401Error() {
  const store = getDefaultStore();
  const isAlertDisplayed = store.get(tokenExpiredAtom);

  if (isAlertDisplayed) return;

  localStorage.clear();
  store.set(userAtom, null);
  store.set(tokenExpiredAtom, true);
}

export default api;
