import React, { useRef, useState, useEffect } from 'react';
import HowItWorksButton from '../HowItWorksButton';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import styles from './styles.module.scss';
import scannerMask from '../../../assets/images/scannerMask.svg';
import scannerMaskDesktop from '../../../assets/images/scannerMaskDesktop.svg';
import scannerBlob from '../../../assets/icons/scannerBlob.svg';
import scannerBlobDesktop from '../../../assets/icons/scannerBlobDesktop.svg';
import GeneralButton from '../GeneralButton';
import SwitchMethodButton from '../SwitchMethodButton ';
import scanIconBlack from '../../../assets/icons/scanIconBlack.svg';
import galleryIcon from '../../../assets/icons/galleryIcon.svg';
import manualInputIcon from '../../../assets/icons/ManualInputIcon.svg';
import takeActionIcon from '../../../assets/icons/TakeActionIcon.svg';
import { IScanData } from 'types/IIngredientsScanner';
import ScanModal from '../ScanModal';
import { processImage } from '../../../utils/imageProcessing';

interface ScannerStepProps {
  onDataCapture: (data: IScanData) => void;
  onFileUpload: (file: File) => void;
}

function ScannerStep({ onDataCapture, onFileUpload }: ScannerStepProps) {
  const [isScanning, setIsScanning] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 1001;
  const camRef = useRef<Webcam>(null);
  const [isCameraReady, setIsCameraReady] = useState(false);

  const handleUserMedia = () => {
    setIsCameraReady(true);
  };

  const handlePhotoWasTaken = async () => {
    if (!camRef.current) return;
    if (!isCameraReady) return;

    try {
      const video = camRef.current.video;
      if (!video) {
        console.error('Video element not found');
        return;
      }

      const { file } = processImage(video);

      // Pass the captured image to parent component
      onDataCapture({
        type: 'IMAGE',
        data: file,
      });
    } catch (error) {
      console.error('Error capturing photo:', error);
    }
  };

  const handleFileInputClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        onFileUpload(file);
      }
    };
    input.click();
  };
  const ingredientsInputRef = useRef<HTMLTextAreaElement>(null);

  function handleAnalyseData(data: string) {
    const dataArr = data.split(',').map((text) => text.trim());
    onDataCapture({ type: 'MANUAL', data: dataArr });
  }

  const renderMobile = () => {
    return isScanning ? (
      <div className={styles.scannerContainer}>
        <h1 className={styles.title}>{t('ingredientsScan.scanner.scan.title')}</h1>
        <div className={styles.generalContainer}>
          <Webcam
            className={styles.webcam}
            ref={camRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: { ideal: 'environment' },
              width: { min: 640, ideal: 1280, max: 1920 },
              height: { min: 480, ideal: 720, max: 1440 },
              aspectRatio: { ideal: 4 / 3 },
              frameRate: { ideal: 25 },
            }}
            imageSmoothing={true}
            screenshotQuality={1}
            onUserMedia={handleUserMedia}
          />

          {isCameraReady && (
            <div className={styles.overlay}>
              <img src={scannerMask} />
              <img className={styles.blob} src={scannerBlob} />
            </div>
          )}
          <div className={styles.scanButtonsWrapper}>
            <div className={styles.scanButtonsContainer}>
              <SwitchMethodButton onClick={handleFileInputClick} icon={galleryIcon} />
              <GeneralButton text={t('ingredientsScan.scanner.scan.cta')} onClick={() => handlePhotoWasTaken()} icon={takeActionIcon} />
              <SwitchMethodButton onClick={() => setIsScanning(false)} icon={manualInputIcon} />
            </div>
          </div>
        </div>
        <HowItWorksButton onClick={() => setIsModalOpen(true)} />
      </div>
    ) : (
      <div className={styles.scannerContainer}>
        <h1 className={styles.title}>{t('ingredientsScan.scanner.manual.title')}</h1>
        <div className={styles.generalContainer}>
          <textarea ref={ingredientsInputRef} className={styles.ingredientsInput} placeholder={t('ingredientsScan.scanner.manual.placeholder')} rows={8} value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
          <div className={styles.scanButtonsWrapper}>
            <div className={styles.scanButtonsContainer}>
              <SwitchMethodButton onClick={handleFileInputClick} icon={galleryIcon} />
              <GeneralButton
                onClick={() => handleAnalyseData(inputValue)}
                text={t('ingredientsScan.scanner.manual.cta')}
                icon={takeActionIcon}
                disabled={!inputValue.trim()} // Button is disabled when input is empty or only whitespace
              />
              <SwitchMethodButton onClick={() => setIsScanning(true)} icon={scanIconBlack} />
            </div>
          </div>
        </div>
        <HowItWorksButton onClick={() => setIsModalOpen(true)} />
      </div>
    );
  };

  const renderDesktop = () => {
    return isScanning ? (
      <div className={styles.wrapper}>
        <div className={styles.scannerContainer}>
          <h2 className={styles.mainTitle}>{t('ingredientsScan.scanner.mainTitlePc')}</h2>

          <h1 className={styles.title}>{t('ingredientsScan.scanner.scan.title')}</h1>
          <div className={styles.generalContainer}>
            <Webcam
              className={styles.webcam}
              ref={camRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                facingMode: { ideal: 'environment' },
                width: { min: 640, ideal: 1280, max: 1920 },
                height: { min: 480, ideal: 720, max: 1080 },
                aspectRatio: { ideal: 16 / 9 },
                frameRate: { ideal: 25 },
              }}
              imageSmoothing={true}
              screenshotQuality={1}
              onUserMedia={handleUserMedia}
            />

            {isCameraReady && (
              <div className={styles.overlay}>
                <img src={scannerMaskDesktop} />
                <img className={styles.blob} src={scannerBlobDesktop} />
              </div>
            )}
            <div className={styles.scanButtonsWrapper}>
              <div className={styles.scanButtonsContainer}>
                <SwitchMethodButton onClick={() => setIsScanning(false)} icon={manualInputIcon} text={t('ingredientsScan.scanner.scan.enterManually')} />
                <GeneralButton text={t('ingredientsScan.scanner.scan.cta')} icon={takeActionIcon} onClick={() => handlePhotoWasTaken()} />
                <SwitchMethodButton onClick={handleFileInputClick} icon={galleryIcon} text={t('ingredientsScan.scanner.scan.gallery')} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.howItWorksButtonWrapper}>
          <HowItWorksButton onClick={() => setIsModalOpen(true)} />
        </div>
      </div>
    ) : (
      <div className={styles.scannerContainer}>
        <h2 className={styles.mainTitle}>{t('ingredientsScan.scanner.mainTitlePc')}</h2>
        <h1 className={styles.title}>{t('ingredientsScan.scanner.manual.titlePc')}</h1>
        <div className={`${styles.generalContainer} ${styles.textInputContainer}`}>
          <div className={styles.inputContainer}>
            <textarea ref={ingredientsInputRef} className={styles.ingredientsInput} placeholder={t('ingredientsScan.scanner.manual.placeholderPc')} rows={8} value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
          </div>
          <div className={styles.scanButtonsWrapper}>
            <div className={styles.analyseButton}>
              <GeneralButton
                onClick={() => handleAnalyseData(inputValue)}
                text={t('ingredientsScan.scanner.manual.cta')}
                icon={takeActionIcon}
                disabled={!inputValue.trim()} // Button is disabled when input is empty or only whitespace
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Clean up camera ready state when switching modes
  useEffect(() => {
    if (!isScanning) {
      setIsCameraReady(false);
    }
  }, [isScanning]);

  return (
    <div>
      {isMobile ? renderMobile() : renderDesktop()}
      {isModalOpen && <ScanModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} handleSetNextStep={() => setIsScanning(false)} />}
    </div>
  );
}

export default ScannerStep;
