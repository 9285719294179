import React from 'react';
import styles from './styles.module.scss';
import ethos from 'assets/images/ethos.png';
import { useTranslation } from 'react-i18next';

export const EthosSection = () => {
    const {t} = useTranslation()
    const handleReadClick = (): void => {
        window.location.href = '/feed/article/1854bf4c-7fa1-80db-9eaa-f4d338098297';
    };
    return (
        <div
            className={styles.ethosSection} // Reference your SCSS styles
        >
            <div className={styles.ethosInnerContainer}>
                <h1 className={styles.title}>{t('ethosSection.title')}</h1>
                <p className={styles.text}>{t('ethosSection.description')}</p>
                <button className={styles.learnMoreButton} onClick={handleReadClick}>
                    {t('ethosSection.learnMore')}
                </button>
            </div>
        </div>
    );
};
