import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import GeneralButton from './GeneralButton';
import scanIcon from '../../assets/icons/scanIcon.svg';
import ingredientsList from '../../assets/images/ingredientsList.png';
import outerBlob from '../../assets/icons/outerBlob.svg';

interface ScanModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSetNextStep: () => void;
}

function ScanModal({ isOpen, onClose, handleSetNextStep }: ScanModalProps) {
  const { t } = useTranslation();
  if (!isOpen) return null;

  const isMobile = window.innerWidth < 1001;

  const handleScanClick = () => {
    onClose();
    handleSetNextStep();
  };

  const handleBackdropClick = (e: React.MouseEvent) => {
    // Only close if clicking the backdrop, not the modal itself
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const renderMobile = () => (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h1 className={styles.modalTitle}>{t('ingredientsScan.landing.howItWorks')}</h1>
        <div className={styles.stepsContainer}>
          <p className={styles.modalStep}>{t('ingredientsScan.modal.step1')}</p>
          <p className={styles.modalStep}>{t('ingredientsScan.modal.step2')}</p>
          <p className={styles.modalStep}>{t('ingredientsScan.modal.step3')}</p>
          <p className={styles.modalStep}>{t('ingredientsScan.modal.step4')}</p>
          <p className={styles.modalStep}>{t('ingredientsScan.modal.step5')}</p>
          <p className={styles.modalStep}>{t('ingredientsScan.modal.step6')}</p>
        </div>
        <div className={styles.buttonWrapper}>
          <div className={styles.buttonContainer}>
            <GeneralButton text={t('ingredientsScan.landing.scanButton')} icon={scanIcon} onClick={handleScanClick} />
          </div>
        </div>
      </div>
    </div>
  );

  const renderDesktop = () => (
    <div className={styles.modalWrapper} onClick={handleBackdropClick}>
      <div className={styles.modalOverlay}>
        <span onClick={onClose} className={styles.closeButton}>
          {t('common.close')}
        </span>
        <div className={styles.blobWrapper}>
          <img src={outerBlob} alt="blob border" className={styles.outerBlob} />
          <div className={styles.innerBlob}>
            <img src={ingredientsList} alt="ingredients list" className={styles.maskedImage} />
          </div>
        </div>
        <div className={styles.modalContent}>
          <h1 className={styles.modalTitle}>{t('ingredientsScan.landing.howItWorks')}</h1>
          <div className={styles.stepsContainer}>
            <p className={styles.modalStep}>{t('ingredientsScan.modal.step1')}</p>
            <p className={styles.modalStep}>{t('ingredientsScan.modal.step2')}</p>
            <p className={styles.modalStep}>{t('ingredientsScan.modal.step3')}</p>
            <p className={styles.modalStep}>{t('ingredientsScan.modal.step4')}</p>
            <p className={styles.modalStep}>{t('ingredientsScan.modal.step5')}</p>
            <p className={styles.modalStep}>{t('ingredientsScan.modal.step6')}</p>
          </div>
          <div className={styles.buttonWrapper}>
            <div className={styles.buttonContainer}>
              <GeneralButton text={t('ingredientsScan.modal.ctaButton')} onClick={handleScanClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return <>{isMobile ? renderMobile() : renderDesktop()}</>;
}

export default ScanModal;
