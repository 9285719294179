import React from 'react';
import { config } from '../../../../../config';
import styles from './ContactBox.module.scss';
import { useTranslation } from 'react-i18next';

export const ContactBox: React.FC = () => {
    const handleEmail = (subject: string) => {
        const email = config.REACT_APP_SUPPORT_EMAIL;
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
        window.location.href = mailtoLink;
    };
    const {t} = useTranslation()

    const handleRead = () => {
        window.open(config.REACT_APP_TERMS_OF_USE_URL, '_blank');
    }

    return (
        <div className={styles.contactBox}>
            <div className={styles.contentContainer}>
                <div className={styles.contactItem}>
                    <h5 className={styles.itemTitle}>{t('profileSettings.contactBox.termsTitle')}</h5>
                    <button className={styles.btnAction} onClick={handleRead}>{t('profileSettings.contactBox.readButton')}</button>
                </div>
                <div className={styles.dividerInBox} />

                <div className={styles.contactItem}>
                    <h5 className={styles.itemTitle}>{t('profileSettings.contactBox.helpTitle')}</h5>
                    <button className={styles.btnAction} onClick={() => handleEmail("Help & Support")}>
                        {t('profileSettings.contactBox.contactButton')}
                    </button>
                </div>
                <div className={styles.dividerInBox} />

                <div className={styles.contactItem}>
                    <h5 className={styles.itemTitle}>{t('profileSettings.contactBox.reportTitle')}</h5>
                    <button className={styles.btnAction} onClick={() => handleEmail("Bug Report")}>
                        {t('profileSettings.contactBox.contactButton')}
                    </button>
                </div>
            </div>
        </div>
    );
};
