import { Voucher, DiscountCalculationResult } from 'types/TInterfaces';



// check voucher status to display on profile page
export const validateVoucher = (voucher: Voucher): {
    isValid: boolean;
    message: string
} => {
    const currentDate = new Date();
    const expirationDate = new Date(voucher.voucherID.expirationDate);

    if (voucher.status === 'used') {
        return { isValid: false, message: 'This voucher has already been used' };
    }

    if (voucher.voucherID.active === false) {
        return { isValid: false, message: 'This voucher is no longer active' };
    }

    if (expirationDate <= currentDate) {
        return { isValid: false, message: 'This voucher has expired' };
    }

    return { isValid: true, message: 'Voucher is valid' };
};



export const calculateVoucherDiscount = (
    voucher: Voucher,
    totalAmount: number
): DiscountCalculationResult => {
    if (totalAmount <= voucher.voucherID.minPurchaseAmount) {
        return {
            isApplicable: false,
            finalAmount: totalAmount,
            discountAmount: 0,
            message: `Minimum purchase amount of ${voucher.voucherID.minPurchaseAmount}€ required`
        };
    }

    let discountAmount = 0;

    if (voucher.voucherID.discountType === 'percentage') {
        const percentageAsDecimal = voucher.voucherID.discountValue / 100;
        discountAmount = totalAmount * percentageAsDecimal;
    } else { // if fixed discount
        discountAmount = voucher.voucherID.discountValue;
    }

    discountAmount = Math.min(discountAmount, totalAmount);

    discountAmount = Math.round(discountAmount * 100) / 100;
    const finalAmount = Math.round((totalAmount - discountAmount) * 100) / 100;

    return {
        isApplicable: true,
        finalAmount,
        discountAmount,
    };
};