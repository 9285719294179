import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

function LoadingStep() {
  const { t } = useTranslation();
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [outgoingText, setOutgoingText] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);

  const loadingTexts = [
    t('ingredientsScan.scanningIngredients'),
    t('ingredientsScan.identifyingActives'),
    t('ingredientsScan.identifyingRisks'),
    t('ingredientsScan.ratingIngredients')
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setOutgoingText(loadingTexts[currentTextIndex]);
      
      setTimeout(() => {
        setCurrentTextIndex((prev) => (prev + 1) % loadingTexts.length);
        setIsAnimating(false);
      }, 600); // Matches animation duration
    }, 3000); // Total time for each text

    return () => clearInterval(interval);
  }, [currentTextIndex, loadingTexts]);

  return (
    <div className={styles.container}>
      {isAnimating && (
        <div className={styles.outgoingText}>
          {outgoingText}
        </div>
      )}
      <div className={`${styles.incomingText} ${isAnimating ? styles.animating : ''}`}>
        {loadingTexts[currentTextIndex]}
      </div>
    </div>
  );
}

export default LoadingStep;
