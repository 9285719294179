import IframePage from 'components/IframePage/IframePage';
import { staticIframeUrls } from 'utils/staticUrlUtil';

function FaqPage() {
  const url = staticIframeUrls.faq
  return (
   <IframePage url={url} />
  );
}

export default FaqPage