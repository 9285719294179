// dynamicHeadSection.tsx

import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from './dynamic.module.scss';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import TextHeadSection from './textHeadSection/textHeadSection';
import { config } from 'config';
import { Autoplay, Navigation } from 'swiper/modules';

// Install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

interface HeadSectionProps {
    carousel: string[];
    logos: string[];
}

export const DynamicHeadSection: React.FC<HeadSectionProps> = ({
    carousel,
    logos
}) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const swiperInstance = useRef<SwiperCore | null>(null);

    const handleButtonClick = (slideIndex: number) => {
        // Navigate to the given slide using slideToLoop for proper looping behavior.
        if (swiperInstance.current) {
            swiperInstance.current.slideToLoop(slideIndex);
        }
        setActiveSlide(slideIndex);
    };

    // Filter out empty URLs
    const validCarousel = carousel.filter((url) => url && url.trim() !== '');
    // Extract image names
    const imageNames: string[] = validCarousel
        .map((url) => {
            try {
                const urlObj = new URL(url);
                const pathSegments = urlObj.pathname.split('/');
                const filename = pathSegments[pathSegments.length - 1];
                const nameWithoutExt =
                    filename.substring(0, filename.lastIndexOf('.')) || filename;
                // Remove trailing digits
                const cleanName = nameWithoutExt.replace(/\d+$/, '');
                return cleanName;
            } catch (error) {
                console.error(`Invalid URL: ${url}`, error);
                return '';
            }
        })
        .filter((name) => name !== '');

    // The Swiper and its associated button navigation
    const backgroundRender = (
        <div className={styles.sliderContainer}>
            {/* Dynamic Buttons */}

            {/* Swiper */}
            <Swiper
                onSwiper={(swiper: SwiperCore) => {
                    swiperInstance.current = swiper;
                }}
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                autoplay={{
                    delay: config.REACT_APP_DELAY,
                    disableOnInteraction: false,
                }}
                // Use swiper.realIndex to always get the actual slide index
                onSlideChange={(swiper: SwiperCore) => setActiveSlide(swiper.realIndex)}
                modules={[Autoplay, Navigation]}
            >
                <div className={styles.navigationButtonsContainer}>
                    {validCarousel.map((_, index) => (
                        <button
                            key={index}
                            className={`${styles.dynamicButton} ${activeSlide === index ? '' : styles.blurred}`}
                            onClick={() => handleButtonClick(index)}
                        >
                            {/* Format index + 1 to two digits */}
                            {String(index + 1).padStart(2, '0')}
                        </button>
                    ))}
                </div>
                {validCarousel.map((img, index) => (
                    <SwiperSlide key={index}>
                        <div
                            className={styles.slideContent}
                            style={{ backgroundImage: `url(${img})` }}
                        >
                            {/* Pass the full list of imageNames as well as the current index */}
                            <TextHeadSection imagesName={imageNames} index={index} logos={logos} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );

    return (
        <>
            <HomepageNavbar />
            <div className={styles.headerBlockHome}>
                {backgroundRender}
            </div>
        </>
    );
};

export default DynamicHeadSection;
