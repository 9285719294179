import React from 'react';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';

const IframePage = ({ url }: {url: string}) => {
  return (
    <div>
      <HomepageNavbar />
      <div style={{ height: '100vh', position: 'relative' }}>
        <iframe 
          src={url} 
          width="100%" 
          height="100%" 
          frameBorder="0" 
          allowFullScreen 
          allowTransparency={true} 
          style={{ zIndex: 1, marginBottom: 56 }} 
        />
        <div 
          style={{ 
            height: '100%', 
            width: '100%', 
            backgroundColor: 'rgb(240, 233, 135)', 
            opacity: 0.1, 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            zIndex: 0, 
            pointerEvents: 'none' 
          }} 
        />
      </div>
      <FooterSection />
    </div>
  );
};

export default IframePage;
