//const dotenv = require('dotenv');
//dotenv.config();
export const config = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL || 'https://l2ymsa6gyj.execute-api.us-east-1.amazonaws.com',
  REACT_APP_STRIPE_SECRET: process.env.REACT_APP_STRIPE_SECRET,
  REACT_APP_SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL || '',
  ENV: process.env.REACT_APP_ENV || 'local',
  REACT_APP_BRAND_OF_THE_MONTH: ['Rowse', 'Torriden', 'Equilib', 'COSRX'],
  REACT_APP_DELAY: 7000,
  REACT_APP_COMING_SOON: process.env.REACT_APP_COMING_SOON,
  REACT_APP_MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  REACT_APP_TERMS_OF_USE_URL: process.env.REACT_APP_TERMS_OF_USE_URL,
  REACT_APP_PRIVACY_POLICY_URL: process.env.REACT_APP_PRIVACY_POLICY_URL,
  ABOUT_US_ARTICLE_LINK: 'feed/article/1974bf4c-7fa1-806a-a226-cb35f5b30ec3',
  POPUP_ARTICLE_LINK: '/feed/article/a13935aa-64f0-42eb-856b-6073c5f30cbd',
  REACT_APP_GA_ID: process.env.REACT_APP_GA_ID,
  REACT_APP_AWS_S3_BUCKET: process.env.REACT_APP_AWS_S3_BUCKET,
  REACT_APP_BASE_FRONTEND_URL: process.env.REACT_APP_BASE_FRONTEND_URL,
};
