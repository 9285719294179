// Homepage.tsx

import './style.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { userAtom } from 'stores/UserProfile';
import { DynamicHeadSection } from 'components/homepage/dynamicHeadSection/dynamicHeadSection';
import { ArticlesCarousel } from 'components/homepage/ArticlesCarousel/ArticlesCarousel';
import { LatestTrends } from 'components/homepage/LatestTrends/LatestTrends';
import { ArticlesPreview } from 'components/homepage/ArticlesPreview/ArticlesPreview';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import { BestSellersBrandSection } from 'components/homepage/BestSellersSection/BestSellersBrandSection';
import globalService from 'service/GlobalService';
import { CombineSection } from 'components/combineSection/combineSection';
import { useDynamicImages } from 'hooks/dynamicImages';
import { AdviceSection } from 'components/homepage/AdviceSection/AdviceSection';
import { useAllBrands } from 'hooks/useAllBrands';
import { Brand } from 'service/API/ProductsApi';
import HeaderBackgroundImage from 'assets/images/headSectionBackground.jpg';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import mouse_read from 'assets/cursors/mouse_read.svg';
import { useWindowSize } from 'hooks/useWindowSize';
import { EditSection } from 'components/edit/editSection';
import collectionApi from '../../service/API/collectionApi'
import { ShopProduct } from 'types/ShopInterfaces';
import { ActivePreview } from 'components/homepage/ActivePreview/ActivePrevie';
import IngredientScannerPreview from 'components/homepage/IngredientScannerPreview/IngredientScannerPreview';

// Example Banner interface
export interface Banner {
  id?: string;
  title?: string;
  description?: string;
  link?: string;
}

interface Tile {
  preTitle: string;
  title: string;
  subTitle: string;
  image: string;
  link?: string;
  products?: ShopProduct[];
}

interface Category {
  name: string;
  description: string;
  image: string;
  products: ShopProduct[];
}

type BrandWithCategories = Brand & {
  categories?: string[];
  about?: string;
  productCount?: number;
  homePageDescription?: string;
  WhyOli?: string;
  banner?: [{
    title: string,
    description: string,
    link: string,
  }

  ];
  backgroundImage?: string;
  titleImage?: string;
};

export const Homepage: React.FC = () => {

  // Atom for user state
  const [user, setUser] = useAtom(userAtom);

  // State to determine if the device is mobile
  const { isMobile } = useWindowSize();


  // Custom hooks
  const { dynamicImages, dynamicImagesIsLoading, dynamicImagesError } = useDynamicImages();
  const { data: allBrandsData, error: brandsError, isLoading: brandsLoading } = useAllBrands();

  // State variables for dynamic content
  const [carousel, setCarousel] = useState<string[]>([]);
  const [logos, setLogos] = useState<string[]>([]);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [editItems, setEditItems] = useState<Tile[]>([]);
  const [initialEditItems, setInitialEditItems] = useState<Tile[]>([]);
  // Additional state variables for brand details
  const [details, setDetails] = useState<BrandWithCategories | null>(null);
  const homepage = "HomePage";
  const TILES_NAMES = ['BEST SELLERS','THE ESSENTIALS EDIT','THE HYDRATATION EDIT']

  // First useEffect - fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await collectionApi.getTilesByName(TILES_NAMES);

        if (response && Array.isArray(response) && response.length > 0) {
          const mappedItems = response.map((collection: Category) => ({
            preTitle: '',
            title: collection.name || '',
            subTitle: collection.description || '',
            image: collection.image || '',
            products: collection.products || [],
            link: '',
          }));

          setInitialEditItems(mappedItems);
          setEditItems(mappedItems);
        } else {
          console.warn('No categories found.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Effect to load user from storage on component mount
  useEffect(() => {
    globalService.loadUserFromStorage(setUser);
  }, [setUser]);

  const sectionsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (!isMobile) {
      let isScrolling = false;
      let currentIndex = 0;
      let isDragging = false;

      const sections = sectionsRef.current;
      if (!sections || sections.length === 0) return;

      const smoothScrollTo = (target: HTMLElement, duration = 700) => {
        if (isScrolling || isDragging) return;
        isScrolling = true;

        const startPosition = window.scrollY;
        const targetPosition = target.getBoundingClientRect().top + window.scrollY;
        const distance = targetPosition - startPosition;
        let startTime: number | null = null;

        const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
          t /= d / 2;
          if (t < 1) return (c / 2) * t * t + b;
          t--;
          return (-c / 2) * (t * (t - 2) - 1) + b;
        };

        const animation = (currentTime: number) => {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
          window.scrollTo(0, run);
          if (timeElapsed < duration) {
            requestAnimationFrame(animation);
          } else {
            isScrolling = false;
          }
        };

        requestAnimationFrame(animation);
      };

      const handleMouseDown = () => {
        isDragging = true;
      };

      const handleMouseUp = () => {
        isDragging = false;
      };

      window.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mouseup', handleMouseUp);

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.3) {
              const index = sections.findIndex((section) => section === entry.target);

              if (index !== -1 && index !== currentIndex) {
                currentIndex = index;
                smoothScrollTo(sections[currentIndex]);
              }
            }
          });
        },
        { threshold: 0.4 }
      );

      sections.forEach((section) => {
        observer.observe(section);
      });

      return () => {
        observer.disconnect();
        window.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }
  });


  // Effect to set brand details based on allBrandsData
  useEffect(() => {
    if (allBrandsData) {
      const foundDetails = allBrandsData.find(
        (b) => b.brand.toLowerCase() === homepage.toLowerCase()
      ) as BrandWithCategories | undefined;
      setDetails(foundDetails || null);
    }
  }, [allBrandsData, homepage]);


  // Second useEffect - process dynamic images
  useEffect(() => {
    const carouselImages: string[] = [];
    const logoImages: string[] = [];
    const bannerImages: string[] = [];
    const tilesImages: string[] = [];

    if (dynamicImages) {
      Object.entries(dynamicImages).forEach(([key, value]) => {
        const valueLower = (value as string).toLowerCase();

        if (valueLower.includes("/carousel/")) {
          carouselImages.push(value as string);
        } else if (valueLower.includes("/brandslogo/")) {
          logoImages.push(value as string);
        } else if (valueLower.includes("/banners/")) {
          bannerImages.push(value as string);
        } else if (valueLower.includes("/tile/")) {
          tilesImages.push(value as string);
        }
      });

      setCarousel(carouselImages);



      setLogos(logoImages);
      setBanners(
        bannerImages.map((image, index) => ({
          id: (index + 1).toString(),
          title: details?.banner?.[index]?.title || 'Default Title',
          description: details?.banner?.[index]?.description || 'Default Description',
          image: image,
          link: details?.banner?.[index]?.link || '',

        }))
      );

      setEditItems((prevItems) =>
        prevItems.map((item, index) => {
          return {
            ...item,
            image: tilesImages[
              item.title === 'BEST SELLERS' ? 0 :
              item.title === 'THE HYDRATATION EDIT' ? 1 : 2
            ] || initialEditItems[index].image,
          }
        })
      );
    }
  }, [dynamicImages, details, initialEditItems]);


  const magnetScrollingStyle = {
    skincareJourney: {
      height: 'auto',
      background: 'transparent',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      cursor: `url(${mouse_read}), auto`,
    },

    browsingThrough: {
      background: 'transparent',
      height: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      cursor: `url(${mouse_read}), auto`,
    },

    saferIngredients: {
      background: 'transparent',
      height: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      cursor: `url(${mouse_read})`,
    },
    scanIngredients: {
      background: 'linear-gradient(180deg, #f7f6e6 0%, #f7f6e6 30%, rgba(204, 185, 167, 0.65) 70%, rgba(204, 185, 167, 0.45) 100%)',
      height: '100vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column' as const,
      overflow: 'hidden',
      position: 'relative',
    } as React.CSSProperties
  }


  return (
    <>
      <div className="homepageContainer">
        <div ref={(el) => (sectionsRef.current[0] = el!)} className="headSection">
          <DynamicHeadSection carousel={carousel} logos={logos} />
        </div>

        <div ref={(el) => (sectionsRef.current[1] = el!)} className="bestSellersBrandSection">
          <BestSellersBrandSection />
        </div>

        <div ref={(el) => (sectionsRef.current[2] = el!)} className="adviceSection">
          <AdviceSection />
        </div>
        <EditSection tiles={editItems} isHomePage={true} />

        <div 
          className="scannerPreviewSection"
          style={magnetScrollingStyle.scanIngredients}
        >
          <IngredientScannerPreview 
            sectionRef={(el) => (sectionsRef.current[7] = el!)}
            magnetScrollingStyle={magnetScrollingStyle.scanIngredients}
          />
        </div>

        <div className="combineSection"
          style={{ height: 'auto' }}
        >
          <CombineSection banners={banners} magnetScrollingStyle={magnetScrollingStyle} sectionsRef={sectionsRef} /> {/* sectionsRef.current 3,4,5,6  */}
        </div>

        <div ref={(el) => (sectionsRef.current[8] = el!)} className="latestTrends">
          <LatestTrends />
          {/* <ArticlesPreview isLoggedIn={!!user} /> */}
          <ActivePreview isLoggedIn={!!user} />
        </div>

        <FooterSection />
      </div >
    </>
  );
}

