import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './RootLayout.module.scss';
import BetaBadge from '../BetaBadge/BetaBadge';

interface RootLayoutProps {
  children: ReactNode;
  onBack?: () => void;
  showBackButton?: boolean;
}

export default function RootLayout({ children, onBack, showBackButton }: RootLayoutProps) {
  const location = useLocation();
  const isResultsPage = location.pathname.includes('/ingredients-scan') && document.querySelector('[class*="ResultsStep"]'); // Check if ResultsStep component is rendered

  return (
    <div className={`${styles.background} ${isResultsPage ? styles.backgroundResults : ''}`}>
      <HomepageNavbar customBackAction={onBack} showCustomBack={showBackButton} />
      <div className={styles.content}>{children}</div>
      <BetaBadge />
    </div>
  );
}
