import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface LoginPopupProps {
    onClose: () => void;
}

export const LoginPopup = ({ onClose }: LoginPopupProps) => {
    const navigate = useNavigate();
    const {t} = useTranslation()

    const handleLoginClick = () => {
        onClose();
        navigate('/login');
    };

    // Prevent background scroll when popup is open
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className={styles.overlay}>
            <div className={styles.loginPopup}>
                <div className={styles.loginPopupTitle}>
                    {t('loginPopup.loginRequired')}
                </div>
                <div className={styles.loginPopupSubtitle}>
                    <button className={styles.loginPopupSubtitleButton} onClick={handleLoginClick}>
                        <p className={styles.loginPopupSubtitleButtonText}>{t('loginPopup.loginButton')}</p>
                    </button>
                    <div className={styles.continueButton} onClick={onClose}>
                        <p className={styles.continueButtonText}>{t('loginPopup.closeButton')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
