import api from './apiConfig';
import globalService from 'service/GlobalService';
import { IAnonymousUser, IUser } from 'types/TInterfaces';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import axios from 'axios';
import { config } from 'config';
import i18next from 'i18next';

export default class PaymentsApi {
    static async handlePayment(totalPrice: number, user: IUser | IAnonymousUser, checkoutData: any, selectedRelayPoint: any, userEmail: string, currentLanguage: string) {
        try {
            const stripe = await loadStripe(config.REACT_APP_STRIPE_SECRET ?? '');
            let pickup_point: any = {};

            if (selectedRelayPoint && typeof selectedRelayPoint === 'object') {
                pickup_point.id = selectedRelayPoint.Num;
                pickup_point.display_name = selectedRelayPoint.LgAdr1;
                pickup_point.state = selectedRelayPoint.Ville;
                pickup_point.country = selectedRelayPoint.Pays;
                pickup_point.carrier_service = 'mondialrelay-relaisl';
            }

            const body = {
                price: totalPrice,
                checkoutData: {
                    ...checkoutData,
                    voucherToUpdate: checkoutData.voucher ? {
                        voucherCode: checkoutData.voucher.voucherID.code
                    } : null,
                    ...(Object.keys(pickup_point).length > 0 && { pickup_point }) // Add pickup_point only if it's not an empty object
                },
                user: user,
                email: userEmail,
                locale: currentLanguage
            };

            const response = await axios.post(
                `${config.REACT_APP_API_URL}/payments/create-checkout-session`,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const session = response.data;

            if (!session || !session.sessionId) {
                throw new Error('Session ID is missing in the API response');
            }

            const result = await stripe?.redirectToCheckout({ sessionId: session.sessionId });
            if (result?.error) {
                console.error('Stripe redirectToCheckout error:', result.error.message);
                return { success: false, message: result.error.message };
            }

            return { success: true };
        } catch (error: any) {
            console.error('Error handling payment:', error.message);
            return { success: false, message: error.message || 'Unknown error' };
        }
    }
}

