import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { TConcern } from '../../../types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { StepIndicator } from 'components/counter/counter';
import styles from '../style.module.scss';
import { useTranslation } from 'react-i18next';

export const SPF_Concerns: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const [selectedConcerns, setSelectedConcerns] = useState<TConcern[]>([]);
  const {t} = useTranslation()
  
  const concernMapping: Record<TConcern, string> = {
    'Dryness and/or deshydration': 'spfForm.concerns.options.dryness',
    'Fine lines and wrinkles': 'spfForm.concerns.options.fineLines',
    'Shiny areas': 'spfForm.concerns.options.shinyAreas',
    'Redness & rosacea': 'spfForm.concerns.options.redness',
    'Eye bags and/or dark circles': 'spfForm.concerns.options.darkCircles',
    'Hyperpigmentation': 'spfForm.concerns.options.hyperpigmentation',
    'Acne, spots': 'spfForm.concerns.options.acne',
    'Blackheads': 'spfForm.concerns.options.blackheads',
    'Dullness': 'spfForm.concerns.options.dullness',
    'To define': 'spfForm.concerns.options.toDefine',
  };

  const concerns: TConcern[] = Object.keys(concernMapping) as TConcern[];


  const handleConcernSelect = (concern: TConcern) => {
    setSelectedConcerns((prev) =>
      prev.includes(concern) ? prev.filter((c) => c !== concern) : [...prev, concern]
    );
  };

  useEffect(() => {
    if (user && user.SPF && Array.isArray(user.SPF.concerns)) {
      setSelectedConcerns(user.SPF.concerns);
    } else {
      console.warn('No valid concerns found or user is null.');
    }
  }, [user]);

  const goToNext = () => {
    if (selectedConcerns.length) {
      setSkinProfile({ ...skinProfile, concerns: selectedConcerns,
        textToRender: {
          ...skinProfile?.textToRender,
          concerns: selectedConcerns.map((concern) => concernMapping[concern]), // i18n keys for UI
        },
       });
      navigate('/spf-form/spf-risks');
    }
  };

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <div className={styles.groupOne}>
            <StepIndicator currentStep={4} />
            <div className={styles.titleContainer}>
              <h1 className={styles.TitlesSpf}>{t('spfForm.concerns.title')}</h1>
            </div>
            <div className={styles.subtitleContainer}>
              <h5 className={styles.SubtitleSpf}>{t('spfForm.age.subtitle')}</h5>
            </div>
          </div>
          <div className={styles.groupTwoLongList}>
            <div className={styles.inputGroup}>
              {concerns.map((concern) => (
                <button
                  key={concern}
                  className={`${styles.inputButtons} ${selectedConcerns.includes(concern) ? styles.selected : ''}`}
                  onClick={() => handleConcernSelect(concern)}
                >
                  {t(concernMapping[concern])}
                </button>
              ))}
            </div>

          </div>
        </div>
        <div className={styles.groupThree}>
          <div className={`${styles.spfbuttonContainer} ${!selectedConcerns.length ? styles.disabled : ''}`}>
            <button
              className={styles.nextButtonForm}
              disabled={!selectedConcerns.length}
              onClick={goToNext}
            >
              {t('common.nextButton')}
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};
