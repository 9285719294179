import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { config } from '../config';

export function useCurrentLanguage() {
  const allowedLanguages: ['en', 'fr'] = ['en', 'fr'];

  const getInitialLanguage = (): 'en' | 'fr' => {  

    const storedLang = localStorage.getItem('i18nextLng');
    const browserLang = navigator.language.split('-')[0] as 'en' | 'fr';

    return allowedLanguages.includes(storedLang as 'en' | 'fr') ? (storedLang as 'en' | 'fr') : allowedLanguages.includes(browserLang) ? browserLang : 'en';
  };

  const [currentLanguage, setCurrentLanguage] = useState<'en' | 'fr'>(getInitialLanguage);

  // Listen to i18next language changes
  useEffect(() => {
    const handleLanguageChanged = (lng: string) => {
      setCurrentLanguage(lng as 'en' | 'fr');
    };

    i18next.on('languageChanged', handleLanguageChanged);

    return () => {
      i18next.off('languageChanged', handleLanguageChanged);
    };
  }, []);

  // Handle language changes
  const handleLanguageChange = (lang: 'en' | 'fr') => {

    if (lang === (i18next.language as 'en' | 'fr')) return;

    i18next.changeLanguage(lang);
    setCurrentLanguage(lang);
  };

  return {
    currentLanguage,
    setCurrentLanguage: handleLanguageChange,
  };
}
