//midnight product
import midnightdesktop from '../Midnight/midnight_desktop.png'
import midnightmobile from '../Midnight/midnight_mobile.png'
import midnighttitle from '../Midnight/midnight_title.png'
//celimax product
import celimaxdesktop from '../../images/celimax.png'
import celimaxmobile from '../../images/celimax.png'
import celimaxtitle from '../Celimax/celimax_title.png'
//hyeja product
import hyejadesktop from '../Hyeja/heyja_desktop.png'
import hyejamobile from '../Hyeja/hyeja_mobile.png'
import hyejatitle from '../Hyeja/hyeja_title.png'
//agentNateur
import agentNateurdesktop from '../AgentNateur/agentNateur_desktop.png'
import agentNaturmobile from '../AgentNateur/agentNateur_mobile.png'
import agentNaturtitle from '../AgentNateur/agentNateur_title.png'
//NuTexture
import nutexturedesktop from '../NuTexture/NuTexture_desktop.png'
import nutexturemobile from '../NuTexture/NuTexture_mobile.png'
import nutexturetitle from '../NuTexture/NuTexture_title.png'
//Default
import defaultdesktop from '../default/default_desktop.png'
import defaultmobile from '../default/default_mobile.png'
import defaulttitle from '../default/default_title.png'


export const backgroundBrands = [
    {
        "name": "Mid/night 00" || 'mid/night 00',
        "image": midnightdesktop,
        "mobileImage": midnightmobile,
        "titleImage": midnighttitle
    },
    {
        "name": "celimax" || 'Celimax',
        "image": celimaxdesktop,
        "mobileImage": celimaxmobile,
        "titleImage": celimaxtitle
    },
    {
        "name": "Hyeja" || 'hyeja',
        "image": hyejadesktop,
        "mobileImage": hyejamobile,
        "titleImage": hyejatitle
    },
    {
        "name": "Agent Nateur" || 'agent nateur',
        "image": agentNateurdesktop,
        "mobileImage": agentNaturmobile,
        "titleImage": agentNaturtitle
    },
    {
        "name": "NuTexture" || 'nutexture',
        "image": nutexturedesktop,
        "mobileImage": nutexturemobile,
        "titleImage": nutexturetitle
    },
    {
        "name": "Default",
        "image": defaultdesktop,
        "mobileImage": defaultmobile,
        "titleImage": defaulttitle
    }

]
