import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { TGender } from '../../../types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { StepIndicator } from 'components/counter/counter';
import styles from '../style.module.scss';
import { useTranslation } from 'react-i18next';

export const SPF_Gender: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState<TGender | null>(null);

  useEffect(() => {
    if (user?.SPF?.gender) {
      setSelectedGender(user.SPF.gender);
    }
  }, [user, skinProfile]);

  const handleGenderSelect = (gender: TGender) => {
    setSelectedGender(gender);
  };

  const goToNext = () => {
    if (selectedGender) {
      setSkinProfile({ ...skinProfile, gender: selectedGender });
      navigate('/spf-form/spf-age');
    }
  };

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <div className={styles.groupOne}>
            <StepIndicator currentStep={1} />
            <div className={styles.titleContainer}>
              <h1 className={styles.TitlesSpf}>{t('spfForm.gender.title')}</h1>
            </div>
            <div className={styles.subtitleContainer}>
              <h5 className={styles.SubtitleSpf}>{t('spfForm.gender.subtitle')}</h5>
            </div>
          </div>
          <div className={styles.groupTwo}>

            <div className={styles.inputGroup}>
              <button
                className={`${styles.inputButtons} ${selectedGender === 'Female' ? styles.selected : ''}`}
                onClick={() => handleGenderSelect('Female')}
              >
                {t('spfForm.gender.options.female')}
              </button>
            </div>

            <div className={styles.inputGroup}>
              <button
                className={`${styles.inputButtons} ${selectedGender === 'Male' ? styles.selected : ''}`}
                onClick={() => handleGenderSelect('Male')}
              >
                {t('spfForm.gender.options.male')}
              </button>
            </div>

            <div className={styles.typeInputGroup}>
              <button
                className={`${styles.inputButtons} ${selectedGender === 'Non binary' ? styles.selected : ''}`}
                onClick={() => handleGenderSelect('Non binary')}
              >
                {t('spfForm.gender.options.nonBinary')}
              </button>
            </div>

            <div className={styles.inputGroup}>
              <button
                className={`${styles.inputButtons} ${selectedGender === 'I don’t want to define myself' ? styles.selected : ''}`}
                onClick={() => handleGenderSelect('I don’t want to define myself')}
              >
                {t('spfForm.gender.options.noDefinition')}
              </button>
            </div>
            <div className={styles.groupThree}>
              <div className={`${styles.spfbuttonContainer} ${!selectedGender ? styles.disabled : ''}`}>
                <button className={styles.nextButtonForm} disabled={!selectedGender} onClick={goToNext}>
                  {t('common.nextButton')}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
