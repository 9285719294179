import React, { useState, useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import blackdot from '../../../../assets/icons/blackDot.svg'
import ProductsApi from "../../../../service/API/ProductsApi"
import ActivesApi from "../../../../service/API/activesApi"
import NotionsApi from "../../../../service/API/notionsApi"
import articleApi from "../../../../service/API/articleApi"
import activesApi from '../../../../service/API/activesApi';
import SearchLoader from '../../../animation/searchLoader/searchLoader';
import { useNavigate } from 'react-router-dom';
import activesCardImage from '../../../../assets/images/activesCard.png'
import notionsApi from '../../../../service/API/notionsApi';
import { CollectionPage } from 'pages/Collection/CollectionPage/CollectionPage';
import { urlencoded } from 'express';
import { useCurrentLanguage } from 'hooks/useLanguage';
import { useTranslation } from 'react-i18next';

interface SearchResult {
    title?: string;
    imgUrl: string;
    inci: string;
    brand: string;
    price: number;
    quantity: string;
    _id: string;
    active_name: string;
    synonyms: { active_name: string }[];
    group_elements: { active_name: string }[];
    image: string;
    type: string;
    notion_id?: string;
    dossierNames: string[];
    element_of_group: [
        {
            id: string;
            image: string;
        }
    ];
    translations : any;
    properties?: {
        Image: {
            files: {
                url: string;
            }[];
        };
        Title: {
            title: {
                plain_text: string;
            }[];
        };
    

    };
    

}

interface ExpandProps {
    onClose: () => void;
    products: SearchResult[];
    articles: SearchResult[];
    actives: SearchResult[];
    searchQuery: string;
}

interface ApiResponse {
    status: number;
    data?: any;
}
interface GroupElement {
    image?: string | null;
    id: number;
    active_name: string;
}


const ExpandHeader: React.FC<{ handleClose: () => void, totalCount: number, isLoading: boolean, isMobile: boolean }> =
    ({ handleClose, totalCount, isLoading, isMobile }) => {
        const {currentLanguage} = useCurrentLanguage()
        const {t} = useTranslation()
    return(
        <div className={styles.menuHeaderContainer}>
            {!isLoading && (
                <div className={styles.menuHeaderResultsContainer}>
                    <p className={styles.menuHeaderResultsText}>
                    {totalCount} {t(totalCount > 1 ? 'menu.expandProducts.results' : 'menu.expandProducts.result')}
                    </p>
                </div>
            )}

            {!isMobile && (
                <div className={styles.menuHeaderCloseContainer}>
                    <button onClick={handleClose} className={styles.menuCloseButton}>
                        <img src={blackdot} alt="blackdot" className={styles.menuCloseButtonDot} />
                        <p className={styles.menuContainerCloseText}>{t('common.close')}</p>
                    </button>
                </div>
            )}
        </div>
    );}

const OptionsNav: React.FC<{
    activeOption: string,
    handleOptionClick: (option: string) => void,
    counts: { products: number, articles: number, actives: number }
}> = ({ activeOption, handleOptionClick, counts }) =>{
    const {currentLanguage} = useCurrentLanguage()
    const {t} = useTranslation()

 return(
    <div className={styles.optionsContainer}>
        <button
            onClick={() => handleOptionClick('PRODUCTS')}
            className={activeOption === 'PRODUCTS' ? styles.activeOption : styles.nonActiveOption}
            disabled={counts.products === 0}
        >
            {activeOption === 'PRODUCTS' && <img src={blackdot} alt="Active" className={styles.optionIcon} />}
            {t('menu.expandProducts.products')} {activeOption === 'PRODUCTS' ? '' : `(${counts.products})`}
        </button>

        <button
            onClick={() => handleOptionClick('ARTICLES')}
            className={activeOption === 'ARTICLES' ? styles.activeOption : styles.nonActiveOption}
            disabled={counts.articles === 0}
        >
            {activeOption === 'ARTICLES' && <img src={blackdot} alt="Active" className={styles.optionIcon} />}
            ARTICLES {activeOption === 'ARTICLES' ? '' : `(${counts.articles})`}
        </button>

        <button
            onClick={() => handleOptionClick('ACTIVES')}
            className={activeOption === 'ACTIVES' ? styles.activeOption : styles.nonActiveOption}
            disabled={counts.actives === 0}
        >
            {activeOption === 'ACTIVES' && <img src={blackdot} alt="Active" className={styles.optionIcon} />}
            {t('menu.expandProducts.actives')} {activeOption === 'ACTIVES' ? '' : `(${counts.actives})`}
        </button>
    </div>
);
}
const ProductItem: React.FC<{ product: SearchResult, onProductClick: (product: SearchResult) => void }> =
    ({ product, onProductClick }) => {
        const {currentLanguage} = useCurrentLanguage()
        const productTitle = product.translations?.[currentLanguage]?.title
        
        return(
        <div className={styles.productItem} onClick={() => onProductClick(product)}>
            <Link to={`/product/${product._id}`} className={styles.productLink}>
                <div className={styles.productImageContainer}>
                    <img
                        src={product.imgUrl}
                        alt={product.title}
                        className={styles.productImage}
                        onClick={(e) => {
                            e.stopPropagation();
                            ProductsApi.updateSearchCounter(product._id);
                        }}
                    />
                    <div className={styles.productInfo}>
                        <p className={styles.productBrand}>{product.brand}</p>
                        <p className={styles.productTitle}>
                            {(productTitle?.length ?? 0) > 20 ? `${productTitle?.substring(0, 17)}...` : productTitle}
                        </p>
                        <p className={styles.productPrice}>
                            €{product.price?.toFixed(2)} | ({product?.quantity}ml)
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );
}

const ProductsSection: React.FC<{
    isLoading: boolean,
    products: SearchResult[],
    showAllProducts: boolean,
    handleSeeMore: () => void,
    handleProductClick: (product: SearchResult) => void,
    isMobile: boolean
}> = ({ isLoading, products, showAllProducts, handleSeeMore, handleProductClick, isMobile }) => {
    return (
        <>
            {isLoading ? (
                <div className={styles.menuLoaderContainer}>
                    <SearchLoader />
                </div>
            ) : (
                <div className={`${styles.menuProductContainer}`}>
                    <div className={`${styles.productList}`}>
                        {products
                            .filter((product, index, self) =>
                                product?.title &&
                                self.findIndex(p => p._id === product._id) === index
                            )
                            //.slice(0, showAllProducts ? products.length : isMobile ? 4 : 6)
                            .slice(0, products.length)
                            .map((product, index) => (
                                product &&
                                <div key={product._id || index}>
                                    <ProductItem
                                        key={product._id || index}
                                        product={product}
                                        onProductClick={handleProductClick}
                                    />
                                </div>
                            ))
                        }
                    </div>


                </div>
            )}
        </>
    );
};

// Article Item Component
const ArticleItem: React.FC<{
    article: SearchResult,
    currentLanguage : string,
    handleArticleClick: (article: SearchResult) => void
}> = ({ article, handleArticleClick, currentLanguage }) => {
    
return(
    <li className={styles.articleItem} onClick={() => handleArticleClick(article)}>
        {article.translations?.[currentLanguage].properties?.Image?.files[0]?.url && (
            <div
                className={styles.articleImage}
                style={{ backgroundImage: `url(${article.translations?.[currentLanguage].properties.Image.files[0].url})`, backgroundPosition: 'center' }}
            >
                <DossierNames dossierNames={article.translations?.[currentLanguage].dossierNames} />
                <div className={styles.articleTitleWrapper}>
                    <ArticleTitle title={article.translations?.[currentLanguage].properties?.Title?.title[0]?.plain_text} />
                </div>
            </div>
        )}
    </li>
);
}
// Dossier Names Component
const DossierNames: React.FC<{ dossierNames?: string[] }> = ({ dossierNames }) => {
    if (!dossierNames?.length) return null;

    return (
        <div className={styles.articleDossierContainer}>
            {dossierNames.slice(0, 2).map((dossier, index) => (
                <p key={index} className={styles.articleDossier}>
                    {dossier.split(' ').slice(0, 3).map((word, wordIndex) => (
                        <span key={wordIndex} className={styles.articleDossierWord}>
                            {word}
                        </span>
                    ))}
                </p>
            ))}
        </div>
    );
};

// Article Title Component
const ArticleTitle: React.FC<{ title?: string }> = ({ title }) => {
    if (!title) return null;

    return (
        <div className={styles.articleTitleContainer}>
            <p className={styles.articleTitle}>{title}</p>
        </div>
    );
};


const ArticlesSection: React.FC<{
    articles: SearchResult[],
    showAllArticles: boolean,
    handleArticleClick: (article: SearchResult) => void,
    isMobile: boolean
}> = ({ articles, showAllArticles, handleArticleClick, isMobile }) => {
    const {currentLanguage} = useCurrentLanguage()

    const filteredArticles = articles
        .filter((article, index, self) =>
            article?.translations?.[currentLanguage].properties.Title.title[0].plain_text &&
            self.findIndex(a => a?._id === article?._id) === index
        )
        .slice(0, showAllArticles ? articles.length : isMobile ? 2 : 3);
    

    return (
        <div className={`${styles.menuArticlesContainer} ${showAllArticles ? styles.scroll : ''}`}>
            {
                filteredArticles
                    .map((article, index) => (
                        article &&
                        <ul className={styles.articlesList} key={article._id || index}>
                            <ArticleItem
                                key={article._id || index}
                                article={article}
                                handleArticleClick={handleArticleClick}
                                currentLanguage={currentLanguage}
                            />
                        </ul>

                    ))
            }
        </div>
    );

};

// Active Item Component
const ActiveItem: React.FC<{
    active: SearchResult,
    handleActiveClick: (id: string) => void
}> = ({ active, handleActiveClick }) => {
    return (
        <li >
            <div className={styles.activeGrid} onClick={() => handleActiveClick(active._id)}>
                <div
                    className={styles.activeImage}
                    style={{ backgroundImage: `url(${active.image ? active.image : activesCardImage})` }}
                >
                    <p className={styles.activeName}>{active.active_name}</p>
                </div>
            </div>
        </li>
    );
}
// Actives Section Component
const ActivesSection: React.FC<{
    activeList: SearchResult[],
    //products: SearchResult[],
    handleActiveClick: (id: string) => void,
    showAllActives: boolean
}> = ({ activeList, handleActiveClick, showAllActives }) => {
    const displayList = activeList
        .filter(active => active.active_name)
        .slice(0, showAllActives ? activeList.length : 6)
        .map((active, index) => (
            <div key={active._id || `active-${index}`} className={styles.activeItem}>
                <ActiveItem
                    active={active}
                    handleActiveClick={handleActiveClick}
                />
            </div>
        ));

    return (
        <div className={styles.menuActivesContainer}>
            <ul className={styles.activesList}>
                {displayList}
            </ul>
        </div>
    );
};

const Expand = forwardRef<HTMLDivElement, ExpandProps>(({ onClose, products, articles, actives, searchQuery }, ref) => {
    const enableProducts = products.length > 0;
    const enableArticles = articles.length > 0;
    const enableActives = actives.length > 0;
    const [activeOption, setActiveOption] = useState(enableProducts ? 'PRODUCTS' : enableArticles ? 'ARTICLES' : enableActives ? 'ACTIVES' : '');
    const [recentProducts, setRecentProducts] = useState<SearchResult[]>([]);
    const [synonyms, setSynonyms] = useState<string[]>([]);
    const [groupElements, setGroupElements] = useState<string[]>([]);
    const [active, setActive] = useState<any>(null);
    const [productsResult, setProductsResult] = useState<SearchResult[]>(products);
    const [articlesResult, setArticlesResult] = useState<SearchResult[]>(articles);
    const [activesResult, setActivesResult] = useState<SearchResult[]>(actives);
    const [activetRelatedArticles, setActivetRelatedArticles] = useState<SearchResult[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [showAllProducts, setShowAllProducts] = useState(false);
    const [showAllArticles, setShowAllArticles] = useState(false);
    const [showAllActives, setShowAllActives] = useState(false);
    const [activeList, setActiveList] = useState<any[]>([]);
    const isMobile = window.innerWidth < 768;
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchActives = async () => {
            if (!activesResult?.length) {
                setActiveList([]);
                return;
            }

            const groupActives = activesResult.filter(active =>
                active.element_of_group?.some(group => group?.id === active?.notion_id) ||
                !active.element_of_group?.length
            );

            // Process all actives in parallel
            const updatedActives = await Promise.all(
                activesResult.map(active => {
                    if (active.image || !active.element_of_group?.[0]?.id) {
                        return active;
                    }

                    // Find in existing groupActives first
                    const foundActive = groupActives.find(groupActive =>
                        active.element_of_group?.some(element => element?.id === groupActive?.notion_id)
                    );

                    if (foundActive?.image) {
                        return { ...active, image: foundActive.image };
                    }

                    // Only make API call if necessary
                    return notionsApi.getActiveByNotionId(active.element_of_group[0].id)
                        .then(result => ({
                            ...active,
                            image: result?.data?.image || activesCardImage
                        }))
                        .catch(() => ({
                            ...active,
                            image: activesCardImage
                        }));
                })
            );
            setActiveList(updatedActives);
        };

        fetchActives();
    }, [activesResult]);


    useEffect(() => {
        const storedProducts = localStorage.getItem('recentProducts');
        if (storedProducts) {
            setRecentProducts(JSON.parse(storedProducts));
        }
    }, []);

    const fetchArticlesByActiveName = async (activeName: string) => {
        try {
            setActivetRelatedArticles([]); // Reset before fetching
            const response = await articleApi.findByActiveName(activeName);
            if (Array.isArray(response)) {
                setActivetRelatedArticles(response);
            } else {
                console.error("Unexpected API response format:", response);
                setActivetRelatedArticles([]); // Fallback to empty array if the response is not iterable
            }
        } catch (error) {
            console.error("Error fetching related articles:", error);
            setActivetRelatedArticles([]); // Reset on error
        }
    };

    useEffect(() => {
        setActivetRelatedArticles([]);

        if (active) {
            fetchArticlesByActiveName(active.active_name);
        }
    }, [active]);

    const handleOptionClick = (option: string) => {
        setActiveOption(option);
    };

    const handleProductClick = (product: SearchResult) => {
        const updatedRecentProducts = [product, ...recentProducts.filter(p => p._id !== product._id).slice(0, 2)];
        setRecentProducts(updatedRecentProducts);
        localStorage.setItem('recentProducts', JSON.stringify(updatedRecentProducts));
    };

    useEffect(() => {
        if (productsResult.length > 0) {
            setTotalCount(productsResult.length + articlesResult.length + activeList.length);
        }
        else {
            setTotalCount(productsResult.length + articlesResult.length + activeList.length);
        }
    }, [productsResult.length, articlesResult, productsResult, activeList]);

    useEffect(() => {
        const fetchActiveById = async () => {
            let filteredProducts;
            if (productsResult.length > 0) {
                const productsWithoutTitles = productsResult.filter(product => product?.type === "product");

                if (productsWithoutTitles.length > 0) {
                    setProductsResult([]); // Clear existing products first

                    for (let product of productsWithoutTitles) {
                        try {

                        } catch (error) {
                            console.error(`Error for product ID: ${product._id}`, error);
                        }
                    }
                } else {
                    filteredProducts = productsResult.filter(p => p.title).map(p => ({ ...p }));
                    setProductsResult(filteredProducts);
                }
            }
        };

        fetchActiveById();
    }, [productsResult]);

    useEffect(() => {
        setProductsResult(products);
    }, [products]);


    const handleClose = () => {
        setProductsResult([]);
        setActivetRelatedArticles([]);
        setActive(null);
        setSynonyms([]);
        setGroupElements([]);
        setArticlesResult([]);
        onClose();

    };

    const handleSeeMore = () => {
        if (activeOption === 'PRODUCTS') {
            // Navigate to the collection page with searchQuery as a URL query parameter
            const encodedSearchQuery = encodeURIComponent(searchQuery);
            navigate(`/collection/products?search=${encodedSearchQuery}`);
        }
        if (activeOption === 'ARTICLES') setShowAllArticles(!showAllArticles); // Toggle the state to show all or fewer articles
        if (activeOption === 'ACTIVES') setShowAllActives(!showAllActives); // Toggle the state to show all or fewer actives
    };
    const handleActiveClick = (id: string) => {
        ActivesApi.updateSearchCounter(id);
        navigate(`/feed/actives/${id}`);
        window.location.reload();
    }
    const handleArticleClick = (article: any) => {
        navigate(`/feed/article/${article.id}`);
        window.location.reload();
    }

    const getButtonText = (showAll: boolean) => {
        if (showAll) {
            return 'See less';
        } else {
            return `See all ${activeOption}`;
        }
    }
    const renderSeeMoreButton = () => {
        let generateSeeMoreButton = false;
        let buttonText = '';
        if (activeOption === 'PRODUCTS') {
            generateSeeMoreButton = productsResult.length > 6;
            buttonText = getButtonText(showAllProducts);

        } else if (activeOption === 'ARTICLES') {
            generateSeeMoreButton = articlesResult.length > 2;
            buttonText = getButtonText(showAllArticles);
        } else if (activeOption === 'ACTIVES') {
            generateSeeMoreButton = activeList.length > 6;
            buttonText = getButtonText(showAllActives);
        }
        if (generateSeeMoreButton) {
            return (
                <div className={styles.SeeMoreContainer}>
                    <button onClick={handleSeeMore} className={styles.SeeMoreButton}>
                        {buttonText}
                    </button>
                </div>
            )
        }

    }

    return (
        <div ref={ref} className={styles.menuRightSection}>
            <div className={styles.expendContainer}>
                <ExpandHeader
                    handleClose={handleClose}
                    totalCount={totalCount}
                    isLoading={isLoading}
                    isMobile={isMobile}
                />

                <OptionsNav
                    activeOption={activeOption}
                    handleOptionClick={handleOptionClick}
                    counts={{
                        products: productsResult.length || 0,
                        articles: articlesResult.length || 0,
                        actives: activeList.length || 0
                    }}
                />

                {activeOption === 'PRODUCTS' && (
                    <ProductsSection
                        isLoading={isLoading}
                        products={productsResult}
                        showAllProducts={showAllProducts}
                        handleSeeMore={handleSeeMore}
                        handleProductClick={handleProductClick}
                        isMobile={isMobile}
                    />
                )}

                {activeOption === 'ARTICLES' && (
                    <ArticlesSection
                        articles={articlesResult}
                        showAllArticles={showAllArticles}
                        handleArticleClick={handleArticleClick}
                        isMobile={isMobile}
                    />
                )}

                {activeOption === 'ACTIVES' && (
                    <ActivesSection
                        activeList={activeList}
                        handleActiveClick={handleActiveClick}
                        showAllActives={showAllActives}
                    />
                )}

                {
                    !enableProducts && !enableArticles && !enableActives && activeOption === '' && (
                        <div className={styles.menuContainerSuggestions}>
                            <p className={styles.menuContainerSuggestionsTitle}>No results found</p>
                        </div>
                    )
                }

            </div>

            <div className={styles.seeMoreContainerDiv}>
                {renderSeeMoreButton()}
            </div>

        </div>
    );
});

export default Expand;