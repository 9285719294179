import { FC } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { SPF_Gender } from './FormTypes/SPF_Gender';
import { SPF_Age } from './FormTypes/SPF_Age';
import { SPF_Concerns } from './FormTypes/SPF_Concerns';
import { SPF_Risks } from './FormTypes/SPF_Risks';
import { SPF_Resume } from './FormTypes/SPF_Resume';
import { SPF_WelcomePage } from './FormTypes/SPF_WelcomePage';
import { SPF_SkinType } from './FormTypes/SPF_SkinType';
import { AnalyzeSkin } from './FormTypes/SPF_Analyze';

export const SkinTypeFormStack: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SPF_WelcomePage />} />
      <Route path="spf-gender" element={<SPF_Gender />} />
      <Route path="spf-age" element={<SPF_Age />} />
      <Route path="spf-skin-type" element={<SPF_SkinType />} />
      <Route path="spf-concerns" element={<SPF_Concerns />} />
      <Route path="spf-risks" element={<SPF_Risks />} />
      <Route path="spf-resume" element={<SPF_Resume />} />
      <Route path="spf-analyze" element={<AnalyzeSkin />} />
    </Routes>
  );
};

