import React, { useState, useEffect, useRef } from 'react';
import { ShopProduct } from 'types/ShopInterfaces';
import styles from './styles.module.scss';
import FilterSection from './section';
import SavedFilters from './savedFilters';
import { useLocation } from 'react-router-dom';
import plusIcon from '../../../assets/icons/plusIcon.svg';
import minusIcon from '../../../assets/icons/minusIcon.svg';
import brownXicon from '../../../assets/icons/brownXIcon.svg';
import rightArrowIcon from '../../../assets/icons/rightArrowIcon.svg';
import vInCircle from '../../../assets/icons/SelectedAddress.svg'
import emptyCircle from '../../../assets/icons/emptyEggShape.svg'
import xInCircle from '../../../assets/icons/xInCircle.svg'
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

interface CategoriesFilterProps {
    products: ShopProduct[];
    onFilteredProducts: (filteredProducts: ShopProduct[]) => void;
    targetComponentRef: React.RefObject<HTMLElement>
}

export const CategoriesFilter: React.FC<CategoriesFilterProps> = ({
    products,
    onFilteredProducts,
    targetComponentRef,
}) => {
    const location = useLocation();
    // Add null check for products
    const safeProducts = products || [];
    const {t} = useTranslation()
    // Create a set of unique subcategories and brands
    const skinTypes = new Set<string>();
    const brands = new Set<string>();
    const subCategories = new Set<string>();
    const concerns = new Set<string>();
    const isMobile = window.innerWidth <= 440;
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [isDontShowMeVisible, setIsDontShowMeVisible] = useState(false);
    const [filteredProductsLength, setFilteredProductsLength] = useState<number>(safeProducts.length);
    const prevFilteredProductsRef = useRef<ShopProduct[]>([]);
    const isBrandpage = location.pathname.includes('/brand');

    const {currentLanguage} = useCurrentLanguage()

    safeProducts.forEach(product => {
        if (Array.isArray(product.translations?.[currentLanguage]?.skinTypes)) {
            product.translations?.[currentLanguage]?.skinTypes!.forEach((skinType: string) => skinTypes.add(skinType));
        }

        if (product.brand && !isBrandpage) {
            brands.add(product.brand);
        }
        if (Array.isArray(product.translations?.[currentLanguage]?.subcategories)) {
            product.translations?.[currentLanguage]?.subcategories!.forEach(subCategory => subCategories.add(subCategory));
        }
        if (Array.isArray(product.translations?.[currentLanguage]?.concerns)) {
            product.translations?.[currentLanguage]?.concerns!.forEach(concern => concerns.add(concern));
        }
    });

    // Menu and subMenus items data
    const skinTypesArray = Array.from(skinTypes);
    const brandsArray = Array.from(brands);
    const subCategoriesArray = Array.from(subCategories);
    const concernsArray = Array.from(concerns);

    // Chosen filters on mobile
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedPreoccupations, setSelectedPreoccupations] = useState<string[]>([]);
    const [selectedSkinTypes, setSelectedSkinTypes] = useState<string[]>([]);

    // Combined choses filters from all sections
    const [activeFilters, setActiveFilters] = useState<string[]>([]);

    // Exluded filters on mobile
    const [exludedCategories, setExludedCategories] = useState<string[]>([]);
    const [exludedBrands, setExludedBrands] = useState<string[]>([]);
    const [exludedPreoccupations, setExludedPreoccupations] = useState<string[]>([]);
    const [exludedSkinTypes, setExludedSkinTypes] = useState<string[]>([]);

    // State hooks for toggling visibility mobile
    const [showSkinTypes, setShowSkinTypes] = useState(false);
    const [showSubCategories, setShowSubCategories] = useState(false);
    const [showBrands, setShowBrands] = useState(false);
    const [dontShowBrand, setDontShowBrand] = useState(false);
    const [showConcerns, setShowConcerns] = useState(false);
    const [showFloatingButton, setShowFloatingButton] = useState(true);

    // State hooks for toggling visibility desktop
    const [activeFilterSection, setActiveFilterSection] = useState<string | null>(null);

    // State hooks for toggling visibility of dont show me desktop
    const [activeFilterSectionDontShowMe, setActiveFilterSectionDontShowMe] = useState<string | null>(null);



    const [isSubcategoriesPage, setIsSubcategoriesPage] = useState(false);
    const [openOption, setOpenOption] = useState<string | null>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);

    const toggleSubMenu = (option: string) => {
        setOpenOption(openOption === option ? null : option);
    };

    const handleToggleFilter = (section: string) => {
        setActiveFilterSection((prevSection) => (prevSection === section ? null : section));
    };

    const handleToggleFilterDontShowMe = (section: string) => {
        setActiveFilterSectionDontShowMe((prevSection) => (prevSection === section ? null : section));
    };

    function checkIfSubcategoriesPage(url: any) {
        const urlParams = new URLSearchParams(url);
        const searchParam = urlParams.get('search');
        return searchParam && searchParam.includes('=');
    }

    // Whenever one of the arrays changes (on mobile), the activeFilters change either
    useEffect(() => {
        setActiveFilters([
            ...selectedCategories,
            ...selectedBrands,
            ...selectedPreoccupations,
            ...selectedSkinTypes
        ]);
    }, [selectedCategories, selectedBrands, selectedPreoccupations, selectedSkinTypes]);

    useEffect(() => {
        const isSubcategoriesPage = checkIfSubcategoriesPage(location.search);
        setIsSubcategoriesPage(isSubcategoriesPage || false);
    }, [location.search]);

    // Handling click on filters (not in dont show me section)
    const handleFilterClick = (filter: string) => {
        setActiveFilters(currentFilters => {
            const updatedFilters = currentFilters.includes(filter)
                ? currentFilters.filter(f => f !== filter)
                : [...currentFilters, filter];
            return updatedFilters;
        });
    };

    // Handle "Don't Show Me" click
    const handleFilterDontShowMeClick = (filter: string) => {
        const removeFilter = (array: string[], setArray: React.Dispatch<React.SetStateAction<string[]>>) => {
            if (array.includes(filter)) {
                setArray(array.filter(item => item !== filter));
            }
        };

        removeFilter(exludedCategories, setExludedCategories);
        removeFilter(exludedBrands, setExludedBrands);
        removeFilter(exludedPreoccupations, setExludedPreoccupations);
        removeFilter(exludedSkinTypes, setExludedSkinTypes);
    };

    // Handling click on dont show me brands
    const handleeExludedBrandsClick = (filter: string) => {
        setExludedBrands(currentFilters => {
            const updatedFilters = currentFilters.includes(filter)
                ? currentFilters.filter(f => f !== filter)
                : [...currentFilters, filter];
            return updatedFilters;
        });
    };

    // Handling click on dont show me categories
    const handleeExludedCategoriesClick = (filter: string) => {
        setExludedCategories(currentFilters => {
            const updatedFilters = currentFilters.includes(filter)
                ? currentFilters.filter(f => f !== filter)
                : [...currentFilters, filter];
            return updatedFilters;
        });
    };

    // Handling click on dont show me preoccupations
    const handleeExludedPreoccupationsClick = (filter: string) => {
        setExludedPreoccupations(currentFilters => {
            const updatedFilters = currentFilters.includes(filter)
                ? currentFilters.filter(f => f !== filter)
                : [...currentFilters, filter];
            return updatedFilters;
        });
    };

    // Handling click on dont show me skin types
    const handleeExludedSkinTypesClick = (filter: string) => {
        setExludedSkinTypes(currentFilters => {
            const updatedFilters = currentFilters.includes(filter)
                ? currentFilters.filter(f => f !== filter)
                : [...currentFilters, filter];
            return updatedFilters;
        });
    };

    useEffect(() => {
        const targetElement = targetComponentRef.current;

        if (!targetElement) {
            console.warn('Target component not found.');
            return;
        }

        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                setShowFloatingButton(false);
            } else {
                setShowFloatingButton(true);
            }
        };


        observerRef.current = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.1,
        });

        observerRef.current.observe(targetElement);

        return () => {
            if (observerRef.current && targetElement) {
                observerRef.current.unobserve(targetElement);
                observerRef.current.disconnect();
            }
        };
    }, [targetComponentRef]);

    // Use effect to triger the filtering function whenever the filters changes
    useEffect(() => {
        const filteredProducts = getFilteredProducts();

        const filteredByExclusion = filteredProducts.filter(product => {
            const isBrandExcluded = exludedBrands.some(
                excludedBrand => product.brand?.toLowerCase() === excludedBrand.toLowerCase()
            );

            const isCategoryExcluded = exludedCategories.some(
                excludedCategory => product.translations?.[currentLanguage]?.subcategories?.some(
                    subcategory => subcategory.toLowerCase() === excludedCategory.toLowerCase()
                )
            );

            const isPreoccupationExcluded = exludedPreoccupations.some(
                excludedPreoccupation => product.translations?.[currentLanguage]?.concerns?.some(
                    concern => concern.toLowerCase() === excludedPreoccupation.toLowerCase()
                )
            );

            const isSkinTypeExcluded = exludedSkinTypes.some(
                excludedSkinType => product.translations?.[currentLanguage]?.skinTypes?.some(
                    skinType => skinType.toLowerCase() === excludedSkinType.toLowerCase()
                )
            );

            return !isBrandExcluded && !isCategoryExcluded && !isPreoccupationExcluded && !isSkinTypeExcluded;
        });

        setFilteredProductsLength(filteredByExclusion.length);

        if (JSON.stringify(filteredByExclusion) !== JSON.stringify(prevFilteredProductsRef.current)) {
            onFilteredProducts(filteredByExclusion);
            prevFilteredProductsRef.current = filteredByExclusion;
        }
    }, [activeFilters, safeProducts, exludedCategories, exludedBrands, exludedPreoccupations, exludedSkinTypes, onFilteredProducts]);

    // Filtering function
    const getFilteredProducts = () => {
        let finalProducts = safeProducts;

        const classifiedFilters: {
            skinTypes: string[];
            brands: string[];
            subCategories: string[];
            concerns: string[];
        } = {
            skinTypes: [],
            brands: [],
            subCategories: [],
            concerns: []
        };

        activeFilters.forEach(filter => {
            const lowercasedFilter = filter.toLowerCase();

            if (skinTypesArray.some(skinType => skinType.toLowerCase() === lowercasedFilter)) {
                classifiedFilters.skinTypes.push(filter);
            } else if (brandsArray.some(brand => brand.toLowerCase() === lowercasedFilter)) {
                classifiedFilters.brands.push(filter);
            } else if (subCategoriesArray.some(subCategory => subCategory.toLowerCase() === lowercasedFilter)) {
                classifiedFilters.subCategories.push(filter);
            } else if (concernsArray.some(concern => concern.toLowerCase() === lowercasedFilter)) {
                classifiedFilters.concerns.push(filter);
            }
        });

        if (activeFilters.length > 0 && !activeFilters.includes('RESET')) {
            finalProducts = finalProducts.filter(product => {

                const matchesSubcategories =
                    classifiedFilters.subCategories.length === 0 ||
                    classifiedFilters.subCategories.some(filter =>
                        product.translations?.[currentLanguage]?.subcategories?.some(sub => sub.toLowerCase() === filter.toLowerCase())
                    );

                const matchesBrands =
                    classifiedFilters.brands.length === 0 ||
                    classifiedFilters.brands.some(filter =>
                        product.brand?.toLowerCase() === filter.toLowerCase()
                    );

                const matchesConcerns =
                    classifiedFilters.concerns.length === 0 ||
                    classifiedFilters.concerns.some(filter =>
                        product.translations?.[currentLanguage]?.concerns?.some(concern => concern.toLowerCase() === filter.toLowerCase())
                    );

                const matchesSkinTypes =
                    classifiedFilters.skinTypes.length === 0 ||
                    classifiedFilters.skinTypes.some(filter =>
                        product.translations?.[currentLanguage]?.skinTypes?.some(st => st.toLowerCase() === filter.toLowerCase())
                    );

                return matchesSubcategories && matchesBrands && matchesConcerns && matchesSkinTypes;
            });
        }

        return finalProducts;
    };

    const TotalProductsSection = (
        <div className={styles.totalProducts}>
            <h3 className={styles.totalProductsTitle}>{t('categories.allProductsTitle')}</h3>
            <p className={styles.totalProductsText}>{filteredProductsLength} {t('categories.products')}</p>
        </div>
    );

    const toggleFilterVisibility = () => {
        setIsFilterVisible(prev => {
            document.body.style.overflow = prev ? 'auto' : 'hidden';
            return !prev;
        });
    };

    const toggleFilterVisibilityDontShowMeScreen = () => {
        setIsDontShowMeVisible(false);
        setIsFilterVisible(prev => {
            document.body.style.overflow = prev ? 'auto' : 'hidden';
            return !prev;
        });
    };

    const handleClose = () => {
        if (showBrands || showConcerns || showSkinTypes || showSubCategories) {
            setShowBrands(false);
            setShowConcerns(false);
            setShowSkinTypes(false);
            setShowSubCategories(false);
        } else {
            toggleFilterVisibility();
        }
    };

    const handleBack = () => {
        setIsDontShowMeVisible(false);
    };

    const handleDontShowMeClearAll = () => {
        setExludedCategories([]);
        setExludedBrands([]);
        setExludedPreoccupations([]);
        setExludedSkinTypes([]);
    };

    const handleClearAll = () => {
        setActiveFilters([]);
    };

    const dontShowMe = (
        <div className={styles.filterSectionDontShowMe}>
            <div onClick={() => setDontShowBrand(!dontShowBrand)} className={styles.filterSectionDontShowMeHeader}>
                <h3 className={styles.filterTitle}
                >{t('categories.dontShowMeTitle')}</h3>
                <img
                    src={dontShowBrand ? minusIcon : plusIcon}
                    className={styles.optionImage}
                    alt={dontShowBrand ? 'Collapse' : 'Expand'}
                />
            </div>
            {(exludedBrands.length > 0 || exludedCategories.length > 0 || exludedPreoccupations.length > 0 || exludedSkinTypes.length > 0) && (
                <div>
                    <SavedFilters
                        activeFilters={[
                            ...exludedBrands,
                            ...exludedCategories,
                            ...exludedPreoccupations,
                            ...exludedSkinTypes,
                        ]}
                        handleFilterClick={handleFilterDontShowMeClick}
                        isDontShowMe={true}
                        handleClearAllClick={handleDontShowMeClearAll}
                    />
                </div>
            )}
            {dontShowBrand && (
                <>
                    {!isSubcategoriesPage && (
                        <FilterSection
                            label={t('categories.title')}
                            items={subCategoriesArray}
                            show={activeFilterSectionDontShowMe === "Categories"}
                            setShow={() => handleToggleFilterDontShowMe("Categories")}
                            handleFilterClick={handleeExludedCategoriesClick}
                            activeFilters={exludedCategories}
                            isMobile={isMobile}
                            isDontShowMe={true}
                        />
                    )}

                    {!isBrandpage && (
                        <FilterSection
                            label={t('categories.brandsLabel')}
                            items={brandsArray}
                            show={activeFilterSectionDontShowMe === "Brands"}
                            setShow={() => handleToggleFilterDontShowMe("Brands")}
                            handleFilterClick={handleeExludedBrandsClick}
                            activeFilters={exludedBrands}
                            isMobile={isMobile}
                            isDontShowMe={true}
                        />
                    )}
                    <FilterSection
                        label={t('categories.preoccupationsLabel')}
                        items={concernsArray}
                        show={activeFilterSectionDontShowMe === "Preoccupations"}
                        setShow={() => handleToggleFilterDontShowMe("Preoccupations")}
                        handleFilterClick={handleeExludedPreoccupationsClick}
                        activeFilters={exludedPreoccupations}
                        isMobile={isMobile}
                        isDontShowMe={true}

                    />
                    <FilterSection
                        label={t('categories.skinTypesLabel')}
                        items={skinTypesArray}
                        show={activeFilterSectionDontShowMe === "Skin Types"}
                        setShow={() => handleToggleFilterDontShowMe("Skin Types")}
                        handleFilterClick={handleeExludedSkinTypesClick}
                        activeFilters={exludedSkinTypes}
                        isMobile={isMobile}
                        isDontShowMe={true}
                    />
                </>
            )}
        </div>
    );

    const dynamicSection = (
        <div className={styles.collectionSection}>
            {isSubcategoriesPage ? (
                null
            ) : (
                <FilterSection
                    label={t('categories.title')}
                    items={subCategoriesArray}
                    show={activeFilterSection === "Categories"}
                    setShow={() => handleToggleFilter("Categories")}
                    handleFilterClick={handleFilterClick}
                    activeFilters={activeFilters}
                    isMobile={isMobile}
                    isDontShowMe={false}
                />
            )}

            {!isBrandpage && (
                <FilterSection
                    label={t('categories.brandsLabel')}
                    items={brandsArray}
                    show={activeFilterSection === "Brands"}
                    setShow={() => handleToggleFilter("Brands")}
                    handleFilterClick={handleFilterClick}
                    activeFilters={activeFilters}
                    isMobile={isMobile}
                    isDontShowMe={false}
                />
            )}
            <FilterSection
                label={t('categories.preoccupationsLabel')}
                items={concernsArray}
                show={activeFilterSection === "Preoccupations"}
                setShow={() => handleToggleFilter("Preoccupations")}
                handleFilterClick={handleFilterClick}
                activeFilters={activeFilters}
                isMobile={isMobile}
                isDontShowMe={false}
            />
            <FilterSection
                label={t('categories.skinTypesLabel')}
                items={skinTypesArray}
                show={activeFilterSection === "Skin Types"}
                setShow={() => handleToggleFilter("Skin Types")}
                handleFilterClick={handleFilterClick}
                activeFilters={activeFilters}
                isMobile={isMobile}
                isDontShowMe={false}
            />

            {dontShowMe}
        </div>
    );

    return (
        <>
            {isMobile ? (
                <div className={styles.collectionCategories}>
                    {/* Floating Filter Button */}
                    <div
                        onClick={toggleFilterVisibility}
                        className={`${styles.floatingButtonContainer} ${showFloatingButton ? styles.show : styles.hide
                            }`}
                    >
                        <button className={styles.floatingButton}>{t('categories.filterButtonLabel')}</button>
                    </div>

                    {/* Mobile Filter Screen */}
                    {isFilterVisible && (
                        <div className={styles.mobileFilterSection}>
                            <div className={styles.mobileFilterSectionHeader}>
                                <div className={styles.closeButtonContainer}>
                                    <button
                                        className={styles.closeButton}
                                        onClick={handleClose}
                                    >
                                        {t('categories.closeButtonLabel')}
                                    </button>
                                </div>
                                <div className={styles.textContainer}>
                                    <h1 className={styles.filterTitleText}>{t('categories.filterButtonLabel')}</h1>
                                </div>
                            </div>
                            <div className={styles.filteringOptionsContainer}>

                                {/* CATEGORIES MENU */}
                                <div className={styles.optionContainer} onClick={() => toggleSubMenu('categories')}>
                                    <div className={styles.selectedOptionsContainer}>
                                        {selectedCategories.length > 0 && (
                                            <>
                                                {selectedCategories.map((category, index) => (
                                                    <div key={index} className={styles.selectedOptionTextContainer}>
                                                        <span className={styles.selectedOptionText}>
                                                            {category}
                                                        </span>
                                                        <img
                                                            className={styles.removeIcon}
                                                            src={brownXicon}
                                                            alt="Remove"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedCategories((prev) => prev.filter((item) => item !== category));
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                                <button
                                                    className={styles.clearAllButtonMobile}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedCategories([]);
                                                    }}
                                                >
                                                    {t('categories.clearAllButtonLabel')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.optionHeader}>
                                        <span className={styles.optionText}>{t('categories.title')}</span>
                                        <img
                                            className={styles.optionImage}
                                            src={openOption === 'categories' ? minusIcon : plusIcon}
                                            alt={openOption === 'categories' ? 'minusIcon' : 'plusIcon'}
                                        />
                                    </div>
                                </div>
                                {openOption === 'categories' && (
                                    <div className={styles.subMenu}>
                                        {subCategoriesArray.map((category, index) => (
                                            <span
                                                key={index}
                                                className={`${styles.subMenuText} ${selectedCategories.includes(category) ? styles.active : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedCategories((prev) =>
                                                        prev.includes(category) ? prev : [...prev, category]
                                                    );
                                                }}
                                            >
                                                {category}
                                                <img
                                                    className={styles.optionImage}
                                                    src={selectedCategories.includes(category) ? vInCircle : emptyCircle}
                                                    alt='checkbox'
                                                />
                                            </span>
                                        ))}
                                    </div>
                                )}

                                {/* BRANDS MENU */}
                                <div className={styles.optionContainer} onClick={() => toggleSubMenu('brands')}>
                                    <div className={styles.selectedOptionsContainer}>
                                        <div className={styles.selectedOptions}>
                                            {selectedBrands.map((brand, index) => (
                                                <div key={index} className={styles.selectedOptionTextContainer}>
                                                    <span className={styles.selectedOptionText}>
                                                        {brand}
                                                    </span>
                                                    <img
                                                        className={styles.removeIcon}
                                                        src={brownXicon}
                                                        alt="Remove"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSelectedBrands((prev) => prev.filter((item) => item !== brand));
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {selectedBrands.length > 0 && (
                                            <button
                                                className={styles.clearAllButtonMobile}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedBrands([]);
                                                }}
                                            >
                                                {t('categories.clearAllButtonLabel')}
                                            </button>
                                        )}
                                    </div>
                                    <div className={styles.optionHeader}>
                                        <span className={styles.optionText}>{t('categories.brandsLabel')}</span>
                                        <img
                                            className={styles.optionImage}
                                            src={openOption === 'brands' ? minusIcon : plusIcon}
                                            alt={openOption === 'brands' ? 'minusIcon' : 'plusIcon'}
                                        />
                                    </div>
                                </div>
                                {openOption === 'brands' && (
                                    <div className={styles.subMenu}>
                                        {brandsArray.map((brand, index) => (
                                            <span
                                                key={index}
                                                className={`${styles.subMenuText} ${selectedBrands.includes(brand) ? styles.active : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedBrands((prev) =>
                                                        prev.includes(brand) ? prev : [...prev, brand]
                                                    );
                                                }}
                                            >
                                                {brand}
                                                <img
                                                    className={styles.optionImage}
                                                    src={selectedBrands.includes(brand) ? vInCircle : emptyCircle}
                                                    alt='checkbox'
                                                />
                                            </span>
                                        ))}
                                    </div>
                                )}

                                {/* PREOCCUPATIONS MENU */}
                                <div className={styles.optionContainer} onClick={() => toggleSubMenu('preoccupations')}>
                                    <div className={styles.selectedOptionsContainer}>
                                        {selectedPreoccupations.length > 0 && (
                                            <>
                                                {selectedPreoccupations.map((concern, index) => (
                                                    <div key={index} className={styles.selectedOptionTextContainer}>
                                                        <span className={styles.selectedOptionText}>
                                                            {concern}
                                                        </span>
                                                        <img
                                                            className={styles.removeIcon}
                                                            src={brownXicon}
                                                            alt="Remove"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedPreoccupations((prev) => prev.filter((item) => item !== concern));
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                                <button
                                                    className={styles.clearAllButtonMobile}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedPreoccupations([]);
                                                    }}
                                                >
                                                    {t('categories.clearAllButtonLabel')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.optionHeader}>
                                        <span className={styles.optionText}>{t('categories.preoccupationsLabel')}</span>
                                        <img
                                            className={styles.optionImage}
                                            src={openOption === 'preoccupations' ? minusIcon : plusIcon}
                                            alt={openOption === 'preoccupations' ? 'minusIcon' : 'plusIcon'}
                                        />
                                    </div>
                                </div>
                                {openOption === 'preoccupations' && (
                                    <div className={styles.subMenu}>
                                        {concernsArray.map((concern, index) => (
                                            <span
                                                key={index}
                                                className={`${styles.subMenuText} ${selectedPreoccupations.includes(concern) ? styles.active : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedPreoccupations((prev) =>
                                                        prev.includes(concern) ? prev : [...prev, concern]
                                                    );
                                                }}
                                            >
                                                {concern}
                                                <img
                                                    className={styles.optionImage}
                                                    src={selectedPreoccupations.includes(concern) ? vInCircle : emptyCircle}
                                                    alt='checkbox'
                                                />
                                            </span>
                                        ))}
                                    </div>
                                )}

                                {/* SKIN TYPES MENU */}
                                <div className={styles.optionContainer} onClick={() => toggleSubMenu('skin types')}>
                                    <div className={styles.selectedOptionsContainer}>
                                        {selectedSkinTypes.length > 0 && (
                                            <>
                                                {selectedSkinTypes.map((skinType, index) => (
                                                    <div key={index} className={styles.selectedOptionTextContainer}>
                                                        <span className={styles.selectedOptionText}>
                                                            {skinType}
                                                        </span>
                                                        <img
                                                            className={styles.removeIcon}
                                                            src={brownXicon}
                                                            alt="Remove"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedSkinTypes((prev) => prev.filter((item) => item !== skinType));
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                                <button
                                                    className={styles.clearAllButtonMobile}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedSkinTypes([]);
                                                    }}
                                                >
                                                    {t('categories.clearAllButtonLabel')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.optionHeader}>
                                        <span className={styles.optionText}>{t('categories.skinTypesLabel')}</span>
                                        <img
                                            className={styles.optionImage}
                                            src={openOption === 'skin types' ? minusIcon : plusIcon}
                                            alt={openOption === 'skin types' ? 'minusIcon' : 'plusIcon'}
                                        />
                                    </div>
                                </div>
                                {openOption === 'skin types' && (
                                    <div className={styles.subMenu}>
                                        {skinTypesArray.map((skinType, index) => (
                                            <span
                                                key={index}
                                                className={`${styles.subMenuText} ${selectedSkinTypes.includes(skinType) ? styles.active : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedSkinTypes((prev) =>
                                                        prev.includes(skinType) ? prev : [...prev, skinType]
                                                    );
                                                }}
                                            >
                                                {skinType}
                                                <img
                                                    className={styles.optionImage}
                                                    src={selectedSkinTypes.includes(skinType) ? vInCircle : emptyCircle}
                                                    alt='checkbox'
                                                />
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>

                            { /* DONT SHOW ME BUTTON */}
                            <div className={styles.dontShowMeContainer}>
                                <div
                                    className={styles.dontShowMeTextContainer}
                                    onClick={() => setIsDontShowMeVisible(true)}
                                >
                                    <div className={styles.dontShowMeHeader}>
                                        <span className={styles.dontShowMeText}>{t('categories.dontShowMeLabel')}</span>
                                        <img className={styles.optionImage} src={rightArrowIcon} />
                                    </div>
                                    {(exludedCategories.length > 0 || exludedBrands.length > 0 || exludedPreoccupations.length > 0 || exludedSkinTypes.length > 0) && (
                                        <div className={styles.exludedOptionsContainer}>
                                            {/* Excluded Categories */}
                                            {exludedCategories.map((category, index) => (
                                                <div key={index} className={styles.selectedOptionTextContainer}>
                                                    <span className={styles.selectedOptionText}>{category}</span>
                                                    <img
                                                        className={styles.removeIcon}
                                                        src={brownXicon}
                                                        alt="Remove"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setExludedCategories((prev) => prev.filter((item) => item !== category));
                                                        }}
                                                    />
                                                </div>
                                            ))}

                                            {/* Excluded Brands */}
                                            {exludedBrands.map((brand, index) => (
                                                <div key={index} className={styles.selectedOptionTextContainer}>
                                                    <span className={styles.selectedOptionText}>{brand}</span>
                                                    <img
                                                        className={styles.removeIcon}
                                                        src={brownXicon}
                                                        alt="Remove"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setExludedBrands((prev) => prev.filter((item) => item !== brand));
                                                        }}
                                                    />
                                                </div>
                                            ))}

                                            {/* Excluded Preoccupations */}
                                            {exludedPreoccupations.map((preoccupation, index) => (
                                                <div key={index} className={styles.selectedOptionTextContainer}>
                                                    <span className={styles.selectedOptionText}>{preoccupation}</span>
                                                    <img
                                                        className={styles.removeIcon}
                                                        src={brownXicon}
                                                        alt="Remove"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setExludedPreoccupations((prev) =>
                                                                prev.filter((item) => item !== preoccupation)
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            ))}

                                            {/* Excluded Skin Types */}
                                            {exludedSkinTypes.map((skinType, index) => (
                                                <div key={index} className={styles.selectedOptionTextContainer}>
                                                    <span className={styles.selectedOptionText}>{skinType}</span>
                                                    <img
                                                        className={styles.removeIcon}
                                                        src={brownXicon}
                                                        alt="Remove"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setExludedSkinTypes((prev) => prev.filter((item) => item !== skinType));
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>)}
                                    {(exludedCategories.length > 0 ||
                                        exludedBrands.length > 0 ||
                                        exludedPreoccupations.length > 0 ||
                                        exludedSkinTypes.length > 0) && (
                                            <button
                                                className={styles.dontShowMeClearAll}
                                                onClick={handleDontShowMeClearAll}
                                            >
                                                {t('categories.clearAllButtonLabel')}
                                            </button>
                                        )}
                                </div>
                            </div>

                            <div className={`${styles.seeResultButtonContainer}`}>
                                <button
                                    disabled={
                                        activeFilters.length <= 0 &&
                                        selectedCategories.length <= 0 &&
                                        selectedBrands.length <= 0 &&
                                        selectedPreoccupations.length <= 0 &&
                                        selectedSkinTypes.length <= 0 &&
                                        exludedCategories.length <= 0 &&
                                        exludedBrands.length <= 0 &&
                                        exludedPreoccupations.length <= 0 &&
                                        exludedSkinTypes.length <= 0 ||
                                        filteredProductsLength <= 0
                                    }
                                    className={styles.seeResultButton}
                                    onClick={() => {
                                        toggleFilterVisibility();
                                    }}
                                >
                                    {`${t('categories.seeResultsButtonLabel')} (${(filteredProductsLength)})`}
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Dont Show Me Screen */}
                    {isDontShowMeVisible && (
                        <div className={styles.mobileFilterSection} style={{ backgroundColor: '#CCB9A7' }}>
                            <div className={styles.mobileFilterSectionHeader}>
                                <div className={styles.closeButtonContainer}>
                                    <button
                                        className={styles.closeButton}
                                        onClick={handleBack}
                                    >
                                        {`< ${t('categories.backButtonLabel')}`}
                                    </button>
                                </div>
                                <div className={styles.textContainer}>
                                    <h1 className={styles.filterTitleText}>{t('categories.dontShowMeTitle')}</h1>
                                </div>
                            </div>
                            <div className={styles.filteringOptionsContainer}>

                                {/* CATEGORIES MENU */}
                                <div className={styles.optionContainer} onClick={() => toggleSubMenu('categories')}>
                                    <div className={styles.selectedOptionsContainer}>
                                        {exludedCategories.length > 0 && (
                                            <>
                                                {exludedCategories.map((category, index) => (
                                                    <div key={index} className={styles.selectedOptionTextContainer}>
                                                        <span className={styles.selectedOptionText}>
                                                            {category}
                                                        </span>
                                                        <img
                                                            className={styles.removeIcon}
                                                            src={brownXicon}
                                                            alt="Remove"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setExludedCategories((prev) => prev.filter((item) => item !== category));
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                                <button
                                                    className={styles.clearAllButtonMobile}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setExludedCategories([]);
                                                    }}
                                                >
                                                    {t('categories.clearAllButtonLabel')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.optionHeader}>
                                        <span className={styles.optionText}>{t('categories.title')}</span>
                                        <img
                                            className={styles.optionImage}
                                            src={openOption === 'categories' ? minusIcon : plusIcon}
                                            alt={openOption === 'categories' ? 'minusIcon' : 'plusIcon'}
                                        />
                                    </div>
                                </div>
                                {openOption === 'categories' && (
                                    <div className={styles.subMenu}>
                                        {subCategoriesArray.map((category, index) => (
                                            <span
                                                key={index}
                                                className={`${styles.subMenuText} ${exludedCategories.includes(category) ? styles.active : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setExludedCategories((prev) =>
                                                        prev.includes(category) ? prev : [...prev, category]
                                                    );
                                                }}
                                            >
                                                {category}
                                                <img
                                                    className={styles.optionImage}
                                                    src={exludedCategories.includes(category) ? xInCircle : emptyCircle}
                                                    alt='checkbox'
                                                />
                                            </span>
                                        ))}
                                    </div>
                                )}

                                {/* BRANDS MENU */}
                                <div className={styles.optionContainer} onClick={() => toggleSubMenu('brands')}>
                                    <div className={styles.selectedOptionsContainer}>
                                        <div className={styles.selectedOptions}>
                                            {exludedBrands.map((brand, index) => (
                                                <div key={index} className={styles.selectedOptionTextContainer}>
                                                    <span className={styles.selectedOptionText}>
                                                        {brand}
                                                    </span>
                                                    <img
                                                        className={styles.removeIcon}
                                                        src={brownXicon}
                                                        alt="Remove"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setExludedBrands((prev) => prev.filter((item) => item !== brand));
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {exludedBrands.length > 0 && (
                                            <button
                                                className={styles.clearAllButtonMobile}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setExludedBrands([]);
                                                }}
                                            >
                                               {t('categories.clearAllButtonLabel')}
                                            </button>
                                        )}
                                    </div>
                                    <div className={styles.optionHeader}>
                                        <span className={styles.optionText}>{t('categories.brandsLabel')}</span>
                                        <img
                                            className={styles.optionImage}
                                            src={openOption === 'brands' ? minusIcon : plusIcon}
                                            alt={openOption === 'brands' ? 'minusIcon' : 'plusIcon'}
                                        />
                                    </div>
                                </div>
                                {openOption === 'brands' && (
                                    <div className={styles.subMenu}>
                                        {brandsArray.map((brand, index) => (
                                            <span
                                                key={index}
                                                className={`${styles.subMenuText} ${exludedBrands.includes(brand) ? styles.active : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setExludedBrands((prev) =>
                                                        prev.includes(brand) ? prev : [...prev, brand]
                                                    );
                                                }}
                                            >
                                                {brand}
                                                <img
                                                    className={styles.optionImage}
                                                    src={exludedBrands.includes(brand) ? xInCircle : emptyCircle}
                                                    alt='checkbox'
                                                />
                                            </span>
                                        ))}
                                    </div>
                                )}

                                {/* PREOCCUPATIONS MENU */}
                                <div className={styles.optionContainer} onClick={() => toggleSubMenu('preoccupations')}>
                                    <div className={styles.selectedOptionsContainer}>
                                        {exludedPreoccupations.length > 0 && (
                                            <>
                                                {exludedPreoccupations.map((concern, index) => (
                                                    <div key={index} className={styles.selectedOptionTextContainer}>
                                                        <span className={styles.selectedOptionText}>
                                                            {concern}
                                                        </span>
                                                        <img
                                                            className={styles.removeIcon}
                                                            src={brownXicon}
                                                            alt="Remove"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setExludedPreoccupations((prev) => prev.filter((item) => item !== concern));
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                                <button
                                                    className={styles.clearAllButtonMobile}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setExludedPreoccupations([]);
                                                    }}
                                                >
                                                    {t('categories.clearAllButtonLabel')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.optionHeader}>
                                        <span className={styles.optionText}>{t('categories.preoccupationsLabel')}</span>
                                        <img
                                            className={styles.optionImage}
                                            src={openOption === 'preoccupations' ? minusIcon : plusIcon}
                                            alt={openOption === 'preoccupations' ? 'minusIcon' : 'plusIcon'}
                                        />
                                    </div>
                                </div>
                                {openOption === 'preoccupations' && (
                                    <div className={styles.subMenu}>
                                        {concernsArray.map((concern, index) => (
                                            <span
                                                key={index}
                                                className={`${styles.subMenuText} ${exludedPreoccupations.includes(concern) ? styles.active : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setExludedPreoccupations((prev) =>
                                                        prev.includes(concern) ? prev : [...prev, concern]
                                                    );
                                                }}
                                            >
                                                {concern}
                                                <img
                                                    className={styles.optionImage}
                                                    src={exludedPreoccupations.includes(concern) ? xInCircle : emptyCircle}
                                                    alt='checkbox'
                                                />
                                            </span>
                                        ))}
                                    </div>
                                )}

                                {/* SKIN TYPES MENU */}
                                <div className={styles.optionContainer} onClick={() => toggleSubMenu('skin types')}>
                                    <div className={styles.selectedOptionsContainer}>
                                        {exludedSkinTypes.length > 0 && (
                                            <>
                                                {exludedSkinTypes.map((skinType, index) => (
                                                    <div key={index} className={styles.selectedOptionTextContainer}>
                                                        <span className={styles.selectedOptionText}>
                                                            {skinType}
                                                        </span>
                                                        <img
                                                            className={styles.removeIcon}
                                                            src={brownXicon}
                                                            alt="Remove"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setExludedSkinTypes((prev) => prev.filter((item) => item !== skinType));
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                                <button
                                                    className={styles.clearAllButtonMobile}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setExludedSkinTypes([]);
                                                    }}
                                                >
                                                    {t('categories.clearAllButtonLabel')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.optionHeader}>
                                        <span className={styles.optionText}>{t('categories.skinTypesLabel')}</span>
                                        <img
                                            className={styles.optionImage}
                                            src={openOption === 'skin types' ? minusIcon : plusIcon}
                                            alt={openOption === 'skin types' ? 'minusIcon' : 'plusIcon'}
                                        />
                                    </div>
                                </div>
                                {openOption === 'skin types' && (
                                    <div className={styles.subMenu}>
                                        {skinTypesArray.map((skinType, index) => (
                                            <span
                                                key={index}
                                                className={`${styles.subMenuText} ${exludedSkinTypes.includes(skinType) ? styles.active : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setExludedSkinTypes((prev) =>
                                                        prev.includes(skinType) ? prev : [...prev, skinType]
                                                    );
                                                }}
                                            >
                                                {skinType}
                                                <img
                                                    className={styles.optionImage}
                                                    src={exludedSkinTypes.includes(skinType) ? xInCircle : emptyCircle}
                                                    alt='checkbox'
                                                />
                                            </span>
                                        ))}
                                    </div>
                                )}

                                <div className={`${styles.seeResultButtonContainer}`}>
                                    <button
                                        disabled={
                                            activeFilters.length <= 0 &&
                                            selectedCategories.length <= 0 &&
                                            selectedBrands.length <= 0 &&
                                            selectedPreoccupations.length <= 0 &&
                                            selectedSkinTypes.length <= 0 &&
                                            exludedCategories.length <= 0 &&
                                            exludedBrands.length <= 0 &&
                                            exludedPreoccupations.length <= 0 &&
                                            exludedSkinTypes.length <= 0 ||
                                            filteredProductsLength <= 0
                                        }
                                        className={styles.seeResultButton}
                                        onClick={() => {
                                            toggleFilterVisibilityDontShowMeScreen();
                                        }}
                                    >
                                        {`${t('categories.seeResultsButtonLabel')} (${(filteredProductsLength)})`}
                                    </button>
                                </div>

                            </div>
                        </div>
                    )}
                </div >
            ) : (
                <div className={styles.collectionCategories}>
                    {TotalProductsSection}
                    <SavedFilters
                        activeFilters={activeFilters}
                        handleFilterClick={handleFilterClick}
                        isDontShowMe={false}
                        handleClearAllClick={handleClearAll}
                    />
                    {dynamicSection}
                </div>
            )}
        </>
    );
}
