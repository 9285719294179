import { useEffect, useState } from "react";
import { ShopProduct } from "types/ShopInterfaces";
import styles from './product.module.scss';
import { useFetchFavorites } from 'hooks/fetchFavorites';
import { useFavoriteHandler } from 'utils/faveoritUtil';
import userApi from 'service/API/UserApi';
import favFull from '../../assets/icons/favFullIcon.svg';
import favEmpty from '../../assets/icons/favEmptyIcon.svg';
import single from 'assets/icons/single.svg';
import double from 'assets/icons/double.svg';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames'; // If you prefer using a utility for conditional classes
import { useTranslation } from "react-i18next";
import { useCurrentLanguage } from "hooks/useLanguage";

interface ProductGridProps {
    products: ShopProduct[] | undefined;
    isFavoritePage?: boolean; // New prop
}

export const GridProducts: React.FC<ProductGridProps> = ({ products, isFavoritePage = false }) => {
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 440;
    const [gridColumns, setGridColumns] = useState(isFavoritePage ? 3 : 4);
    const [hoveredItem, setHoveredItem] = useState<number | null>(null);
    const { t } = useTranslation()

    const isBrandPage = window.location.pathname.includes('/brand');
    const isShopPage = window.location.pathname.includes('/shop');
    const isScanPage = window.location.pathname.includes('/ingredients-scan');

    const { favorites, setFavorites, handleFavoriteToggle } = useFavoriteHandler(userApi);

    const { currentLanguage } = useCurrentLanguage()



    useFetchFavorites(setFavorites);

    const handleMouseEnter = (index: number) => setHoveredItem(index);
    const handleMouseLeave = () => setHoveredItem(null);

    const handleProductClick = (productId: string) => {
        navigate(`/product/${productId}`);
    };

    const handleGridChange = (columns: number) => {
        if (!isFavoritePage) {
            setGridColumns(columns);
        }
    };

    useEffect(() => {
        function updateColumns() {
            const width = window.innerWidth;
            let columns = 4; // default

            if (width <= 1390 && width >= 1090) {
                columns = 3;
            }
            if (width < 1090 && width >= 786) {
                columns = 2;
            }
            if (width < 786) {
                columns = 2;
            }

            if (isMobile) {
                columns = 2;
            }
            if (isFavoritePage) {
                columns = 3;
            }

            setGridColumns(columns);
        }

        updateColumns();
        window.addEventListener("resize", updateColumns);
        return () => window.removeEventListener("resize", updateColumns);
    }, [isMobile, isFavoritePage]);

    // ---- NEW LOGIC: Should this grid be scrollable for brand page? ----
    const totalItems = products?.length || 0;
    const shouldScroll = (
        (isBrandPage && gridColumns <= 4 && totalItems > 6) ||
        (!isBrandPage && gridColumns <= 4 && totalItems > 8)
    );

    return (
        <div className={styles.collectionProducts}>
            {/** Mobile Title / Grid Switch Buttons **/}
            {isMobile && !isBrandPage && !isShopPage && !isScanPage && (
                <div className={styles.recommendationsContainer}>
                    <h1 className={styles.recommendationsTitle}>{t('shop.recommendedForYou')}</h1>
                    <div className={styles.gridChangesContainer}>
                        <div className={styles.gridChanges} onClick={() => handleGridChange(2)}>
                            <img src={double} alt="double" className={styles.gridChangesImage} />
                        </div>
                        <div className={styles.gridChanges} onClick={() => handleGridChange(1)}>
                            <img src={single} alt="single" className={styles.gridChangesImage} />
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.productContainer}>
                <div
                    // Use a conditional class that adds 'scrollable' when shouldScroll is true
                    className={classNames(
                        styles.productGrid,
                        { [styles.singleColumn]: gridColumns === 1 },
                        { [styles.scrollable]: shouldScroll }
                    )}
                    style={{ '--grid-columns': gridColumns } as React.CSSProperties}
                >
                    {products?.map((product: ShopProduct, index) => (
                        <div
                            key={product._id}
                            className={styles.productGridItem}
                            onClick={() => handleProductClick(product._id)}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className={styles.upperSectionSection}>
                                <div className={styles.justDroppedContainer}>
                                    <div className={styles.justDroppedText}>
                                        {t('shop.justDropped')}
                                    </div>
                                </div>
                                <div
                                    className={styles.favoriteIconContainer}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleFavoriteToggle(product._id);
                                    }}
                                >
                                    <img
                                        className={styles.favoriteIcon}
                                        src={favorites[product._id] ? favFull : favEmpty}
                                        alt="Favorite Icon"
                                    />
                                </div>
                            </div>
                            <img
                                className={styles.productGridItemImage}
                                src={
                                    hoveredItem === index && product.textureImgUrl
                                        ? product.textureImgUrl
                                        : product.imgUrl || 'path/to/default/image.jpg'
                                }
                                alt={product.translations?.[currentLanguage]?.title}
                                onError={(e) => {
                                    e.currentTarget.src = product.imgUrl || 'path/to/default/image.jpg';
                                }}
                            />
                            <div className={styles.productInfo}>
                                <p className={styles.productBrand}>{product.brand}</p>
                                <p className={styles.productTitle}>
                                    {product.translations?.[currentLanguage]?.title && product.translations?.[currentLanguage]!.title!.length > 20
                                        ? `${product.translations?.[currentLanguage]?.title!.substring(0, 20)}...`
                                        : product.translations?.[currentLanguage]?.title}
                                </p>
                                <p className={styles.productPrice}>
                                    €{product.price?.toFixed(2)} | ({product.quantity}ml)
                                </p>
                            </div>
                        </div>
                    ))}


                </div>
                {products?.length === 0 && (
                    <div className={styles.menuContainerSuggestionsTitle}>
                        <p>{t('shop.noProductsFound')}</p>
                    </div>
                )}
            </div>
        </div>
    );
};
