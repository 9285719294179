import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import './style.scss';
import { RecommandedProducts } from 'components/homepage/Recommended/RecommandedProducts';
import { RecentlyViewed } from 'components/homepage/RecentlyViewed/RecentlyViewed';
import { SimmilarProducts } from 'components/homepage/SimilarProducts/SimilarProducts';
import productsApi from '../../../src/service/API/ProductsApi';
import { IProductInCart } from '../../types/TInterfaces';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { userAtom } from '../../stores/UserProfile';
import { useAtom } from 'jotai';
import userService from '../../service/UserService';
import { useNavigate } from 'react-router-dom';
import { loadLocalCart, addToLocalCart } from '../../service/localCartService';
import { cartItemsAtom } from '../../stores/UserProfile';
import { ProductLoader } from './ProductLoader';
import { useFavoriteHandler } from '../../utils/faveoritUtil';
import { insertProductId, isRecentlyViewedExists } from 'utils/localStoragUtil';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Helmet } from 'react-helmet';
import { MiniProductsDisplay } from './MiniProductsDisplay'
import {config} from '../../config'
import { fallBackToClipboard } from 'service/serviceFunctions';

//Olis design numbers
import slash from '../../assets/numbers/slash.png';
import num0 from '../../assets/numbers/10.png';
import num1 from '../../assets/numbers/01.png';
import num2 from '../../assets/numbers/02.png';
import num3 from '../../assets/numbers/03.png';
import num4 from '../../assets/numbers/04.png';
import num5 from '../../assets/numbers/05.png';
import num6 from '../../assets/numbers/06.png';
import num7 from '../../assets/numbers/07.png';
import num8 from '../../assets/numbers/08.png';
import num9 from '../../assets/numbers/09.png';

//Olis design plus and minus
import plusIcon from '../../assets/icons/plusIcon.svg'
import minusIcon from '../../assets/icons/minusIcon.svg'

import currentDot from '../../assets/icons/currentdot.svg'
import lightdot from '../../assets/icons/lightdot.svg'
import userApi from 'service/API/UserApi';
import { useFetchFavorites } from 'hooks/fetchFavorites';
import { ScorePopup } from './ScorePopup/ScorePopup';
import { useTranslation } from 'react-i18next';
import { ShopProduct } from 'types/ShopInterfaces';
import { useCurrentLanguage } from 'hooks/useLanguage';


type SideMenuItem = {
  title: string;
  subtext: string;
};

export const ProductDetail = () => {
  const galleryRef = useRef<HTMLDivElement>(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [tempSelectedSize, setTempSelectedSize] = useState<string>('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<ShopProduct | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useAtom(userAtom);
  const [inCart, setInCart] = useState<boolean>(false);
  const [selectedSize, setSelectedSize] = useState<string>('');
  const navigate = useNavigate();
  const [localCart, setLocalCart] = useState<IProductInCart[]>([]);
  const [cartItems, setCartItems] = useAtom(cartItemsAtom);
  const [inStock, setInStock] = useState<boolean>(false);
  const [brandName, setBrandName] = useState<string>(product?.brand || '');
  const [isMobile, setIsMobile] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [isLongDescription, setIsLongDescription] = useState(false);
  const swiperRef = useRef<any>(null); // Swiper instance reference
  const { favorites, setFavorites, handleFavoriteToggle } = useFavoriteHandler(userApi);
  const [sideMenuItems, setSideMenuItems] = useState<SideMenuItem[]>([]);

  useFetchFavorites(setFavorites);
  const {currentLanguage} = useCurrentLanguage()
  const {t} = useTranslation()

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [recentlyViewedKey, setRecentlyViewedKey] = useState(0);
  const [displayScorePopup, setDisplayScorePopup] = useState(false);

  const toggleScorePopup = () => {
    setDisplayScorePopup((prev) => !prev);
  };

  const toggleExpand = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    setActiveImageIndex(0);
  }, [productId]);

  useEffect(() => {
    if (product) {
      const inciText = Array.isArray(product.inci) ? product.inci.join(', ') : '';
      const skinTypesText = Array.isArray(product.translations?.[currentLanguage]?.skinTypes) ? product.translations?.[currentLanguage]?.skinTypes!.join(', ') : '';

      const updatedMenuItems = [
        ...(isMobile ? [{ title: 'productDetails.description', subtext: product.translations?.[currentLanguage]?.description || '' }] : []),
        { title: 'productDetails.whyOliLovesIt', subtext: product.translations?.[currentLanguage]?.whyOliLovesIt || '' },
        { title: 'productDetails.howToUse', subtext: product.translations?.[currentLanguage]?.howToUse || '' },
        { title: 'productDetails.ingredientsList', subtext: inciText || '' },
        { title: 'productDetails.skinRecommendation', subtext: skinTypesText || '' },
      ];

      const filteredMenuItems = updatedMenuItems.filter(item => item.subtext && item.subtext.trim() !== '');


      setSideMenuItems(filteredMenuItems);
    }
  }, [product, isMobile, currentLanguage]);
  
  useEffect(() => {
    const galleryElement = galleryRef.current;

    const stopScrollPropagation = (event: WheelEvent) => {
      if (galleryElement) {
        const { scrollTop, scrollHeight, clientHeight } = galleryElement;
        const isAtTop = scrollTop === 0;
        const isAtBottom = scrollTop + clientHeight === scrollHeight;

        if ((isAtTop && event.deltaY < 0) || (isAtBottom && event.deltaY > 0)) {
          event.preventDefault();
        }
      }
    };

    if (galleryElement) {
      galleryElement.addEventListener('wheel', stopScrollPropagation, { passive: false });
    }

    return () => {
      if (galleryElement) {
        galleryElement.removeEventListener('wheel', stopScrollPropagation);
      }
    };
  }, []);


  //dynamicly create score image according to the score number 
  const getNumberImage = (digit: string) => {
    const numberImages: { [key: string]: string } = {
      '0': num0, '1': num1, '2': num2, '3': num3, '4': num4,
      '5': num5, '6': num6, '7': num7, '8': num8, '9': num9
    };
    return numberImages[digit];
  };


  const handleDotClick = (index: number) => {
    setActiveImageIndex(index);
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1360) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (productId) {
      fetchProduct(productId);
      insertProductId(productId);
      setRecentlyViewedKey((prevKey) => prevKey + 1);
    }
  }, [productId]);


  useEffect(() => {
    if (product?.brand) {
      setBrandName(product?.brand);
    }
  }, [product?.brand]);

  useEffect(() => {
    const storedCart = loadLocalCart();
    if (storedCart) setLocalCart(storedCart);
    else console.error('Error loading local cart');
  }, [cartItems]);

  useEffect(() => {
    if (user?.cart && product?._id) {
      setInCart(user.cart.some(item => item.product?._id === product._id));
    } else if (localCart && product?._id) {
      setInCart(localCart.some(item => item.product._id === product._id));
    } else {
      setInCart(false);
    }
  }, [user?.cart, localCart, product?._id]);

  useEffect(() => {
    const checkAvailability = async () => {
      try {
        if (product?.sku) {
          const result = await productsApi.checkProductAvailability(product.sku);

          setInStock(result.available);
        } else {
          setInStock(false);
        }
      } catch (error) {
        console.error("Error checking product availability:", error);
        setInStock(false);
      }
    };

    checkAvailability();

  }, [product?.sku]);


  const fetchProduct = async (id: string) => {
    try {
      setLoading(true);
      const result = await productsApi.getProductById(id);

      if (result.success && result.data) {
        setProduct(result.data as ShopProduct);
      } else {
        setProduct(null);
        setError(result.message || 'Failed to fetch product');
      }
    } catch (err) {
      setProduct(null);
      setError('An error occurred while fetching the product');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!inCart) {
      setSelectedSize('');
      setTempSelectedSize('');
    }
  }, [inCart]);


  const saveToBasket = async () => {
    try {
      if (user && product) {
        const result = await userService.addtocart(user, setUser, product, navigate);
        if (result?.success) {
          setInCart(true);
        } else {
          console.error("Error adding product to cart", result.message);
          // setError(result.message);
        }
      } else if (product) {
        const updatedCart = addToLocalCart({ product, quantity: 1 });
        setLocalCart(updatedCart);
        setCartItems(updatedCart);
        setInCart(true);
      }
    } catch (e) {
      console.error('Saving product to basket on ProductPage.tsx failed', e);
    }
  };

  const navigateToBrand = (brandName: string) => {
    const encodedBrandName = encodeURIComponent(brandName)
    navigate(`/brand/${encodedBrandName}`);
  };

  const renderRatingAdvice = (
    <>
      <div className="scoreDataContainer">
        <div className="advice-container">
          {product?.score && (
            <>
              <span className='advice-text'>{t('productDetails.olisLabRating')}</span>
              <div>
                <div className="score-display">
                  {product.score.toString().padStart(2, '0').split('').map((digit, index) => (
                    <img
                      key={index}
                      src={getNumberImage(digit)}
                      alt={digit}
                      className="score-digit"
                    />
                  ))}
                  <img src={slash} alt="/" className="score-digit" />
                  {['1', '0', '0'].map((digit, index) => (
                    <img
                      key={`total-${index}`}
                      src={getNumberImage(digit)}
                      alt={digit}
                      className="score-digit"
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>

        {product?.score && (
          <div className="explainScoreContainer">
            <span className="explainScoreText" onClick={toggleScorePopup}>
              {t('productDetails.whatDoesItMean')}
            </span>
          </div>
        )}
      </div>
    </>
  );

  if (error) {
    // TODO - Error page
    navigate('/notFound');
    setError(null);
    fetchProduct(productId || '');
    return <div className="brand-loader">
      <ProductLoader />
    </div>
    //return <div>Error: {error}</div>;
  }

  if (!product) {
    return <div className="brand-loader">
      <ProductLoader />
    </div>
  }

  const share = async () => {
          const title = product.translations?.[currentLanguage]?.metaTitle
          const message = `${t('productDetails.share.message')} \n ${title} \n`
          const url = `${config.REACT_APP_BASE_FRONTEND_URL}/product/${productId}`
      
          if (navigator.share) {
            try {
              await navigator.share ({
                text : message,
                url
              })
            } catch (shareError) {
              console.error ('Error sharing article:', shareError)
              fallBackToClipboard(message, url, t)
            }
          } else {
            fallBackToClipboard(message, url, t)
          }
        }

  return (
    <>
      <Helmet>
        <title>{product.translations?.[currentLanguage]?.metaTitle}</title>
        <meta name="description" content={product.translations?.[currentLanguage]?.metaDescription} />
      </Helmet>

      <div className="productDetailsContainer">
        <HomepageNavbar />
        <div className='product-box'>
          <div className="product-detail-container">

            <div className="side-menu">
              <div className="sideMenuContainer">
                {renderRatingAdvice}
                {sideMenuItems.map((item, index) => (
                  <div className="sideMenuItemContainer" key={index}>
                    {/* Header */}
                    <div
                      className="sideMenuItemHeader"
                      onClick={() => toggleExpand(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="sideMenuText">{t(item.title)}</span>
                      <img
                        className="plusMinusIcon"
                        src={expandedIndex === index ? minusIcon : plusIcon}
                        alt={expandedIndex === index ? "Minus Icon" : "Plus Icon"}
                      />
                    </div>

                    {/* Subtext */}
                    {expandedIndex === index && item.subtext && (
                      <div className="sideMenuSubText">{item.subtext}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="product-image-section">
              <div className="product-image">
                {isMobile && (
                  <div className='save-product-container-mobile'>
                    <button
                                    className="save-share-product-button-mobile"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        share()
                                    }}
                                >
                                    {t('common.share')}
                    </button>
                    <button className='save-share-product-button-mobile' onClick={() => handleFavoriteToggle(product._id)}>
                      {favorites[product._id] ? t('productDetails.removeFavorite')  : t('productDetails.addFavorite')}
                    </button>
                  </div>
                )}
                {loading ? (
                  <ProductLoader />
                ) : (
                  <>
                    {product?.imgUrl && product.textureImgUrl && (
                      <div className="dots-container">
                        {/* Dot for imgUrl */}
                        <img
                          src={activeImageIndex === 0 ? currentDot : lightdot}
                          alt="dot 1"
                          className="dot"
                          onClick={() => handleDotClick(0)}
                        />
                        {/* Dot for textureImgUrl */}
                        <img
                          src={activeImageIndex === 1 ? currentDot : lightdot}
                          alt="dot 2"
                          className="dot"
                          onClick={() => handleDotClick(1)}
                          onTouchStart={() => handleDotClick(1)}
                        />
                        {/* Dots for extraImgs */}
                        {product.extraImgs?.map((_, index) => {
                          const dotIndex = index + 2;
                          return (
                            <img
                              key={dotIndex}
                              src={activeImageIndex === dotIndex ? currentDot : lightdot}
                              alt={`dot ${dotIndex + 1}`}
                              className="dot"
                              onClick={() => handleDotClick(dotIndex)}
                              onTouchStart={() => handleDotClick(dotIndex)}
                            />
                          );
                        })}
                      </div>
                    )}

                    <Swiper
                      direction="vertical"
                      spaceBetween={10}
                      slidesPerView={1}
                      onSlideChange={(swiper: any) => setActiveImageIndex(swiper.activeIndex)}
                      onSwiper={(swiper: any) => (swiperRef.current = swiper)}
                      style={{ height: '669px', width: '100%', marginRight: '3%' }}
                    >
                      {product?.imgUrl && (
                        <SwiperSlide>
                          <img
                            src={product.imgUrl}
                            alt={product.translations?.[currentLanguage]?.title}
                            className="scrollable-image"
                          />
                        </SwiperSlide>
                      )}
                      {product?.textureImgUrl && (
                        <SwiperSlide>
                          <img
                            src={product.textureImgUrl}
                            alt={`${product.translations?.[currentLanguage]?.title} texture`}
                            className="scrollable-image"
                          />
                        </SwiperSlide>
                      )}

                      {/* Render extra images if available */}
                      {product?.extraImgs && product.extraImgs.length > 0 &&
                        product.extraImgs.map((imgUrl, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={imgUrl}
                              alt={`${product.translations?.[currentLanguage]?.title} extra image ${index + 1}`}
                              className="scrollable-image"
                            />
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>


                    {isMobile && (
                      <>
                        <p
                          className="productpage-brand"
                          onClick={() => navigateToBrand(product?.brand || 'Untitled-Brand')}
                        >
                          {product?.brand ? product?.brand : t('productDetails.brandNotAvailable')}
                        </p>
                        <div className="title-price-container">
                          <p className="productpage-title">{product?.translations?.[currentLanguage]?.title}</p>
                          <p className="product-price">{product?.price ? `€${product?.price}` : t('productDetails.priceNotAvailable')}</p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

            </div>
            {isMobile && (
              <div></div>
            )}

            <div className="product-info">
              {!isMobile && (
                <>
                  <div className='save-product-container'>
                  <button
                                    className="save-share-product-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        share()
                                        
                                    }}
                                >
                                    {t('common.share')}
                    </button>                    
                    <button className='save-share-product-button' onClick={() => handleFavoriteToggle(product._id)}>
                      {favorites[product._id] ? t('productDetails.removeFavorite') : t('productDetails.addFavorite')}
                    </button>
                  </div>
                  <div className="brand-name">
                    <h1 onClick={() => navigateToBrand(product?.brand || 'Untitled-Brand')}>
                      {product?.brand || t('productDetails.untitleProduct')}
                    </h1>

                    <p className="productpagePrice" style={{ fontSize: '15px' }}>
                      {product?.price ? `€${product?.price}` : t('productDetails.priceNotAvailable')}
                    </p>
                  </div>
                </>
              )}
              {!isMobile && (
                <p className="product-brand">{product?.translations?.[currentLanguage]?.title || t('productDetails.productTitleNotAvailable')}</p>
              )}

              {isMobile && <MiniProductsDisplay productId={productId as string} />}
              {!isMobile && <div className="description-container" style={{ position: 'relative' }}>
                <div
                  className={`description-style ${isMobile
                    ? showDescription
                      ? 'mobile-shown'
                      : 'mobile-hidden'
                    : ''
                    } ${showDescription ? 'no-clamp' : ''}`}
                >
                  {product?.translations?.[currentLanguage]?.description || t('productDetails.descriptionNotAvailable')}
                </div>

                {!isMobile && isLongDescription && (
                  <button
                    onClick={() => setShowDescription(!showDescription)}
                    className="show-more-btn"
                  >
                    {showDescription ? t('productDetails.showLess') : t('productDetails.showMore')}
                  </button>
                )}
              </div>}

              <div className="certificates">
                {product?.certificates &&
                  product.certificates
                    .filter((cert) => cert !== 'Unknown')
                    .map((cert, index) => (
                      <span key={index} className="certificate-badge">
                        {cert}
                      </span>
                    ))}
              </div>

              {!isMobile && (
                <>
                  <div className="product-sizes">
                    <h3>{t('productDetails.chooseSize')}</h3>
                    <div className="size-options">
                      <button
                        className={`size-button ${selectedSize === '250ml' ? 'active' : ''}`}
                        onClick={() => setSelectedSize('250ml')}
                      >
                        {product?.quantity || '250'} ml <span>{product?.price ? `€${product?.price}` : t('productDetails.priceNotAvailable')}</span>
                      </button>

                      {/* 
              TODO: when we will have different sizes for product we support different sizes and prices
              <button
                className={`size-button ${selectedSize === '500ml' ? 'active' : ''}`}
                onClick={() => setSelectedSize('500ml')}
              >
                500ml <span>${product.price}</span>
              </button>
              <button
                className={`size-button ${selectedSize === '750ml' ? 'active' : ''}`}
                disabled
              >
                750ml <span>${product.price}</span>
              </button> */}
                    </div>
                  </div>
                  <div className="add-to-cart-container">
                    <button
                      className="add-to-cart"
                      disabled={inCart || !inStock}
                      onClick={() => saveToBasket()}
                    >
                      {inStock ? (
                        inCart ? t('productDetails.inBasket') : (
                          <div className="add-to-cart-text">
                            <p>
                              <span className="add-text">{t('productDetails.addToBasket')} - </span>
                              <span className="from-text">{product?.price ? `€${product?.price.toFixed(2)}` : t('productDetails.priceNotAvailable')}</span>
                            </p>
                          </div>
                        )
                      ) : t('productDetails.outOfStock')}
                    </button>
                  </div>
                </>
              )}

              {isMobile && (
                <>
                  <button
                    className="add-to-cart"
                    onClick={() => {
                      if (!inCart && (!selectedSize || !tempSelectedSize)) {
                        setTempSelectedSize('');
                        setSelectedSize('');
                        setIsPopupOpen(true);
                      } else if (tempSelectedSize) {
                        setSelectedSize(tempSelectedSize);
                        saveToBasket();
                        setIsPopupOpen(false);
                      }
                    }}
                    disabled={inCart || (isPopupOpen && !tempSelectedSize) || !inStock}
                  >
                    {inStock ? (inCart ? t('productDetails.inBasket') : t('productDetails.addToBasket')) : t('productDetails.outOfStock')}
                  </button>
                  {isPopupOpen && (
                    <div className="size-popup-overlay">
                      <div className="size-popup">
                        <div className='header-popup'>
                          <h3>{t('productDetails.chooseSize')}</h3>
                          <button className="close-button" onClick={() => setIsPopupOpen(false)}>{t('common.close')}</button>
                        </div>
                        <div className="size-options">
                          <button
                            className={`size-button ${tempSelectedSize === '250ml' ? 'active' : ''}`}
                            onClick={() => {
                              setTempSelectedSize('250ml');
                              setSelectedSize('250ml');
                            }}
                          >
                            {product?.quantity || '250'} ml
                            <span>{product?.price ? `€${product?.price}` : t('productDetails.priceNotAvailable')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {!isMobile && (
            <>
              <MiniProductsDisplay productId={productId as string} />
            </>
          )}
        </div>

        {displayScorePopup && <ScorePopup onClose={toggleScorePopup} />}
        <RecommandedProducts brandName={brandName || 'Untitled-Brand'} />
        <SimmilarProducts productId={productId as string} />
        {isRecentlyViewedExists() && <RecentlyViewed key={recentlyViewedKey} />}
      </div >
    </>
  );
};
