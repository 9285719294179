import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useCollectionProducts } from "hooks/usCollectionProducts";
import { ShopHeadBestsellers } from 'components/shopPage/ShopHeadBestsellers/ShopHeadBestsellers';
import styles from './style.module.scss';
import SearchLoader from 'components/animation/searchLoader/searchLoader';
import { GridProducts } from 'components/grid/gridProducts';
import { FooterSection } from "components/homepage/FooterSection/FooterSection";
import { CategoriesFilter } from 'pages/Categories/CategoriesFilter/CategoriesFilter';
import { ShopProduct } from 'types/ShopInterfaces';
import { useCurrentLanguage } from 'hooks/useLanguage';
import { categoryTranslations, subcategoryTranslations } from 'utils/categoryTransUtils';

// Helper function to sanitize the search term
const sanitizeSearchTerm = (search: string): string => {
    // If the search contains "=", extract the part after "="
    const sanitized = search.includes('=') ? search.split('=')[1].trim() : search.trim();
    return sanitized.toLowerCase(); // Return a lowercase sanitized term
};

export const CollectionPage: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const { subCategoryImgUrl } = location.state || {};
    const gridProductsRef = useRef<HTMLDivElement>(null);
    const { currentLanguage } = useCurrentLanguage();
    const { products, loading } = useCollectionProducts(search);
    const [filteredProducts, setFilteredProducts] = useState<ShopProduct[]>([]);

    const getTranslatedTitle = (searchParam: string): string => {
        // Split the search parameter if it contains '='
        const parts = searchParam.toLowerCase().split('=');
        
        if (parts.length === 2) {
            // We have both category and subcategory
            const category = parts[0].trim();
            const subcategory = parts[1].trim();
            
            const translatedCategory = categoryTranslations[category]?.[currentLanguage] || category;
            const translatedSubcategory = subcategoryTranslations[subcategory]?.[currentLanguage] || subcategory;
            
            return `${translatedCategory} / ${translatedSubcategory}`;
        } else {
            // We only have a search term
            const searchTerm = parts[0].trim();
            return categoryTranslations[searchTerm]?.[currentLanguage] || 
                   subcategoryTranslations[searchTerm]?.[currentLanguage] || 
                   searchTerm;
        }
    };

    const handleFilteredProducts = (products: ShopProduct[]) => {
        setFilteredProducts(products);
    };

    useEffect(() => {
        setFilteredProducts(products);
    }, [products]);

    return (
        <>
            {loading ? (
                <div className={styles.loadingContainer}>
                    <SearchLoader />
                </div>
            ) : (
                <>
                    <div className={styles.collectionPage}>
                        <ShopHeadBestsellers
                            backgroundImage={subCategoryImgUrl}
                            bestsellerHeaderAdvice={getTranslatedTitle(search)}
                        />
                    </div>

                    <div className={styles.collectionPageContent}>
                        <div className={styles.filtered}>
                            <CategoriesFilter
                                products={products}
                                onFilteredProducts={handleFilteredProducts}
                                targetComponentRef={gridProductsRef}
                            />
                        </div>

                        <div className={styles.collectionPageGrid}>
                            <GridProducts products={filteredProducts} />
                        </div>
                    </div>
                </>
            )}
            <div ref={gridProductsRef}>
                <FooterSection />
            </div>
        </>
    );
};
