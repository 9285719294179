import React, { useState, useEffect } from 'react';
import { IIngredient, IProcessedData } from 'types/IIngredientsScanner';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import RenderTable from './RenderTable/RenderTable';
import IngredientModal from './IngredientModal/IngredientModal';
import { CarouselService } from 'components/carousels/service/carouselService';
import { ShopProduct } from 'types/ShopInterfaces';
import productsApi from 'service/API/ProductsApi';
import { GridProducts } from 'components/grid/gridProducts';

import slash from '../../../assets/numbers/slash.png';
import num0 from '../../../assets/numbers/10.png';
import num1 from '../../../assets/numbers/01.png';
import num2 from '../../../assets/numbers/02.png';
import num3 from '../../../assets/numbers/03.png';
import num4 from '../../../assets/numbers/04.png';
import num5 from '../../../assets/numbers/05.png';
import num6 from '../../../assets/numbers/06.png';
import num7 from '../../../assets/numbers/07.png';
import num8 from '../../../assets/numbers/08.png';
import num9 from '../../../assets/numbers/09.png';
import BetaBadge from '../BetaBadge/BetaBadge';
import { config } from 'config';

interface ResultsStepProps {
  processedData: IProcessedData | null;
  score: number;
}

function ResultsStep({ processedData, score }: ResultsStepProps) {
  const isMobile = window.innerWidth < 1001;
  const { t } = useTranslation();
  const [selectedIngredient, setSelectedIngredient] = useState<IIngredient | null>(null);
  const [similarProducts, setSimilarProducts] = useState<ShopProduct[]>([]);
  const [visibleItems, setVisibleItems] = useState<number>(10);

  const originalList = processedData?.results.map((item) => item.original) || [];
  const totalIngredients = processedData?.results.length || 0;

  const handleLoadMore = () => {
    setVisibleItems((prev) => Math.min(prev + 10, totalIngredients));
  };

  useEffect(() => {
    const fetchSimilarProductsData = async () => {
      try {
        const response = await productsApi.getSimilarProductsByInci(originalList);

        if (response.success) {
          setSimilarProducts(response?.data as ShopProduct[]);
        } else {
          setSimilarProducts([]);
        }
      } catch (error) {
        console.error('Error fetching similar products:', error);
      }
    };

    fetchSimilarProductsData();
  }, []);

  const getNumberImage = (digit: string) => {
    const numberImages: { [key: string]: string } = {
      '0': num0,
      '1': num1,
      '2': num2,
      '3': num3,
      '4': num4,
      '5': num5,
      '6': num6,
      '7': num7,
      '8': num8,
      '9': num9,
    };
    return numberImages[digit];
  };

  const ingredientScore: number = Math.round(score);

  const renderMobile = () => {
    return (
      <div className={styles.container}>
        { config.ENV !== 'prod' && (
          <div className={styles.ratingWrapper}>
            <div className={styles.scoreContainer}>
              <div className={styles.scoreDisplay}>
              {ingredientScore
                .toString()
                .padStart(3, '0')
                .split('')
                .map((digit, index) => (
                  <img key={index} src={getNumberImage(digit)} alt={digit} className={styles.scoreDigit} />
                ))}
              <img src={slash} alt="/" className={styles.scoreDigit} />
              {['1', '0', '0'].map((digit, index) => (
                <img key={`total-${index}`} src={getNumberImage(digit)} alt={digit} className={styles.scoreDigit} />
              ))}
            </div>
            <h2 className={styles.scoreTitle}>{t('ingredientsScan.results.score')}</h2>
          </div>
          <BetaBadge />
        </div>
        )}
        <div className={styles.ingredientsTable}>
          <div className={styles.lineContainer}>
            <div className={styles.longLine} />
            <div className={styles.shortLine} />
          </div>
          <div className={styles.ingredientsTableContainer}>
            <h2>{t('ingredientsScan.results.ingredientsFound.title')}</h2>
            <RenderTable
              processedData={{
                ...processedData!,
                results: processedData?.results.slice(0, visibleItems) || [],
              }}
              onIngredientSelect={setSelectedIngredient}
            />
            {totalIngredients > visibleItems && (
              <div className={styles.loadMoreContainer}>
                <button className={styles.loadMoreButton} onClick={handleLoadMore}>
                  {t('common.loadMore')}
                </button>
              </div>
            )}
          </div>
          {config.ENV === 'prod' && similarProducts.length !== 0&& (
            <BetaBadge/>
          )}
        </div>
        <div className={styles.similarProducts}></div>
        {/* Use key to make react differentiate between modal instances */}
        <div className={styles.ProductsGrid}>
          <div className={styles.similarProductsTitleContainer}>
            <h3 className={styles.similarProductsTitleText}>{t('ingredientsScan.results.similarProducts.title')}</h3>
          </div>
          <GridProducts products={similarProducts} />
        </div>
        <IngredientModal key={selectedIngredient ? selectedIngredient.inci_name : 'modal'} ingredient={selectedIngredient} onClose={() => setSelectedIngredient(null)} />
      </div>
    );
  };

  const renderDesktop = () => {
    return (
      <div className={styles.container}>
        { config.ENV !== 'prod' && (
        <div className={styles.ratingWrapper}>
          <div className={styles.scoreContainer}>
            <div className={styles.scoreDisplay}>
              {ingredientScore
                .toString()
                .padStart(3, '0')
                .split('')
                .map((digit, index) => (
                  <img key={index} src={getNumberImage(digit)} alt={digit} className={styles.scoreDigit} />
                ))}
              <img src={slash} alt="/" className={styles.scoreDigit} />
              {['1', '0', '0'].map((digit, index) => (
                <img key={`total-${index}`} src={getNumberImage(digit)} alt={digit} className={styles.scoreDigit} />
              ))}
            </div>
            <h2 className={styles.scoreTitle}>{t('ingredientsScan.results.score')}</h2>
          </div>

          <BetaBadge />
        </div>
        )}
        <div className={styles.ingredientsTable}>
          <div className={styles.ingredientsTableContainer}>
            <h2>{t('ingredientsScan.results.ingredientsFound.title')}</h2>
            <RenderTable
              processedData={{
                ...processedData!,
                results: processedData?.results.slice(0, visibleItems) || [],
              }}
              onIngredientSelect={setSelectedIngredient}
            />
            {totalIngredients > visibleItems && (
              <div className={styles.loadMoreContainer}>
                <button className={styles.loadMoreButton} onClick={handleLoadMore}>
                  {t('common.loadMore')}
                </button>
              </div>
            )}
          </div>
          {config.ENV === 'prod' && similarProducts.length !== 0 && (
            <BetaBadge/>
          )}
        </div>
        {similarProducts.length > 0 && (
          <div className={styles.bestsellersCarouselContainer}>
            <div className={styles.similarProductsTitleContainer}>
              <h3 className={styles.similarProductsTitleText}>{t('ingredientsScan.results.similarProducts.title')}</h3>
            </div>
          <CarouselService
            products={similarProducts}
            carouselLayout="GENERAL"
            quantity={5}
            paginationLocation={'down'}
            slideStyles={{
              desktop: {
                relatedProductSlide: { height: '67vh', width: '24.5vw', pointerEvents: 'all' },
                imageContainer: { maxHeight: '50vh' },
              },
              mobile: {
                relatedProductSlide: { height: '51vh', width: '73vw', pointerEvents: 'all' },
                imageContainer: {},
              },
            }}
            />
          </div>
        )}
        <IngredientModal key={selectedIngredient ? selectedIngredient.inci_name : 'modal'} ingredient={selectedIngredient} onClose={() => setSelectedIngredient(null)} />
      </div>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
}

export default ResultsStep;

// NOTE: this code shows the user the picture he took, what the extracted text is and gives an option to retake the picture or analyze the text
// import { useState } from 'react';
// import { useEffect } from 'react';
// import GeneralButton from '../GeneralButton';
// import styles from './styles.module.scss';
// import { useTranslation } from 'react-i18next';

// interface ResultsStepProps {
//   imageUrl?: string;
//   extractedText: string | undefined;
//   onAnalyze: (cleanText: string) => void;
// }

// export default function ResultsStep({ imageUrl, extractedText, onAnalyze }: ResultsStepProps) {
//   const { t } = useTranslation();
//   const [cleanText, setCleanText] = useState(extractedText || '');

//   useEffect(() => {
//     const cleanText = extractedText?.toLowerCase()
//       .replace(/[^a-zA-Z0-9\s()%-]/g, '')
//       .replace(/\s+/g, ' ')
//       .trim();
//     setCleanText(cleanText || '');
//   }, [extractedText]);

//   return (
//     <div className={styles.resultsContainer}>
//       <h2>Scanned Results</h2>
//       {imageUrl && (
//         <div className={styles.imageContainer}>
//           <img src={imageUrl} alt="Scanned ingredients" className={styles.scannedImage} />
//         </div>
//       )}
//       {extractedText && (
//         <div className={styles.textContainer}>
//           <h3>Extracted Text:</h3>
//           <pre className={styles.extractedText}>{cleanText}</pre>
//         </div>
//       )}
//       <div className={styles.buttonContainer}>
//         <GeneralButton text={t('ingredientsScan.scanner.scan.analyze')} onClick={() => onAnalyze(cleanText)} />
//         <GeneralButton text={t('ingredientsScan.scanner.scan.scanAgain')} onClick={() => window.location.reload()} />

//       </div>
//     </div>
//   );
// }
