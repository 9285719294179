import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import articlesApi from '../../../../service/API/articleApi';
import Userapi from '../../../../service/API/UserApi';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { userAtom } from 'stores/UserProfile';
import { useFetchFavoriteArticles } from 'hooks/fetchFavoritesArticles';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
import { Scrollbar } from 'swiper/modules';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

interface Article {
    id: string;
    title: string;
    imageUrl: string;
    dossier: string;
}

export const FavoriteArticleSection = () => {
    const [articles, setArticles] = useState<Article[]>([]);
    const navigate = useNavigate();
    const [user] = useAtom(userAtom);
    const handleSeeAll = () => {
        navigate('/profile/saved-articles');
    };
    const {t} = useTranslation()

    useEffect(() => {
        if (user) {
            fetchArticles();
        }
    }, []);

    const fetchArticles = async () => {
        const res = await Userapi.getUserFavoritesArticles();
        if (res.length > 0) {
            const articlesResponse = await articlesApi.getArticlesByArrayOfIds(res);
            const articles = (articlesResponse.data || []).map((article: any) => ({
                id: article.id,
                title: article.properties?.Title?.title?.[0]?.plain_text || '',
                imageUrl: article.properties?.Image?.files?.[0]?.url || '',
                dossier: article.dossierNames.slice(0, 1).join(', ')
            }));
            setArticles(articles);
        }

    };
    const noLikedArticles = (
        <>
            <div className={styles.noLikedArticles}>
                <p className={styles.noLikedArticlesTitle}>{t('favoriteArticleSection.noLikedArticlesMessage')}</p>
            </div>
            <div className={styles.noLikedArticlesButtonContainer}>
                <button className={styles.noLikedArticlesButton} onClick={handleSeeAll}>{t('favoriteArticleSection.discoverArticlesButton')}</button>
            </div>
        </>

    );

    const renderArticles = () => {
        return (
            <Swiper
                slidesPerView={2}
                spaceBetween={50}
                loop={true}
            >
                {articles.map((article) => (
                    <SwiperSlide key={article.id}>
                        <div
                            className={styles.articleCard}
                            style={{
                                backgroundImage: `url(${article.imageUrl})`
                            }}
                        >
                            <div className={styles.articleDossiers}>
                                <div className={styles.articleDossier}>
                                    <p className={styles.articleDossierText}>{article.dossier}</p>
                                </div>
                            </div>
                            <div className={styles.articleTitleContainer}>
                                <h3 className={styles.articleTitle}>{article.title}</h3>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    };

    const renderContent = () => {
        if (articles.length === 0) {
            return noLikedArticles;
        } else {
            return renderArticles();
        }
    };

    return (
        <div className={styles.favoriteArticleSection}>
            <div className={styles.favoriteArticleContainer}>
                <h1 className={styles.favoriteArticleTitle}>{t('favoriteArticleSection.title')}</h1>
                <p className={styles.favoriteArticleSeeAll} onClick={handleSeeAll}>
                    {t('favoriteArticleSection.seeAllButton')}
                </p>
            </div>
            <div className={styles.articleContainer}>
                {renderContent()}
            </div>
        </div>
    );
};
