import styles from './style.module.scss';
import { ShopHeadSection } from 'components/shopPage/ShopHeadSection/ShopHeadSection';
import { PopularCategories } from 'components/shopPage/PopularCategories/PopularCategories';
import { LargePhotoSection } from 'components/shopPage/LargePhotoSection/LargePhotoSection';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import { useEffect, useRef, useState } from 'react';
import { ShopPageGrid } from 'pages/shopPageGrid/shopPageGrid';
import { BestSellersBrandSection } from 'components/homepage/BestSellersSection/BestSellersBrandSection';
import { useDynamicImages } from 'hooks/dynamicImages';
import CollectionApi from 'service/API/collectionApi';
import CategoriesSection from 'pages/categoriesSection/categoriesSection';
import { useWindowSize } from 'hooks/useWindowSize';

// Define the interface for tile items
interface Tile {
  id: string;
  name: string;
  description: string;
  image: string;
  products: any[];
  text: string;
}

export const ShopPage = () => {
  const { dynamicImages } = useDynamicImages();
  const [shopCarousel, setShopCarousel] = useState<string[]>([]);
  const [tiles, setTiles] = useState<Tile[]>([]);
  const [initialTiles, setInitialTiles] = useState<Tile[]>([]); // New state for initial tiles
  const { isMobile } = useWindowSize();

  const TILES_NAMES = ['APRIL FAVOURITES', '10% OFF YOUR FIRST ORDER', 'NEW LAUNCHES']

  // First useEffect - fetch initial data
  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const collections = await CollectionApi.getTilesByName(TILES_NAMES);
        if (collections && Array.isArray(collections)) {
          const sortedCollections = collections.sort((a, b) => {
            return TILES_NAMES.indexOf(a.name.toUpperCase()) - TILES_NAMES.indexOf(b.name.toUpperCase());
          });
          setInitialTiles(sortedCollections);
          setTiles(sortedCollections);
        }
      } catch (error) {
        console.error("Error fetching collections:", error);
        setTiles([]); // Prevent undefined issues
        setInitialTiles([]);
      }
    };

    fetchCollections();
  }, []);

  // Second useEffect - process dynamic images
  useEffect(() => {
    if (!dynamicImages) return;

    const shopCarousel: string[] = [];
    const shopTiles: string[] = [];

    Object.entries(dynamicImages).forEach(([_, value]) => {
      const valueLower = (value as string).toLowerCase();
      if (valueLower.includes("/shopbackground/")) {
        shopCarousel.push(value as string);
      }
      if (valueLower.includes("/tilesshop/")) {
        shopTiles.push(value as string);
      }
    });

    setShopCarousel(shopCarousel);
    // Re-map tiles to attach the dynamic image
    setTiles(prevTiles => {
      const newTiles = prevTiles.map((tile, index) => ({
        ...tile,
        image: shopTiles[index] || initialTiles[index]?.image || "",
      }))
      return newTiles;
    })
  }, [dynamicImages, initialTiles]); // Added initialTiles as dependency

  const sectionsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (!isMobile) {
      let isScrolling = false;
      let isDragging = false;

      const debounce = (func: (...args: any[]) => void, delay: number) => {
        let timeoutId: NodeJS.Timeout;
        return (...args: any[]) => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => func(...args), delay);
        };
      };

      const debouncedScroll = debounce((target: HTMLElement) => {
        smoothScrollTo(target);
      }, 100);

      const smoothScrollTo = (target: HTMLElement, duration = 700) => {
        if (isScrolling || isDragging) return;
        isScrolling = true;

        const startPosition = window.scrollY;
        const targetPosition = target.getBoundingClientRect().top + window.scrollY;
        const distance = targetPosition - startPosition;
        let startTime: number | null = null;

        const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
          t /= d / 2;
          if (t < 1) return (c / 2) * t * t + b;
          t--;
          return (-c / 2) * (t * (t - 2) - 1) + b;
        };

        const animation = (currentTime: number) => {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
          window.scrollTo(0, run);
          if (timeElapsed < duration) {
            requestAnimationFrame(animation);
          } else {
            isScrolling = false;
          }
        };

        requestAnimationFrame(animation);
      };

      const handleMouseDown = () => {
        isDragging = true;
      };

      const handleMouseUp = () => {
        isDragging = false;
      };

      window.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mouseup', handleMouseUp);

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
              const index = sectionsRef.current.findIndex(
                (section) => section === entry.target
              );

              if (index !== -1) {
                debouncedScroll(sectionsRef.current[index]);
              }
            }
          });
        },
        {
          threshold: 0.3,
        }
      );

      sectionsRef.current.forEach((section) => {
        if (section) observer.observe(section);
      });

      return () => {
        observer.disconnect();
        window.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }
  });

  return (
    <div className={styles.shopPageContainer}>
      <div className='shopHeadSection' ref={(el) => (sectionsRef.current[0] = el!)} style={{ maxHeight: '100svh' }}>
        <ShopHeadSection carousel={shopCarousel} />
      </div>

      <div className='categoriesSectionAndShop' style={{ minHeight: '100svh', height: 'auto' }} ref={(el) => (sectionsRef.current[1] = el!)}>

        {tiles.length >= 3 && <PopularCategories tiles={tiles} />}
        <CategoriesSection />

        <div style={{ minHeight: '100dvh' }}>
          <ShopPageGrid />
        </div>
      </div>

      <div className='largePhotoSection' style={{ minHeight: '101dvh' }} ref={(el) => (sectionsRef.current[2] = el!)}>
        <LargePhotoSection />
      </div>

      <div className='bestSellersBrandSection' style={{ minHeight: '100dvh' }} ref={(el) => (sectionsRef.current[3] = el!)}>
        <BestSellersBrandSection />
      </div>

      <FooterSection />
    </div>
  );
};
