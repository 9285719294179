import { useParams } from "react-router-dom";
import { useEffect, useState, useMemo, useRef } from "react";
import { FooterSection } from "components/homepage/FooterSection/FooterSection";
import { ShopBrandBestseller } from "components/shopPage/shopBrandBestseller/shopBrandBestseller";
import ProductsApi, { Brand } from "service/API/ProductsApi";
import { backgroundBrands } from "assets/brands/backgrounds/backgroundBrands";
import { ShopProduct } from "types/ShopInterfaces";
import { CategoriesFilter } from "pages/Categories/CategoriesFilter/CategoriesFilter";
import styles from "./brand.module.scss";
import { useAllBrands } from "hooks/useAllBrands";
import { BrandDetailsSection } from "./brandDetailsSection/brandDetailsSection";
import { ArticlesCarousel } from "components/homepage/ArticlesCarousel/ArticlesCarousel";
import { useAtom } from "jotai";
import { userAtom } from "stores/UserProfile";
import { CombineSection } from "components/combineSection/combineSection";
import SearchLoader from "components/animation/searchLoader/searchLoader";
import { WhyOli } from "pages/whyOLI/whyOli";
import { EthosSection } from "pages/ethosSection/ethosSection";
import { set } from "lodash";
import { GridProducts } from "components/grid/gridProducts";
import { ArticlesPreview } from "components/homepage/ArticlesPreview/ArticlesPreview";
import { EditSection } from "components/edit/editSection";
import { ActivePreview } from "components/homepage/ActivePreview/ActivePrevie";

type BrandWithCategories = Brand & {
    categories?: string[];
    about?: string;
    productCount?: number;
    WhyOli?: string;
    banner?: [];
    backgroundImage?: string;
    titleImage?: string;
    tiles?: [];
};

// Define the response type to include averageScore
interface ApiResponse {
    success: boolean;
    data?: ShopProduct[];
    averageScore?: number;
    message?: string;
}

export const BrandsHomePage = () => {
    const { brandName } = useParams<{ brandName: string }>();
    const [user] = useAtom(userAtom);
    // State for brand images
    const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
    const [brandNameImage, setBrandNameImage] = useState<string | null>(null);

    // We want to determine if it's mobile only once (or you can implement a custom hook to listen on window resize)
    const isMobile = useMemo(() => window.innerWidth < 440, []);
    const isMobileForScroll = window.innerWidth < 768;

    const [products, setProducts] = useState<ShopProduct[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<ShopProduct[]>([]);
    const { data: allBrandsData, error, isLoading } = useAllBrands();
    const [details, setDetails] = useState<BrandWithCategories | null>(null);
    const [averageScore, setAverageScore] = useState<number>(0);
    const gridProductsRef = useRef<HTMLDivElement>(null);

    // Initialize sectionsRef
    const sectionsRef = useRef<HTMLDivElement[]>([]);

    // Define magnetScrollingStyle
    const magnetScrollingStyle = {
        skincareJourney: {
            background: '#f0f0f0',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
            cursor: 'pointer',
        },
        browsingThrough: {
            background: '#e0e0e0',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
            cursor: 'pointer',
        },
        saferIngredients: {
            background: '#d0d0d0',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
            cursor: 'pointer',
        },
    };

    useEffect(() => {
        if (!isMobileForScroll) {
            let isScrolling = false;
            let isDragging = false;

            const debounce = (func: (...args: any[]) => void, delay: number) => {
                let timeoutId: NodeJS.Timeout;
                return (...args: any[]) => {
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(() => func(...args), delay);
                };
            };

            const debouncedScroll = debounce((target: HTMLElement) => {
                smoothScrollTo(target);
            }, 100);

            const smoothScrollTo = (target: HTMLElement, duration = 700) => {
                if (isScrolling || isDragging) return;
                isScrolling = true;

                const startPosition = window.scrollY;
                const targetPosition = target.getBoundingClientRect().top + window.scrollY;
                const distance = targetPosition - startPosition;
                let startTime: number | null = null;

                const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
                    t /= d / 2;
                    if (t < 1) return (c / 2) * t * t + b;
                    t--;
                    return (-c / 2) * (t * (t - 2) - 1) + b;
                };

                const animation = (currentTime: number) => {
                    if (startTime === null) startTime = currentTime;
                    const timeElapsed = currentTime - startTime;
                    const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
                    window.scrollTo(0, run);
                    if (timeElapsed < duration) {
                        requestAnimationFrame(animation);
                    } else {
                        isScrolling = false;
                    }
                };

                requestAnimationFrame(animation);
            };

            const handleMouseDown = () => {
                isDragging = true;
            };

            const handleMouseUp = () => {
                isDragging = false;
            };

            window.addEventListener('mousedown', handleMouseDown);
            window.addEventListener('mouseup', handleMouseUp);

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && entry.intersectionRatio >= 0.3) {
                            const index = sectionsRef.current.findIndex(
                                (section) => section === entry.target
                            );

                            if (index !== -1) {
                                debouncedScroll(sectionsRef.current[index]);
                            }
                        }
                    });
                },
                {
                    threshold: 0.3,
                }
            );

            sectionsRef.current.forEach((section) => {
                if (section) observer.observe(section);
            });

            return () => {
                observer.disconnect();
                window.removeEventListener('mousedown', handleMouseDown);
                window.removeEventListener('mouseup', handleMouseUp);
            };
        }
    });

    // Effect to handle brand-specific data whenever brandName changes
    useEffect(() => {
        if (!brandName || !allBrandsData) return;

        // 1. Prepare brand name capitalization (if your API requires it)
        const capitalizedBrandName =
            brandName.charAt(0).toUpperCase() + brandName.slice(1);

        // 2. Fetch products by brand name
        ProductsApi.getProductsByBrand(capitalizedBrandName, true)
            .then((response: ApiResponse) => {
                if (response.success && response.data) {
                    setProducts(response.data);
                    setAverageScore(response.averageScore || 0);
                }
            })
            .catch((error) => console.error("Error fetching products:", error));

        // 3. Update background images based on brand name


        // 4. Find brand details in allBrandsData
        const foundDetails = allBrandsData.find(
            (b) => b.brand.toLowerCase() === brandName.toLowerCase()
        ) as BrandWithCategories | undefined;
        setDetails(foundDetails || null);
        setBackgroundImage(foundDetails?.backgroundImage || '');
        setBrandNameImage(foundDetails?.titleImage || '');
    }, [brandName, allBrandsData, isMobile]);

    // Handler for filtering
    const handleFilteredProducts = (filtered: ShopProduct[]) => {
        setFilteredProducts(filtered);
    };

    if (isLoading) {
        // Renders your loader (or placeholder) and nothing else.
        return (
            <div className={styles.loaderContainer}>
                <SearchLoader />
            </div>
        );
    }

    return (

        <div className={styles.collectionPage}>
            {/* ShopBrandBestseller Section */}
            <div ref={(el) => (sectionsRef.current[0] = el!)} className="section1" style={{ height: '100svh', overflow: 'hidden' }}>
                <ShopBrandBestseller
                    backgroundImage={backgroundImage || ""}
                    brandname={brandNameImage || ""}
                />
            </div>


            <div ref={(el) => (sectionsRef.current[1] = el!)} className="section2" style={{ height: 'auto', overflow: 'hidden' }}>
                {/* BrandDetailsSection: Render only if details && details.about */}
                {details?.about && (
                    <BrandDetailsSection about={details.about} score={averageScore} />
                )}
            </div>


            <div className={styles.collectionPageContent}>
                {/* Show filter on larger screens */}
                {!isMobile && (
                    <div className={styles.categoriesFilter}>
                        <CategoriesFilter
                            products={products}
                            onFilteredProducts={handleFilteredProducts}
                            targetComponentRef={gridProductsRef}
                        />
                    </div>
                )}

                {/* BrandGrid displaying the filtered list (or all products by default) */}
                <div className={styles.collectionPageGrid}>
                    <GridProducts products={filteredProducts.length ? filteredProducts : products} />
                </div>
            </div>

            {/* Additional sections below */}

            <div ref={(el) => (sectionsRef.current[2] = el!)} className="section3" style={{ minHeight: '100svh', height: 'auto', overflow: 'hidden' }}>
                <EditSection tiles={details?.tiles || []} />
                {details?.WhyOli ? (
                    <WhyOli whyOLI={details?.WhyOli || ''} brandName={brandName} />

                ) : (
                    null
                )}
            </div>


            <div className="section4" style={{ height: 'auto', overflow: 'hidden' }}>
                {details?.banner ? (
                    <CombineSection
                        banners={details?.banner}
                        brandName={brandName}
                        magnetScrollingStyle={magnetScrollingStyle}
                        sectionsRef={sectionsRef}
                    />
                ) : (
                    <CombineSection
                        magnetScrollingStyle={magnetScrollingStyle}
                        sectionsRef={sectionsRef}
                    />
                )}
            </div>

            <div ref={(el) => (sectionsRef.current[6] = el!)} className="section5" style={{ height: '100dvh', overflow: 'hidden' }}>
                <EthosSection />
            </div>
            <ActivePreview isLoggedIn={!!user} />
            <div ref={gridProductsRef}>
                <FooterSection />
            </div>
        </div>
    );
};
