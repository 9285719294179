interface SubcategoryTranslations {
    [key: string]: {
        en: string;
        fr: string;
    };
}

export const subcategoryTranslations: SubcategoryTranslations = {
    "moisturisers": { en: "moisturisers", fr: "hydratants" },
    "cleansers": { en: "cleansers", fr: "nettoyants" },
    "serums": { en: "serums", fr: "sérums" },
    "toners": { en: "toners", fr: "toniques" },
    "sun care": { en: "sun care", fr: "protection solaire" },
    "lip care": { en: "lip care", fr: "soin des lèvres" },
    "essences": { en: "essences", fr: "essences" },
    "mask": { en: "mask", fr: "masque" },
    "deodorant": { en: "deodorant", fr: "déodorant" },
    "body moisturiser": { en: "body moisturiser", fr: "hydratant corps" }
};