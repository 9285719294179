import React from "react";
import styles from "./OrderTrack.module.scss";
import { IOrder } from "types/TInterfaces";
import { OrderTimeline } from "../OrderTimeline/OrderTimeline";
import { useTranslation } from "react-i18next";

interface OrderInfoProps {
    order: IOrder;
}

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        weekday: "short",
        day: "numeric",
        month: "long",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    return formattedDate.replace(",", ".");
};

export const OrderTrack: React.FC<OrderInfoProps> = ({ order }) => {
    const {t} = useTranslation()
    const orderShippingStatus = Array.isArray(order.statusHistory) && order.statusHistory.length > 0
        ? order.statusHistory[order.statusHistory.length - 1].status
        : t("profile.myOrders.orderTrack.noStatus");

    const isDisabled = ['RETURNED', 'CANCELLED', 'FAILED'].includes(orderShippingStatus);


    const getStatusDate = (status: string, statusHistory: any[]) => {
        const statusObj = statusHistory.find(item => item.status === status);
        if (statusObj) {
            const date = new Date(statusObj.updated_at);
            return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
        }
        return null;
    };

    const sections = [
        { title: t("profile.myOrders.orderTrack.orderReceived"), date: getStatusDate("RECEIVED", order.statusHistory) },
        { title: t("profile.myOrders.orderTrack.processed"), date: getStatusDate("PROCESSED", order.statusHistory) },
        { title: t("profile.myOrders.orderTrack.orderShipped"), date: getStatusDate("SHIPPED", order.statusHistory) },
        { title: t("profile.myOrders.orderTrack.orderDeliveredCustomer"), date: getStatusDate("DELIVERED", order.statusHistory) },
    ];

    // Mock date. meanwhile not in use.
    const estimatedDelivery = "2023-03-06";

    return (
        <div className={styles.orderTrackContainer}>
            {/* Left: Estimated Delivery Time */}
            <div className={styles.estimatedDelivery}>
                {!isDisabled ? (
                    <>
                        <h3>{t("profile.myOrders.orderTrack.estimatedDelivery")}</h3>
                        {/* <h3>{formatDate(estimatedDelivery)}</h3> */}
                    </>
                ) : (
                    <h3>{t(`profile.myOrders.orderTrack.${orderShippingStatus.toLowerCase()}`)}</h3>
                )}
            </div>

            {/* Right: Order Tracker */}
            <div className={styles.orderTracker}>
                <div className={styles.timelineAndData}>
                    <OrderTimeline step={orderShippingStatus} />
                    <div className={styles.trackDataContainer}>
                        {sections.map((section, index) => (
                            <div key={index} className={styles.trackDataSection}>
                                <h3 className={styles.trackDataContainerTitle}>{section.title}</h3>
                                <p className={styles.trackDataContainerDate}>{section.date}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
