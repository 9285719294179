import React, { useState, useEffect } from 'react';
import styles from './ProfileVouchers.module.scss';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';
import userService from '../../../service/UserService';
import { Voucher } from 'types/TInterfaces';
import Modal from './components/Modal';
import SelectedVoucherModal from './components/SelectedVoucherModal';
import { useTranslation } from 'react-i18next';

export const ProfileVouchers: React.FC = () => {
    const isMobile = window.innerWidth < 768;
    const [user, setUser] = useAtom(userAtom);
    const [voucherCode, setVoucherCode] = useState('');
    const [error, setError] = useState('');
    const [userVouchers, setUserVouchers] = useState<Voucher[]>([]);
    const [showAddVoucher, setShowAddVoucher] = useState(false);
    const [selectedVoucher, setSelectedVoucher] = useState<Voucher | null>(null);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation()


    useEffect(() => {
        if (user) {
            fetchUserVouchers();
        }
    }, [user?._id]);


    const fetchUserVouchers = async () => {
        if (user) {
            setLoading(true);
            const response = await userService.getUserVouchers(user, setUser);
            if (response.success) {
                setUserVouchers(response.vouchers);
            }
            setLoading(false);
        }
    };


    const handleAddVoucher = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        if (!voucherCode.trim()) {
            setError('Please enter a voucher code');
            return;
        }

        if (user) {
            const response = await userService.addVoucher(user, setUser, voucherCode);
            if (response.success) {
                setVoucherCode('');
                setShowAddVoucher(false);

                await fetchUserVouchers();
            } else {
                setError(response.message || 'Failed to add voucher');
            }
        }
    };

    const handleCardClick = (voucher: Voucher) => {
        setSelectedVoucher(voucher);
    };

    const lastVoucher = userVouchers.length > 0 ?
        userVouchers
            .filter(voucher => voucher && voucher.voucherID) // Add safety check
            .sort((a, b) => new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime())[0]
        : null;

    const allVouchers = userVouchers
        .filter(v => v && v.voucherID && v !== lastVoucher);

    const isVoucherInactive = (voucher: Voucher) => {
        return (
            voucher.status === 'used' ||
            new Date(voucher.voucherID.expirationDate) < new Date() ||
            !voucher.voucherID.active
        );
    };

    const getBackgroundStyle = (imageUrl: string | undefined) => imageUrl ? { backgroundImage: `url(${imageUrl})` } : { backgroundColor: '#5D5246' };


    return (
        <>
            {!isMobile ? (
                <div className={styles.desktopContainer}>
                    <HomepageNavbar />
                    <div className={styles.topSection}>
                        <div className={styles.menuTopSection}>
                            {/* PLACE HOLDER DONT REMOVE */}
                        </div>
                        <div className={styles.contentTopSection}>
                            <h3 className={styles.userName}>{user?.name}</h3>
                            <h3 className={styles.userName}>{user?.lastName}</h3>
                        </div>
                    </div>
                    <div className={styles.bottomSection}>
                        <div className={styles.menuSection}>
                            <ProfileMenu />
                        </div>
                        <div className={styles.contentSection}>
                            <div className={styles.voucherTitle}>
                                <div className={styles.voucherSectionHeaderMain}>
                                    <span className={styles.voucherSectionHeader}>{t('profileVouchers.vouchers')}</span>
                                    <span className={styles.voucherCount}>{t(userVouchers.length > 1 ? 'profileVouchers.voucherCountPlural' : 'profileVouchers.voucherCountSingle', { count: userVouchers.length })}</span>
                                </div>
                                <span className={styles.addVoucherButton} onClick={() => setShowAddVoucher(true)}>
                                    {t('profileVouchers.addVoucher')}
                                </span>
                            </div>

                            {!loading && userVouchers.length === 0 && (
                                <div className={styles.noVouchersContainer}>
                                    <div className={styles.noVouchersCircle}>
                                        <h2 className={styles.noVouchersHeader}>{t('profileVouchers.noVouchersYet')}</h2>
                                        <p className={styles.noVouchersP}>{t('profileVouchers.readyToReveal')}</p>
                                        <button className={styles.addVoucherButtonEmpty} onClick={() => setShowAddVoucher(true)}>
                                            {t('profileVouchers.addVoucherButton')}
                                        </button>
                                    </div>
                                </div>
                            )}

                            {lastVoucher && lastVoucher.voucherID && (
                                <div className={styles.lastVoucherSection}>
                                    <h3 className={styles.sectionTitle}>{t('profileVouchers.lastVouchers')}</h3>
                                    <div className={`${styles.voucherCard} ${styles.lastVoucherCard}`}
                                        style={getBackgroundStyle(lastVoucher.voucherID.imageUrl)}
                                        onClick={() => handleCardClick(lastVoucher)}>
                                        <div className={styles.expirationDateContainer}>
                                            <p className={styles.expirationDate}>
                                                {lastVoucher.status === 'used'
                                                    ? t('profileVouchers.used')
                                                    : !lastVoucher.voucherID.active
                                                        ? t('profileVouchers.notActive')
                                                        : new Date(lastVoucher.voucherID.expirationDate) < new Date()
                                                            ? t('profileVouchers.expired')
                                                            : `${t('profileVouchers.expirationPrefix')} ${new Date(lastVoucher.voucherID.expirationDate).toLocaleDateString('en-GB')}`
                                                }
                                            </p>
                                        </div>
                                        <div className={styles.voucherInfo}>
                                            <p className={styles.voucherCode}>{lastVoucher.voucherID.code}</p>
                                            <p className={styles.discountValue}>
                                                {lastVoucher.voucherID.discountType === 'percentage'
                                                    ? t('profileVouchers.percentageOff', {value: lastVoucher.voucherID.discountValue})
                                                    : t('profileVouchers.euroOff', {value: lastVoucher.voucherID.discountValue})
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {allVouchers.length > 0 && (
                                <>
                                    <h3 className={styles.sectionTitle}>{t('profileVouchers.allVouchers')}</h3>
                                    <div className={styles.voucherList}>
                                        {allVouchers.map((voucher) => (
                                            <div key={voucher._id}
                                                className={`${styles.voucherCard} ${styles.regularVoucherCard}`}
                                                style={getBackgroundStyle(voucher.voucherID.imageUrl)}
                                                onClick={() => handleCardClick(voucher)}>
                                                <div className={styles.expirationDateContainer}>
                                                    <p className={styles.expirationDate}>
                                                        {voucher.status === 'used'
                                                            ? t('profileVouchers.used')
                                                            : !voucher.voucherID.active
                                                                ? t('profileVouchers.notActive')
                                                                : new Date(voucher.voucherID.expirationDate) < new Date()
                                                                    ? t('profileVouchers.expired')
                                                                    : `${t('profileVouchers.expirationPrefix')} ${new Date(voucher.voucherID.expirationDate).toLocaleDateString()}`
                                                        }
                                                    </p>
                                                </div>
                                                <div className={styles.voucherInfo}>
                                                    <p className={styles.voucherCode}>{voucher.voucherID.code}</p>
                                                    <p className={styles.discountValue}>
                                                        {voucher.voucherID.discountType === 'percentage'
                                                            ? t('profileVouchers.percentageOff', {value: voucher.voucherID.discountValue})
                                                            : t('profileVouchers.euroOff', {value: voucher.voucherID.discountValue})
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.mobileContainer}>
                    <HomepageNavbar />
                    <div>
                        <h1 className={styles.mobileTitle}>{t('profileVouchers.vouchers')}</h1>
                    </div>

                    <div className={styles.voucherTitle}>
                        <div className={styles.voucherSectionHeaderMain}>
                            <span className={styles.voucherCount}>{t('profileVouchers.voucherCount', {count: userVouchers.length})}</span>
                        </div>
                        <span className={styles.addVoucherButton} onClick={() => setShowAddVoucher(true)}>
                            {t('profileVouchers.addVoucher')}
                        </span>
                    </div>

                    {!loading && userVouchers.length === 0 && (
                        <div className={styles.noVouchersContainer}>
                            <div className={styles.noVouchersCircle}>
                                <h2 className={styles.noVouchersHeader}>{t('profileVouchers.noVouchersYet')}</h2>
                                <p className={styles.noVouchersP}>{t('profileVouchers.readyToReveal')}</p>
                                <button className={styles.addVoucherButtonEmpty} onClick={() => setShowAddVoucher(true)}>
                                    {t('profileVouchers.addVoucherButton')}
                                </button>
                            </div>
                        </div>
                    )}

                    {lastVoucher && lastVoucher.voucherID && (
                        <div className={styles.lastVoucherSection}>
                            <h3 className={styles.sectionTitle}>{t('profileVouchers.lastVouchers')}</h3>
                            <div className={`${styles.voucherCard} ${styles.lastVoucherCard}`}
                                style={getBackgroundStyle(lastVoucher.voucherID.imageUrl)}
                                onClick={() => handleCardClick(lastVoucher)}>
                                <div className={styles.expirationDateContainer}>
                                    <p className={styles.expirationDate}>
                                        {lastVoucher.status === 'used'
                                            ? t('profileVouchers.used')
                                            : !lastVoucher.voucherID.active
                                                ? t('profileVouchers.notActive')
                                                : new Date(lastVoucher.voucherID.expirationDate) < new Date()
                                                    ? t('profileVouchers.expired')
                                                    : `${t('profileVouchers.expirationPrefix')} ${new Date(lastVoucher.voucherID.expirationDate).toLocaleDateString()}`
                                        }
                                    </p>
                                </div>
                                <div className={styles.voucherInfo}>
                                    <p className={styles.voucherCode}>{lastVoucher.voucherID.code}</p>
                                    <p className={styles.discountValue}>
                                        {lastVoucher.voucherID.discountType === 'percentage'
                                            ? t('profileVouchers.percentageOff', {value: lastVoucher.voucherID.discountValue})
                                            : t('profileVouchers.euroOff', {value: lastVoucher.voucherID.discountValue})
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {allVouchers.length > 0 && (
                        <>
                            <h3 className={styles.sectionTitle}>{t('profileVouchers.allVouchers')}</h3>
                            <div className={styles.voucherList}>
                                {allVouchers.map((voucher) => (
                                    <div key={voucher._id}
                                        className={`${styles.voucherCard} ${styles.regularVoucherCard}`}
                                        style={getBackgroundStyle(voucher.voucherID.imageUrl)}
                                        onClick={() => handleCardClick(voucher)}>
                                        <div className={styles.expirationDateContainer}>
                                            <p className={styles.expirationDate}>
                                                {voucher.status === 'used'
                                                    ? t('profileVouchers.used')
                                                    : !voucher.voucherID.active
                                                        ? t('profileVouchers.notActive')
                                                        : new Date(voucher.voucherID.expirationDate) < new Date()
                                                            ? t('profileVouchers.expired')
                                                            : `${t('profileVouchers.expirationPrefix')} ${new Date(voucher.voucherID.expirationDate).toLocaleDateString()}`
                                                }
                                            </p>
                                        </div>
                                        <div className={styles.voucherInfo}>
                                            <p className={styles.voucherCode}>{voucher.voucherID.code}</p>
                                            <p className={styles.discountValue}>
                                                {voucher.voucherID.discountType === 'percentage'
                                                    ? t('profileVouchers.percentageOff', {value: voucher.voucherID.discountValue})
                                                    : t('profileVouchers.euroOff', {value: voucher.voucherID.discountValue})
                                                }
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            )}

            <Modal t={t} isOpen={showAddVoucher} onClose={() => setShowAddVoucher(false)}>
                <form onSubmit={handleAddVoucher} className={styles.voucherForm}>
                    <label className={styles.codePopHeader}>{t('profileVouchers.voucherCode')}</label>
                    <input
                        type="text"
                        value={voucherCode}
                        onChange={(e) => setVoucherCode(e.target.value)}
                        placeholder= {t('profileVouchers.enterVoucherCode')}
                        className={styles.voucherInput}
                    />
                    <button type="submit" className={styles.addButton}>
                        {t('common.add')}
                    </button>
                    {error && <p className={styles.errorMessage}>{error}</p>}
                </form>
            </Modal>

            {selectedVoucher && (
                <SelectedVoucherModal
                    isOpen={!!selectedVoucher}
                    onClose={() => setSelectedVoucher(null)}
                    voucher={selectedVoucher}
                    onVoucherDeleted={fetchUserVouchers}
                />
            )}
        </>
    );
};
