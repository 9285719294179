import styles from './style.module.scss';
import { useLocation } from 'react-router-dom';
import HeaderBackgroundImage from 'assets/images/101.jpg'; // Default background image
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import scrollDownVector from '../../../assets/icons/whiteVector.svg';
import { useTranslation } from 'react-i18next';

interface ShopBrandBestsellerProps {
    backgroundImage?: string;
    brandname?: string;
    customTitle?: string;
    headerBackground?: string;
    headerAdvice?: string;
    headerRightText?: string;
}

export const ShopBrandBestseller = ({
    backgroundImage = HeaderBackgroundImage,
    brandname,
    customTitle,
    headerBackground,
    headerAdvice,
    headerRightText
}: ShopBrandBestsellerProps) => {
    const location = useLocation();
    const isBrandPage = location.pathname.includes('/brand/');
    const isShopPageHeader = location.pathname.includes('/shop');
    const { t } = useTranslation()

    const effectiveBackgroundImage = headerBackground || backgroundImage;
    const effectiveHeaderAdvice = isBrandPage ? (customTitle || t('brands.brandsHomePage.customTitle')) : (headerAdvice || t('brands.brandsHomePage.headerAdvice'));
    const effectiveHeaderRightText = isBrandPage ? (headerAdvice || t('brands.brandsHomePage.headerAdvice')) : (headerRightText || t('brands.brandsHomePage.headerRightText'))

    const scrollDownSection = (
        <div className={styles.scrollDownSection}>
            <div className={styles.imageWrapper}>
                <img
                    src={scrollDownVector}
                    alt="scroll down"
                    className={styles.scrollDownVector}
                />
                <p className={styles.overlayText}>{t('common.scrollDown')}</p>
            </div>
        </div>
    );

    return (
        <div
            className={isShopPageHeader ? styles.shopHeaderBlockPage : styles.shopHeaderBlock}
            style={{
                backgroundImage: `url(${effectiveBackgroundImage})`,
            }}
        >

            <HomepageNavbar />
            <div className={styles.brandShopHeaderBlockContent}>
                <div className={styles.shopLogoContainer}>
                    {isBrandPage && (
                        <div className={styles.BrandHeaderAdviceContainer}>
                            <p className={styles.BrandHeaderAdvice}>{effectiveHeaderAdvice}</p>
                        </div>
                    )}
                </div>
                <div>
                    <div className={styles.brandHeaderRightTextContainer}>
                        <p className={styles.brandHeaderRightText}>{effectiveHeaderRightText}</p>
                    </div>
                </div>
                <div className={styles.ShopHeaderTitleContainer}>
                    {brandname && (
                        <img className={styles.ShopHeaderTitle} src={brandname} alt="brandname" />
                    )}
                </div>
            </div>
            <div className={styles.brandScrollDownSection}>

                {scrollDownSection}
            </div>
        </div>
    );
};
