import React, { useState, useEffect, useCallback } from 'react';
import userService from '../../../../../service/UserService';
import { userAtom } from '../../../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { DeliveryService } from '../../../../../types/TInterfaces';
import deliveryApi from '../../../../../service/API/deliveryApi';
import { debounce } from 'utils/debounceUtil';
import { isValidPhoneNumber } from 'react-phone-number-input';
import MapboxApi from 'service/API/mapboxApi';
import { MobileBillingForm } from './mobileBillingForm';
import { DesktopBillingForm } from './desktopForm';
import { useTranslation } from 'react-i18next';

// --- SUB-COMPONENTS (Desktop & Mobile) ---
type TAddress = {
    addressName: string;
    firstName: string;
    lastName: string;
    phone: string;
    country: string;
    city: string;
    street: string;
    postalCode: string;
    addressComplement: string;
    asDefault: boolean;
    type: string;
};

export const BillingForm: React.FC = () => {
    const { addressId } = useParams<{ addressId?: string }>();
    const [user, setUser] = useAtom(userAtom);
    const navigate = useNavigate();
    const {t} = useTranslation()

    const [address, setAddress] = useState<TAddress>({
        addressName: '',
        firstName: '',
        lastName: '',
        phone: '',
        country: '',
        city: '',
        street: '',
        postalCode: '',
        addressComplement: '',
        asDefault: false,
        type: 'billing',
    });

    const [errors, setErrors] = useState<Record<string, string>>({
        addressName: '',
        firstName: '',
        lastName: '',
        phone: '',
        street: '',
        city: '',
        postalCode: '',
        addressComplement: '',
    });

    const [deliveryCountries, setDeliveryCountries] = useState<
        Pick<DeliveryService, 'country'>[]
    >([]);

    // Mobile or desktop
    const isMobile = window.innerWidth < 768;

    // For "add address" vs. "edit address" toggle
    const [addClicked, setAddClicked] = useState(false);

    // Mapbox suggestions
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    // We can define a default phone country
    const [countryForPhoneInput] = useState('FR');

    // --- EFFECTS ---
    useEffect(() => {
        // Fetch available countries
        const getDeliveryCountries = async () => {
            const { uniqueCountries } = await deliveryApi.fetchDeliveryServices();
            setDeliveryCountries(uniqueCountries);
        };
        getDeliveryCountries();
    }, []);

    useEffect(() => {
        const debouncedFetch = debounce(async (value: string) => {
            if (value.trim() === '') {
                setSuggestions([]);
                return;
            }
            try {
                const result = await MapboxApi.getAddressSuggestions(value);
                setSuggestions(result.map((item) => item.place_name) || []);
            } catch (error) {
                console.error('Failed to fetch address suggestions:', error);
            }
        }, 300);

        debouncedFetch(inputValue);
    }, [inputValue]);

    // If editing address, load existing data
    useEffect(() => {
        if (addressId && user) {
            const addressToEdit = user.addresses.find(
                (addr: any) => addr._id === addressId
            );
            if (addressToEdit) {
                const isFirstAddress = user.addresses.indexOf(addressToEdit) === 0;
                setAddress({
                    addressName: addressToEdit.addressName || '',
                    firstName: addressToEdit.firstName || '',
                    lastName: addressToEdit.lastName || '',
                    phone: addressToEdit.phoneNumber?.number || '',
                    country: addressToEdit.country || '',
                    city: addressToEdit.city || '',
                    street: addressToEdit.street || '',
                    postalCode: addressToEdit.postalCode || '',
                    addressComplement: addressToEdit.addressComplement || '',
                    asDefault: isFirstAddress ? true : addressToEdit.asDefault || false,
                    type: 'billing',
                });
                setInputValue(addressToEdit.street || '');
            }
        }
    }, [addressId, user]);

    // --- HANDLERS ---
    const handleAdd = () => setAddClicked(true);
    const handleBack = () => navigate(-1);
    const handleCancel = () => navigate('/profile');

    const handleDelete = async (id: string) => {
        if (!user) {
            console.error('User not found. Please log in to delete an address.');
            return;
        }
        const result = await userService.deleteDeliveryAddress(user, setUser, id, navigate);
        if (result.success) {
            navigate(-1);
        } else {
            alert(result.message);
        }
    };

    const handlePhoneChange = (value: string | undefined) => {
        const phoneValue = value || '';
        // Validate phone on every change
        if (!phoneValue) {
            setErrors((prev) => ({ ...prev, phone: t('profileSettings.errors.phoneRequired') }));
        } else if (!isValidPhoneNumber(phoneValue)) {
            setErrors((prev) => ({ ...prev, phone: t('profileSettings.errors.invalidPhone') }));
        } else {
            setErrors((prev) => ({ ...prev, phone: '' }));
        }
        setAddress((prevAddress) => ({ ...prevAddress, phone: phoneValue }));
    };

    const validatePostalCode = (postalCode: string) => {
        return /^[0-9]{1,12}$/.test(postalCode); // Only numbers, up to 12 digits
    };

    const validateMaxLength = (value: string, maxLength: number) => {
        return value.length <= maxLength;
    };

    const validateFields = () => {
        if (!address) return false; // If address somehow is null, just return false

        // 1) Check if required fields are non-empty
        const requiredFieldsFilled =
            (address.addressName ?? '').trim() &&
            (address.firstName ?? '').trim() &&
            (address.lastName ?? '').trim() &&
            (address.phone ?? '').trim() &&
            (address.street ?? '').trim() &&
            (address.city ?? '').trim() &&
            (address.postalCode ?? '').trim();

        if (!requiredFieldsFilled) {
            return false;
        }

        // 2) Ensure phone is valid
        if (!isValidPhoneNumber(address.phone ?? '')) {
            return false;
        }

        // 3) Validate postal code
        if (!validatePostalCode(address.postalCode ?? '')) {
            return false;
        }

        // 4) Check for any existing error messages
        const hasErrors = Object.values(errors).some((error) => error !== '');
        if (hasErrors) {
            return false;
        }

        return true;
    };

    // Update input changes
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { name, value, type } = e.target;
            const isCheckbox = type === 'checkbox';
            let errorMsg = '';

            // Basic maxLength
            if (name !== 'asDefault' && !validateMaxLength(value, 38)) {
                errorMsg = t('profileSettings.errors.maxLength');
            }

            // Postal code check
            if (name === 'postalCode' && value) {
                if (!validatePostalCode(value)) {
                    errorMsg = t('profileSettings.errors.postalCodeFormat');
                }
            }

            setErrors((prev) => ({ ...prev, [name]: errorMsg }));
            setAddress((prev) => ({
                ...prev,
                [name]: isCheckbox ? (e.target as HTMLInputElement).checked : value,
            }));

            // If the user is typing into the "street" field (or a separate inputValue?), keep them in sync:
            if (name === 'street') {
                setInputValue(value);
            }
        },
        []
    );

    const handleSuggestionClick = (suggestion: string) => {
        const { street, postalCode, city } = splitSuggestion(suggestion);
        setAddress((prev) => ({ ...prev, street, postalCode, city }));
        setInputValue(suggestion);
        setShowSuggestions(false);
    };

    function splitSuggestion(suggestion: string) {

        const parts = suggestion.split(',');


        const streetPart = parts[0]?.trim() ?? '';
        const secondPart = parts[1]?.trim() ?? '';

        // Attempt to parse something like "75002 Paris" -> postalCode = "75002", city = "Paris"
        let postalCode = '';
        let city = secondPart;

        // If "secondPart" starts with digits, treat them as ZIP and the remainder as city
        const match = secondPart.match(/^(\d+)\s*(.*)/);
        if (match) {
            postalCode = match[1];
            city = match[2].trim();
        }

        // Return the pieces we want
        return {
            street: streetPart,
            postalCode,
            city,
        };
    }

    const handleInputFocus = () => setShowSuggestions(true);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!user) {
            console.error('User not found. Please log in to add an address.');
            return;
        }

        if (!validateFields()) {
            alert(t('profileSettings.errors.fillRequired'));
            return;
        }

        try {
            if (addressId) {
                // Update existing
                const result = await userService.updateDeliveryAddress(
                    user,
                    setUser,
                    address,
                    addressId,
                    navigate
                );
                if (result?.success) {
                    const userDataResult = await userService.getUserData(user, setUser);
                    if (!userDataResult.success) {
                        console.error('Failed to fetch updated user data');
                    }
                    clearFields();
                    navigate(-1);
                } else {
                    console.error(`Failed to update address: ${result?.message}`);
                    alert(`${t('profileSettings.errors.updateFailed')} ${result?.message}`);
                }
            } else {
                // Add new
                const result = await userService.addDeliveryAddress(user, setUser, address, navigate,);
                if (result.success) {
                    const userDataResult = await userService.getUserData(user, setUser);
                    if (!userDataResult.success) {
                        console.error('Failed to fetch updated user data');
                    }
                    clearFields();
                    navigate(-1);
                } else {
                    console.error(`Failed to add address: ${result.message}`);
                    alert(`${t('profileSettings.errors.addFailed')} ${result.message}`);
                }
            }
        } catch (error) {
            console.error('An error occurred while saving the address:', error);
            alert(t('profileSettings.billingForm.errors.saveFailed'));
        }
    };

    const clearFields = () => {
        setAddress({
            addressName: '',
            firstName: '',
            lastName: '',
            phone: '',
            country: '',
            city: '',
            street: '',
            postalCode: '',
            addressComplement: '',
            asDefault: false,
            type: 'billing',
        });
        setErrors({
            addressName: '',
            firstName: '',
            lastName: '',
            phone: '',
            street: '',
            city: '',
            postalCode: '',
            addressComplement: '',
        });
        setInputValue('');
    };

    // --- RENDER ---
    return isMobile ? (
        <MobileBillingForm
            address={address}
            errors={errors}
            suggestions={suggestions}
            showSuggestions={showSuggestions}
            inputValue={inputValue}
            deliveryCountries={deliveryCountries}
            handleBack={handleBack}
            handleDelete={handleDelete}
            handleAdd={handleAdd}
            handleChange={handleChange}
            handlePhoneChange={handlePhoneChange}
            handleSuggestionClick={handleSuggestionClick}
            handleInputFocus={handleInputFocus}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            addressId={addressId}
            validateFields={validateFields}
            addClicked={addClicked}
            user={user}
            countryForPhoneInput={countryForPhoneInput}
        />
    ) : (
        <DesktopBillingForm
            address={address}
            errors={errors}
            suggestions={suggestions}
            showSuggestions={showSuggestions}
            inputValue={inputValue}
            deliveryCountries={deliveryCountries}
            handleBack={handleBack}
            handleDelete={handleDelete}
            handleAdd={handleAdd}
            handleChange={handleChange}
            handlePhoneChange={handlePhoneChange}
            handleSuggestionClick={handleSuggestionClick}
            handleInputFocus={handleInputFocus}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            addressId={addressId}
            validateFields={validateFields}
            addClicked={addClicked}
            user={user}
            countryForPhoneInput={countryForPhoneInput}
        />
    );
};