import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useNotionDossiers, useNotionArticles, useArticlesByDossier } from '../../../hooks/useNotionArticles';
import './style.scss';
import { NotionArticle } from 'hooks/useNotionArticles.types';
import emptyFav from 'assets/icons/whiteFavIcone.svg';
import filledFav from 'assets/icons/whitFullFavIcon.svg';
import UserApi from 'service/API/UserApi';
import articleApi from 'service/API/articleApi';
import { LoginPopup } from 'components/popups/loginPopUp/loginPopup';
import { useFetchFavoriteArticles } from 'hooks/fetchFavoritesArticles';
import { useFavoriteHandlerArticle } from '../../../utils/faveoritUtil';
import { Feed } from '../Feed';
import { FeedPageNavbar } from 'components/navbars/FeedPageNavbar/FeedPageNavbar';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

export const DossiersList: React.FC = () => {
    const { dossierId } = useParams<{ dossierId: string }>();
    const { data: dossiers, isLoading: dossiersLoading, error: dossiersError } = useNotionDossiers();
    const { data: articlesId, isLoading: articlesLoading, error: articlesError } = useArticlesByDossier(dossierId!);
    const { favorites, setFavorites, handleFavoriteToggle } = useFavoriteHandlerArticle(UserApi);
    const articlesContainerRef = useRef<HTMLDivElement>(null);
    const {t} = useTranslation()
    const isMobile = window.innerWidth < 440;
    // State that determines whether to hide the navbar.
    const [hideNavbar, setHideNavbar] = useState(false);
    const[articles, setArticles] = useState<any>()
    // To compare scroll positions.
    const [prevScrollTop, setPrevScrollTop] = useState(0);
    //TODO: get currentLanguage from context

    useFetchFavoriteArticles(setFavorites);

    useEffect(()=>{
        const getArticles = async () => {
            try {
                const response = await articleApi.getArticlesByIds(articlesId)
                setArticles(response)
            } catch (error) {
                console.log('Error fetching articles by ids :', error)
            } 

        }
        if(articlesId){
            getArticles()
        }
        
    },[articlesId])

    // Attach a scroll event on the articles container.
    useEffect(() => {
        const container = articlesContainerRef.current;
        if (!container) return;

        const handleScroll = () => {
            const currentScrollTop = container.scrollTop;

            // If scrolling down beyond 50 pixels, hide the navbar.
            if (currentScrollTop > prevScrollTop && currentScrollTop > 50) {
                setHideNavbar(true);
            } else if (currentScrollTop < prevScrollTop) {
                setHideNavbar(false);
            }
            setPrevScrollTop(currentScrollTop);
        };

        container.addEventListener('scroll', handleScroll);
        return () => container.removeEventListener('scroll', handleScroll);
    }, [prevScrollTop]);

    if (dossiersLoading || articlesLoading) return <div>{t('feed.dossiers.loading')}</div>;
    if (dossiersError || articlesError)
        return <div>{t('feed.dossiers.errorLoading')} {dossiersError?.message || articlesError?.message}</div>;

    const dossier = dossiers?.find((d) => d.id === dossierId);
    if (!dossier) return <div>{t('feed.dossiers.notFound')}</div>;
    
    const topViewerArticle = articles?.[0];

    const allArticles = articles?.filter(
        (article: NotionArticle) => article.id !== topViewerArticle?.id
    );

    return (
        <div className="dossier-list-container">
            {/* Pass the hideNavbar state into the FeedPageNavbar */}
            {/* NOTE: switched to HomepageNavbar to support translation */}
            {isMobile ? <HomepageNavbar /> : <HomepageNavbar  />}

            <div
                className="dossier-list__image"
                style={{ backgroundImage: `url(${dossier.properties.Image.files[0]?.file.url})` }}
            >
                <div className="dossier-list__image-overlay">
                    <h1 className="dossier-list__title">
                        {dossier.properties.Name.title[0]?.plain_text}
                    </h1>
                    <span className="dossier-list__article-count">
                        {articles?.length} {t('feed.articles')}
                    </span>
                </div>
            </div>

            <div className="dossier-list__articles-content">
                <div
                    className="dossier-list__articles-section"
                    ref={articlesContainerRef}
                    style={{ overflowY: 'auto', maxHeight: '100svh' }}
                >
                    <h2 className="dossier-list__articles-heading">{t('feed.dossiers.topView')}</h2>
                    {topViewerArticle && (
                        <DossierArticleCard
                            article={topViewerArticle}
                            className="dossier-list__top-viewer"
                            handleFavorite={handleFavoriteToggle}
                            favorites={favorites}
                        />
                    )}
                    <h2 className="dossier-list__articles-heading">{t('feed.dossiers.allArticles')}</h2>
                    <div className="dossier-list__articles-grid">
                        {allArticles?.map((article: NotionArticle) => (
                            <DossierArticleCard
                                key={article.id}
                                article={article}
                                handleFavorite={handleFavoriteToggle}
                                favorites={favorites}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const DossierArticleCard = React.memo(({
    article,
    className = '',
    handleFavorite,
    favorites,
}: {
    article: NotionArticle;
    className?: string;
    handleFavorite: (articleId: string) => void;
    favorites: { [key: string]: boolean };
}) => {
    const {t} = useTranslation()

    //TODO: get currentLanguage from context
    const {currentLanguage} = useCurrentLanguage();
    return (
        <Link to={`/feed/article/${article.id}`} className={`dossier-list__article-item ${className}`}>
            <div
                className="dossier-list__article-image"
                style={{ backgroundImage: `url(${article.translations[currentLanguage].properties.Image.files[0]?.url})` }}
            >
                <div
                    className="favArticle"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleFavorite(article._id);
                    }}
                >
                    <img
                        src={favorites[article._id] ? filledFav : emptyFav}
                        alt="favorite icon"
                        className="favArticle__icon"
                    />
                </div>
                <div className="dossier-list__article-info">
                    <h3 className="dossier-list__article-title">
                        {article.translations[currentLanguage].properties.Title.title[0]?.plain_text}
                    </h3>
                    <span className="dossier-list__article-category">
                        {article.translations[currentLanguage].properties.Categories.multi_select[0]?.name || t('articles.defaultCategory')}
                    </span>
                </div>
            </div>
        </Link>
    );
});
