import { useState, useEffect } from 'react';
import dynamicImagesApiInstance from "service/API/dynamicImagesApi";

export const useDynamicImages = () => {
    const [dynamicImages, setDynamicImages] = useState<any>([]);
    const [dynamicImagesIsLoading, setDynamicImagesIsLoading] = useState<boolean>(true);
    const [dynamicImagesError, setDynamicImagesError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                setDynamicImagesIsLoading(true);
                const data = await dynamicImagesApiInstance.getDynamicImages();
                setDynamicImages(data);
            } catch (error: any) {
                setDynamicImagesError(error);
            } finally {
                setDynamicImagesIsLoading(false);
            }
        };

        fetchImages();
    }, []);

    return { dynamicImages, dynamicImagesIsLoading, dynamicImagesError };
};
