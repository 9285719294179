import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MiniProductsDisplay.module.scss';
import productsApi from 'service/API/ProductsApi';
import { ShopProduct } from 'types/ShopInterfaces';
import selectedImg from '../../assets/icons/selectedMiniProd.svg';
import eggShape from '../../assets/icons/smalleggShape.svg'
import blackDot from '../../assets/icons/blackDot.svg'
import { useCurrentLanguage } from 'hooks/useLanguage';

interface Props {
    productId: string;
}

export const MiniProductsDisplay: React.FC<Props> = ({ productId }) => {
    const [products, setProducts] = useState<ShopProduct[]>([]);
    const navigate = useNavigate();
    const isMobile = window.innerWidth < 1360;
    const {currentLanguage} = useCurrentLanguage()

    useEffect(() => {
        const fetchSimilarProducts = async () => {
            try {
                const response = await productsApi.fetchSimilarProducts(productId);
                if (response && response.similarProducts) {
                    setProducts(response.similarProducts as ShopProduct[]);
                } else {
                    setProducts([]);
                }
            } catch (err: any) {
                console.error("Error fetching similar products:", err);
                setProducts([]);
            }
        };

        fetchSimilarProducts();
    }, [productId]);


    const handleProductClick = (productId: string) => {
        navigate(`/product/${productId}`)
    }

    const desktopLayout = (
        <div className={styles.mainContainer}>
            <div className={styles.allImgsContainer}>
                {products.map((product) => (
                    <div
                        className={styles.imgContainer}
                        onClick={() => handleProductClick(product._id)}                            >
                        <img
                            src={product.imgUrl}
                            alt={product.translations?.[currentLanguage]?.title}
                            className={styles.miniProductImg}
                        />
                        {productId === product._id && (
                            <img src={selectedImg} alt="Selected Product" className={styles.selectedImg} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    )

    const mobileLayout = (
        <div className={styles.mainContainer} style={{ height: products.length > 0 ? '110px' : '0' }}>
            <div className={styles.allImgsContainer}>
                {products.map((product) => (
                    <div
                        className={styles.imgContainer}
                        onClick={() => handleProductClick(product._id)}
                    >
                        <img
                            src={product.imgUrl}
                            alt={product.translations?.[currentLanguage]?.title}
                            className={styles.miniProductImg}
                        />
                        {productId === product._id && (
                            <>
                                <img src={eggShape} alt="Selected Product" className={styles.selectedImg} />
                                <img src={blackDot} alt="Black dot" className={styles.dotImg} />
                            </>
                        )}
                    </div>
                ))}
                <div className={styles.selectedBar}></div>
                <hr className={styles.horizontalLine} />
            </div>
        </div>
    )


    return (
        <>
            {(isMobile ? mobileLayout : desktopLayout)}
        </>
    );
};