export type IScanData = {
  type: 'IMAGE' | 'MANUAL';
  data: string[] | File; // string for manual input, File for image
};

export type ICommonConcern = {
  concern: string;
  level: string;
};

export type IIngredient = {
  _id: string;
  inci_name: string;
  functions: string[];
  cluster_name: string;
  commonConcerns: ICommonConcern[];
  description: string;
};

export type IProcessedData = {
  success: boolean;
  results: Iresult[];

};

export type Iresult = {
  original: string;
  cleaned: string;
  ingredient: IIngredient;
};

export enum ScanSteps {
  LANDING = 'LANDING',
  SCANNER = 'SCANNER',
  LOADING = 'LOADING',
  RESULTS = 'RESULTS',
}
