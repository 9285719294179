import styles from './style.module.scss';
import axios from 'axios';
import React, { useState } from 'react';
import SignUpBackgroudImage from '../../assets/images/headSectionBackground.jpg';
import blackLogo from '../../assets/icons/blackLogo.svg';
import { useAtom } from 'jotai';
import { userAtom } from '../../stores/UserProfile';
import globalService from '../../service/GlobalService';
import { config } from 'config';
import { validateEmail, validatePassword } from '../../utils/inputValidationUtil';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import userApi from 'service/API/UserApi';
import subscribedUserApi from 'service/API/subscribedUserApi';
import vIcon from '../../assets/images/vIcon.png';
import { setLocalStorageItem } from '../../utils/localStoragUtil';
import { useTranslation } from 'react-i18next';

export const SignUpPage = () => {
  const { t } = useTranslation();
  const [, setUser] = useAtom(userAtom);
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleNextStep = () => {
    const passwordInvalidError = t('signup.errors.passwordInvalid')
    // Step 1: Validate email format
    if (currentStep === 1) {
      if (!email || !validateEmail(email)) {
        setError(t('signup.errors.invalidEmail'));
        return; // Prevent moving to the next step
      }
    }

    // Step 2: Validate password match
    if (currentStep === 2) {
      if (!password || password.trim() === '') {
        setError(t('signup.errors.passwordRequired'));
        return; // Prevent moving to the next step
      }

      if (password !== confirmPassword) {
        setError(t('signup.errors.passwordsMismatch'));
        return; // Prevent moving to the next step
      }

      if (validatePassword(password, passwordInvalidError)) {
        setError(validatePassword(password, passwordInvalidError));
        return;
      }
    }

    setError(null);
    // Move to the next step if validations pass
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setError(null);

    if (validateEmail(value)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setError(validatePassword(newPassword, t('signup.errors.passwordInvalid')));
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (error) setError(null);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    if (error) setError(null);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate inputs before submitting
    if (!email || !validateEmail(email)) {
      setError(t('signup.errors.invalidEmail'));
      return;
    }

    if (!password || password.trim() === '') {
      setError(t('signup.errors.passwordRequired'));
      return;
    }

    if (password !== confirmPassword) {
      setError(t('signup.errors.passwordsMismatch'));
      return;
    }

    if (!name || name.trim() === '') {
      setError(t('signup.errors.nameRequired'));
      return;
    }

    if (!lastName || lastName.trim() === '') {
      setError(t('signup.errors.lastNameRequired'));
      return;
    }

    try {
      const response = await axios.post(`${config.REACT_APP_API_URL}/auth/register`, {
        data: {
          email,
          password,
          name,
          lastName,
        },
      });

      const { user, accessToken, refreshToken } = response.data;
      setLocalStorageItem('accessToken', accessToken);
      setLocalStorageItem('refreshToken', refreshToken);


      await globalService.saveUser(user, setUser);
      const isSubscribed = await subscribedUserApi.checkIfSubscribed(user.email);
      if (!isSubscribed) {
        await userApi.newsletterSubscribe(user.email);
      }
      window.location.href = '/spf-form';
    } catch (err: any) {
      if (err.response?.status === 401) {
        setError(t('signup.errors.emailRegistered'));
      } else {
        const errorMessage = err.response?.data?.error || t('signup.errors.registrationError');
        console.error('Registration error:', errorMessage);
        setError(errorMessage);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      if (currentStep < 3) {
        handleNextStep();
      } else {
        handleSubmit(e as unknown as React.FormEvent); // Type casting to match handleSubmit parameter
      }
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className={styles.signupInputGroups}>
            <input
              type="email"
              placeholder={t('signup.placeholders.email')}
              value={email}
              onChange={handleEmailChange}
              required
              onKeyDown={handleKeyDown} // Attach key down event
            />
            {isEmailValid && (
              <img
                src={vIcon}
                alt="Valid Email"
                className={styles.validIcon}
              />
            )}
          </div>
        );
      case 2:
        return (
          <>
            <div className={styles.signupInputGroups}>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder={t('signup.placeholders.password')}
                value={password}
                onChange={handlePasswordChange}
                required
                onKeyDown={handleKeyDown} // Attach key down event
              />
              <button
                type="button"
                className={styles.showPasswordBtn}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? t('common.hidePassword') : t('common.showPassword')}
              </button>
            </div>
            <div className={styles.signupInputGroups}>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder={t('signup.placeholders.confirmPassword')}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
                onKeyDown={handleKeyDown} // Attach key down event
              />
              <button
                type="button"
                className={styles.showConfirmPassword}
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? t('common.hidePassword') : t('common.showPassword')}
              </button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className={styles.signupInputGroups}>
              <input
                type="text"
                placeholder={t('signup.placeholders.name')}
                value={name}
                onChange={handleNameChange}
                required
                onKeyDown={handleKeyDown} // Attach key down event
              />
            </div>
            <div className={styles.signupInputGroups}>
              <input
                type="text"
                placeholder={t('signup.placeholders.lastName')}
                value={lastName}
                onChange={handleLastNameChange}
                required
                onKeyDown={handleKeyDown} // Attach key down event
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleStepNavigation = (step: number) => {
    if (step <= currentStep) {
      setCurrentStep(step);
    }
  };

  return (
    <>
      <div className={styles.signUpHeaderBlock} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
        <HomepageNavbar />
        <div className={styles.signUpContainer}>
          <div className={styles.headSection}>
            <img className={styles.olisLogo} alt="Group" src={blackLogo} />
            <h1 className={styles.signupTitle}>{t('signup.createAccount')}</h1>
            <div className={styles.stepIndicatorContainer}>
              <p className={styles.stepIndicator}>
                {Array.from({ length: 3 }, (_, index) => {
                  const stepNumber = index + 1;
                  const isActive = currentStep === stepNumber;

                  return (
                    <span
                      key={stepNumber}
                      className={`${styles.step} ${isActive ? styles.active : ''}`}
                      data-step-label={t('signup.step')}
                      onClick={() => handleStepNavigation(stepNumber)}
                    >
                      {`0${stepNumber}`}
                    </span>
                  );
                })}
              </p>
            </div>

          </div>
          <div className={styles.formContainer}>
            <form onSubmit={handleSubmit}>
              {renderStepContent()}
              {error &&
                <div className={styles.errorContainer}>
                  <p className={styles.errorMessage}>{error}</p>
                </div>
              }
              <button
                type={currentStep < 3 ? 'button' : 'submit'}
                className={styles.nextButton}
                onClick={currentStep < 3 ? handleNextStep : undefined}
                disabled={currentStep === 1 && !isEmailValid}
              >
                {currentStep < 3 ? t('signup.next') : t('signup.submit')}
              </button>
            </form>
          </div>

          <p className={styles.loginText}>
          {t('signup.alreadyHaveAccount')}{' '}
            <a href="/login" className={styles.loginLink}>
            {t('common.login')}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};


