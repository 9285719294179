import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './Dossiers.module.scss';
import { useNotionDossiers, useNotionArticles } from 'hooks/useNotionArticles';
import { useNavigate } from 'react-router-dom';
import { NotionArticle } from 'hooks/useNotionArticles.types';
import { Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

export const Dossiers: React.FC = () => {
  const { data: dossiers, isLoading, error } = useNotionDossiers();
  // const { data: articles } = useNotionArticles({ page: 1, limit: 90 });
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { currentLanguage } = useCurrentLanguage()
  const [isDragging, setIsDragging] = useState(false);

  
  // const filteredDossiersWithCount = dossiers
  //   ?.map((dossier) => {
  //     const relatedArticleIds = dossier.properties['📰 Articles']?.relation?.map(rel => rel.id) || [];
  //     console.log('dossier', dossier)
  //     console.log('relatedArtcilesIds', relatedArticleIds)
  //     const dossierArticles = articles?.filter((article: NotionArticle) =>
  //       relatedArticleIds.includes(article.id)
  //     );
  //     return {
  //       ...dossier,
  //       articleCount: dossierArticles?.length || 0,
  //     };
  //   })
  //   .filter((dossier) => dossier.articleCount > 0);

  const filteredDossiersWithCount = dossiers
    ?.map((dossier) => {
      const relatedArticleIds = dossier.properties['📰 Articles']?.relation?.map(rel => rel.id) || [];
      
      return {
        ...dossier,
        articleCount: relatedArticleIds?.length || 0,
      };
    })
    .filter((dossier) => dossier.articleCount > 0);

    

  const handleDossierClick = (dossierId: string) => {
    if (!isDragging) {
      navigate(`/feed/dossier/${dossierId}`);
    }
  };

  

  

  if (isLoading) {
    return <div>{t('feed.dossiers.loading')}</div>;
  }

  if (error) {
    return <div>{t('feed.dossiers.errorLoading')} {error.message}</div>;
  }

  return (
    <section className={styles.dossiersSection}>
      <div className={styles.dossiersHeader}>
        <h2>DOSSIERS</h2>
        <div className={styles.dossiersCount}>{filteredDossiersWithCount?.length || 0} {t('feed.dossiers.topics')}</div>
      </div>
      <Swiper
        modules={[Pagination]}
        slidesPerView={3} // Adjust based on your design
        spaceBetween={0} // Adjust spacing between slides
        loop={true} // Disable looping to prevent over-scroll
        resistanceRatio={0} // Remove resistance to prevent over-scroll
        watchOverflow={true} // Disable Swiper if not enough slides
        breakpoints={{
          320: {
            slidesPerView: 1.1,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 2.1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 3.2,
            spaceBetween: 0,
          },
        }}
        onTouchStart={() => setIsDragging(true)}
        onTouchEnd={() => setIsDragging(false)}
        className={styles.dossiersSwiper}
      >
        {filteredDossiersWithCount?.map((dossier) => (
          <SwiperSlide
            key={dossier.id}
            className={styles.dossierItem}
            onClick={() => handleDossierClick(dossier.id)}
          >
            <div
              className={styles.dossierBackground}
              style={{ backgroundImage: `url(${dossier.properties.Image.files[0]?.file.url})` }}
            >
              <div className={styles.dossierContent}>
                {currentLanguage === "en" ? (
                  <h3>{dossier.properties.Name.title[0].en.plain_text}</h3>
                ) : (
                  <h3>{dossier.properties.Name.title[0].fr.plain_text}</h3>
                )}
                <div className={styles.articleCount}>{dossier.articleCount} {t('feed.articles')}</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};
