import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import productsApi from '../../../../service/API/ProductsApi';
import blackdot from '../../../../assets/icons/blackDot.svg'
import Expand from '../expand/expand';
import _ from 'lodash';
import { SearchResult } from '../../../../types/search.types';
import { useMostSearchedProducts } from '../../../../hooks/mostSearched';
import SearchLoader from '../../../../components/animation/searchLoader/searchLoader';
import './style.scss';
import globalService from '../../../../service/GlobalService';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hooks/useLanguage';

interface SearchProps {
    isSearchActive: boolean;
    setIsSearchActive: (value: boolean) => void;
    isMobile: boolean;
    toggleVisibility: () => void;
    isMenuVisible?: boolean;
}

interface SearchResultsProps {
    results: any[];
    query: string;
    navigate: (path: string) => void;
    handleChange: (text: string) => void;
    setIsMenuExpanded: (value: boolean) => void;
    setViewExpandedMobile: (value: boolean) => void;
    isMobile: boolean;
}

const LoadingSkeleton = () => (
    <div className="menuContainerSuggestions">
        <SearchLoader />
    </div>
);

// Types
interface SearchResultItemProps {
    text: string;
    type: 'product' | 'active' | 'article' | 'brand' | 'produit' | 'marque' | 'actif';
    query: string;
    onClick: () => void;
}



// Helper function to escape special RegExp characters
const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

// Updated highlightMatch function with proper type checking
const highlightMatch = (text: string | undefined, query: string) => {
    // Add null check for text
    if (!text || !query) return text || '';

    const escapedQuery = escapeRegExp(query);
    const regex = new RegExp(`(${escapedQuery})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, i) =>
        regex.test(part) ? <strong key={i}>{part}</strong> : part
    );
};

// Individual result item component
const SearchResultItem: React.FC<SearchResultItemProps> = ({ text, type, query, onClick }) => (
    <div className="menuContainerSuggestionsListItem" onClick={onClick}>
        {highlightMatch(text, query)}
        <strong> | </strong> {type}
    </div>
);

// Main SearchResults component
const SearchResults: React.FC<SearchResultsProps> = ({
    results,
    query,
    navigate,
    handleChange,
    setIsMenuExpanded,
    setViewExpandedMobile,
    isMobile
}) => {
    const { t } = useTranslation()
    const { currentLanguage } = useCurrentLanguage()
    const handleItemClick = (text: string, path?: string) => {
        if (isMobile) setViewExpandedMobile(true);
        else {
            handleChange(text);
            if (path) navigate(path);
            setIsMenuExpanded(true);
        }


    };

    return (
        <div className="menuContainerSuggestions">
            <p className="menuContainerSuggestionsTitle">{t('menu.searchFunction.suggestions')}</p>
            <div className="menuContainerSuggestionsList">
                {results.slice(0, 10).map((result: any, index: any) => (
                    <React.Fragment key={index}>
                        {result.type === 'product' && (
                            <SearchResultItem
                                text={result.translations?.[currentLanguage]?.title || result.title}
                                type={t('menu.searchFunction.SearchResultItem.product')}
                                query={query}
                                onClick={() => handleItemClick(result.title, `/product/${result._id}`)}
                            />
                        )}

                        {result.type === 'active' && (
                            <SearchResultItem
                                text={result.active_name}
                                type={t('menu.searchFunction.SearchResultItem.active')}
                                query={query}
                                onClick={() => handleItemClick(result.active_name, `/feed/actives/${result._id}`)}
                            />
                        )}

                        {result.type === 'article' && (
                            <SearchResultItem
                                text={result.translations?.[currentLanguage].properties.Title.title[0].plain_text}
                                type="article"
                                query={query}
                                onClick={() => handleItemClick(
                                    result.translations?.[currentLanguage].properties.Title.title[0].plain_text,
                                    `/feed/article/${result.id}`
                                )}
                            />
                        )}

                        {result.type === 'brand' && (
                            <SearchResultItem
                                text={result.brand}
                                type={t('menu.searchFunction.SearchResultItem.brand')}
                                query={query}
                                onClick={() => handleItemClick(result.brand, `/brand/${result.brand}`)}
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

const NoResults = () => {
    const { t } = useTranslation()
    return <div className="menuContainerSuggestions">
        <p className="menuContainerSuggestionsTitle">{t('menu.searchFunction.noSuggestions')}</p>
    </div>
}

const Search: React.FC<SearchProps> = ({ isSearchActive, setIsSearchActive, isMobile, toggleVisibility, isMenuVisible }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);

    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const [searchHistory, setSearchHistory] = useState<string[]>([]);
    const [showLastSearch, setShowLastSearch] = useState(false);
    const [showFullSearchHistory, setShowFullSearchHistory] = useState(false);
    const [viewExpandedMobile, setViewExpandedMobile] = useState(false);
    const [userInput, setUserInput] = useState('');
    const { mostSearchedProducts } = useMostSearchedProducts();
    const [loading, setLoading] = useState(false);
    const [allArticlesResults, setAllArticlesResults] = useState([]);
    const [allProductsResults, setAllProductsResults] = useState([]);
    const [allActivesResults, setAllActivesResults] = useState([]);
    const navigate = useNavigate();
    const SearchContainerRef = useRef<HTMLDivElement | null>(null);
    const expandRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation()
    const { currentLanguage } = useCurrentLanguage()


    useEffect(() => {
        if (setShowSuggestions && searchQuery.length > 0) {
            setShowSuggestions(true);

        } else {
            setShowSuggestions(false);
        }
    }, [searchQuery]);

    useEffect(() => {
        const storedSearchHistory = globalService.getSearchHistory();
        setSearchHistory(storedSearchHistory);
    }, []);

    useEffect(() => {
        if (searchHistory.length > 0) {
            setShowLastSearch(true);
        } else {
            setShowLastSearch(false);
        }
    }, [searchHistory]);

    const handleSeeAll = () => {
        setShowFullSearchHistory(prevState => {
            setShowLastSearch(!prevState);
            return !prevState;
        });
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const inputValue = (e.target as HTMLInputElement).value;
            const updatedHistory = globalService.updateSearchHistory(inputValue);
            // the timeout is for user experience, it's not necessary but it's a nice touch
            setTimeout(() => {
                setSearchHistory(updatedHistory);
            }, 900);
        }
    };

    const handleChange = useCallback((query: string) => {
        setSearchQuery(query);
    }, []);


    const getAllProductsandArticles = async (results: any) => {
        const data = results?.data;
        if (data) {
            if (data?.articles && data?.articles.length > 0) {
                setAllArticlesResults(data?.articles);
            } if (data?.products && data?.products.length > 0) {
                setAllProductsResults(data?.products);
            }
            if (data?.actives && data?.actives.length > 0) {
                setAllActivesResults(data?.actives);
            }

            if (!isMenuExpanded) setIsMenuExpanded(true);
        }

    }

    useEffect(() => {
        if (searchResults && searchResults.length > 0) {
            setIsMenuExpanded(true);
        } else {
            setAllArticlesResults([]);
            setAllProductsResults([]);
            setIsMenuExpanded(false);
        }
    }, [searchResults]);

    const search = async (query: string) => {

        if (query.length > 0) {
            setLoading(true);
            const results = await productsApi.searchByNameInciArticle(query);
            const filteredResults = results.success && results.data && results.data.suggestions
                ? results.data.suggestions.filter((product: any) => {
                    const translation = product.translations?.[currentLanguage]; // Get correct translation (en/fr)
                    const title = translation?.properties?.Title?.title?.[0]?.plain_text; // Extract title safely
                    const hasValidTitle = title && title.trim() !== ""; // Ensure title isn't empty
                    return hasValidTitle || product.title || product.active_name || product.brand;
                })
                : [];
            setSearchResults(filteredResults as SearchResult[]);
            setLoading(false);
            getAllProductsandArticles(results);
        } else {
            setSearchResults([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (searchQuery && searchQuery.length > 0) {
            search(searchQuery);
        } else {
            setSearchResults([]);
            setLoading(false);
        }
    }, [searchQuery]);

    const debouncedHandleChange = useCallback(
        _.debounce((value: string) => {
            handleChange(value);
        }, 1000),
        [handleChange]
    );

    const returnMostSearchedProducts = () => {


        if (mostSearchedProducts && mostSearchedProducts.length > 0) {
            return (
                <div className={`menuContainerMostSearchBar ${showFullSearchHistory ? 'expanded' : ''}`} >
                    <div className="seeAll-mostSearched">
                        <p className="menuContainerMostSearchedText">{t('menu.searchFunction.mostSearched')}</p>

                        <p className="menuContainerMostSearchedSeeAll" onClick={handleSeeAll}>
                            {showFullSearchHistory ? t('menu.searchFunction.seeLess') : t('menu.searchFunction.seeAll')}
                        </p>
                    </div>


                    <div className="menuContainerMostSearchedList">
                        {mostSearchedProducts?.slice(0, showFullSearchHistory ? mostSearchedProducts?.length > 6 ? 6 : mostSearchedProducts?.length : mostSearchedProducts?.length > 3 ? 3 : mostSearchedProducts?.length).map((product: any, index: any) => {
                            return (
                                <li
                                    key={index}
                                    className="menuContainerMostSearchedItem"
                                    onClick={() => {
                                        navigate(`/product/${product._id}`);
                                        toggleVisibility();
                                    }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img className="menuContainerMostSearchedItemImg" src={product.imgUrl} alt={product.title} />
                                    </div>
                                    <div className="menuContainerMostSearchedItemTextContainer">
                                        <p className="menuContainerMostSearchedItemBrand">{product.brand}</p>
                                        <p className="menuContainerMostSearchedItemTitle">
                                            {(product?.translations?.[currentLanguage]?.title?.length ?? 0) > 20
                                                ? `${product.translations?.[currentLanguage]?.title.substring(0, 17)}...`
                                                : product.translations?.[currentLanguage]?.title || product.title}
                                        </p>
                                        <p className="menuContainerMostSearchedItemPrice">€{product.price.toFixed(2)} | ({product.quantity} ml)</p>
                                    </div>
                                </li>
                            )
                        })}
                    </div>

                </div>
            )
        }
    }

    const getSuggestionsResults = () => {
        if (loading) return <LoadingSkeleton />;
        if (searchResults?.length > 0) return (
            <SearchResults
                results={searchResults}
                query={searchQuery}
                navigate={navigate}
                handleChange={handleChange}
                setIsMenuExpanded={setIsMenuExpanded}
                setViewExpandedMobile={setViewExpandedMobile}
                isMobile={isMobile}
            />
        );
        return <NoResults />;
    };

    const updateUserInput = (value: string) => {
        setUserInput(value);
        setSearchResults([])
        setLoading(true);
        debouncedHandleChange(value);
    }

    const returnSocialMedia = () => {
        return (
            <div className='socialMediaContainer'>
                <Link className="socialMediaContainerItem" to="https://www.instagram.com/olis.lab" target="_blank" rel="noopener noreferrer">Instagram</Link>
                <Link className="socialMediaContainerItem" to="https://www.tiktok.com/@olis_lab" target="_blank" rel="noopener noreferrer">TikTok</Link>
                <Link className="socialMediaContainerItem" to="https://www.linkedin.com/company/olis-lab" target="_blank" rel="noopener noreferrer">LinkedIn</Link>
            </div>
        )
    }



    const lastSearchContainer = () => {
        return (
            <div className='menuContainerLastSearchContainer'>
                <p className="menuContainerLastSearchText">LATEST SEARCHES</p>
                <ul className="menuContainerLastSearchList">
                    {searchHistory.map((search, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="menuContainerLastSearchItem" onClick={() => {
                                handleChange(search);
                                setUserInput(search);
                                //setIsMenuExpanded(true); // Expand the menu when a search item is clicked
                            }} style={{ cursor: 'pointer' }}>
                                {search}
                                <button className="menuContainerSearchDeleteButton"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering the span's onClick
                                        const updatedSearchHistory = searchHistory.filter((item) => item !== search);
                                        setSearchHistory(updatedSearchHistory);
                                        globalService.saveSearchHistory(updatedSearchHistory);
                                    }}
                                    style={{ cursor: 'pointer', border: 'none', background: 'transparent' }}
                                >
                                    x
                                </button>
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    const changeViewExpandedMobile = () => {
        if (isMobile) {
            return (
                <Expand articles={allArticlesResults} products={allProductsResults} actives={allActivesResults} searchQuery={searchQuery} onClose={() => setIsMenuExpanded(false)} />
            )
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isMenuExpanded) {
                if (
                    SearchContainerRef.current &&
                    !SearchContainerRef.current.contains(event.target as Node) &&
                    expandRef.current &&
                    !expandRef.current.contains(event.target as Node) && isSearchActive
                ) {
                    toggleVisibility();
                    setIsMenuExpanded(false);
                }
            } else {
                if (
                    SearchContainerRef.current &&
                    !SearchContainerRef.current.contains(event.target as Node) && isSearchActive
                ) {
                    setIsSearchActive(false)
                    if (isMenuVisible) {
                        toggleVisibility();
                    }
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSearchActive, toggleVisibility, isMenuExpanded]);

    return (
        <div className="searchContainer">
            <div className="menuContainerSearchBar" style={{ borderRadius: isMobile ? "0" : isMenuExpanded ? "10px 0 0 10px" : "10px" }} ref={SearchContainerRef}>
                <div className='menuSearchContainer'>
                    <div className="menuContainerSearchBarHeader">
                        <button className="menuBacktoMenuButton" onClick={() => setIsSearchActive(false)}>
                            {t('menu.backToMenu')}
                        </button>

                        <button onClick={toggleVisibility} className="menuCloseButton" >
                            <img src={blackdot} alt="blackdot" className="menuContainerCloseIcon" />
                            <p className="menuContainerCloseText" style={{ marginLeft: '10px' }}>{t('common.close')}</p>
                        </button>
                    </div>

                    <div className="menuContainerSearchBarBody">
                        <p className="menuTitleSearchbar">{t('menu.search')}</p>

                        <div className="menuContainerSearchBarInputContainer">
                            <input
                                type="text"
                                placeholder={t('menu.searchFunction.searchPlaceholder')}
                                value={userInput}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    updateUserInput(value);
                                    if (value.trim() === '') {
                                        setUserInput(''); // Clear input immediately
                                        debouncedHandleChange.cancel(); // Cancel any pending debounced calls
                                        handleChange(''); // Call handleChange immediately for clearing
                                        setViewExpandedMobile(false);
                                    }
                                    if (isMobile) {
                                        setViewExpandedMobile(false);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                                className="searchBarInput"
                            />


                            <button
                                className="searchClearButton"
                                onClick={() => {
                                    setUserInput(''); // Clear input immediately
                                    debouncedHandleChange.cancel(); // Cancel any pending debounced calls
                                    handleChange(''); // Call handleChange immediately for clearing
                                    setViewExpandedMobile(false);
                                }}
                            >
                                {t('common.delete')}
                            </button>
                        </div>

                    </div>

                    {
                        viewExpandedMobile ? changeViewExpandedMobile() :
                            showSuggestions ?
                                <div className='searchContainerSuggestions'>

                                    {getSuggestionsResults()}
                                    {returnSocialMedia()}
                                </div>
                                :
                                <>
                                    {showLastSearch && lastSearchContainer()}
                                    {returnMostSearchedProducts()}
                                </>
                    }


                </div>
            </div>


            <div className='menuExpandContainer'>
                {isMenuExpanded && !isMobile && <Expand articles={allArticlesResults} products={allProductsResults} actives={allActivesResults} searchQuery={searchQuery} onClose={() => setIsMenuExpanded(false)} ref={expandRef} />}
            </div>

        </div>
    );
};

export default Search;
//
