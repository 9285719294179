import axios from 'axios';
import { config } from 'config';

class DynamicImagesApi {
    private readonly TIMEOUT = 10000; // 10 seconds timeout
    private readonly MAX_RETRIES = 3;
    private readonly RETRY_DELAY = 1000; // 1 second

    private async retryRequest(fn: () => Promise<any>, retries: number = 0): Promise<any> {
        try {
            return await fn();
        } catch (error) {
            if (retries < this.MAX_RETRIES) {
                await new Promise(resolve => setTimeout(resolve, this.RETRY_DELAY));
                return this.retryRequest(fn, retries + 1);
            }
            throw error;
        }
    }

    async getDynamicImages(): Promise<any> {
        return this.retryRequest(async () => {
            try {
                const response = await axios.get(
                    `${config.REACT_APP_API_URL}/images/getimages`,
                    {
                        timeout: this.TIMEOUT,
                        headers: {
                            'Cache-Control': 'max-age=3600',
                        }
                    }
                );
                return response.data;
            } catch (error) {
                console.error('Error fetching dynamic images:', error);
                return [];
            }
        });
    }
}

// Create an instance of DynamicImagesApi
const dynamicImagesApiInstance = new DynamicImagesApi();

// Export the instance as the default export
export default dynamicImagesApiInstance;