import React, { useEffect, useState } from 'react';
import styles from './OrderPage.module.scss';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import { OrderInfo } from '../OrderInfo/OrderInfo';
import { OrderTrack } from '../OrderTrack/OrderTrack';
import { ProductCard } from '../OrderProducts/ProductCard';
import { OrderTotal } from '../OrderTotal/OrderTotal';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ProfileMenu } from 'pages/ProfilePage/ProfileMenu/ProfileMenu';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { IOrder } from 'types/TInterfaces';
import { getLocalStorageItem } from 'utils/localStoragUtil';
import { ProductLoader } from "pages/ProductDetails/ProductLoader";
import userApi from 'service/API/UserApi';
import { set } from 'mongoose';
import { useTranslation } from 'react-i18next';

export const OrderPage: React.FC = () => {
    const delivered = true;
    const [user, setUser] = useAtom(userAtom);
    const navigate = useNavigate();
    const location = useLocation();
    const [order, setOrder] = useState<IOrder | null>(null);
    const { id } = useParams<{ id: string }>();
    const isMobile = window.innerWidth <= 768;
    const isAnonymousUrl = location.pathname.includes('/order/');
    const {t} = useTranslation()

    // Searching for the specific order every 16 secs
    useEffect(() => {
        const fetchOrderById = () => {
            if (id) {
                const storedOrders = getLocalStorageItem("userOrders");

                if (storedOrders && Array.isArray(storedOrders)) {
                    const orders: IOrder[] = storedOrders.map((order: any) => ({
                        ...order,
                    }));

                    const foundOrder = orders.find((order) => order.logisticOrderId === id);

                    if (foundOrder) {
                        setOrder(foundOrder);
                    }
                }
            }
        };

        fetchOrderById();

        const intervalId = setInterval(() => {
            fetchOrderById();
        }, 16000);

        return () => clearInterval(intervalId);
    }, [id]);

    // Fetching the users orders from the database every 15 secs
    useEffect(() => {
        const fetchUserOrders = async () => {
            if (user) {
                await userApi.getUserOrders(user._id as string);
            }
        };

        fetchUserOrders();

        const intervalId = setInterval(() => {
            fetchUserOrders();
        }, 15000);

        return () => clearInterval(intervalId);
    }, [user]);

    // If its anonymous, fetch its order from the db every 15 secs
    useEffect(() => {
        const fetchOrder = async () => {
            if (isAnonymousUrl) {
                try {
                    const order: IOrder = await userApi.getAnonymousUserOrder(id as string);
                    setOrder(order);
                } catch (error) {
                    console.error("Failed to fetch order:", error);
                }
            }
        };

        fetchOrder();

        const intervalId = setInterval(() => {
            fetchOrder();
        }, 15000);

        return () => clearInterval(intervalId);
    }, [isAnonymousUrl, id]);


    const handleBack = () => {
        if (isAnonymousUrl) {
            navigate('/')
        } else {
            navigate(-1);
        }
    }

    const rebuyAll = () => {
        const skus: string[] = [];

        order?.products.forEach((product) => {
            if (product.sku) {
                skus.push(product.sku);
            }
        });
        userApi.rebuyAll(user?._id as string, skus, setUser);
    };

    const mobileLayout = (
        <>
            <div className={styles.mobileContainer}>
                <HomepageNavbar />

                <div className={styles.mobileHeaderSection}>
                    <h3 className={styles.mobileTitle}>{t('profile.myOrders.orderPage.myOrder')}</h3>
                </div>

                <div className={styles.mobileSubTitleContainer}>
                    <h3 className={styles.mobileSubTitle}>{t('profile.myOrders.orderPage.details')}</h3>
                </div>

                <OrderInfo order={order as IOrder} />
                <OrderTrack order={order as IOrder} />
                {order?.products.map((product) => (
                    <ProductCard product={product} />
                ))}
                <OrderTotal order={order as IOrder} />
            </div>
            {!isAnonymousUrl && (<div className={styles.rebuyAllMobileContainer}>
                <button className={styles.mobileRebuyAllBtn} onClick={rebuyAll}>{t('profile.myOrders.orderPage.rebuyAll')}</button>
            </div>)}
        </>
    )


    const desktopLayout = (
        <div className={styles.desktopContainer}>
            <HomepageNavbar />

            <div className={styles.topSection}>
                <div className={styles.menuTopSection}>
                    {/* PLACE HOLDER DONT REMOVE */}
                </div>
                <div className={styles.contentTopSection}>
                    <h3 className={styles.userName}>{user?.name}</h3>
                    <h3 className={styles.userName}>{user?.lastName}</h3>
                </div>
            </div>

            <div className={styles.bottomSection}>
                <div className={styles.menuSection}>
                    {!isAnonymousUrl && (<ProfileMenu />)}
                </div>
                <div className={styles.contentSection}>
                    <div className={styles.OrderPageHeadSection}>
                        <button className={styles.backBtn} onClick={handleBack}>{t('profile.myOrders.orderPage.backButton')}</button>
                        {delivered && !isAnonymousUrl && (
                            <button className={styles.rebuyBtn} onClick={rebuyAll}>{t('profile.myOrders.orderPage.rebuyAll')}</button>
                        )}
                        <h3 className={styles.OrderPageTitle}>{t('profile.myOrders.orderPage.details')}</h3>
                    </div>
                    <OrderInfo order={order as IOrder} />
                    <OrderTrack order={order as IOrder} />

                    {order?.products.map((product) => (
                        <ProductCard product={product} />
                    ))}

                    <OrderTotal order={order as IOrder} />
                </div>
            </div>
        </div>
    );

    return order ? (isMobile ? mobileLayout : desktopLayout) : null;
};
