import { getErrorMessage } from 'service/serviceFunctions';
import api from './apiConfig';
import { ShopProduct } from 'types/ShopInterfaces';

export default class articleApi {
    static async findByActiveName(activeName: string) {
        try {
            const response = await api.post('/articles/by-active-name', {
                params: {
                    activeName: activeName
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching article by active name:', error);
            return null;
        }
    }

    static async getArticlesByIds(ids: string[]) {
        try {
            const response = await api.post('/articles/articles-by-ids', { ids });
            return response.data;
        } catch (error) {
            console.error('Error fetching articles by ids:', error);
            return null;
        }
    }

    static async getArticlesByArrayOfIds(ids: string[]): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
        try {
            const response = await api.post('/articles/get-all-articles-by-ids', { ids });
            return { success: true, data: response.data.articles };
        } catch (error) {
            return { success: false, message: getErrorMessage(error) };
        }
    }
}
